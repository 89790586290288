import React, { memo } from 'react'
import IconText from './IconText'

const SUB_HEADER = [{
  type: 'News',
  isActive: true
}, {
  type: 'Sports',
  isActive: false
}, {
  type: 'calendar',
  isActive: false
}, {
  type: 'tvprogram',
  isActive: false
}, {
  type: 'firmy',
  isActive: false
}, {
  type: 'horoscope',
  isActive: false
}, {
  type: 'entertainment',
  isActive: false
}, {
  type: 'health',
  isActive: false
}, {
  type: 'lifestyle',
  isActive: false
}]

const SubHeader = memo(props => {
  const { currentPage } = props;

  return (
    <ul className="list-menu list-unstyled bounceInUp" data-wow-duration="1s" data-wow-delay="0.2s">
      {SUB_HEADER.map(item => <IconText selectMenu={currentPage} key={`subheader${item.type}`} {...item} />)}
      <li className="dropdown">
        <a href="" className="dropdown-toggle no-after" data-toggle="dropdown" aria-expanded="true">more...</a>
        <ul className="dropdown-menu list-unstyled">
          <li><a href="#">dropdown item</a></li>
          <li><a href="#">dropdown item</a></li>
        </ul>
      </li>
    </ul>
  )
})

export default SubHeader
