import React from 'react';
import styles from '../NewsStyles.module.css';
import { Link } from 'react-router-dom';
import classNames from "classnames"
import Images from '../../../../themes/images';
import { ReactComponent as IcEnvelope } from "../../../../assets/images/envelope.svg";
import { ReactComponent as IcLink } from "../../../../assets/images/icon-link.svg";

export const SocialIcons = () => {
  return (
    <div className={styles.SocialIcons}>
      <span className={styles.Share}>Share</span>
      <Link to='#'>
        <i className={classNames('icon-facebook', styles.Icon)} />
      </Link>
      <Link to='#'>
        <i className={classNames('icon-twiter', styles.Icon)} />
      </Link>
      <Link to='#'>
        <IcEnvelope alt='ic-envelope' fill="#344563" className={styles.Icon}/>
      </Link>
      <Link to='#'>
        <IcLink alt='ic-envelope' alt='ic-link' fill="#344563" className={styles.Icon}/>
      </Link>
    </div>
  );
}