import React from 'react';

const renderNode = ({ attributes, children, leaf }) => {
  switch (leaf['font-size']) {
    case 'very-large':
      return <font size={6} {...attributes}>{children}</font>
    case 'large':
      return <font size={4} {...attributes}>{children}</font>
    case 'normal':
      return <span {...attributes}>{children}</span>
    case 'small':
      return <font size={1} {...attributes}>{children}</font>
  }
}

const FontSizeNode = (props) => (
  <>
    {renderNode(props)}
  </>
)

export default FontSizeNode;