import {
  ADMIN,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constants';

const initialState = {
  articleList: {
    data: [],
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    loading: false,
    errors: null
  },
  articleDetail: {
    data: {},
    loading: false,
    errors: null
  },
};

const adminArticleReducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST(ADMIN.ARTICLE_ACTION.GET_LIST): {
			return {
				...state,
				articleList: {
          ...state.articleList,
          loading: true,
        },
			};
    }
		case SUCCESS(ADMIN.ARTICLE_ACTION.GET_LIST): {
      const { data } = action.payload;
			return {
				...state,
				articleList: {
          ...state.articleList,
          pages: data.pages,
          data: data.items,
          loading: false,
          errors: false,
        },
			};
    }
		case FAILURE(ADMIN.ARTICLE_ACTION.GET_LIST): {
			return {
				...state,
        articleList: {
          ...state.articleList,
          loading: false,
          errors: action.errors,
        },
			};
    }

    case REQUEST(ADMIN.ARTICLE_ACTION.GET_DETAIL): {
			return {
				...state,
				articleDetail: {
          ...state.articleDetail,
          loading: true,
        },
			};
    }
		case SUCCESS(ADMIN.ARTICLE_ACTION.GET_DETAIL): {
      const { data } = action.payload;
			return {
				...state,
				articleDetail: {
          ...state.articleDetail,
          data: data.items,
          loading: false,
          errors: false,
        },
			};
    }
		case FAILURE(ADMIN.ARTICLE_ACTION.GET_DETAIL): {
			return {
				...state,
        articleDetail: {
          ...state.articleDetail,
          loading: false,
          errors: action.errors,
        },
			};
    }
		default:
			return state;
	}
};

export default adminArticleReducer;
