import styled from 'styled-components';
import { Button, Select, Modal } from 'antd';

export const MainContainer = styled.div``;

export const AddCalendarContainer = styled.div`
  display: flex;
  align-items: center;
  height: 76px;
`;

export const AddCalendarButton = styled(Button)`
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: ${(props) => props.theme.fontWeight.w6};
`;

export const SidebarContainer = styled.div`
  width: 300px;
  padding: 0 24px;
  background-color: white;

  @media (max-width: 992px) {
    position: fixed;
    top: 64px;
    left: 0;
    height: calc(100vh - 64px);
    border-right: 1px solid #F1F1F5;
    z-index: 9;
    transform: translateX(-100%);
  }
`;

export const CalendarContainer = styled.div`
  width: calc(100% - 300px);
  border-left: 1px solid #F1F1F5;

  @media (max-width: 992px) {
    width: 100%;
    border-left: none;
  }
`;

export const SidebarCalendar = styled.div`
  margin: 30px 0 24px;
`;

export const SidebarCalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const SidebarFilter = styled.div`
  margin: 24px 0;
  
  & .ant-collapse-header {
    padding: 0 !important;
  }

  & .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const CalendarContent = styled.div`
  padding: 8px;
`;

export const SidebarFilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EventContentContainer = styled.div`
  position: relative;
  padding: 10px;
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.theme.radius.lg};
  background-color: ${(props) => props.backgroundColor || '#F1F1F5'};
  box-shadow: 0px 0px 0px 1px #FAFAFA;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${(props) => props.borderColor || props.theme.colors.primaryText};
  }
`;

export const TimeGridWeekContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
`;

export const DayGridMonthContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
`;

export const SubHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  height: 76px;
  box-shadow: 0px 3px 24px rgba(241, 241, 245, 0.5);
`;

export const CalendarTypeOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .ant-select-item-option-content {
    color: ${(props) => props.theme.colors.primary} !important;
  }
`;

export const CalendarTypeSelect = styled(Select)`
  width: 130px;

  &:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #F1F1F5;
    border-radius: ${(props) => props.theme.radius.md} !important;
    background-color: #FAFAFA !important;
  }
`;

export const CalendarEventModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: ${(props) => props.theme.radius.lg};
  }

  & .ant-modal-body {
    padding: 12px;
  }
`;

export const ModalTopContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalInfoContainer = styled.div`
  position: relative;
  padding: 0 14px 12px 32px;
`;

export const ModalInfoLabel = styled.div`
  position: absolute;
  top: 8px;
  left: 6px;
  width: 14px;
  height: 14px;
  border-radius: ${(props) => props.theme.radius.sm};
  background-color: ${(props) => props.color || '#00B9FF'};
`;

export const ModalFormContainer = styled.div`
  padding: 0 14px 0 4px;

  & .ant-input-borderless:focus,
  & .ant-select-borderless.ant-select-open,
  & .ant-picker-borderless.ant-picker-focused {
    background-color: #F1F1F5 !important;
    border-radius: ${(props) => props.theme.radius.sm};
  }

  & .ant-select-multiple .ant-select-selection-item {
    justify-content: space-between;
    align-items: center;
    padding-left: 4px;
    height: 32px;
    border: none;
    background-color: white;
  }
`;

export const ModalActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & .ant-btn {
    height: 35px;
    border-radius: ${(props) => props.theme.radius.lg};
    font-weight: ${(props) => props.theme.fontWeight.w6};
  }

  & .ant-btn-text {
    color: #8397AF;
  }
`;
