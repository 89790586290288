import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Icon } from './components';

const Container = styled.div`
  position: relative;
  user-select: none;
`;

const Wrapper = styled.div`
  user-select: none;
  opacity: 0.55;

  &:hover {
    opacity: 1;
    color: #444;
    background: rgba(32,33,36,0.059);
  }
`;

const Button = styled.span`
  cursor: pointer;
  user-select: none;
  color: #222;
`;

const DropDown = styled.div`
  position: absolute;
  padding: 4px;
  bottom: 25px;
  z-index: 10;
  font-size: .875rem;
  letter-spacing: .2px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
  line-height: 20px;
  user-select: none;
  background: #fff;
`;

const GroupButton = ({ icon, children }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const toggle = () => setOpen(!open);

  const handleClickOutside = (event) => {
    if (ref.current
      && !ref.current.contains(event.target)
      && !event.target.attributes['data-popup']
    ) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <Container>
      <Wrapper data-popup onClick={toggle}>
        <Button data-popup>
          <Icon data-popup>{icon}</Icon>
        </Button>
        <Button data-popup>
          <Icon data-popup>arrow_drop_down</Icon>
        </Button>
      </Wrapper>

      {open && (
        <DropDown ref={ref}>{children}</DropDown>
      )}
    </Container>
  );
}

export default GroupButton;
