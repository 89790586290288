import React, { useState } from 'react';
import {
  Space,
  Button,
  Dropdown,
  Menu,
  Popover,
} from 'antd';

import history from '../../../utils/history';

import { Text, SelectEmailCheckbox } from '../../../commons/styles';

import { MAIL_ITEM_LIMIT } from '../../../constants/mail';

import { ReactComponent as ArchiveIcon } from '../../../assets/images/ic_top_menu_archive.svg';
import { ReactComponent as BackIcon } from '../../../assets/images/ic_top_menu_back.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/ic_top_menu_delete.svg';
import { ReactComponent as ForwardIcon } from '../../../assets/images/ic_top_menu_forward.svg';
import { ReactComponent as LabelIcon } from '../../../assets/images/ic_top_menu_label.svg';
import { ReactComponent as MoreIcon } from '../../../assets/images/ic_top_menu_more.svg';
import { ReactComponent as MoveIcon } from '../../../assets/images/ic_top_menu_move.svg';
import { ReactComponent as NextIcon } from '../../../assets/images/ic_top_menu_next.svg';
import { ReactComponent as PinIcon } from '../../../assets/images/ic_top_menu_pin.svg';
import { ReactComponent as PrevIcon } from '../../../assets/images/ic_top_menu_prev.svg';
import { ReactComponent as RefreshIcon } from '../../../assets/images/ic_top_menu_refresh.svg';
import { ReactComponent as ReplyIcon } from '../../../assets/images/ic_top_menu_reply.svg';
import { ReactComponent as SeenIcon } from '../../../assets/images/ic_top_menu_seen.svg';
import { ReactComponent as SettingIcon } from '../../../assets/images/ic_top_menu_setting.svg';
import { ReactComponent as SpamIcon } from '../../../assets/images/ic_top_menu_spam.svg';
import { ReactComponent as UnseenIcon } from '../../../assets/images/ic_top_menu_unseen.svg';
import { ReactComponent as ListGridIcon } from '../../../assets/images/ic_top_menu_list_grid.svg';
import { ReactComponent as ColumnGridIcon } from '../../../assets/images/ic_top_menu_column_grid.svg';
import { ReactComponent as RowGridIcon } from '../../../assets/images/ic_top_menu_row_grid.svg';
import { ReactComponent as DropdownIcon } from '../../../assets/images/ic_top_menu_dropdown.svg';

import '../LabelDropdown.css';

import * as Style from '../styles';

const MORE_ACTIONS = [
  'Mark as read',
  'Mark as important',
  'Mark as not important',
  'Add star',
  'Remove star',
  'Filter messages like these',
  'Mute',
  'Forward as attachment',
];

const LABEL_LIST = [
  { name: "Updates", isChecked: false },
  { name: "Mailspring", isChecked: false },
  { name: "Mailspring/Snoozed", isChecked: false },
  { name: "Notes", isChecked: false },
  { name: "Test new Label", isChecked: false },
  { name: "Test New Label / Academia", isChecked: false },
  { name: "Test New Label / Acamedia child", isChecked: false },
  { name: "Test New Label / Test new label", isChecked: false },
  { name: "Updates", isChecked: false },
]

const TopMenu = ({
  isSelectAllItems,
  isSelectingSomeItems,
  onSelectAllMail,
  onDeleteMails,
  onSeenMails,
  onUnSeenMails,
  onSetSpamMails,
  mailType,
  isMailDetail,
  pages,
  gridType,
  setGridType,
}) => {
  const [checkedLabels, setCheckedLabels] = useState(LABEL_LIST);

  const startPageIndex = MAIL_ITEM_LIMIT * (pages.currentPage - 1) + 1;
  const pageInfo = `${startPageIndex}-${pages.total < MAIL_ITEM_LIMIT ? pages.total : MAIL_ITEM_LIMIT} of ${pages.total}`;

  function renderDropdownActionMenu() {
    return (
      <Menu>
        {MORE_ACTIONS.map((actionItem, actionIndex) => (
          <Menu.Item key={`more-action-${actionIndex}`}>
            {actionItem}
          </Menu.Item>
        ))}
      </Menu>
    );
  }

  function renderDropdownLabelMenu() {
    return (
      <div className="label-dropdown-content-wrapper">
        <p>Labels as:</p>
        <div className="label-dropdown-wrapper">
          <input
            onBlur={(e) => e.target.focus()}
            type="text"
            className="label-dropdown-content-search"
            autoFocus
          ></input>
          <div className="label-dropdown-search-icon"></div>
        </div>
        <div className="label-dropdown-list-item">
          <ul>
            {checkedLabels.map((labelItem, labelIndex) => {
              return (
                <li
                  className="label-dropdown-checkbox-wrapper"
                  key={`label-${labelIndex}`}
                >
                  <div
                    onClick={() => {
                      const newCheckedLabels = checkedLabels;
                      newCheckedLabels[labelIndex].isChecked = !checkedLabels[labelIndex].isChecked;
                      setCheckedLabels([...newCheckedLabels]);
                    }}
                    className={
                      labelItem.isChecked
                        ? "label-dropdown-checkbox label-dropdown-checked"
                        : "label-dropdown-checkbox"
                    }
                  ></div>
                  <p>{labelItem.name}</p>
                </li>
              );
            })}
          </ul>
        </div>
        {checkedLabels.findIndex((labelItem) => labelItem.isChecked) !== -1
          ? (
            <div className="label-dropdown-tools">
              <button>Apply</button>
            </div>
          )
          : (
            <div className="label-dropdown-tools">
              <button>Create label</button>
              <button>Manage labels</button>
            </div>
          )}
      </div>
    );
  }

  function renderDropdownGridMenu() {
    return (
      <Menu selectedKeys={[gridType]}>
        <Menu.Item
          key="listGrid"
          onClick={() => setGridType('listGrid')}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <ListGridIcon />
          <Text style={{ marginLeft: 8 }}>List</Text>
        </Menu.Item>
        <Menu.Item
          key="columnGrid"
          onClick={() => setGridType('columnGrid')}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <ColumnGridIcon />
          <Text style={{ marginLeft: 8 }}>Column</Text>
        </Menu.Item>
        <Menu.Item
          key="rowGrid"
          onClick={() => setGridType('rowGrid')}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <RowGridIcon />
          <Text style={{ marginLeft: 8 }}>Row</Text>
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Style.TopMenuContainer>
      <Space size={4}>
        {!isMailDetail && (
          <Space size={0}>
            <SelectEmailCheckbox
              indeterminate={isSelectingSomeItems}
              checked={isSelectAllItems}
              onChange={() => onSelectAllMail()}
              style={{ width: 22, height: 22 }}
            />
            <Button
              size="small"
              type="text"
              shape="circle"
              icon={<DropdownIcon />}
            ></Button>
          </Space>
        )}
        {!isMailDetail && !(isSelectingSomeItems || isSelectAllItems) && (
          <Button size="large" type="text" shape="circle" icon={<RefreshIcon />}></Button>
        )}
        {isMailDetail && (
          <>
            <Button
              size="large"
              type="text"
              shape="circle"
              icon={<BackIcon />}
              onClick={() => history.push(`/mail/${mailType}`)}
            ></Button>
            <Button size="large" type="text" shape="circle" icon={<ReplyIcon />}></Button>
            <Button size="large" type="text" shape="circle" icon={<ForwardIcon />}></Button>
          </>
        )}
        {isSelectingSomeItems && !isSelectAllItems && (
          <Button
            size="large"
            type="text"
            shape="round"
            onClick={() => onSelectAllMail(true)}
          >
            Select All
          </Button>
        )}
        {(isSelectingSomeItems || isSelectAllItems) && <Style.TopMenuSeparationLine />}
        {(isSelectingSomeItems || isSelectAllItems || isMailDetail) && (
          <>
            <Button
              size="large" 
              type="text"
              shape="circle"
              icon={<DeleteIcon style={{ fontSize: 20 }} />}
              onClick={() => onDeleteMails()}
            ></Button>
            <Button
              size="large"
              type="text"
              shape="circle"
              icon={<SpamIcon />}
              onClick={() => onSetSpamMails()}
            ></Button>
            <Style.TopMenuSeparationLine />
            <Button
              size="large"
              type="text"
              shape="circle"
              icon={<UnseenIcon />}
              onClick={() => onUnSeenMails()}
            ></Button>
            <Button
              size="large"
              type="text"
              shape="circle"
              icon={<SeenIcon />}
              onClick={() => onSeenMails()}
            ></Button>
            <Style.TopMenuSeparationLine />
            <Popover
              content={renderDropdownLabelMenu()}
              trigger="click"
            >
              <Button size="large" type="text" shape="circle" icon={<LabelIcon />}></Button>
            </Popover>
            <Button size="large" type="text" shape="circle" icon={<MoveIcon />}></Button>
            <Button size="large" type="text" shape="circle" icon={<PinIcon />}></Button>
            <Button size="large" type="text" shape="circle" icon={<ArchiveIcon />}></Button>
          </>
        )}

        <Dropdown overlay={renderDropdownActionMenu()} trigger={['click']} placement="bottomRight">
          <Button size="large" type="text" shape="circle" icon={<MoreIcon />}></Button>
        </Dropdown>
      </Space>
      <Space>
        <Text>{pageInfo}</Text>
        <Button size="large" type="text" shape="circle" icon={<PrevIcon />}></Button>
        <Button size="large" type="text" shape="circle" icon={<NextIcon />}></Button>
        <Button size="large" type="text" shape="circle" icon={<SettingIcon />}></Button>
        <Dropdown overlay={renderDropdownGridMenu()} trigger={['click']} placement="bottomRight">
          <Button
            size="large"
            type="text"
            shape="round"
            icon={<ListGridIcon />}
            style={{ display: 'flex', alignItems: 'center', padding: '6px 14px' }}
          >
            &nbsp;<DropdownIcon />
          </Button>
        </Dropdown>
      </Space>
    </Style.TopMenuContainer>
  );
};

export default TopMenu;
