import React, { useRef, useState, } from "react";
import MailItem from "./MailItem/MailItem";
import MailDetail from "../MailDetail/MailDetail";
import { isEmpty } from 'lodash';
import NoResult from "../MailBoxWrapper/NoResult";
import NoEmailResult from "../components/NoEmailResult";

const MailList = ({
  mails,
  mailType,
  gridType,
  onSelectMailItem,
  selectedItemIds,
  mailDetail,
  setMailDetail,
  inboxTab,
}) => {
  const mailItemsRef = useRef(null);
  const mailContentRef = useRef(null);
  const [showContentDetail, setShowContentDetail] = useState(false);
  const [currentMail, setCurrentMail] = useState();

  let mousePosition;
  const columnMinSize = 400;
  const rowMinSize = 250;

  document.addEventListener(
    "mouseup",
    () => {
      document.removeEventListener("mousemove", handleResize, false);
    },
    false
  );

  function handleMouseDown(e) {
    mousePosition = e.x;
    document.addEventListener("mousemove", handleResize, false);
  }

  function handleResize(e) {
    if (gridType === "columnGrid") {
      const dx = mousePosition - e.x;
      mousePosition = e.x;

      const mailItemsElement = mailItemsRef.current;
      const mailContentElement = mailContentRef.current;

      const mailItemsWidth = mailItemsRef.current.offsetWidth;
      const mailContentWidth = mailContentRef.current.offsetWidth;
      if (mousePosition <= 680) {
        return;
      }

      if (
        mailItemsWidth >= columnMinSize &&
        mailContentWidth >= columnMinSize
      ) {
        mailItemsElement.style.width = `calc(100% - ${
          mailContentWidth + dx + 5
        }px)`;
        mailContentElement.style.width = mailContentWidth + dx + "px";
      }
      if (mailItemsWidth < columnMinSize) {
        mailItemsElement.style.width = columnMinSize - dx + "px";
      }
      if (mailContentWidth < columnMinSize) {
        mailContentElement.style.width = columnMinSize + dx + "px";
      }
    } else if (gridType === "rowGrid") {
      const dy = mousePosition - e.y;
      mousePosition = e.y;

      const mailItemsElement = mailItemsRef.current;
      const mailContentElement = mailContentRef.current;

      const mailItemsHeight = mailItemsRef.current.offsetHeight;
      const mailContentHeight = mailContentRef.current.offsetHeight;

      if (mailItemsHeight >= rowMinSize && mailContentHeight >= rowMinSize) {
        mailItemsElement.style.height = `calc(100vh - 177px - ${
          mailContentHeight + dy + 5
        }px)`;
        mailContentElement.style.height = mailContentHeight + dy + "px";
      }
      if (mailItemsHeight < rowMinSize) {
        mailItemsElement.style.height = rowMinSize - dy + "px";
      }
      if (mailContentHeight < rowMinSize) {
        mailContentElement.style.height = rowMinSize + dy + "px";
      }
    }
  }

  const onMailDetail = (mail) => {
    setShowContentDetail(true);
    setMailDetail({ ...mail });
  };

  const renderMailDetailDefault = () => {
    return (
      <div className="detail-content-default">
        <div className="detail-h">
          No chat selected.
        </div>
        <div className="detail-b">
          You are currently using 13.96 GB (93%) of your 15 GB total. - Manage
        </div>
      </div>
    )
  }

  const renderMailDetail = () => {
    let isMail = isEmpty(mailDetail && mailDetail.data);
    if (!showContentDetail && isMail) return renderMailDetailDefault();
    if (isMail) {
      return renderMailDetailDefault();
    } else {
      return <MailDetail gridType={gridType} mailType={mailType} mailDetail={mailDetail} />;
    }
  };

  if (gridType === "columnGrid") {
    return (
      <div
        className="mail-items-container tab-pane fade show active"
        id="nav-primary"
        role="tabpanel"
        aria-labelledby="nav-primary-tab"
      >
        <div className="column-mail-items">
          <div className="mail-items left-column-mail-items" ref={mailItemsRef}>
            {(!mails || mails.length === 0) && <NoResult mailType={mailType} />}
            {mails &&
              mails.map((mail) => {
                return (
                  <MailItem
                    key={mail.Id}
                    selected={selectedItemIds.includes(mail.Id)}
                    mail={mail}
                    mailType={mailType}
                    onSelectItem={onSelectMailItem}
                    gridType={gridType}
                    onMailDetail={() => onMailDetail(mail)}
                    currentMail={currentMail}
                    setCurrentMail={setCurrentMail}
                    inboxTab={inboxTab}
                  />
                );
              })}
          </div>
          <div
            className="drapdrop-mail-layout-line"
            onMouseDown={(e) => handleMouseDown(e)}
          />
          <div className="right-column-mail-items" ref={mailContentRef}>
            {renderMailDetail()}
          </div>
        </div>
      </div>
    );
  } else if (gridType === "rowGrid") {
    return (
      <div
        className="mail-items-container tab-pane fade show active"
        id="nav-primary"
        role="tabpanel"
        aria-labelledby="nav-primary-tab"
      >
        <div className="window-mail-items">
          <div className="mail-items top-window-mail-items" ref={mailItemsRef}>
            {(!mails || mails.length === 0) && <NoResult mailType={mailType} />}
            {mails &&
              mails.map((mail) => {
                return (
                  <MailItem
                    key={mail.Id}
                    selected={selectedItemIds.includes(mail.Id)}
                    mail={mail}
                    mailType={mailType}
                    onSelectItem={onSelectMailItem}
                    gridType={gridType}
                    onMailDetail={() => onMailDetail(mail)}
                    currentMail={currentMail}
                    setCurrentMail={setCurrentMail}
                    inboxTab={inboxTab}
                  />
                );
              })}
          </div>
          <div
            className="drapdrop-mail-layout-line"
            onMouseDown={(e) => handleMouseDown(e)}
          />
          <div className="bottom-window-mail-items" ref={mailContentRef}>
            {renderMailDetail()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="tab-pane fade show active"
      id="nav-primary"
      role="tabpanel"
      aria-labelledby="nav-primary-tab"
    >
      <div className="mail-items">
        {mails && mails.length > 0
          ? mails.map((mail) => {
            return (
              <MailItem
                key={mail.Id}
                selected={selectedItemIds.includes(mailType !== "draft" ? mail.Id : mail.Id)}
                mail={mail}
                mailType={mailType}
                onSelectItem={onSelectMailItem}
                gridType={gridType}
                onMailDetail={() => onMailDetail(mail)}
                currentMail={currentMail}
                setCurrentMail={setCurrentMail}
                inboxTab={inboxTab}
              />
            );
          })
          : <NoEmailResult />
        } 
      </div>
    </div>
  );
};

export default MailList;
