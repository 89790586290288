import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from 'lodash/lang';

import Home from './../Home/components/Home'
import MailBox from "./../MailBox";
import NewsPage from './../News/components/NewsPage';

import LoginPage from '../Login';
import RegisterPage from '../Register';

import EntertainmentPage from '../Entertainment';
import WeatherPage from '../Weather';
import HoroscopePage from '../Horoscope';
import FirmyPage from '../Firmy';
import FirmyDetailPage from '../FirmyDetail';
import TVProgramPage from '../TVProgram';
import CalendarPage from '../Calendar';

import AdminArticleListPage from '../../screens/admin/ArticleList';
import AdminModifyArticlePage from '../../screens/admin/ModifyArticle';

import AuthLayout from '../../commons/layout/AuthLayout';
import PublishLayout from '../../commons/layout/PublishLayout';
import AdminLayout from '../../commons/layout/AdminLayout';

import history from '../../utils/history';
import { LoginStorage } from '../../utils/localStorge';

import {
  getUserInfo as getUserInfoAction,
} from "../../redux/actions";

// UPDATE_LATE: Remove css
import '../../styles/scss/main-admin.scss';

const Root = ({
  getUserInfo,
  userInfo,
}) => {
  const userStorage = LoginStorage.getData();

  useEffect(() => {
    if (!isEmpty(userStorage) && isEmpty(userInfo)) {
      getUserInfo();
    }
  }, [getUserInfo, userInfo, userStorage]);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Home} />
        <PublishLayout exact path="/login" component={LoginPage} />
        <PublishLayout exact path="/register" component={RegisterPage} />

        <AuthLayout path="/mail" component={MailBox} />
        <Route path="/news" component={NewsPage} />
        <PublishLayout exact path="/entertainment" component={EntertainmentPage} />
        <PublishLayout exact path="/weather" component={WeatherPage} />
        <PublishLayout exact path="/horoscope" component={HoroscopePage} />
        <PublishLayout exact path="/firmy" component={FirmyPage} />
        <PublishLayout exact path="/firmy/:id" component={FirmyDetailPage} />
        <PublishLayout exact path="/tv" component={TVProgramPage} />
        <PublishLayout exact path="/calendar" component={CalendarPage} />

        <AdminLayout exact path="/admin" component={AdminArticleListPage} />
        <AdminLayout exact path="/admin/articles" component={AdminArticleListPage} />
        <AdminLayout exact path="/admin/article/new" component={AdminModifyArticlePage} />
        <AdminLayout exact path="/admin/article/:id" component={AdminModifyArticlePage} />
      </Switch>
    </Router>
  );
};

const mapStateToProp = (state) => {
  const { userInfo } = state.auth;
  return {
    userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserInfo: () => dispatch(getUserInfoAction()),
  };
};

export default connect(mapStateToProp, mapDispatchToProps)(Root);
