import { Editor, Transforms } from 'slate';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const ALIGNMENTS = ['align-center', 'align-left', 'align-right'];

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? !!marks[format] : false;
}

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match;
}

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);
  const isAlignment = ALIGNMENTS.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => [...LIST_TYPES, ...ALIGNMENTS].includes(n.type),
    split: true,
  })
  
  Transforms.wrapNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })
  
  if (!isActive && (isList || isAlignment)) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
}

export const handleIndent = (editor, format, clear) => {
  if (!clear) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  } else {
    Transforms.unwrapNodes(editor, {
      match: n => n.type === 'indent',
      split: true,
    })
  }
}

export const toggleMark = (editor, format, data, clear) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else if(!clear) {
    Editor.addMark(editor, format, data || true);
  }
}