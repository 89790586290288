import React from "react";
import { Button, Input, Tabs, Space } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

import { Text } from '../../../../commons/styles';

import * as Style from '../styles';

const MOST_CONTACT_LIST = [
  {
    name: 'Pol Soria',
    email: 'polpolpolll@mail.com',
    sameCount: 3,
    avatar: 'https://via.placeholder.com/36',
  },
  {
    name: 'Akumjeli A',
    email: 'akumkum@mail.com',
    sameCount: 1,
    avatar: 'https://via.placeholder.com/36',
  },
  {
    name: 'Dominik D',
    email: 'daminik_d@mail.com',
    sameCount: 10,
    avatar: 'https://via.placeholder.com/36',
  },
]

const CONTACT_LIST = [
  {
    name: 'Pol Soria',
    email: 'polpolpolll@mail.com',
    sameCount: 3,
    avatar: 'https://via.placeholder.com/36',
  },
  {
    name: 'Akumjeli A',
    email: 'akumkum@mail.com',
    sameCount: 1,
    avatar: 'https://via.placeholder.com/36',
  },
  {
    name: 'Dominik D',
    email: 'daminik_d@mail.com',
    sameCount: 10,
    avatar: 'https://via.placeholder.com/36',
  },
  {
    name: 'Pol Soria',
    email: 'polpolpolll@mail.com',
    sameCount: 3,
    avatar: 'https://via.placeholder.com/36',
  },
  {
    name: 'Akumjeli A',
    email: 'akumkum@mail.com',
    sameCount: 1,
    avatar: 'https://via.placeholder.com/36',
  },
  {
    name: 'Dominik D',
    email: 'daminik_d@mail.com',
    sameCount: 10,
    avatar: 'https://via.placeholder.com/36',
  },
]

const ContactList = ({ setRightLayout }) => {
  function renderMostContactItems() {
    return MOST_CONTACT_LIST.map((contactItem, contactIndex) => {
      return (
        <Style.ContactItemContainer onClick={() => setRightLayout({ path: 'contact-detail', params: { id: 1 } })}>
          <Style.ContactItemAvatar image={contactItem.avatar} />
          <Style.ContactItemContent>
            <Space size={8}>
              <Text primaryText sm w6>{contactItem.name}</Text>
              <Text subText>|</Text>
              <Text subText xxs>{`${contactItem.sameCount} Same Contacts`}</Text>
            </Space>
            <Text subText xs>{contactItem.email}</Text>
          </Style.ContactItemContent>
        </Style.ContactItemContainer>
      )
    })
  }

  function renderContactItems() {
    return CONTACT_LIST.map((contactItem, contactIndex) => {
      return (
        <Style.ContactItemContainer onClick={() => setRightLayout({ path: 'contact-detail', params: { id: 1 } })}>
          <Style.ContactItemAvatar image={contactItem.avatar} />
          <Style.ContactItemContent>
            <Space size={8}>
              <Text primaryText sm w6>{contactItem.name}</Text>
              <Text subText>|</Text>
              <Text subText xxs>{`${contactItem.sameCount} Same Contacts`}</Text>
            </Space>
            <Text subText xs>{contactItem.email}</Text>
          </Style.ContactItemContent>
        </Style.ContactItemContainer>
      )
    })
  }

  return (
    <>
      <Style.RightLayoutHeader>
        <Text primaryText lg w6>Contact List</Text>
        <Button type="text" icon={<CloseOutlined />} shape="circle" onClick={() => setRightLayout(null)}></Button>
      </Style.RightLayoutHeader>
      <Style.ContactListSearch>
        <Input size="large" placeholder="Search Contact" prefix={<SearchOutlined />} bordered={false} />
      </Style.ContactListSearch>
      <Style.ContactListTabs>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="All" key="1">
            <Text subText xxs style={{ padding: '0 12px 8px' }}>Most Contacted</Text>
            {renderMostContactItems()}
            <Text subText xxs style={{ padding: '16px 12px 8px' }}>Your Contacts</Text>
            {renderContactItems()}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Top" key="2">
            Content of Tab Pane 2
          </Tabs.TabPane>
          <Tabs.TabPane tab="List" key="3">
            Content of Tab Pane 3
          </Tabs.TabPane>
        </Tabs>
      </Style.ContactListTabs>
    </>
  );
};

export default ContactList;