import React from 'react';
import { Space } from 'antd';

import { Text } from '../../../commons/styles';

import { ReactComponent as ClockIcon } from "../../../assets/images/ic_clock.svg";

import * as Style from '../styles';

const HeadlineNews = ({
  newsItem
}) => {
  return (
    <div>
      <Style.CardNewsContainer isHeadline>
        <Style.CardNewsImage image={newsItem.image} />
        <Style.CardNewsContent>
          <Style.CardNewsTop>
            <Style.CardNewsLabel>
              ENTERTAINMENT
            </Style.CardNewsLabel>
            <Space size={36}>
              <Text white sm>{newsItem.view}</Text>
              <Text white sm>{newsItem.like}</Text>
              <Text white sm>{newsItem.share}</Text>
            </Space>
          </Style.CardNewsTop>
          <div>
            <Text
            white
              xl
              fontSize="22px"
              truncateMultiLine={2}
              pointer
              style={{ marginBottom: 16 }}
            >
              {newsItem.title}
            </Text>
            <Space size={6}>
              <ClockIcon />
              <Text subText xs>
                {`${newsItem.createAt} | ${newsItem.url}`}
              </Text>
            </Space>
          </div>
        </Style.CardNewsContent>
      </Style.CardNewsContainer>
    </div>
  );
};

export default HeadlineNews;
