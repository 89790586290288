import React from 'react';
import { Row, Col, Space, Button, Form, Input, DatePicker, Select, Avatar } from 'antd';
import {
  CloseOutlined,
  ClockCircleOutlined,
  UsergroupAddOutlined,
  EnvironmentOutlined,
  AlignLeftOutlined,
  UserOutlined,
} from '@ant-design/icons';
import moment from 'moment';

import { Text } from '../../../commons/styles';

import * as Style from '../styles';

const ModifyEventModal = ({
  setIsShowModifyModal,
  isShowModifyModal,
}) => {
  return (
    <Style.CalendarEventModal
      visible={isShowModifyModal}
      closable={false}
      footer={false}
      onCancel={() => setIsShowModifyModal(false)}
      width={400}
    >
      <Style.ModalTopContainer>
        <Button
          type="text"
          shape="circle"
          onClick={() => setIsShowModifyModal(false)}
        >
          <CloseOutlined style={{ color: '#92929D' }} />
        </Button>
      </Style.ModalTopContainer>
      <Style.ModalFormContainer>
        <Form
          colon={false}
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
          name="modifyCalendar"
          initialValues={{
            name: 'Design a Wireframe',
            dateTime: [moment(), moment().add(1, 'day')],
            guests: ['paulopez12@mail.com', 'paulo_sergio@mail.com'],
            location: '1',
            note: '',
          }}
          onFinish={(values) => console.log(values)}
        >
          <Form.Item
            label=" "
            name="name"
          >
            <Input bordered={false} style={{ height: 46, fontSize: 22, borderBottom: '1px solid #F1F1F5' }} />
          </Form.Item>
          <Form.Item label=" ">
            <Space size={[6, 6]} wrap>
              <Button type="text">Personal Projects</Button>
              <Button type="text">Work</Button>
              <Button type="text">Appoinments</Button>
              <Button type="text">Reminders</Button>
              <Button type="text">Tasks</Button>
            </Space>
          </Form.Item>
          <Form.Item
            label={<ClockCircleOutlined style={{ fontSize: 20, color: '#8997AB' }} />}
            name="dateTime"
          >
            <DatePicker.RangePicker
              showTime={{ format: 'HH:mm' }}
              format="MMM DD, YYYY HH:mm"
              bordered={false}
            />
          </Form.Item>
          <Form.Item
            label={<UsergroupAddOutlined style={{ fontSize: 20, color: '#8997AB' }} />}
            name="guests"
          >
            <Select
              mode="tags"
              placeholder="Add Guest"
              bordered={false}
            >
              <Select.Option value="paulopez12@mail.com">
                <Space>
                  <Avatar shape="circle" size="small" icon={<UserOutlined />} />
                  <Text>paulopez12@mail.com</Text>
                </Space>
              </Select.Option>
              <Select.Option value="paulo_sergio@mail.com">
                <Space>
                  <Avatar shape="circle" size="small" icon={<UserOutlined />} />
                  <Text>paulo_sergio@mail.com</Text>
                </Space>
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={<EnvironmentOutlined style={{ fontSize: 20, color: '#8997AB' }} />}
            name="location"
          >
            <Select
              showSearch
              showArrow={false}
              allowClear
              placeholder="Add Location"
              bordered={false}
              optionLabelProp="label"
            >
              <Select.Option value="1" label="Copenhagen City, Denmark">
                <Row>
                  <Col span={2}>
                    <EnvironmentOutlined style={{ fontSize: 16, color: '#8997AB' }} />
                  </Col>
                  <Col span={22}>
                    <Text primaryText xxs>Copenhagen</Text>
                    <Text xxs color="#8397AF" style={{ fontSize: 10 }}>
                      Copenhagen City, Denmark
                    </Text>
                  </Col>
                </Row>
              </Select.Option>
              <Select.Option value="2" label="Denmark, Europe">
                <Row>
                  <Col span={2}>
                    <EnvironmentOutlined style={{ fontSize: 16, color: '#8997AB' }} />
                  </Col>
                  <Col span={22}>
                    <Text primaryText xxs>Copenhagen</Text>
                    <Text xxs color="#8397AF" style={{ fontSize: 10 }}>
                      Denmark, Europe
                    </Text>
                  </Col>
                </Row>
              </Select.Option>
              <Select.Option value="3" label="Denmark, EUR">
                <Row>
                  <Col span={2}>
                    <EnvironmentOutlined style={{ fontSize: 16, color: '#8997AB' }} />
                  </Col>
                  <Col span={22}>
                    <Text primaryText xxs>Copenhagen</Text>
                    <Text xxs color="#8397AF" style={{ fontSize: 10 }}>
                      Denmark, EUR
                    </Text>
                  </Col>
                </Row>
              </Select.Option>
              <Select.Option value="4" label="Copenhagen City, Denmark">
                <Row>
                  <Col span={2}>
                    <EnvironmentOutlined style={{ fontSize: 16, color: '#8997AB' }} />
                  </Col>
                  <Col span={22}>
                    <Text primaryText xxs>Copenhagen</Text>
                    <Text xxs color="#8397AF" style={{ fontSize: 10 }}>
                      Copenhagen City, Denmark
                    </Text>
                  </Col>
                </Row>
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={<AlignLeftOutlined style={{ fontSize: 20, color: '#8997AB', transform: 'scaleY(-1)' }} />}
            name="note"
          >
            <Input.TextArea bordered={false} />
          </Form.Item>
          <Style.ModalActionContainer>
            <Space size={10}>
              <Button htmlType="button" type="text">More Options</Button>
              <Button htmlType="submit" type="primary">Save Agenda</Button>
            </Space>
          </Style.ModalActionContainer>
        </Form>
      </Style.ModalFormContainer>
    </Style.CalendarEventModal>
  );
};

export default ModifyEventModal;
