import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useSlate } from 'slate-react';

import { fontFamilyList } from './FontFamilyList';
import { FontFamilyButton, getActiveFont } from './FontFamilyButton';
import { Icon } from '../../components';
import { Container, Wrapper, DropDown } from '../common/components';

const Button = styled.span`
  cursor: pointer;
  user-select: none;
`;

const ActiveFont = styled.div`
  user-select: none;
  overflow: hidden;
  padding: 0 0 0 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 4.8125rem;
`;

const FontWrapper = styled(Wrapper)`
  display: flex;
`;

const TYPE = 'font-family';

const FontFamilyDropDown = () => {
  const editor = useSlate();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const toggle = () => setOpen(!open);
  const activeFont = getActiveFont(editor, TYPE).label || fontFamilyList[0].label;

  const handleClickOutside = (event) => {
    if (ref.current
      && !ref.current.contains(event.target)
      && !event.target.attributes['data-popup']
    ) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <Container>
      <FontWrapper data-popup onClick={toggle}>
        <ActiveFont data-popup>
          {activeFont}
        </ActiveFont>
        <Button data-popup>
          <Icon data-popup>arrow_drop_down</Icon>
        </Button>
      </FontWrapper>

      {open && (
        <DropDown ref={ref}>
          {fontFamilyList.map(font => (
            <FontFamilyButton
              key={font.label}
              format={TYPE}
              data={font.value}
            >
              {font.label}
            </FontFamilyButton>
          ))}
        </DropDown>
      )}
    </Container>
  );
}

export default FontFamilyDropDown;
