import styled from 'styled-components';
import { Input, Checkbox } from 'antd';

export const AuthInput = styled(Input)`
  height: 46px;
  border: 1px solid #F5F5F7;
  border-radius: ${(props) => props.theme.radius.md};
  font-size: ${(props) => props.fontSize || props.theme.fontSize.md};

  & input {
    color: ${(props) => props.color || props.theme.colors.secondaryText};
  }

  & .ant-input-prefix {
    margin-right: 8px;
  }
`;

export const AuthPassword = styled(Input.Password)`
  height: 46px;
  border: 1px solid #F5F5F7;
  border-radius: ${(props) => props.theme.radius.md};
  font-size: ${(props) => props.fontSize || props.theme.fontSize.md};

  & input {
    color: ${(props) => props.color || props.theme.colors.secondaryText};
  }

  & .ant-input-prefix {
    margin-right: 8px;
  }
`;

export const AuthCheckbox = styled(Checkbox)`
  & .ant-checkbox-inner, & .ant-checkbox-checked::after {
    border-radius: ${(props) => props.theme.radius.md};
  }

  &:hover .ant-checkbox-inner,
  & .ant-checkbox:hover .ant-checkbox-inner,
  & .ant-checkbox-checked::after,
  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.primary};
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
  }
`;
