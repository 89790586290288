import React from 'react';
import { Editor, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { Button, Icon } from '../../components';

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match;
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  
  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : format,
  })
}

const BlockQuoteButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

export default BlockQuoteButton;