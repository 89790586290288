import styled, {css} from 'styled-components';

export const RightLayoutContainer = styled.div`
  width: 0;
  height: 100vh;
  box-shadow: 0px 2px 8px rgba(213, 213, 220, 0.4);
  overflow-y: auto;
  z-index: 999;

  ${(props) => props.showRightLayout
    && css`
      min-width: 280px;
    `}
`;

export const RightLayoutHeader = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 75px; // UPDATE_LATE
  border-bottom: 1px solid #F1F1F5;
  background-color: white;
  z-index: 998;

  & .ant-select-selector {
    padding: 0 !important;
  }
`;

export const RightLayoutContent = styled.div`
  padding: 16px;
`;

export const AgendaCalendar = styled.div`
  padding: 0 12px;
  border-bottom: 1px solid #F1F1F5;
`;

export const AgendaListTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 53px;
  border-bottom: 1px solid #F1F1F5;
`;

export const AgendaItemContainer = styled.div`
  margin-bottom: 16px;
  cursor: pointer;
`;

export const AgendaItemContent = styled.div`
  padding: 6px 10px;
  border-radius: ${(props) => props.theme.radius.lg};
  background-color: ${(props) => props.color};
`;

export const AgendaDetailInfo = styled.div`
  margin-bottom: 16px;
`;

export const ContactListSearch = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px;
  height: 53px;
  background-color: #F9F9F9;
`;

export const ContactListTabs = styled.div`
  & .ant-tabs-nav {
    padding: 0 16px;
  }

  & .ant-tabs-ink-bar {
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.primary};
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.primaryText};
    font-weight: ${(props) => props.theme.fontWeight.w6};
  }

  & .ant-tabs-tab {
    color: ${(props) => props.theme.colors.subText};
  }

  & .ant-tabs-tab:hover {
    color: ${(props) => props.theme.colors.primaryText};
  }
`;

export const ContactItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #FAFAFA;

  &:hover {
    background-color: #FAFAFA;
  }
`;

export const ContactItemAvatar = styled.div`
  width: 36px;
  height: 36px;
  border-radius: ${(props) => props.theme.radius.circle};
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ContactItemContent = styled.div`
  margin-left: 12px;
  width: calc(100% - 48px);
`;

export const ContactDetailInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #F9F9F9;
`;

export const ContactDetailAvatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: ${(props) => props.theme.radius.circle};
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ContactDetailContent = styled.div`
  margin-left: 16px;
  width: calc(100% - 76px);
`;

export const ContactHistoryContainer = styled.div`
  margin-bottom: 12px;
  padding: 12px;
  border-bottom: 1px solid #FAFAFA;

  &:hover {
    background-color: #FAFAFA;
  }
`;

export const ContactHistoryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContactHistoryThumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 164px;
  margin-bottom: 12px;
  background-image: url(${(props) => props.thumbnail});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ContactHistoryAttachInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px;
  width: 100%;
  background-color: white;

  &:hover {
    background-color: #FAFAFA;
  }
`;
