import React from "react";
import classnames from 'classnames';
import styles from "../SearchInput/SearchInput.module.css";

const SearchFilterSearch = ({ value, onChange }) => {
  return  (
    <div className={styles.inputWrapper}>
      <div className={styles.inputLabel}>Search</div>
      <select name="search" style={{backgroundPositionX: "98.5%"}} className={classnames(styles.input, styles.selectDropdownIcon)}>
        <option value="0">All Mail</option>
        <option value="1">Inbox</option>
        <option value="2">Starred</option>
        <option value="3">Sent Mail</option>
        <option value="4">Dreafts</option>
        <option value="5">Chats</option>
        <option value="6">Spam</option>
        <option value="7">Trash</option>
        <option value="8">Mail & Spam & Trash</option>
        <option value="9">Read Mail</option>
        <option value="10">Unread Mail</option>
        <option value="11">Social</option>
        <option value="12">Updates</option>
        <option value="13">Forums</option>
        <option value="14">Promotions</option>
      </select>
    </div>
  );
}

export default SearchFilterSearch
