import React, { useState } from 'react';
import { Row, Col, Space, Select, Radio, Button } from 'antd';

import { Text, LoadMoreButton } from '../../../commons/styles';

import programTVBrand1 from '../../../assets/images/program_tv_brand_1.png';

import * as Style from '../styles';

const PROGRAM_TAGS = [
  {
    label: 'LIVE',
    value: 'live',
  },
  {
    label: 'PREMIERE',
    value: 'premiere',
  },
  {
    label: 'KIDS',
    value: 'kids',
  },
  {
    label: 'SERIAL',
    value: 'serial',
  },
]

const TVProgramColumn = () => {
  function renderProgramTags() {
    return PROGRAM_TAGS.map((tagItem, tagIndex) => {
      return (
        <Style.ProgramTag live={tagItem.value === 'live'} premiere={tagItem.value === 'premiere'}>
          {tagItem.label}
        </Style.ProgramTag>
      )
    })
  }

  function renderNextPrograms() {
    return [...Array(5)].map((programItem, programIndex) => {
      return (
        <Style.ColumnNextPrograms>
          <Row style={{ marginBottom: 6 }}>
            <Style.ProgramTime>08:00</Style.ProgramTime>
            <Style.ProgramTitle>
              <Text headerText lg w6 truncate>Lion King Simba</Text>
            </Style.ProgramTitle>
          </Row>
          <Text secondaryText xs truncateMultiLine={3}>
            Calling the human race. One day the wise panther of Baghira finds a small baby deep in the jungle when something is not right and …
          </Text>
          <Space size={6} wrap style={{ marginTop: 12 }}>
            {renderProgramTags()}
          </Space>
        </Style.ColumnNextPrograms>
      )
    })
  }

  function renderColumnChannels() {
    return [...Array(5)].map((channelItem, channelIndex) => {
      return (
        <Style.ColumnChannelContent>
          <Style.ColumnChannelTitle className="column-channel-title">
            <img src={programTVBrand1} alt="" />
          </Style.ColumnChannelTitle>
          <Style.ColumnChannelSchedule className="column-channel-schedule">
            <Style.ColumnCurrentProgram>
              <Style.ColumnProgramThumbnail thumbnail="https://via.placeholder.com/260x140">
                <Style.ColumnProgramProgress progress={60} />
              </Style.ColumnProgramThumbnail>
              <Space size={6} wrap style={{ margin: '12px 0' }}>
                {renderProgramTags()}
              </Space>
              <Row style={{ marginBottom: 6 }}>
                <Style.ProgramTime>08:00</Style.ProgramTime>
                <Style.ProgramTitle>
                  <Text headerText lg w6 truncate>Lion King Simba Lion King Simba</Text>
                </Style.ProgramTitle>
              </Row>
              <Text secondaryText xs truncateMultiLine={3}>
                Calling the human race. One day the wise panther of Baghira finds a small baby deep in the jungle when something is not right and …
              </Text>
            </Style.ColumnCurrentProgram>
            {renderNextPrograms()}
          </Style.ColumnChannelSchedule>
        </Style.ColumnChannelContent>
      )
    })
  }

  return (
    <Style.ColumnChannelContainer>
      {renderColumnChannels()}
    </Style.ColumnChannelContainer>
  );
};

export default TVProgramColumn;
