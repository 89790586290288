import React from "react";
import { Item } from "./item";
import ReactWOW from 'react-wow';
import { connect } from "react-redux";

const Trends = ({ articleNewTrending }) => {
  function renderTrendingItem() {
    return articleNewTrending.items.map((trendingItem, trendingIndex) => {
      return (
        <Item key={`trending-item-${trendingIndex}`} trendingItem={trendingItem} />
      )
    })
  }

  return (
    <ReactWOW delay='0.2s' animation='bounceInLeft' duration='1s' >
      <div className="trend-container">
        <div className="trend-title">Another News Trending</div>
        <div className="trend-content">
          {renderTrendingItem()}
        </div>
      </div>
    </ReactWOW>
  );
};

const mapStateToProp = state => {
  return {
    trend: state.trend
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProp, mapDispatchToProps)(Trends);
