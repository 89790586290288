import React from "react";
import { Space, Button } from 'antd';
import { CloseOutlined, ArrowLeftOutlined, CalendarOutlined, ClockCircleFilled, LockFilled } from '@ant-design/icons';

import { Text } from '../../../../commons/styles';

import * as Style from '../styles';

const AgendaDetail = ({ setRightLayout }) => {
  return (
    <>
      <Style.RightLayoutHeader>
        <Button type="text" icon={<ArrowLeftOutlined />} shape="circle" onClick={() => setRightLayout({ path: 'agenda-list' })}></Button>
        <Button type="text" icon={<CloseOutlined />} shape="circle" onClick={() => setRightLayout(null)}></Button>
      </Style.RightLayoutHeader>
      <Style.RightLayoutContent>
        <Button type="primary" size="large" block>Start Project Sneaky</Button>
        <Button size="large" style={{ margin: '10px 0 24px' }} block onClick={() => setRightLayout({ path: 'agenda-modify', params: { id: 1 } })}>Edit in Calendar</Button>
        <Style.AgendaDetailInfo>
          <Space size={10}>
            <CalendarOutlined style={{ fontSize: 18, color: '#B5B5BE' }} />
            <Text primaryText>Saturday, Feb 14, 2020</Text>
          </Space>
        </Style.AgendaDetailInfo>
        <Style.AgendaDetailInfo>
          <Space size={10}>
            <ClockCircleFilled style={{ fontSize: 18, color: '#B5B5BE' }} />
            <Text primaryText>07:30 - 09:30 WIB</Text>
          </Space>
        </Style.AgendaDetailInfo>
        <Style.AgendaDetailInfo>
          <Space size={10}>
            <LockFilled style={{ fontSize: 18, color: '#B5B5BE' }} />
            <Text primaryText>Private</Text>
          </Space>
        </Style.AgendaDetailInfo>
      </Style.RightLayoutContent>
    </>
  );
};

export default AgendaDetail;