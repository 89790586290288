import styled from 'styled-components';

export const MainContainer = styled.div`
  padding-top: 4px;
`;

export const FirmyDetailInfo = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  border-bottom: 1px solid #F1F1F5;
`;

export const FirmyDetailAction = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px;
  min-height: 40px;
  border-bottom: 1px solid #F1F1F5;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #F1F1F5;
  }

  & p {
    opacity: 0.75;
  }
`;

export const DescriptionContainer = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid #F1F1F5;
`;

export const DescriptionImage = styled.div`
  width: 88px;
  height: 88px;
  border-radius: ${(props) => props.theme.radius.xl};
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const PhotoContainer = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid #F1F1F5;
`;

export const PhotoTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const PhotoItem = styled.div`
  width: 100%;
  height: 120px;
  border-radius: ${(props) => props.theme.radius.xl};
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ReviewContainer = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid #F1F1F5;
`;

export const ReviewTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CommentContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const CommentAvatar = styled.div`
  min-width: 36px;
  height: 36px;
  border-radius: ${(props) => props.theme.radius.circle};
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const CommentContent = styled.div`
  margin-left: 12px;
  width: calc(100% - 48px);
`;

export const SuggestionsContainer = styled.div`
  padding: 24px 0 100px;
`;

export const SuggestionsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const SuggestionsItem = styled.div``;

export const SuggestionsImage = styled.div`
  width: 100%;
  height: 160px;
  border-radius: ${(props) => props.theme.radius.xxl};
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 800px;

  @media (max-width: 992px) {
    margin-bottom: 24px;
    height: 200px;
  }
`;
