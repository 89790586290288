import React, { memo } from 'react'
import { Link } from "react-router-dom";

const SUB_HEADER = {
  news: {
    icon: 'icon-news',
    link: '/news'
  },
  sports: {
    icon: 'icon-sports'
  },
  calendar: {
    icon: 'icon-calendar',
    link: '/calendar',
  },
  tvprogram: {
    icon: 'icon-tech',
    link: '/tv',
  },
  firmy: {
    icon: 'icon-diagram-right',
    link: '/firmy',
  },
  horoscope: {
    icon: 'icon-politics',
    link: '/horoscope',
  },
  entertainment: {
    icon: 'icon-video',
    link: '/entertainment'
  },
  health: {
    icon: 'icon-health'
  },
  lifestyle: {
    icon: 'icon-lifestyle'
  },
}
const IconText = memo(props => {
  const { isActive, type, selectMenu } = props
  return (
    <li>
      <Link to={SUB_HEADER[type.toLowerCase()]['link'] || '#'} className={selectMenu === type ? "active" : ''}>
        <i className={`${SUB_HEADER[type.toLowerCase()]['icon']}`}></i>
        {type}
      </Link>
    </li >
  )
})

export default IconText
