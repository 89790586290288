import React from 'react';
import { Row, Col } from 'antd';
import Slider from 'react-slick';

import Header from '../../commons/Header';
import TrendWrapper from '../../commons/components/TrendWrapper';
import WeatherWrapper from '../../commons/components/WeatherWrapper';
import CurrencyWrapper from '../../commons/components/CurrencyWrapper';
import SubHeader from '../../commons/components/SubHeader';

import SmallNews from './components/SmallNews';
import HeadlineNews from './components/HeadlineNews';
import MostNews from './components/MostNews';
import TopVideo from './components/TopVideo';
import PrimaryNews from './components/PrimaryNews';

import { Text } from '../../commons/styles';

import { ReactComponent as ArrowDownIcon } from "../../assets/images/arrow_down.svg";

import * as Style from './styles';

const LAST_24_HOURS = [
  {
    title: 'The kids become famous before the Turned 10 you',
    image: 'https://via.placeholder.com/80',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
  },
  {
    title: 'The kids become famous before the Turned 10 you',
    image: 'https://via.placeholder.com/80',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
  },
  {
    title: 'The kids become famous before the Turned 10 you',
    image: 'https://via.placeholder.com/80',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
  },
  {
    title: 'The kids become famous before the Turned 10 you',
    image: 'https://via.placeholder.com/80',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
  },
];

const YESTERDAY_NEWS = [
  {
    title: 'The kids become famous before the Turned 10 you',
    image: 'https://via.placeholder.com/80',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
  },
  {
    title: 'The kids become famous before the Turned 10 you',
    image: 'https://via.placeholder.com/80',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
  },
  {
    title: 'The kids become famous before the Turned 10 you',
    image: 'https://via.placeholder.com/80',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
  },
  {
    title: 'Proven Techniques Help You Herbal Breast Enlargement Guaranteed',
    image: 'https://via.placeholder.com/80',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
  },
];

const HEADLINE_NEWS = [
  {
    title: 'Proven Techniques Help You Herbal Breast Enlargement Guaranteed',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Proven Techniques Help You Herbal Breast Enlargement Guaranteed',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Proven Techniques Help You Herbal Breast Enlargement Guaranteed',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
];

const MOST_NEWS = [
  {
    title: 'Proven Techniques Help You Herbal Breast Enlargement Guaranteed',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Proven Techniques Help You Herbal Breast Enlargement Guaranteed',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Proven Techniques Help You Herbal Breast Enlargement Guaranteed',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Proven Techniques Help You Herbal Breast Enlargement Guaranteed',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
];

const TOP_VIDEOS = [
  {
    title: 'Get The Most From Your Cast Iron Cookware',
    thumbnail: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Get The Most From Your Cast Iron Cookware',
    thumbnail: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Get The Most From Your Cast Iron Cookware',
    thumbnail: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Get The Most From Your Cast Iron Cookware',
    thumbnail: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
];

const PRIMARY_NEWS = [
  {
    title: 'Dream Interpretation Common Symbols And Their Meanings',
    description: 'All users on MySpace will know that there are millions of people out there. Every day besides so many people joining this community',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Dream Interpretation Common Symbols And Their Meanings',
    description: 'All users on MySpace will know that there are millions of people out there. Every day besides so many people joining this community',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Dream Interpretation Common Symbols And Their Meanings',
    description: 'All users on MySpace will know that there are millions of people out there. Every day besides so many people joining this community',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Dream Interpretation Common Symbols And Their Meanings',
    description: 'All users on MySpace will know that there are millions of people out there. Every day besides so many people joining this community',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
];

const EntertainmentPage = () => {
  function renderLast24Hours() {
    return LAST_24_HOURS.map((last24HoursItem, last24HoursIndex) => {
      return <SmallNews key={`last-24-hours-${last24HoursIndex}`} newsItem={last24HoursItem} />
    })
  }

  function renderYesterdayNews() {
    return YESTERDAY_NEWS.map((yesterdayNewsItem, yesterdayNewsIndex) => {
      return <SmallNews key={`yesterday-news-${yesterdayNewsIndex}`} newsItem={yesterdayNewsItem} />
    })
  }

  function renderHeadlineNews() {
    return HEADLINE_NEWS.map((headlineNewsItem, headlineNewsIndex) => {
      return <HeadlineNews key={`headline-news-${headlineNewsIndex}`} newsItem={headlineNewsItem} />
    })
  }

  function renderMostNews() {
    return MOST_NEWS.map((mostNewsItem, mostNewsIndex) => {
      return <MostNews key={`most-news-${mostNewsIndex}`} newsItem={mostNewsItem} />
    })
  }

  function renderTopVideos() {
    return TOP_VIDEOS.map((topVideoItem, topVideoIndex) => {
      return <TopVideo key={`top-videos-${topVideoIndex}`} videoItem={topVideoItem} />
    })
  }

  function renderPrimaryNews() {
    return PRIMARY_NEWS.map((primaryNewsItem, primaryNewsIndex) => {
      return <PrimaryNews key={`primary-news-${primaryNewsIndex}`} newsItem={primaryNewsItem} />
    })
  }

  return (
    <>
      <Header feature="ENTERTAINMENT" />
      <Style.NewsContainer>
        <div className="container">
          <SubHeader currentPage="Entertainment" />
          <Row gutter={32} style={{ marginTop: 32 }}>
            <Col lg={{ span: 7, order: 1 }} xs={{ span: 24, order: 2 }}>
              <Style.CategoryContainer>
                <Style.CategoryTitle>
                  <Text headerText lg w6>Last 24 Hours</Text>
                </Style.CategoryTitle>
                <Style.CategoryContent>
                  {renderLast24Hours()}
                </Style.CategoryContent>
              </Style.CategoryContainer>
              <Style.CategoryContainer style={{ marginTop: 16 }}>
                <Style.CategoryTitle>
                  <Text headerText lg w6>Yesterday</Text>
                </Style.CategoryTitle>
                <Style.CategoryContent>
                  {renderYesterdayNews()}
                </Style.CategoryContent>
              </Style.CategoryContainer>
            </Col>
            <Col lg={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
              <Style.CategoryContainer>
                <Style.CategoryTitle>
                  <Text headerText lg w6>Headline News</Text>
                </Style.CategoryTitle>
                <Style.CategoryContent>
                  <Slider
                    infinite
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    dots={false}
                    arrows={false}
                    autoplay
                  >
                    {renderHeadlineNews()}
                  </Slider>
                </Style.CategoryContent>
              </Style.CategoryContainer>
              <Style.CategoryContainer style={{ marginTop: 16 }}>
                <Style.CategoryTitle>
                  <Text headerText lg w6>Most Reaction on Entertainment</Text>
                </Style.CategoryTitle>
                <Style.CategoryContent>
                  <Row gutter={[16, 16]}>
                    {renderMostNews()}
                  </Row>
                </Style.CategoryContent>
              </Style.CategoryContainer>
            </Col>
            <Col lg={{ span: 5, order: 3 }} xs={{ span: 24, order: 3 }}>
              <Style.CategoryContainer>
                <Style.CategoryTitle>
                  <Text headerText lg w6>Hot in Entertainment</Text>
                </Style.CategoryTitle>
                {/* UPDATE_LATE */}
                <Style.CategoryContent className="content-wrap">
                  {/* UPDATE_LATE */}
                  <div className="sidebar">
                    <TrendWrapper />
                    <WeatherWrapper />
                    <CurrencyWrapper />
                  </div>
                </Style.CategoryContent>
              </Style.CategoryContainer>
            </Col>
          </Row>
        </div>
        <Style.TopVideoBackground>
          <Style.TopVideoContainer className="container">
            <Style.CategoryTitle white style={{ marginBottom: 16, maxWidth: 500 }}>
              <Text white lg w6>Top Entertainment Video</Text>
            </Style.CategoryTitle>
            <Slider
              className="top-video-slide"
              infinite={false}
              slidesToShow={3}
              speed={500}
              initialSlide={0}
              dots={false}
              arrows={false}
              responsive={[
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: 2,
                  }
                },
              ]}
            >
              {renderTopVideos()}
            </Slider>
          </Style.TopVideoContainer>
        </Style.TopVideoBackground>
        <div className="container">
          <Row>
            <Col lg={{ span: 19 }} xs={{ span: 24 }}>
              {renderPrimaryNews()}
              <Style.PrimaryNewsMore>
                <Style.LoadMoreButton shape="round" size="large">
                  More
                  <ArrowDownIcon style={{ marginLeft: 6 }} />
                </Style.LoadMoreButton>
              </Style.PrimaryNewsMore>
            </Col>
          </Row>
        </div>
      </Style.NewsContainer>
    </>
  );
};

export default EntertainmentPage;
