export const getEmailList = emails => {
  let result = "";
  emails.forEach(email => {
    const elementIndex = emails.indexOf(email);
    if (elementIndex !== 0) {
      result += "|";
    }

    result += email.Address ? email.Address : email;
  });
  return result;
};

export const removeSpaces = str => str.replace(/\s/g, "+");

export const reverseString = str =>
  str
    .split("")
    .reverse()
    .join("");

export const convertEmailStringToList = (emails) => {
  if (emails && typeof emails === "string") {
    return emails.trim().length > 0 ? emails.split("|") : [];
  }
  return [];
};

export const getSubject = (subject) => {
  return subject.trim().length === 0 || subject.includes("RE:")
    ? subject
    : "RE: " + subject;
};
