import React, { memo } from 'react'
import { Images } from './../../../themes'
import ProductItem from './ProductItem'
import { PROCLAMATION, BUS_SCHEDULES } from './../../../mockdata/homeData'
import ScheduleItem from './ScheduleItem'
import ReactWOW from 'react-wow'

const Proclamation = memo(props => {
  const renderMenu = () => {
    const menu = ['car', 'immovability', 'work', 'cellular']
    return (
      <nav className="tabs">
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          {menu.map((item, index) => (
            <a key={`tab${item}`} className={`nav-item nav-link ${index === 0 ? 'active' : ''}`} id={`nav-${item}-tab`} data-toggle="tab" href={`#nav-${item}`} role="tab" aria-controls={`nav-${item}`} aria-selected="true">{item}</a>
          ))}
        </div>
      </nav>
    )
  }

  const renderIcons = () => {
    const icons = ['icon-car', 'icon-home', 'icon-noun-job', 'icon-mobile']
    return (
      <ul className="list-action box-flex list-unstyled">
        {
          icons.map(item => <li key={item}><a href="#"><i className={item}></i></a></li>)
        }
      </ul>
    )
  }

  const renderBtnMore = (text = '') => (
    <div className="show-more">
      <a href="#" className="btn btn-primary">{text}</a>
    </div>
  )

  return (
    <div className="product-items product-item_proclamation box-flex">
      <ReactWOW delay='0.3s' animation='bounceInUp' duration='1s' >
        <div className="show-product">
          <div className="product-items_title box-flex">
            <h3 className="rubikMedium">proclamation</h3>
            {renderMenu()}
          </div>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="nav-car" role="tabpanel" aria-labelledby="nav-car-tab">
              <div className="show-product-items box-flex">
                <div className="product-item-highlight flex-50">
                  <div className="product-item-highlight_info">
                    <div className="thumbnail">
                      <a href="#" style={{ backgroundImage: `url(${Images.homeImg018})` }}></a>
                    </div>
                    <div className="product-txt">
                      <h4 className="product-item_title rubikMedium">
                        <a href="#">Nissan Qashqai 1.6 Gas. 2009</a>
                      </h4>
                      <ul className="product-item_info list-unstyled">
                        <li className="price">250,236 €</li>
                        <li className="distance">179,175 KM</li>
                        <li className="cart">
                          <a href="#"><i className="icon-cart"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="action-add-new">
                    <p className="add-new-title rubikMedium">+ New Announcement</p>
                    {renderIcons()}
                  </div>
                </div>

                <div className="show-product-list flex-50">
                  {
                    PROCLAMATION.map(item => <ProductItem key={`product${item.title}`} {...item} />)
                  }
                  {renderBtnMore('see all cars')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactWOW>
      <ReactWOW delay='0.45s' animation='bounceInUp' duration='1s' >
        <div className="show-product show-product-location">
          <div className="location-title box-flex">
            <div className="dropdown">
              <a href="#" className="dropdown-toggle rubikMedium" data-toggle="dropdown" aria-expanded="true">bus schedule</a>
              <ul className="dropdown-menu list-unstyled">
                <li><a href="#">dropdown item</a></li>
                <li><a href="#">dropdown item</a></li>
              </ul>
            </div>
            <p className="txt-location">
              <i className="icon-location"></i>NYC, US
            </p>
          </div>
          <div className="location-list">
            {
              BUS_SCHEDULES.map((item, index) => <ScheduleItem key={`schedule${index}`} {...item} />)
            }
          </div>
          {renderBtnMore('show more schedule')}
        </div>
      </ReactWOW>
    </div>
  )
})

export default Proclamation
