import styled from 'styled-components';
import { Button } from 'antd';

export const HoroscopeContainer = styled.div`
  padding-top: 4px;
`;

export const HoroscopeSliderContainer = styled.div`
  margin: 24px -8px 28px;
`;

export const HoroscopeCardContainer = styled.div`
  margin-top: 24px;
  padding: 0 8px;
`;

export const HoroscopeCardContent = styled.div`
  position: relative;
  padding: 30px 0 16px;
  border: 1px solid #F1F1F5;
  border-radius: ${(props) => props.theme.radius.xl};
  text-align: center;
`;

export const HoroscopeImage = styled.div`
  position: absolute;
  top: -24px;
  right: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: ${(props) => props.theme.radius.circle};
  background-color: white;
  box-shadow: 2px 5px 45px rgba(146, 146, 157, 0.1);
  transform: translate(50%, 0);
`;

export const HoroscopeContent = styled.div`
  & .ant-tabs-ink-bar {
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.primary};
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.primaryText};
    font-weight: ${(props) => props.theme.fontWeight.w6};
  }

  & .ant-tabs-tab {
    color: ${(props) => props.theme.colors.subText};
  }

  & .ant-tabs-tab:hover {
    color: ${(props) => props.theme.colors.primaryText};
  }
`;

export const HoroscopeTodayContainer = styled.div`
  padding: 20px;
  border: 1px solid #F1F1F5;
  border-radius: ${(props) => props.theme.radius.xl};
`;

export const HoroscopeTodayTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .ant-btn {
    padding: 2px 5px;
    width: 26px;
    height: 26px;
    border-radius: ${(props) => props.theme.radius.xl};
  }
`;

export const HoroscopeTodayContent = styled.div`
  margin-top: 20px;

  & > div {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const HoroscopeMatchImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border: 1px solid #F1F1F5;
  border-radius: ${(props) => props.theme.radius.circle};
`;

export const HoroscopeMoodImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border: 1px solid #F1F1F5;
  border-radius: ${(props) => props.theme.radius.circle};
`;

export const SliderNext = styled(Button)`
  position: absolute;
  top: 58px;
  right: -12px;
  padding: 4px 10px;
  width: 36px;
  height: 36px;
  border-color: ${(props) => props.theme.colors.primaryText};
  border-radius: ${(props) => props.theme.radius.circle};
  background-color: ${(props) => props.theme.colors.primaryText};
  color: white;
  z-index: 1;

  &:hover, &:focus {
    border-color: #40577D;
    background-color: #40577D;
    color: white;
  }
`;

export const SliderPrev = styled(Button)`
  position: absolute;
  top: 58px;
  left: -12px;
  padding: 4px 10px;
  width: 36px;
  height: 36px;
  border-color: ${(props) => props.theme.colors.primaryText};
  border-radius: ${(props) => props.theme.radius.circle};
  background-color: ${(props) => props.theme.colors.primaryText};
  color: white;
  z-index: 1;

  &:hover, &:focus {
    border-color: #40577D;
    background-color: #40577D;
    color: white;
  }
`;
