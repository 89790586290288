import React from 'react';
import { Col, Space } from 'antd';

import { Text } from '../../../commons/styles';

import { ReactComponent as ClockIcon } from "../../../assets/images/ic_clock.svg";

import * as Style from '../styles';

const TopVideo = ({
  videoItem
}) => {
  return (
    <div style={{ padding: '0 8px' }}>
      <Style.CardVideoContainer>
        <Style.CardVideoThumbnail thumbnail={videoItem.thumbnail} />
        <Style.CardVideoContent>
          <Text
          white
            xl
            fontSize="22px"
            truncateMultiLine={2}
            pointer
            style={{ marginBottom: 16 }}
          >
            {videoItem.title}
          </Text>
          <Space size={6}>
            <ClockIcon />
            <Text subText xs>
              {`${videoItem.createAt} | ${videoItem.url}`}
            </Text>
          </Space>
        </Style.CardVideoContent>
      </Style.CardVideoContainer>
    </div>
  );
};

export default TopVideo;
