import styled, { css } from 'styled-components';
import { Button } from 'antd';

export const MainContainer = styled.div``;

export const SidebarContainer = styled.div`
  width: 300px;
  padding: 0 24px;
  background-color: white;

  @media (max-width: 992px) {
    position: fixed;
    top: 64px;
    left: 0;
    height: calc(100vh - 64px);
    border-right: 1px solid #F1F1F5;
    z-index: 9;
    transform: translateX(-100%);
  }
`;

export const TVProgramContainer = styled.div`
  width: calc(100% - 300px);
  border-left: 1px solid #F1F1F5;

  @media (max-width: 992px) {
    width: 100%;
    border-left: none;
  }
`;

export const AddTVProgramContainer = styled.div`
  display: flex;
  align-items: center;
  height: 76px;
  border-bottom: 1px solid #F1F1F5;
`;

export const AddTVProgramButton = styled(Button)`
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: ${(props) => props.theme.fontWeight.w6};
`;

export const SidebarCalendar = styled.div`
  margin: 30px 0 24px;
`;

export const SidebarCalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const SidebarFilter = styled.div`
  margin: 24px 0;
  
  & .ant-collapse-header {
    padding: 0 !important;
  }

  & .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const SubHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  height: 76px;
  border-bottom: 1px solid #F1F1F5;
`;

export const ColumnChannelContainer = styled.div`
  display: flex;
  margin-top: 16px;
  overflow-x: auto;
`;

export const ColumnChannelContent = styled.div`
  &:first-child {
    & > .column-channel-title, & > .column-channel-schedule {
      padding-left: 30px;
    }
  }

  &:last-child {
    & > .column-channel-title, & > .column-channel-schedule {
      padding-right: 30px;
    }
  }
`;

export const ColumnChannelTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  width: 260px;
  height: 54px;
  border-top: 1px solid #F1F1F5;
  border-bottom: 1px solid #F1F1F5;
  box-sizing: content-box;

  & img {
    width: auto;
    height: auto;
  }
`;

export const ColumnChannelSchedule = styled.div`
  padding: 24px 15px;
  width: 260px;
  box-sizing: content-box;
`;

export const ColumnCurrentProgram = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid #F1F1F5;
`;

export const ColumnProgramThumbnail = styled.div`
  position: relative;
  width: 260px;
  height: 140px;
  border-radius: ${(props) => props.theme.radius.lg};
  background-image: url(${(props) => props.thumbnail});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
`;

export const ColumnProgramProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => `${props.progress}%` || 0};
  height: 100%;
  background-color: rgba(48, 191, 184, 0.7);
`;

export const ProgramTag = styled.div`
  padding: 4px 8px;
  border-radius: ${(props) => props.theme.radius.md};
  background-color: rgba(52, 69, 99, 0.08);
  color: ${(props) => props.theme.colors.primaryText};
  font-size: 11px;
  font-weight: ${(props) => props.theme.fontWeight.w6};

  ${(props) => props.live
    && css`
      background-color: rgba(48, 191, 184, 0.08);
      color: #30BFB8;
    `}

  ${(props) => props.premiere
    && css`
      background-color: rgba(76, 66, 255, 0.08);
      color: #4C42FF;
    `}
`;

export const ProgramTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 24px;
  border: 1px solid #EFF1F3;
  border-radius: ${(props) => props.theme.radius.md};
  color: ${(props) => props.theme.colors.secondaryText};
  font-size: 11px;
`;

export const ProgramTitle = styled.div`
  margin-left: 10px;
  width: calc(100% - 52px);
`;

export const ColumnNextPrograms = styled.div`
  margin-top: 24px;
`;

export const ListChannelContainer = styled.div`
  margin-top: 16px;

  & .ant-table-thead > tr > th {
    background-color: white;
  }
`;

export const SidebarFilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
