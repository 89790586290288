import React from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import styled from 'styled-components';

import Images from '../../../../themes/images';

const FontSizeMenu = styled.div`
  user-select: none;
  padding-bottom: 6px;
  padding-top: 6px;
  color: #202124;
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  background: white;
  z-index: 10;

  &:hover {
    background: rgba(32,33,36,0.059);
  }
`;

const renderFontSize = format => {
  switch (format) {
    case 'small':
      return 'x-small';
    case 'normal':
      return 'small';
    case 'large':
      return 'large';
    case 'very-large':
      return 'xx-large';
    default:
      return 'small'
  }
}

const FontSizeItem = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  font-size: ${p => renderFontSize(p.format)};
`;

const Active = styled.div`
  user-select: none;
  background-image: url(${Images.checkBlack});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  display: block;
  height: 20px;
  margin-left: -28px;
  position: absolute;
  width: 20px;
  margin-right: 12px;
  position: absolute;
  right: 100%;
`;

const reapplyMark = (editor, format, data) => {
  Editor.removeMark(editor, format);
  Editor.addMark(editor, format, data || true);
}

const getActiveFontSize = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks && marks[format];
}

const FontSizeButton = ({ format, type, data }) => {
  const editor = useSlate();
  const active = getActiveFontSize(editor, format) === data;
  return (
    <FontSizeMenu
      active={active}
      onMouseDown={event => {
        event.preventDefault();
        reapplyMark(editor, format, data);
      }}
    >
      <FontSizeItem format={data}>
        {active && <Active />}
        {type}
      </FontSizeItem>
    </FontSizeMenu>
  )
}

export default FontSizeButton;