import React from 'react';
import './trend_styles.scss';

export const Item = ({ trendingItem }) => {
  return (
    <div className="trend-item">
      <div className="row">
        <div className="col-md-5 trend-image">
          <img src={trendingItem.thumbnail} alt="" />
        </div>
        <div className="col-md-7 trend-detail">
          <div className="trend-categtory">{trendingItem.type}</div>
          <div className="trend-desc">{trendingItem.meta_description}</div>
          <div className="trend-link">https://www.google.com/</div>
        </div>
      </div>
    </div>
  )
} 