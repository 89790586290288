import React from "react";
import { DEFAULT_INBOX_TABS } from '../../../constants/mail';

const InboxTabs = ({
  onChangeInboxTab,
  inboxTab,
  totalUnseen,
}) => {
  function renderTabItems() {
    return DEFAULT_INBOX_TABS.map((tabItem, tabIndex) => {
      return (
        <a
          className={`nav-item nav-link ${
            inboxTab === tabItem.type ? "active" : ""
          }`}
          id={`nav-${tabItem.title.toLowerCase()}-tab`}
          data-toggle="tab"
          role="tab"
          aria-controls={`nav-${tabItem.title.toLowerCase()}`}
          aria-selected="true"
          onClick={() => onChangeInboxTab(tabItem.type)}
        >
          {tabItem.title}&nbsp;&nbsp;&nbsp;
          {totalUnseen.data?.[tabItem.type] > 0 && (
            <span
              className='category-tag'
              style={{backgroundColor: tabItem.color }}
            >
              {totalUnseen.data?.[tabItem.type]}&nbsp;&nbsp;New
            </span>
          )}
        </a>
      )
    })
  }

  return (
    <div className="mail-nav">
      <nav className="tabs">
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          {renderTabItems()}
        </div>
      </nav>
      <a href="#" className="add-nav-item">
        +
      </a>
    </div>
  );
};

export default InboxTabs;
