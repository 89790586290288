import React from 'react';
import { Route } from 'react-router-dom';

import Footer from './../../../commons/Footer/Footer';

const PublishLayout = ({
  component: Component,
  path,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <Component {...matchProps} />
          <Footer />
        </>
      )}
    />
  );
};

export default PublishLayout;
