import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from 'styled-components';

import configureStore from './configureStore';
import Root from "./screens/Root/Root";
import theme from './themes';
import 'antd/dist/antd.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'leaflet/dist/leaflet.css';
import 'suneditor/dist/css/suneditor.min.css';
import './styles/ant-design-custom.css';
import './styles/react-slick-custom.css';
import './styles/full-calendar-custom.css';

const store = configureStore();
class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Root />
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
