import React, { memo } from 'react'

const Attachments = memo(props => {
  const renderAttachments = () => {
    const attachments = [{
      title: 'down payment',
      size: '146.5 Kb'
    }, {
      title: 'project brief details',
      size: '146.5 Kb'
    }, {
      title: 'wireframe low fidelity',
      size: '146.5 Kb'
    }, {
      title: 'down payment',
      size: '146.5 Kb'
    }]
    return (
      <ul className="document-list list-unstyled">
        {
          attachments.map(item => (
            <li key={item.title}>
              <a href="#">
                <span className="icon-document"></span>
                <span className="document-title">{item.title}</span>
                <span className="document-size">{item.size}</span>
              </a>
            </li>
          ))
        }
      </ul>
    )
  }
  return (
    <div className="widget-item document-group">
      <h3 className="group-title rubikMedium">Document Files</h3>
      {renderAttachments()}
    </div>
  )
})

export default Attachments
