import { combineReducers } from 'redux';

import { AUTH_ACTION, SUCCESS } from '../constants';

import authReducer from './auth.reducer';
import mailsReducer from './user/mail.reducer';
import articleReducer from './user/article.reducer';

import adminArticleReducer from './admin/article.reducer';

const appReducer = combineReducers({
  auth: authReducer,
  mails: mailsReducer,
  article: articleReducer,

  adminArticle: adminArticleReducer,
});

// Reset reducer when logout
const rootReducer = (state, action) => {
  if (action.type === SUCCESS(AUTH_ACTION.LOGOUT)) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
