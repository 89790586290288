import {
  USER,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constants';

const initialState = {
  all: {
    items: [],
    loading: false,
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    error: null
  },
  inbox: {
    items: [],
    loading: false,
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    error: null
  },
  works: {
    items: [],
    loading: false,
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    error: null
  },
  social: {
    items: [],
    loading: false,
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    error: null
  },
  promotion: {
    items: [],
    loading: false,
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    error: null
  },
  important: {
    items: [],
    loading: false,
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    error: null
  },
  starred: {
    items: [],
    loading: false,
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    error: null
  },
  sent: {
    items: [],
    loading: false,
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    error: null
  },
  spam: {
    items: [],
    loading: false,
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    error: null
  },
  scheduled: {
    items: [],
    loading: false,
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    error: null
  },
  draft: {
    items: [],
    loading: false,
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    error: null
  },
  trash: {
    items: [],
    loading: false,
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    error: null
  },
  search: {
    items: [],
    loading: false,
    pages: {
      currentPage: 1,
      total: 0,
      totalPage: 0
    },
    error: null
  },
  detail: {
    data: {},
    loading: false,
    error: null
  },
  totalUnseen: {
    data: {},
    loading: false,
    error: null
  },
  savedDraft: {},
};

const mailsReducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST(USER.MAIL_ACTION.GET_ALL_MAILS):
    case REQUEST(USER.MAIL_ACTION.GET_INBOX_MAILS):
    case REQUEST(USER.MAIL_ACTION.GET_WORKS_MAILS):
    case REQUEST(USER.MAIL_ACTION.GET_SOCIAL_MAILS):
    case REQUEST(USER.MAIL_ACTION.GET_PROMOTION_MAILS):
    case REQUEST(USER.MAIL_ACTION.GET_IMPORTANT_MAILS):
    case REQUEST(USER.MAIL_ACTION.GET_STARRED_MAILS):
    case REQUEST(USER.MAIL_ACTION.GET_SENT_MAILS):
    case REQUEST(USER.MAIL_ACTION.GET_SPAM_MAILS):
    case REQUEST(USER.MAIL_ACTION.GET_DRAFT_MAILS):
    case REQUEST(USER.MAIL_ACTION.GET_TRASH_MAILS):
    case REQUEST(USER.MAIL_ACTION.SEARCH_MAIL): {
      const { path } = action.payload;
      return {
        ...state,
        [path.type]: {
          ...state[path.type],    
          loading: true
        },
      };
    }
    case SUCCESS(USER.MAIL_ACTION.GET_ALL_MAILS):
    case SUCCESS(USER.MAIL_ACTION.GET_INBOX_MAILS):
    case SUCCESS(USER.MAIL_ACTION.GET_WORKS_MAILS):
    case SUCCESS(USER.MAIL_ACTION.GET_SOCIAL_MAILS):
    case SUCCESS(USER.MAIL_ACTION.GET_PROMOTION_MAILS):
    case SUCCESS(USER.MAIL_ACTION.GET_IMPORTANT_MAILS):
    case SUCCESS(USER.MAIL_ACTION.GET_STARRED_MAILS):
    case SUCCESS(USER.MAIL_ACTION.GET_SENT_MAILS):
    case SUCCESS(USER.MAIL_ACTION.GET_SPAM_MAILS):
    case SUCCESS(USER.MAIL_ACTION.GET_DRAFT_MAILS):
    case SUCCESS(USER.MAIL_ACTION.GET_TRASH_MAILS):
    case SUCCESS(USER.MAIL_ACTION.SEARCH_MAIL): {
      const { path, data } = action.payload;
      return {
        ...state,
        [path.type]: {
          ...state[path.type],    
          items: data.items ? data.items : [],
          ...data.pages && { pages: data.pages },
          loading: false,
          error: null
        },
      };
    }
    case FAILURE(USER.MAIL_ACTION.GET_ALL_MAILS):
    case FAILURE(USER.MAIL_ACTION.GET_INBOX_MAILS):
    case FAILURE(USER.MAIL_ACTION.GET_WORKS_MAILS):
    case FAILURE(USER.MAIL_ACTION.GET_SOCIAL_MAILS):
    case FAILURE(USER.MAIL_ACTION.GET_PROMOTION_MAILS):
    case FAILURE(USER.MAIL_ACTION.GET_IMPORTANT_MAILS):
    case FAILURE(USER.MAIL_ACTION.GET_STARRED_MAILS):
    case FAILURE(USER.MAIL_ACTION.GET_SENT_MAILS):
    case FAILURE(USER.MAIL_ACTION.GET_SPAM_MAILS):
    case FAILURE(USER.MAIL_ACTION.GET_DRAFT_MAILS):
    case FAILURE(USER.MAIL_ACTION.GET_TRASH_MAILS):
    case FAILURE(USER.MAIL_ACTION.SEARCH_MAIL): {
      const { path } = action.payload;
      return {
        ...state,
        [path.type]: {
          ...state[path.type],
          loading: false,
          error: action.error
        },
      };
    }

    case REQUEST(USER.MAIL_ACTION.GET_MAIL_DETAIL):
			return {
				...state,
        detail: {
          ...state.detail,
          loading: true,
        },
			};
		case SUCCESS(USER.MAIL_ACTION.GET_MAIL_DETAIL): {
      const { data } = action.payload;
			return {
				...state,
        detail: {
          ...state.detail,
          data: {
            ...data.detail,
            replies: data.replies ? data.replies : [],
          },
          loading: false,
          error: false,
        },
			};
    }
		case FAILURE(USER.MAIL_ACTION.GET_MAIL_DETAIL):
			return {
				...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.error,
        },
			};

    case REQUEST(USER.MAIL_ACTION.SET_MAIL_DETAIL): {
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data: action.payload,
        },
      }
    }

    case REQUEST(USER.MAIL_ACTION.GET_TOTAL_UNSEEN_MAIL):
      return {
        ...state,
        totalUnseen: {
          ...state.totalUnseen,
          loading: true,
        },
      };
    case SUCCESS(USER.MAIL_ACTION.GET_TOTAL_UNSEEN_MAIL): {
      const { data } = action.payload;
      return {
        ...state,
        totalUnseen: {
          ...state.totalUnseen,
          data,
          loading: false,
          error: false,
        },
      };
    }
    case FAILURE(USER.MAIL_ACTION.GET_TOTAL_UNSEEN_MAIL):
      return {
        ...state,
        totalUnseen: {
          ...state.totalUnseen,
          loading: false,
          error: action.error,
        },
      };

    case REQUEST(USER.MAIL_ACTION.SET_IMPORTANT): {
      const { type, data } = action.payload;
      const newItems = state[type].items.map((mailItem) => {
        if (data.ids.indexOf(mailItem.Id) !== -1 && mailItem.Important === 0) {
          return {
            ...mailItem,
            Important: 1,
          }
        }
        return mailItem;
      });
      return {
        ...state,
        [type]: {
          ...state[type],    
          items: newItems,
        },
      };
    }
    // UPDATE_LATE: If error then redo
    case FAILURE(USER.MAIL_ACTION.SET_IMPORTANT): {
      return state;
    }

    case REQUEST(USER.MAIL_ACTION.REMOVE_IMPORTANT): {
      const { type, data } = action.payload;

      if (type === "important") return state;

      const newItems = state[type].items.map((mailItem) => {
        if (data.ids.indexOf(mailItem.Id) !== -1 && mailItem.Important === 1) {
          return {
            ...mailItem,
            Important: 0,
          }
        }
        return mailItem;
      });
      return {
        ...state,
        [type]: {
          ...state[type],    
          items: newItems,
        },
      };
    }
    // UPDATE_LATE: If error then redo
    case FAILURE(USER.MAIL_ACTION.REMOVE_IMPORTANT): {
      return state;
    }

    case REQUEST(USER.MAIL_ACTION.SET_STARRED): {
      const { type, data } = action.payload;
      const newItems = state[type].items.map((mailItem) => {
        if (data.ids.indexOf(mailItem.Id) !== -1 && mailItem.Starred === 0) {
          return {
            ...mailItem,
            Starred: 1,
          }
        }
        return mailItem;
      });
      return {
        ...state,
        [type]: {
          ...state[type],    
          items: newItems,
        },
      };
    }
    // UPDATE_LATE: If error then redo
    case FAILURE(USER.MAIL_ACTION.SET_STARRED): {
      return state;
    }

    case REQUEST(USER.MAIL_ACTION.REMOVE_STARRED): {
      const { type, data } = action.payload;
      const newItems = state[type].items.map((mailItem) => {
        if (data.ids.indexOf(mailItem.Id) !== -1 && mailItem.Starred === 1) {
          return {
            ...mailItem,
            Starred: 0,
          }
        }
        return mailItem;
      });
      return {
        ...state,
        [type]: {
          ...state[type],    
          items: newItems,
        },
      };
    }
    // UPDATE_LATE: If error then redo
    case FAILURE(USER.MAIL_ACTION.REMOVE_STARRED): {
      return state;
    }

    case SUCCESS(USER.MAIL_ACTION.SET_SPAM): {
      return state;
    }

    case REQUEST(USER.MAIL_ACTION.MARK_SEEN): {
      const { type, data } = action.payload;
      const newItems = state[type].items.map((mailItem) => {
        if (data.ids.indexOf(mailItem.Id) !== -1 && mailItem.Seen === 0) {
          return {
            ...mailItem,
            Seen: 1,
          }
        }
        return mailItem;
      });
      return {
        ...state,
        [type]: {
          ...state[type],    
          items: newItems,
        },
      };
    }
    // UPDATE_LATE: If error then redo
    case FAILURE(USER.MAIL_ACTION.MARK_SEEN): {
      return state;
    }

    case REQUEST(USER.MAIL_ACTION.MARK_UNSEEN): {
      const { type, data } = action.payload;
      const newItems = state[type].items.map((mailItem) => {
        if (data.ids.indexOf(mailItem.Id) !== -1 && mailItem.Seen === 1) {
          return {
            ...mailItem,
            Seen: 0,
          }
        }
        return mailItem;
      });
      return {
        ...state,
        [type]: {
          ...state[type],    
          items: newItems,
        },
      };
    }
    // UPDATE_LATE: If error then redo
    case FAILURE(USER.MAIL_ACTION.MARK_UNSEEN): {
      return state;
    }

    case SUCCESS(USER.MAIL_ACTION.MOVE_TO_TAB): {
      return state;
    }

    case REQUEST(USER.MAIL_ACTION.SET_SAVED_DRAFT): {
      return {
        ...state,
        savedDraft: action.payload,
      };
    }

		default:
			return state;
	}
};

export default mailsReducer;
