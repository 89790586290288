import React, { useEffect } from 'react';
import { Row, Col, Form, Space } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash/lang';

import history from '../../utils/history';

import {
  Text,
  AuthInput,
  AuthPassword,
  AuthCheckbox,
  AuthButton,
} from '../../commons/styles';

import { register as registerAction } from './../../redux/actions';

import Image from '../../themes/images';
import { ReactComponent as UserIcon } from "../../assets/images/ic_user.svg";
import { ReactComponent as KeyIcon } from "../../assets/images/ic_key.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/ic_email.svg";

import * as Style from './styles';

const RegisterPage = ({ register, registerData }) => {
  const [registerForm] = Form.useForm();

  useEffect(() => {
    if (!isEmpty(registerData.errors) && registerData.errors.email) {
      registerForm.setFields([
        {
          name: ['email'],
          errors: [registerData.errors.email],
        },
      ])
    }
  }, [registerData.errors]);

  function handleSubmitForm(values) {
    if (!moment([values.year, values.month - 1, values.day]).isValid()) {
      registerForm.setFields([
        {
          name: ['birthday'],
          errors: ['Incorrect date format!'],
        },
      ]);
    } else {
      registerForm.setFields([
        {
          name: ['birthday'],
          errors: [],
        },
      ]);
      const newValues = {
        username: `${values.email}`,
        email: `${values.email}@phuk.net`,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        birthday: `${values.year}-${values.month}-${values.day}`,
      }
      register({ data: newValues });
    }
  }

  return (
    <Style.RegisterContainer>
      <Style.RegisterBackground>
        <img src={Image.registerImage} alt="RegisterImage" />
        <Style.RegisterIntroduce>
          <Text headerText xxxl center w6 style={{ margin: '64px 0 42px' }}>
            Did you Know? With Phuk You Can…
          </Text>
          <Space size={[28, 28]} wrap style={{ justifyContent: 'center' }}>
            <Space size={10}>
              <Style.OvalContainer>
                <img src={Image.icEmailWhite} alt="" />
              </Style.OvalContainer>
              <Text subText lg>
                Goodbye Email Attachment
              </Text>
            </Space>
            <Space size={10}>
              <Style.OvalContainer>
                <img src={Image.icChatWhite} alt="" />
              </Style.OvalContainer>
              <Text subText lg>
                Instant, Actionable Feedback
              </Text>
            </Space>
            <Space size={10}>
              <Style.OvalContainer>
                <img src={Image.icStorageWhite} alt="" />
              </Style.OvalContainer>
              <Text subText lg>
                Large Storage in Phuk Drive
              </Text>
            </Space>
            <Space size={10}>
              <Style.OvalContainer>
                <img src={Image.icUpdateWhite} alt="" />
              </Style.OvalContainer>
              <Text subText lg>
                Latest Version Always
              </Text>
            </Space>
          </Space>
        </Style.RegisterIntroduce>
      </Style.RegisterBackground>
      <Style.RegisterContent>
        <Style.RegisterWrapper>
          <img src={Image.logoSmallNew} width={88} height="auto" alt="Logo" />
          <Text primaryText xxxl w6 style={{ margin: '46px 0 20px' }}>Register</Text>
          <Text subText lg>to continue using PhukMail</Text>
          <Style.RegisterFormContainer>
            <Form
              form={registerForm}
              name="registerForm"
              onFinish={(values) => handleSubmitForm(values)}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    rules={[{ required: true, message: 'Please input your first name!' }]}
                  >
                    <AuthInput placeholder="First Name" prefix={<UserIcon />} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    rules={[{ required: true, message: 'Please input your last name!' }]}
                  >
                    <AuthInput placeholder="Last Name" prefix={<UserIcon />} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                ]}
              >
                <AuthInput placeholder="Email" prefix={<EmailIcon />} suffix="@phuk.net" />
              </Form.Item>
 
              <Form.Item
                name="birthday"
                rules={[
                  ({ getFieldValue }) => ({
                    validator() {
                      if (getFieldValue('day') &&  getFieldValue('month') && getFieldValue('year')) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Please input your birthday!');
                    },
                  }),
                ]}
              >
                <Form.Item
                  name="day"
                  style={{ display: 'inline-block', marginBottom: 0, width: 'calc(30% - 16px)' }}
                >
                  <AuthInput  placeholder="Day"  />
                </Form.Item>
                <Form.Item
                  name="month"
                  style={{ display: 'inline-block', margin: '0 16px', width: 'calc(30% - 16px)' }}
                >
                  <AuthInput  placeholder="Month" />
                </Form.Item>
                <Form.Item
                  name="year"
                  style={{ display: 'inline-block', marginBottom: 0, width: '40%' }}
                >
                  <AuthInput  placeholder="Year" />
                </Form.Item>
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <AuthPassword placeholder="Password" prefix={<KeyIcon />} />
              </Form.Item>
              <Form.Item
                name="repeatPassword"
                rules={[
                  { required: true, message: 'Please input your repeat password!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Repeat password did not match!');
                    },
                  }),
                ]}
              >
                <AuthPassword placeholder="Repeat Password" prefix={<KeyIcon />} />
              </Form.Item>
              <Form.Item
                name="agree"
                valuePropName="checked"
              >
                <AuthCheckbox>
                  <Text subText>I agree about Terms & Condition</Text>
                </AuthCheckbox>
              </Form.Item>
              <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                {() => (
                  <AuthButton
                    type="primary"
                    htmlType="submit"
                    block
                    disabled={!registerForm.getFieldValue('agree')}
                  >
                    Register
                  </AuthButton>
                )}
              </Form.Item>
            </Form>
          </Style.RegisterFormContainer>
          <Text subText>
            Have an account?&nbsp;
            <Style.RedirectLink onClick={() => history.push('/login')}>
              Login here
            </Style.RedirectLink>
          </Text>
        </Style.RegisterWrapper>
      </Style.RegisterContent>
    </Style.RegisterContainer>
  );
};

const mapStateToProp = (state) => {
  return {
    registerData: state.auth.registerData,
  };
};

const mapDispatchToProps = (dispatch) => {
	return {
		register: (params) => {dispatch(registerAction(params))},
	};
};

export default connect(mapStateToProp,mapDispatchToProps)(RegisterPage);
