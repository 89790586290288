import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Row,
  Space,
  Card,
  Table,
  Pagination,
  Button,
  Modal,
} from 'antd';
import { PlusOutlined, ExclamationCircleOutlined  } from '@ant-design/icons';
import moment from 'moment';

import history from '../../../utils/history';

import { DATE_FORMAT } from '../../../constants/date';
import { ROUTES } from '../../../constants/routes';

import { Text } from '../../../commons/styles';

import {
  getArticleListAdmin as getArticleListAdminAction,
  deleteArticleAdmin as deleteArticleAdminAction,
} from '../../../redux/actions';

const Articles = ({
  getArticleListAdmin,
  deleteArticleAdmin,
  articleList,
}) => {
  useEffect(() => {
    getArticleListAdmin({
      param: {
        page: 1,
        limit: 10,
      }
    });
  }, []);

  function onDeleteArticle(record) {
    Modal.confirm({
      title: 'Are you sure delete this article?',
      icon: <ExclamationCircleOutlined />,
      content: record.title,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deleteArticleAdmin({ data: { records: record.id } });
      },
    });
  }

  const tableColumn = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => history.push(`/admin/article/${record.id}`)}
          >
            Edit
          </Button>
          <Button
            size="small"
            onClick={() => onDeleteArticle(record)}
            danger
          >
            Delete
          </Button>
        </Space>
      ),
      width: 150,
    }
  ];

  const tableData = articleList.data.map((articleItem) => ({
    key: articleItem.id,
    createdAt: moment(articleItem.created_at).format(DATE_FORMAT),
    ...articleItem,
  }))

  return (
    <div>
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Text xl w6>Article List</Text>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push(ROUTES.ADMIN.CREATE_ARTICLE)}
        >
          New
        </Button>
      </Row>
      <Card>
        <Table
          columns={tableColumn}
          dataSource={tableData}
          loading={articleList.loading}
          pagination={false}
        />
        <Row justify="end" style={{ marginTop: 16 }}>
          <Pagination
            current={articleList.pages.currentPage}
            total={articleList.pages.total}
            onChange={(page) => getArticleListAdmin({ param: { page, limit: 10 } })}
          />
        </Row>
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { articleList } = state.adminArticle;
  return {
    articleList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getArticleListAdmin: (data) => dispatch(getArticleListAdminAction(data)),
    deleteArticleAdmin: (data) => dispatch(deleteArticleAdminAction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
