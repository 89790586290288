import React, { memo } from 'react'
import { TRENDS } from '../../../mockdata/homeData';

const TrendWrapper = memo(props => {
  function renderTrendItems() {
    return TRENDS.map((item, index) => (
      <li key={`trend${index}`} style={{ width: 'calc((100% - 10px) / 2)' }}>
        <a href="#">#EndGame</a>
      </li>
    ))
  }
  return (
    <ul className="list-tag list-unstyled" style={{ display: 'flex', flexWrap: 'wrap' }}>
      {renderTrendItems()}
    </ul>
  )
})

export default TrendWrapper;
