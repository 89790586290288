import RestClient from './restClient';

import AuthServices from './auth.service';

import MailServices from './user/mail.service';
import ArticleServices from './user/article.service';

import AdminArticleServices from './admin/article.service';

import AdminServices from './admin/admin.service';

const restClient = new RestClient();

export const authServices = new AuthServices(restClient);

export const mailServices = new MailServices(restClient);
export const articleServices = new ArticleServices(restClient);

export const adminArticleServices = new AdminArticleServices(restClient);

export const adminServices = new AdminServices(restClient);
