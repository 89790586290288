import React, { memo } from 'react'
import ProductItem from './ProductItem'
import { PHUK_DATA } from './../../../mockdata/homeData'
import ReactWOW from 'react-wow'

const Mall = memo(props => {
  const { mallTitle, image } = props
  const mallMenu = {
    PhukMall: [{
      title: 'Automotive',
      link: '#'
    }, {
      title: 'Accessories',
      link: '#'
    }, {
      title: 'bookstore',
      link: '#'
    }, {
      title: 'children',
      link: '#'
    }, {
      title: 'health',
      link: '#'
    }],
    Hardware: [{
      title: 'computer',
      link: '#'
    }, {
      title: 'laptop',
      link: '#'
    }, {
      title: 'tablet',
      link: '#'
    }, {
      title: 'smartphone',
      link: '#'
    }, {
      title: 'keyboard',
      link: '#'
    }],
    PhukPlus: [{
      title: 'food',
      link: '#'
    }, {
      title: 'taxi',
      link: '#'
    }, {
      title: 'vehicales',
      link: '#'
    }, {
      title: 'hotels',
      link: '#'
    }, {
      title: 'travel',
      link: '#'
    }, {
      title: 'financial',
      link: '#'
    }]
  }

  return (
    <div className={`product-items product-item_${mallTitle.toLowerCase()}`}>
      <ReactWOW delay='0.3s' animation='bounceInUp' duration='1s' >
        <div className="product-items_title box-flex" data-wow-duration="1s" data-wow-delay="0.3s">
          <h3 className="rubikMedium"><i className="icon-cart"></i>{mallTitle}</h3>
          <ul className="category-tag-list box-flex list-unstyled">
            {
              mallMenu[mallTitle].map(item => (
                <li key={`mall${item.title}`}><a href={item.link}>{item.title}</a></li>
              ))
            }
            <li className="show-all"><a href="#">show all</a></li>
          </ul>
        </div>
      </ReactWOW>
      <div className="show-product-items box-flex">
        <ReactWOW delay='0.4s' animation='flipInY' duration='1s' >
          <div className="product-item product-item-highlight" data-wow-duration="1s" data-wow-delay="0.4s">
            {mallTitle.toLowerCase().includes('plus') ?
              <div className="map-container">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30674.245446793015!2d108.18602098499129!3d16.05091815047652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314219b2ffe8830b%3A0xa1279daba3a14ddb!2zQ8O0bmcgVmnDqm4gMjkgVGjDoW5nIDM!5e0!3m2!1svi!2s!4v1571123257142!5m2!1svi!2s" width="600" height="450" frameBorder="0" style={{ border: "0" }} allowFullScreen=""></iframe>
              </div> :
              <div className="thumbnail">
                <a href="#" style={{ backgroundImage: `url(${image})` }}></a>
              </div>
            }
          </div>
        </ReactWOW>
        <ReactWOW delay='0.4s' animation='flipInY' duration='1s' >
          <div className="product-item show-product-list">
            {
              PHUK_DATA[mallTitle].map((item, index) =>
                index < 3 ? <ProductItem
                  key={item.name}
                  type={`${mallTitle.toLowerCase().includes('plus') ? 'plus' : 'cart'}`}
                  {...item}
                  isSelling
                /> : null
              )
            }
          </div>
        </ReactWOW>
        <ReactWOW delay='0.4s' animation='flipInY' duration='1s' >
          <div className="product-item show-product-list">
            {
              PHUK_DATA[mallTitle].map((item, index) =>
                index >= 3 ? <ProductItem
                  key={item.name}
                  type={`${mallTitle.toLowerCase().includes('plus') ? 'plus' : 'cart'}`}
                  {...item}
                  isSelling
                /> : null
              )
            }
          </div>
        </ReactWOW>
      </div>
    </div>
  )
})

export default Mall
