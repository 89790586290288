import React from "react";
const CheckBox = ({ checked, onSelectItem, isHorizontalLine }) => {
  return (
    <>
      <input type="checkbox" className="form-check-input" checked={checked} />
      <label
        className={`${
          isHorizontalLine ? "form-check-not-total" : "form-check-label"
        } pointer`}
        htmlFor="mail-inbox1"
        onClick={() => onSelectItem()}
      ></label>
    </>
  );
};

export default CheckBox;
