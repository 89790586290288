import React from 'react';
import classnames from 'classnames';
import styles from './MailBodyWrapper.module.css';

const MailBodyWrapper = ({ children }) => {
  return (
    <div
      style={{ padding: 0 }}
      className={
        classnames("inbox-body", styles.wrapper)}>
      {children}
    </div>
  );
};

export default MailBodyWrapper;
