import RestClient from '../restClient';

export default class MailServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getType = (type) => {
    switch (type) {
      case 'work':
        return 'works';
      case 'promotion':
        return 'promotions';
      default:
        return type;
    }
  }

  getMailList({ path, param }) {
    return this.restClient.get(`/mail/${this.getType(path.type)}`, param);
  }

  getDraftList({ param }) {
    return this.restClient.get(`/draft/list`, param);
  }

  getMailDetail({ path, param }) {
    return this.restClient.get(`/mail/detail/${path.id}`, param);
  }

  getDraftDetail({ path }) {
    return this.restClient.get(`/draft/detail/${path.id}`);
  }

  sendMail({ data }) {
    return this.restClient.post('/mail/sendmail', data);
  };

  removeMails({ data }) {
    return this.restClient.post('/mail/remove', data);
  };

  deleteMails({ data }) {
    return this.restClient.post('/mail/delete', data);
  };

  deleteDrafts({ data }) {
    return this.restClient.post('/draft/delete', data);
  };

  searchMail({ param }) {
    return this.restClient.get('/mail/search', param);
  };

  saveDraft({ data }) {
    return this.restClient.post('/draft/save', data);
  };

  setImportant({ data }) {
    return this.restClient.post('/mail/important', data);
  };

  removeImportant({ data }) {
    return this.restClient.post('/mail/remove_important', data);
  };

  setStarred({ data }) {
    return this.restClient.post('/mail/starred', data);
  };

  removeStarred({ data }) {
    return this.restClient.post('/mail/remove_starred', data);
  };

  setSpam({ data }) {
    return this.restClient.post('/mail/spam', data);
  };

  markSeen({ data }) {
    return this.restClient.post('/mail/mark_seen', data);
  };

  markUnSeen({ data }) {
    return this.restClient.post('/mail/mark_unseen', data);
  };

  getTotalUnseenMail() {
    return this.restClient.get('/mail/total_mailbox');
  }

  moveToTab({ data }) {
    return this.restClient.post('/mail/move_to_tab', data);
  };
}
