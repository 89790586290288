import React from 'react';
import { useSlate } from 'slate-react';

import {
  Button,
  Icon,
  Toolbar,
  ToolBarWrapper,
  Spliter,
} from './components';
import {
  isMarkActive,
  isBlockActive,
  toggleBlock,
  toggleMark,
  handleIndent,
} from './EditorUtil';
import GroupButton from './GroupButton';
import { BlockQuoteButton } from './plugins/BlockQuote';
import { FontFamilyDropDown } from './plugins/FontFamily';
import { FontSizeButton } from './plugins/FontSize';
import { ColorSelector } from './plugins/Color';

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const MarkButton = ({ format, icon, data, clear }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format) && !clear}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format, data, clear);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const HistoryButton = ({ type, icon }) => {
  const editor = useSlate();
  return (
    <Button
      onMouseDown={event => {
        event.preventDefault();
        if (type === 'undo') {
          editor.undo();
        } else if (type === 'redo') {
          editor.redo();
        }
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const IndentButton = ({ format, icon, clear }) => {
  const editor = useSlate();
  return (
    <Button
      onMouseDown={event => {
        event.preventDefault();
        handleIndent(editor, format, clear);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const ToolBar = () => (
  <Toolbar>
    <ToolBarWrapper>
      <HistoryButton type="undo" icon="undo" />
      <HistoryButton type="redo" icon="redo" />
      <Spliter />
      <FontFamilyDropDown />
      <Spliter />
      <GroupButton icon="format_size">
        <FontSizeButton format="font-size" data="small" type="small" />
        <FontSizeButton format="font-size" data="normal" type="normal" />
        <FontSizeButton format="font-size" data="large" type="large" />
        <FontSizeButton format="font-size" data="very-large" type="very large" />
      </GroupButton>
      <Spliter />
      <MarkButton format="bold" icon="format_bold" />
      <MarkButton format="italic" icon="format_italic" />
      <MarkButton format="underline" icon="format_underlined" />
      <ColorSelector />
      <Spliter />
      <GroupButton icon="format_align_center">
        <table>
          <tbody>
            <tr>
              <td>
                <BlockButton format="align-left" icon="format_align_left" />
              </td>
            </tr>
            <tr>
              <td>
                <BlockButton format="align-center" icon="format_align_center" />
              </td>
            </tr>
            <tr>
              <td>
                <BlockButton format="align-right" icon="format_align_right" />
              </td>
            </tr>
          </tbody>
        </table>
      </GroupButton>
      <BlockButton format="numbered-list" icon="format_list_numbered" />
      <BlockButton format="bulleted-list" icon="format_list_bulleted" />
      <IndentButton format="indent" clear icon="format_indent_decrease" />
      <IndentButton format="indent" icon="format_indent_increase" />
      <BlockQuoteButton format="block-quote" icon="format_quote" />
      <Spliter />
      <MarkButton format="strikethrough" icon="strikethrough_s" />
      <Spliter />
      <MarkButton format="strikethrough" clear icon="format_clear" />
    </ToolBarWrapper>
  </Toolbar>
)

export default ToolBar;
