import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import chunk from 'lodash/chunk';

import ColorPicker from './ColorPicker';
import { colors } from './Colors';
import { Icon } from '../../components';
import { Container, Wrapper, Button, DropDown } from '../common/components';

const ColorTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  margin: 4px;
`;

const Title = styled.td`
  padding: 8px;
  color: #222;
`;

const ColorRowWrapper = styled.div``;

const ColorRow = styled.div`
  cursor: default;
  outline: none;
`;

const ColorRowTable = styled.table`
  margin: 0;
  padding: 0 6px 6px;
  empty-cells: show;
`;

const renderColorTable = (colors, format) => {
  const chunkColors = chunk(colors, 8);
  const [ firstBlock, secondBlock, ...restBlock ] = chunkColors;
  return (
    <>
      <ColorRowWrapper>
        <ColorRow>
          <ColorRowTable>
            <tbody>
              <tr>
                {firstBlock.map(color => (
                  <ColorPicker
                    color={color.value}
                    format={format}
                  />
                ))}
              </tr>
            </tbody>
          </ColorRowTable>
        </ColorRow>
      </ColorRowWrapper>
      <ColorRowWrapper>
        <ColorRow>
          <ColorRowTable>
            <tbody>
              <tr>
                {secondBlock.map(color => (
                  <ColorPicker
                    color={color.value}
                    format={format}
                  />
                ))}
              </tr>
            </tbody>
          </ColorRowTable>
        </ColorRow>
      </ColorRowWrapper>
      <ColorRowWrapper>
        <ColorRow>
          <ColorRowTable>
            <tbody>
              {restBlock.map(colorBlocks => (
                <tr>
                  {colorBlocks.map(color => (
                    <ColorPicker
                      color={color.value}
                      format={format}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
          </ColorRowTable>
        </ColorRow>
      </ColorRowWrapper>
    </>
  )
}

const ColorSelector = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const toggle = () => setOpen(!open);

  const handleClickOutside = (event) => {
    if (ref.current
      && !ref.current.contains(event.target)
      && !event.target.attributes['data-popup']
    ) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <Container>
      <Wrapper data-popup onClick={toggle}>
        <Button data-popup>
          <Icon data-popup>text_format</Icon>
        </Button>
        <Button data-popup>
          <Icon data-popup>arrow_drop_down</Icon>
        </Button>
      </Wrapper>

      {open && (
        <DropDown ref={ref}>
          <ColorTable>
            <tbody>
              <tr>
                <Title>Background Color</Title>
                <Title>Text Color</Title>
              </tr>
              <tr>
                <td>
                  {renderColorTable(colors, 'background-color')}
                </td>
                <td>
                  {renderColorTable(colors, 'text-color')}
                </td>
              </tr>
            </tbody>
          </ColorTable>
        </DropDown>
      )}
    </Container>
  );
}

export default ColorSelector;
