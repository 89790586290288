import styled, { css } from 'styled-components';
import { Button } from 'antd';

export const NewsContainer = styled.div`
  padding-top: 4px;
`;

export const CategoryContainer = styled.div``;

export const CategoryTitle = styled.div`
  position: relative;
  padding: 8px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary};

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 32px;
    height: 1px;
    background-color: ${(props) => props.theme.colors.primary};
  }

  ${(props) => props.white
    && css`
      border-bottom: 1px solid ${(props) => props.theme.colors.subText};

      &::after {
        content: "";
        background-color: ${(props) => props.theme.colors.subText};
      }
    `}
`;

export const CategoryContent = styled.div`
  padding: 16px 0;
`;

export const SmallNewsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 6px;
  border-radius: ${(props) => props.theme.radius.xxl};
  background-color: #FAFAFB;
  cursor: pointer;

  &:hover {
    & .small-news-title {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SmallNewsImage = styled.div`
  margin-right: 16px;
  min-width: 80px;
  height: 80px;
  border-radius: ${(props) => props.theme.radius.xxl};
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const SmallNewsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardNewsContainer = styled.div`
  position: relative;
  margin: ${(props) => props.isHeadline ? '0px 2px' : '0px'};
  padding-top: ${(props) => props.isHeadline ? '66.66%' : '100%'};
  width: ${(props) => props.isHeadline ? 'calc(100% - 4px)' : '100%'};
  border-radius: ${(props) => props.theme.radius.xxl};
  overflow: hidden;

  &:hover {
    & > div:first-child {
      transform: scale(1.1);
    }
  }
`;

export const CardNewsImage = styled.div`
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.7s;
`;

export const CardNewsContent = styled.div`
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.7) 100%);
`;

export const CardNewsTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardNewsLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 24px;
  border-radius: ${(props) => props.theme.radius.md};
  background-color: #4C42FF;
  color: white;
  font-weight: ${(props) => props.theme.fontWeight.w6};
`;

export const TopVideoBackground = styled.div`
  margin: 20px 0 36px;
  background-color: #1A1A28;
`;

export const TopVideoContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 28px;
`;

export const CardVideoContainer = styled.div`
  position: relative;
  padding-top: 66.66%;
  border-radius: ${(props) => props.theme.radius.xxl};
  overflow: hidden;

  &:hover {
    & > div:first-child {
      transform: scale(1.1);
    }
  }
`;

export const CardVideoThumbnail = styled.div`
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(${(props) => props.thumbnail});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.7s;
`;

export const CardVideoContent = styled.div`
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.8) 100%);
`;

export const PrimaryNewsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 12px 20px 12px 12px;
  border-radius: ${(props) => props.theme.radius.xxl};
  border: 1px solid #E6E6E7;
  cursor: pointer;

  &:hover {
    & .primary-news-title {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const PrimaryNewsImage = styled.div`
  margin-right: 16px;
  min-width: 240px;
  height: 156px;
  border-radius: ${(props) => props.theme.radius.xxl};
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 992px) {
    min-width: 200px;
    height: 120px;
  }

  @media (max-width: 600px) {
    min-width: 120px;
    height: 120px;
  }
`;

export const PrimaryNewsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PrimaryNewsBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PrimaryNewsMore = styled.div`
  display: flex;
  justify-content: center;
  margin: 42px 0 52px;
`;

export const LoadMoreButton = styled(Button)`
  width: 150px;
  color: #44444F;
  font-weight: ${(props) => props.theme.fontWeight.w6};
  
  // UPDATE_LATE
  & svg {
    transition: unset;
  }

  &:hover {
    border-color: ${(props) => props.theme.colors.primary75};
    color: ${(props) => props.theme.colors.primary75};

    & svg {
      fill: ${(props) => props.theme.colors.primary75};
    }
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.primary75};
    color: ${(props) => props.theme.colors.primary75};

    & svg {
      fill: ${(props) => props.theme.colors.primary75};
    }
  }

  &:active {
    border-color: ${(props) => props.theme.colors.primary125};
    color: ${(props) => props.theme.colors.primary125};

    & svg {
      fill: ${(props) => props.theme.colors.primary125};
    }
  }
`;
