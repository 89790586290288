import React from 'react';

import { Text } from '../../../commons/styles';

import * as Style from '../styles';

const NoEmailResult = () => {
  return (
    <Style.NoEmailResultContainer>
      <Text>There are no conversations with this mailbox.</Text>
    </Style.NoEmailResultContainer>
  );
};

export default NoEmailResult;
