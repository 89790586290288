import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { MAIL_TYPES } from "../../constants/mail";
// import ComposeEmail from "../../screens/ComposeEmail/ComposeEmail";
import ComposeEmail from "../../screens/MailBox/components/ComposeEmail";

import {
  getTotalUnseenMail as getTotalUnseenMailAction,
  setMailDetail as setMailDetailAction,
} from "../../redux/actions";

import { ReactComponent as RefreshIcon } from "./../../assets/images/refresh.svg";
import { ReactComponent as FileIcon } from "./../../assets/images/file.svg";
import { ReactComponent as ClockIcon } from "./../../assets/images/clock.svg";
import { ReactComponent as EnvelopeIcon } from "./../../assets/images/envelope.svg";
import { ReactComponent as TrashIcon } from "./../../assets/images/trash.svg";
import { ReactComponent as UpIcon } from "./../../assets/images/up.svg";
import { ReactComponent as RectangleIcon } from "./../../assets/images/Rectangle.svg";
import { ReactComponent as AddIcon } from "./../../assets/images/add.svg";
import { ReactComponent as OctagonIcon } from "./../../assets/images/alert-octagon.svg";

const getCurrentTab = (pathName) => {
  if (pathName.includes("inbox")) return MAIL_TYPES.INBOX;
  if (pathName.includes("sent")) return MAIL_TYPES.SENT;
  if (pathName.includes("draft")) return MAIL_TYPES.DRAFT;
  if (pathName.includes("trash")) return MAIL_TYPES.TRASH;
  if (pathName.includes("work")) return MAIL_TYPES.WORK;
  if (pathName.includes("social")) return MAIL_TYPES.SOCIAL;
  if (pathName.includes("promotion")) return MAIL_TYPES.PROMOTION;
};

class LeftSideBar extends React.Component {
  static propsType = {};

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      isVisibleComposeEmail: false,
      isExpandingCompose: false,
      showLess: false,
    };
    this._onToggleComposePopup = this._onToggleComposePopup.bind(this);
  }

  componentDidMount() {
    window.addEventListener(
      "resize",
      () => {
        if (window.innerWidth < 1200 && !this.props.sideBarOpen) {
          this.props.toggleSideBar(window.innerWidth < 1200);
        } else if (window.innerWidth > 1200 && this.props.sideBarOpen) {
          this.props.toggleSideBar(window.innerWidth > 1200);
        }
      },
      false
    );

    this.props.getTotalUnseenMail();
  }

  fetchEmails = (mailType) => {
    this.props.resetSelectItem && this.props.resetSelectItem();
  };

  _onToggleComposePopup() {
    const { isVisibleComposeEmail } = this.state;
    this.setState({
      isVisibleComposeEmail: !isVisibleComposeEmail,
      isExpandingCompose: false,
    });
  }

  setShowLess = () => {
    this.setState({
      showLess: !this.state.showLess,
    });
  };

  toggleExpandCompose = (value = false) => {
    this.setState({ isExpandingCompose: value });
  };

  render() {
    const {
      isVisibleComposeEmail,
      trashActive,
      isExpandingCompose,
    } = this.state;
    const { sideBarOpen, totalUnseen } = this.props;
    const pathName = this.props.location.pathname;

    return (
      <div className="sidebar">
        {isVisibleComposeEmail && (
          <ComposeEmail
            isVisibleComposeEmail={isVisibleComposeEmail}
            onHide={this._onToggleComposePopup}
            isExpandingCompose={isExpandingCompose}
            toggleExpandCompose={() =>
              this.setState({ isExpandingCompose: !isExpandingCompose })
            }
            styles={{ alignItems: "center" }}
          />
        )}
        <div className="sidebar-item sidebar-action">
          <div className="action-item action-new">
            <div className="btn-new-content">
              <button
                type="button"
                className="btn btn-new rubikMedium"
                onClick={() => {
                  this._onToggleComposePopup();
                }}
              >
                <i className="icon-new"></i>
                {!sideBarOpen && <span>new message</span>}
              </button>
              {!sideBarOpen && (
                <span
                  onClick={() =>
                    this.fetchEmails(getCurrentTab(pathName) || MAIL_TYPES.SENT)
                  }
                  className="refresh refresh-icon hover_square"
                >
                  <RefreshIcon fill="#696974" />
                </span>
              )}
            </div>
          </div>
          <div className="action-item">
            <ul className="list-action list-unstyled">
              <li className="action-inbox">
                <Link
                  to="/mail/inbox"
                  className={`${pathName.includes("inbox") ? "active" : ""}`}
                  onClick={() => this.props.setMailDetail({})}
                >
                  <i className="icon-inbox"></i>
                  { sideBarOpen && totalUnseen.data?.inbox ? <div className="alert-n">{totalUnseen.data?.inbox.toLocaleString()}</div> : null }
                  <span className="txt-action">inbox</span>
                  <span className="numb">
                    {totalUnseen.data?.inbox > 0 ? totalUnseen.data?.inbox : '' }
                  </span>
                </Link>
              </li>
              <li className="action-starred">
                <Link
                  onClick={() => this.fetchEmails(MAIL_TYPES.STARRED)}
                  to="/mail/starred"
                  className={`${
                    pathName.includes("starred") ? "active" : ""
                  }`}
                >
                  <i className="icon-star"></i>
                  <span className="txt-action">starred</span>
                  <span className="numb">
                    {totalUnseen.data?.starred > 0 ? totalUnseen.data?.starred : '' }
                  </span>
                </Link>
              </li>
              <li className="action-important">
                <Link
                  onClick={() => {
                    this.fetchEmails(MAIL_TYPES.IMPORTANT);
                    this.props.setMailDetail({})
                  }}
                  to="/mail/important"
                  className={`${
                    pathName.includes("important") ? "active" : ""
                  }`}
                >
                  <i className="icon-important"></i>
                  <span className="txt-action">important</span>
                  <span className="numb">
                    {totalUnseen.data?.important > 0 ? totalUnseen.data?.important : '' }
                  </span>
                </Link>
              </li>
              <li className={`action-sent ${sideBarOpen && totalUnseen.data?.sent > 0 ? 'has-note': ''}`}>
                <Link
                  to="/mail/sent"
                  onClick={() => {
                    this.fetchEmails(MAIL_TYPES.SENT);
                    this.props.setMailDetail({})
                  }}
                  className={`${pathName.includes("sent") ? "active" : ""}`}
                >
                  <i className="icon-sent"></i>
                  <span className="txt-action">sent</span>
                  <span className="numb">
                    {totalUnseen.data?.sent > 0 ? totalUnseen.data?.sent : '' }
                  </span>
                </Link>
              </li>
              <li className="action-spam">
                <Link
                  to="/mail/spam"
                  onClick={() => {
                    this.fetchEmails(MAIL_TYPES.SPAM);
                    this.props.setMailDetail({})
                  }}
                  className={`${pathName.includes("spam") ? "active" : ""}`}
                >
                  <div className="icon-content number-alert">
                    <OctagonIcon width={20} height={20} />
                  </div>
                  <span className="txt-action">Spam</span>
                  <span className="numb">
                    {totalUnseen.data?.spam > 0 ? totalUnseen.data?.spam : ''}
                  </span>
                </Link>
              </li>
              <li className={`action-drafts ${sideBarOpen && totalUnseen.data?.draft > 0 ? 'has-note': ''}`}>
                <Link
                  to="/mail/draft"
                  onClick={() => {
                    this.fetchEmails(MAIL_TYPES.DRAFT);
                    this.props.setMailDetail({})
                  }}
                  className={`${pathName.includes("draft") ? "active" : ""}`}
                >
                  <div className="icon-content">
                    <FileIcon />
                  </div>
                  <span className="txt-action">draft</span>
                  <span className="numb">
                    {totalUnseen.data?.draft > 0 ? totalUnseen.data?.draft : '' }
                  </span>
                </Link>
              </li>
              <li className="action-scheduled">
                <Link
                  to="/mail/scheduled"
                  className={`${
                    pathName.includes("scheduled") ? "active" : ""
                  }`}
                  onClick={ () => this.props.setMailDetail({}) }
                >
                  <div className="icon-content">
                    <ClockIcon />
                  </div>
                  <span className="txt-action">scheduled</span>
                  <span className="numb"></span>
                </Link>
              </li>
              <li className="action-all-email">
                <Link
                  to="/mail/all"
                  className={`${pathName.includes("all") ? "active" : ""}`}
                  onClick={ () => this.props.setMailDetail({}) }
                >
                  <div className="icon-content">
                    <EnvelopeIcon />
                  </div>
                  <span className="txt-action">all mail</span>
                  <span className="numb">
                    {totalUnseen.data?.all > 0 ? totalUnseen.data?.all : ''}
                  </span>
                </Link>
              </li>
              <li className="action-trash">
                <Link
                  to="/mail/trash"
                  className={`${pathName.includes("trash") ? "active" : ""}`}
                  onClick={ () => this.props.setMailDetail({}) }
                >
                  <div className="icon-content">
                    <TrashIcon />
                  </div>
                  <span className="txt-action">trash</span>
                  <span className="numb">
                    {totalUnseen.data?.trash > 0 ? totalUnseen.data?.trash : '' }
                  </span>
                </Link>
              </li>

              <li className="action-category">
                <Link
                  to="#"
                  className={`btn-down-up`}
                  onClick={() => this.setShowLess()}
                >
                  <div
                    className={`icon-content ${
                      this.state.showLess && "collapse-icon"
                    }`}
                  >
                    <UpIcon />
                  </div>
                  <span className="txt-action">less</span>
                </Link>
              </li>

              {this.state.showLess && (
                <Fragment>
                  <li className="action-category">
                    <Link
                      to="/mail/category"
                      className={`${
                        pathName.includes("category") ? "active" : ""
                      }`}
                    >
                      <div className="icon-content">
                        <RectangleIcon />
                      </div>
                      <span className="txt-action">categories</span>
                      <span className="numb"></span>
                    </Link>
                  </li>

                  <li className="action-category">
                    <Link
                      to="/mail/primary"
                      className={`${
                        pathName.includes("primary") ? "active" : ""
                      }`}
                    >
                      <span className="txt-action txt-lable txt-orange sub-action">
                        <span>primary</span>
                      </span>
                      <span className="numb">
                        {totalUnseen.data?.inbox > 0 ? totalUnseen.data?.inbox : ''}
                      </span>
                    </Link>
                  </li>

                  <li className="action-category">
                    <Link
                      onClick={() => {
                        this.fetchEmails(MAIL_TYPES.WORK);
                        this.props.setMailDetail({})
                      }}
                      to="/mail/work"
                      className={`${pathName.includes("work") ? "active" : ""}`}
                    >
                      <span className="txt-action txt-lable txt-green sub-action">
                        <span>Work</span>
                      </span>
                      <span className="numb">
                        {totalUnseen.data?.works > 0 ? totalUnseen.data?.works : ''}
                      </span>
                    </Link>
                  </li>

                  <li className="action-category">
                    <Link
                      onClick={() => {
                        this.fetchEmails(MAIL_TYPES.SOCIAL);
                        this.props.setMailDetail({})
                      }}
                      to="/mail/social"
                      className={`${
                        pathName.includes("social") ? "active" : ""
                      }`}
                    >
                      <span className="txt-action txt-lable txt-blue sub-action">
                        <span>Social</span>
                      </span>
                      <span className="numb">
                        {totalUnseen.data?.social > 0 ? totalUnseen.data?.social : '' }
                      </span>
                    </Link>
                  </li>

                  <li className="action-category">
                    <Link
                      onClick={() => {
                        this.fetchEmails(MAIL_TYPES.PROMOTION);
                        this.props.setMailDetail({})
                      }}
                      to="/mail/promotion"
                      className={`${
                        pathName.includes("promotion") ? "active" : ""
                      }`}
                    >
                      <span className="txt-action txt-lable txt-pink sub-action">
                        <span>Promotion</span>
                      </span>
                      <span className="numb">
                        {totalUnseen.data?.promotion > 0 ? totalUnseen.data?.promotion : '' }
                      </span>
                    </Link>
                  </li>

                  <li className="action-category">
                    <Link
                      to="/mail/draft"
                      className={`new-labe ${
                        pathName.includes("draft") ? "active" : ""
                      }`}
                      onClick={() => this.props.toggleForm()}
                    >
                      <div className="icon-content">
                        <AddIcon fill="#B5B5BE" />
                      </div>
                      <span className="txt-action">Create New Label</span>
                      <span className="numb"></span>
                    </Link>
                  </li>
                </Fragment>
              )}
            </ul>
          </div>
        </div>
        <div className="side-footer">
          <Link to="#" className="side-footer-text">
            Terms of Use
          </Link>
          <Link to="#" className="side-footer-text">
            Privacy Policy
          </Link>
          <br />
          <Link to="#" className="side-footer-text">
            Help & Feedback
          </Link>
          <Link to="#" className="side-footer-text">
            Advertising
          </Link>
          <br />
          <span className="side-footer-text-copy-right">© 2020, Phuk.Inc</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { totalUnseen } = state.mails;
  return {
    totalUnseen,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTotalUnseenMail: (params) => dispatch(getTotalUnseenMailAction(params)),
    setMailDetail: (params) => dispatch(setMailDetailAction(params)),
  };
};

const MappedStoreComponent = connect(mapStateToProps, mapDispatchToProps)(LeftSideBar);

export default withRouter(MappedStoreComponent);
