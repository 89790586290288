import styled from 'styled-components';
import { Checkbox } from 'antd';

export const SelectEmailCheckbox = styled(Checkbox)`
  & .ant-checkbox-inner {
    width: 22px;
    height: 22px;
    border: 2px solid #B5B5BE;
    border-radius: ${(props) => props.theme.radius.md};
    background-color: transparent;
  }

  &:hover .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.primaryText};
  }

  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.primaryText};
  }
  
  & .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.primaryText};
    background-color: ${(props) => props.theme.colors.primaryText};
  }

  & .ant-checkbox-checked::after {
    border-radius: ${(props) => props.theme.radius.md};
    border-color: ${(props) => props.theme.colors.primaryText};
  }

  & .ant-checkbox-inner::after {
    top: 48%;
    width: 6px;
    height: 11px;
  }

  & .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    top: 50%;
    width: 11px;
    height: 11px;
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.primaryText};
  }
`;
