import React, { memo } from 'react'
import { WEATHER } from './../../../mockdata/homeData'

const WeatherItem = memo(props => {
  const { day, temperature, indication } = props
  return (
    <li>
      <span className={`icon-weather-${indication}`}></span>
      <span className="weather-txt">{day}</span>
      <span className="weather-temperature txt-meta">{temperature}</span>
    </li>
  )
})

const Weather = memo(props => {
  return (
    <div className="sidebar-item">
      <div className="sidebar-title box-flex">
        <h3 className="rubikMedium">Weather</h3>
        <div className="dropdown">
          <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">Moscow</a>
          <ul className="dropdown-menu list-unstyled">
            <li><a href="#">dropdown item</a></li>
            <li><a href="#">dropdown item</a></li>
          </ul>
        </div>
      </div>

      <ul className="weather-list box-flex list-unstyled">
        {WEATHER.map(item => (
          <WeatherItem key={`weather${item.day}`} {...item} />
        ))}
      </ul>
    </div>
  )
})

export default Weather
