import React, { useState } from 'react';
import { Row, Col, Button, Calendar, Input, Collapse, Checkbox, Space } from 'antd';
import { PlusOutlined, DownOutlined, UpOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Text, CustomCheckbox } from '../../../commons/styles';

import * as Style from '../styles';

const WEATHER_TYPE_OPTION = [
  { label: 'Normal', value: 'normal' },
  { label: 'Typhoons', value: 'typhoons' },
  { label: 'Wind', value: 'wind' },
  { label: 'Tornados', value: 'tornados' },
];

const SEARCH_BY_AREA_OPTION = [
  { label: 'Asia', value: 'asia' },
  { label: 'North America', value: 'northAmerica' },
  { label: 'East Euro', value: 'eastEuro' },
  { label: 'Africa', value: 'africa' },
];

const WeatherSidebar = ({
  newsItem
}) => {
  const [weatherTypeCollapseKeys, setWeatherTypeCollapseKeys] = useState(['1']);
  const [searchAreaCollapseKeys, setSearchAreaCollapseKeys] = useState(['1']);

  function onChangeDate(value, mode) {
    console.log(value, mode);
  }

  function renderWeatherType() {
    return WEATHER_TYPE_OPTION.map((item, index) => {
      return (
        <Col span={12}>
          <CustomCheckbox value={item.value}>
            <Text primaryText>{item.label}</Text>
          </CustomCheckbox>
        </Col>
      )
    })
  }

  function renderSearchByArea() {
    return SEARCH_BY_AREA_OPTION.map((item, index) => {
      return (
        <Col span={12}>
          <CustomCheckbox value={item.value}>
            <Text primaryText>{item.label}</Text>
          </CustomCheckbox>
        </Col>
      )
    })
  }

  return (
    <Style.SidebarContainer>
      <Style.AddCityContainer>
        <Style.AddCityButton
          type="text"
          size="large"
          shape="round"
          icon={<PlusOutlined style={{ fontSize: 16, color: '#FF570D' }} />}
        >
          Add City/Place
        </Style.AddCityButton>
      </Style.AddCityContainer>

      <Style.SidebarCalendar>
        <Style.SidebarCalendarHeader>
          <Space size={4}>
            <Button type="text" shape="circle" size="small">
              <LeftOutlined />
            </Button>
            <Text primaryText w6>February 2020</Text>
            <Button type="text" shape="circle" size="small">
              <RightOutlined />
            </Button>
          </Space>
          <Button size="small" style={{ borderRadius: 5 }}>Today</Button>
        </Style.SidebarCalendarHeader>
        <Calendar
          fullscreen={false}
          onPanelChange={(value, mode) => onChangeDate(value, mode)}
          headerRender={() => null}
        />
      </Style.SidebarCalendar>
      <Text primaryText lg w6 style={{ marginBottom: 8 }}>Search another Location</Text>
      <Input placeholder="+ Search City/Location" style={{ height: 36, borderRadius: 6 ,background: '#FAFAFA' }} />
      <Style.SidebarFilter>
        <Collapse
          activeKey={[...weatherTypeCollapseKeys]}
          ghost
          onChange={(keys) => setWeatherTypeCollapseKeys([...keys])}
        >
          <Collapse.Panel
            header={
              <Style.SidebarFilterHeader>
                <Space size={10}>
                  <Text primaryText lg w6 inline>Weather Type</Text>
                  {weatherTypeCollapseKeys.includes('1')
                    ? <UpOutlined style={{ color: '#92929D' }} />
                    : <DownOutlined style={{ color: '#92929D' }} />
                  }
                </Space>
                <Button type="text" shape="circle"><PlusOutlined style={{ color: '#FF570D' }} /></Button>
              </Style.SidebarFilterHeader>
            }
            showArrow={false}
            key="1"
          >
            <Checkbox.Group onChange={() => null}>
              <Row>
                {renderWeatherType()}
              </Row>
            </Checkbox.Group>
          </Collapse.Panel>
        </Collapse>
      </Style.SidebarFilter>
      <Style.SidebarFilter>
        <Collapse
          activeKey={[...searchAreaCollapseKeys]}
          ghost
          onChange={(keys) => setSearchAreaCollapseKeys([...keys])}
        >
          <Collapse.Panel
            header={
              <Style.SidebarFilterHeader>
                <Space size={10}>
                  <Text primaryText lg w6 inline>Search by Area</Text>
                  {searchAreaCollapseKeys.includes('1')
                    ? <UpOutlined style={{ color: '#92929D' }} />
                    : <DownOutlined style={{ color: '#92929D' }} />
                  }
                </Space>
                <Button type="text" shape="circle"><PlusOutlined style={{ color: '#FF570D' }} /></Button>
              </Style.SidebarFilterHeader>
            }
            showArrow={false}
            key="1"
          >
            <Checkbox.Group onChange={() => null}>
              <Row>
                {renderSearchByArea()}
              </Row>
            </Checkbox.Group>
          </Collapse.Panel>
        </Collapse>
      </Style.SidebarFilter>
    </Style.SidebarContainer>
  );
};

export default WeatherSidebar;
