import React, { memo } from 'react'
import { VIDEOS } from './../../../mockdata/homeData'
import { Images } from './../../../themes'

const RadioItem = memo(props => {
  const { poster, content, author } = props
  return (
    <div className="video-item box-flex">
      <div className="thumbnail">
        <a href="#" style={{ backgroundImage: `url(${poster})` }}></a>
      </div>
      <div className="video-info">
        <h4 className="rubikMedium">
          <a href="#">{content}</a>
        </h4>
        <p className="txt-meta">{author}</p>
      </div>
    </div>
  )
})

const Radio = memo(props => {
    const { poster, content, author } = props

  return (
    <div className="sidebar-item">
      <h3 className="sidebar-title rubikMedium">Radio</h3>
      <div className="video-items">
        <div className="video-item box-flex">
         <div className="thumbnail">
            <a href="#" style={{ backgroundImage: `url(${poster})` }}></a>
            </div>
            <div className="video-info">
                <h4 className="rubikMedium">
                <a href="#">Sigmaa Radio</a>
                </h4>
                <p className="txt-meta">Music & Entertainment Radio</p>
            </div>
        </div>
      </div>

      <div className="radio-content">
        <div className="radio-run">
          <div className="radio-run-image">
            <img  src={Images.track}></img>
          </div>
          <div className="radio-run-info">
            <h4 className="rubikMedium">
            <a href="#" className="radio-run-name">Sigmaa Radio</a>
            </h4>
            <p className="txt-meta" className="radio-run-type">Music & Entertainment Radio</p>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Radio;
