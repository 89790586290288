import React from "react";

const ActionButton = ({
  icon,
  handleClick = () => {},
  attachment,
  handleSelectAttachment = () => {},
  acceptedType = "",
  multiple = true,
}) => (
  <li className="hover_square" onClick={handleClick}>
    {attachment && (
      <label>
        <input
          type="file"
          aria-label="Add Image"
          accept={acceptedType}
          multiple={multiple}
          role="button"
          tabIndex="0"
          className="mail-attach-file"
          onChange={handleSelectAttachment}
          htmlFor={icon}
        />
      </label>
    )}
    <span className={icon} />
  </li>
);

export default ActionButton;
