import { ADMIN, REQUEST } from '../../constants';

export function getArticleListAdmin(params) {
  return {
    type: REQUEST(ADMIN.ARTICLE_ACTION.GET_LIST),
    payload: params,
  };
}

export function getArticleDetailAdmin(params) {
  return {
    type: REQUEST(ADMIN.ARTICLE_ACTION.GET_DETAIL),
    payload: params,
  };
}

export function createArticleAdmin(params) {
  return {
    type: REQUEST(ADMIN.ARTICLE_ACTION.CREATE_ARTICLE),
    payload: params,
  };
}

export function updateArticleAdmin(params) {
  return {
    type: REQUEST(ADMIN.ARTICLE_ACTION.UPDATE_ARTICLE),
    payload: params,
  };
}

export function deleteArticleAdmin(params) {
  return {
    type: REQUEST(ADMIN.ARTICLE_ACTION.DELETE_ARTICLE),
    payload: params,
  };
}
