import styled from 'styled-components';
import { Button } from 'antd';

export const TagButton = styled(Button)`
  color: ${(props) => props.theme.colors.primaryText};

  &:hover {
    border-color: #FFC300;
    color: #FFC300;
  }

  &:focus {
    border-color: #FFC300;
    color: #FFC300;
  }

  &.ant-btn-primary {
    border-color: #FFC300;
    background-color: #FFC300;
    color: white;
    font-weight: ${(props) => props.theme.fontWeight.w6};
  }

  &.ant-btn-primary:hover {
    border-color: rgba(255, 195, 0, 0.75);
    background-color: rgba(255, 195, 0, 0.75);
  }
`;
