import React, { memo } from 'react'

const plusIcon = {
  'order food': 'food',
  'goes to there place': 'direction',
  'booking hotels': 'hotel-building',
  'buy ticket': 'park-tickets',
}

const ProductItem = memo(props => {
  const { type = '', isSelling = false, image, name, price, distance, description, btnTitle = 'food' } = props
  return (
    <div className="product-list-item box-flex">
      <div className="thumbnail">
        <a href="#" style={{ backgroundImage: `url(${image})` }}></a>
      </div>
      <div className="product-txt">
        <h4 className="rubikMedium"><a href="#">{name}</a></h4>
        <a href="#" className="desc-title">{description}</a>
        <ul className="product-item_info list-unstyled">
          {type !== 'plus' && <li className="price">{price}</li>}
          {isSelling ?
            <li className="cart">
              <a href="#"><i className={`icon-${type === 'plus' ? plusIcon[btnTitle.toLowerCase()] : type}`}></i><span>{type === 'plus' ? btnTitle : 'Add to cart'}</span></a>
            </li> :
            <li className="distance">{distance}</li>
          }
        </ul>
      </div>
    </div>
  )
})

export default ProductItem
