import React, {
  useCallback,
  useMemo,
  createContext,
  useContext,
} from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, Slate, ReactEditor } from "slate-react";
import { createEditor, Transforms } from "slate";
import { withHistory } from "slate-history";
import imageExtensions from "image-extensions";
import isUrl from "is-url";

import "./Editor.module.css";
import ToolBar from "./ToolBar";
import { toggleMark } from "./EditorUtil";
import { ImageElement } from "./components";
import { FontSizeNode } from "./plugins/FontSize";
import { BlockQuoteNode } from "./plugins/BlockQuote";
import { withHtml } from "./hocs/withHtml";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
};

const EditorComponent = ({
  isShowToolBar,
  setEditorValue,
  editorValue,
  mailEditorRef,
}) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withHtml(withImages(withHistory(withReact(createEditor())))),
    []
  );

  return (
    <Slate
      editor={editor}
      value={editorValue}
      onChange={(value) => setEditorValue(value)}
    >
      {isShowToolBar && <ToolBar />}
      <div ref={mailEditorRef}>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          autoFocus
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
          style={{
            height: "200px",
            overflow: "auto",
          }}
        />
      </div>
    </Slate>
  );
};

export const Element = (props) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case "block-quote":
      return <BlockQuoteNode {...attributes}>{children}</BlockQuoteNode>;
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "align-center":
      return <div style={{ textAlign: "center" }}>{children}</div>;
    case "align-left":
      return <div style={{ textAlign: "left" }}>{children}</div>;
    case "align-right":
      return <div style={{ textAlign: "right" }}>{children}</div>;
    case "indent":
      return <div style={{ marginLeft: "40px" }}>{children}</div>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "heading-three":
      return <h3 {...attributes}>{children}</h3>;
    case "heading-four":
      return <h4 {...attributes}>{children}</h4>;
    case "heading-five":
      return <h5 {...attributes}>{children}</h5>;
    case "heading-six":
      return <h6 {...attributes}>{children}</h6>;
    case "link":
      return (
        <a href={element.url} {...attributes}>
          {children}
        </a>
      );
    case "code":
      return (
        <pre>
          <code {...attributes}>{children}</code>
        </pre>
      );
    case "image":
      return <ImageElement {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }

  if (leaf["font-family"]) {
    children = <font face={leaf["font-family"]}>{children}</font>;
  }

  if (leaf["font-size"]) {
    children = <FontSizeNode {...{ attributes, children, leaf }} />;
  }

  if (leaf["background-color"]) {
    children = (
      <font style={{ backgroundColor: leaf["background-color"] }}>
        {children}
      </font>
    );
  }

  if (leaf["text-color"]) {
    children = <font style={{ color: leaf["text-color"] }}>{children}</font>;
  }

  return <span {...attributes}>{children}</span>;
};

const withImages = (editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const isImageUrl = (url) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return imageExtensions.includes(ext);
};

const insertImage = (editor, url) => {
  const text = { text: "" };
  const image = { type: "image", url, children: [text] };
  Transforms.insertNodes(editor, image);
};

/**
 * A React context for sharing the editor object.
 */

export const EditorContext = (createContext < ReactEditor) | (null > null);

/**
 * Get the current editor object from the React context.
 */

export const useSlateStatic = () => {
  const editor = useContext(EditorContext);

  if (!editor) {
    throw new Error(
      `The \`useSlateStatic\` hook must be used inside the <Slate> component's context.`
    );
  }

  return editor;
};


export default EditorComponent;
