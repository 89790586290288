import { USER, REQUEST } from '../../constants';

export function getArticleList(params) {
  return {
    type: REQUEST(USER.ARTICLE_ACTION.GET_LIST),
    payload: params,
  };
}

export function getArticleDetail(params) {
  return {
    type: REQUEST(USER.ARTICLE_ACTION.GET_DETAIL),
    payload: params,
  };
}

export function getArticleRelated(params) {
  return {
    type: REQUEST(USER.ARTICLE_ACTION.GET_RELATED),
    payload: params,
  };
}

export function getArticleNewTrending(params) {
  return {
    type: REQUEST(USER.ARTICLE_ACTION.GET_NEW_TRENDING),
    payload: params,
  };
}

export function getArticleMostReaction(params) {
  return {
    type: REQUEST(USER.ARTICLE_ACTION.GET_MOST_REACTION),
    payload: params,
  };
}

export function getArticleLikes(params) {
  return {
    type: REQUEST(USER.ARTICLE_ACTION.GET_LIKES),
    payload: params,
  };
}

export function getArticleComments(params) {
  return {
    type: REQUEST(USER.ARTICLE_ACTION.GET_COMMENTS),
    payload: params,
  };
}

