import {
  AUTH_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../constants';

const initialState = {
	authenticated: false,
	token: null,
	userInfo: null,
	loginData: {
		loading: false,
		errors: '',
	},
	registerData: {
		loading: false,
		errors: '',
	},
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST(AUTH_ACTION.LOGIN):
			return {
				...state,
				loginData: {
					...state.loginData,
					loading: true,
				},
			};
		case SUCCESS(AUTH_ACTION.LOGIN): {
      const { data } = action.payload;
			return {
				...state,
				authenticated: true,
				token: data.token,
				userInfo: data.userInfo,
				loginData: {
					loading: false,
					errors: '',
				},
			};
    }
		case FAILURE(AUTH_ACTION.LOGIN):
			return {
				...state,
				loginData: {
					loading: false,
					errors: action.errors,
				},
			};

    case REQUEST(AUTH_ACTION.REGISTER):
      return {
				...state,
				registerData: {
					...state.registerData,
					loading: true,
				},
			};
    case SUCCESS(AUTH_ACTION.REGISTER): 
      return {
        ...state,
        registerData: {
          loading: false,
          errors: '',
        }
      }
    case FAILURE(AUTH_ACTION.REGISTER):
      return {
        ...state,
        registerData: {
          loading: false,
          errors: action.errors
        } 
      }

		case SUCCESS(AUTH_ACTION.GET_USER_INFO): {
			const { data } = action.payload;
			return {
				...state,
				userInfo: data
			}
		}
		default:
			return state;
	}
};

export default authReducer;
