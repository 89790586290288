import React, { memo } from 'react'
import { STANDING_PAGES } from './../../constants/common'
import { Images } from './../../themes'

const Footer = memo(props => {
  const renderItems = (type = 'PRODUCTS') => {
    const footerItems = {
      PRODUCTS: ['Popular', 'Trending', 'Categories', 'Features'],
      CATEGORIES: ['Mail', 'News', 'Health', 'More...'],
      RESOURCES: ['Support', 'Developers', 'Customer Service', 'Ger Started Guide'],
      COMPANY: ['Contact Us', 'Developers', 'Careers', 'Privacy']
    }
    return (
      <div className="col-md-3 col-sm-6 col-6 widget">
        <h4 className="widget-title rubikMedium">{type}</h4>
        <ul className="fnav list-unstyled">
          {footerItems[type].map(item => <li key={item} ><a href="#">{item}</a></li>)}
        </ul>
      </div>
    )
  }

  const renderSocailNetwork = () => {
    const social = ['facebook', 'twiter', 'google', 'instagram']
    return (
      <div className="col-lg-3 widget">

        <h4 className="widget-title rubikMedium">SUBSCRIBE TO NEWSLETTERS</h4>
        <form action="#" className="form-subcribe">
          <input type="text" className="form-control" placeholder="E-mail" />
          <button className="btn btn-search" type="button"><span className="icon-message"></span></button>
        </form>

        <ul className="list-flex social-list list-unstyled">
          {
            social.map(item => (
              <li key={item}><a href="#" className="hover_circle"><i className={`icon-${item}`}></i></a></li>
            ))
          }
        </ul>
        <p className="copyright rubikMedium">© 2019 Phuk. All rights reserved.</p>
      </div>
    )
  }
  return (
    <footer className={`${STANDING_PAGES[props.currentPage] ? 'mail-footer' : "footer"}`}>
      <div className="container">
        {!STANDING_PAGES[props.currentPage] ? <div className="row">
          <div className="col-lg-9 fnav-widget-wrap">
            <div className="row">
              {renderItems('PRODUCTS')}
              {renderItems('CATEGORIES')}
              {renderItems('RESOURCES')}
              {renderItems('COMPANY')}
            </div>
          </div>
          {renderSocailNetwork()}
        </div> :
          <div class="row">
            <div class="col-md-9 footer-nav-wrap">
              <div class="logo" style={{ backgroundImage: `url(${Images.logoSmall})` }}></div>
              <ul class="list-flex footer-nav list-unstyled">
                {['products', 'categories', 'resources', 'company'].map(item => <li key={item}><a href="#">{item}</a></li>)}
              </ul>
            </div>
            <div class="col-md-3 social">
              <ul class="list-flex social-list list-unstyled">
                {['facebook', 'twiter', 'google', 'instagram'].map(item => (
                  <li key={item}><a href="#" class="hover_circle"><i class={`icon-${item}`}></i></a></li>
                ))}
              </ul>
            </div>
          </div>
        }
      </div>
    </footer>
  )
})

export default Footer
