import React from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import styled from 'styled-components';

import { fontFamilyList } from './FontFamilyList';
import Images from '../../../../themes/images';

export const FontMenu = styled.div`
  user-select: none;
  padding-bottom: 6px;
  padding-top: 6px;
  color: #202124;
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  background: white;
  z-index: 10;

  &:hover {
    background: rgba(32,33,36,0.059);
  }
`;

export const FontItem = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  font-family: ${p => p.fontFamily};
`;

export const Active = styled.div`
  user-select: none;
  background-image: url(${Images.checkBlack});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  display: block;
  height: 20px;
  margin-left: -28px;
  position: absolute;
  width: 20px;
  margin-right: 12px;
  position: absolute;
  right: 100%;
`;

export const reapplyMark = (editor, format, data) => {
  Editor.removeMark(editor, format);
  Editor.addMark(editor, format, data || true);
}

export const getActiveFont = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? fontFamilyList.find(font => font.value === marks[format]) || {} : {};
}

export const FontFamilyButton = ({ format, data, children }) => {
  const editor = useSlate();
  return (
    <FontMenu
      onMouseDown={event => {
        event.preventDefault();
        reapplyMark(editor, format, data);
      }}
    >
      <FontItem fontFamily={data}>
        {getActiveFont(editor, format).value === data && <Active />}
        {children}
      </FontItem>
    </FontMenu>
  )
}