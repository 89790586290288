export const MAIL_TYPES = {
  ALL: 'all',
  INBOX: "inbox",
  WORK: "work",
  SOCIAL: "social",
  PROMOTION: "promotion",
  IMPORTANT: "important",
  STARRED: "starred",
  SENT: "sent",
  SPAM: "spam",
  SCHEDULED: 'scheduled',
  DRAFT: "draft",
  TRASH: "trash",
  SEARCH: "search",
};

export const MAIL_ITEM_LIMIT = 50;

export const mailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const MAIL_BOX_PARAMS = {
  inbox: "INBOX",
  all: "INBOX.All",
  drafts: "INBOX.Drafts",
  important: "INBOX.Important",
  sent: "INBOX.Sent",
  spam: "INBOX.Spam",
  starred: "INBOX.Starred",
  trash: "INBOX.Trash",
  promotions: "INBOX.Promotions",
  social: "INBOX.Social",
  works: "INBOX.Works",
}

export const DEFAULT_INBOX_TABS = [
  {
    title: 'Primary',
    type: 'inbox',
    color: '#FE5810',
  },
  {
    title: 'Work',
    type: 'works',
    color: '#52C17B',
  },
  {
    title: 'Social',
    type: 'social',
    color: '#00B6FF',
  },
  {
    title: 'Promotion',
    type: 'promotion',
    color: '#BE2060',
  }
];
