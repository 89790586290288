import React from 'react';
import { Space } from 'antd';

import { Text } from '../../../commons/styles';

import { ReactComponent as ClockIcon } from "../../../assets/images/ic_clock.svg";

import * as Style from '../styles';

const RecommendNews = ({
  newsItem
}) => {
  return (
    <Style.RecommendNewsContainer>
      <Style.RecommendNewsImage image={newsItem.image} />
      <Style.RecommendNewsContent>
        <div>
          <Text
            className="primary-news-title"
            headerText
            xl
            w6
            truncate
            style={{ marginBottom: 8, fontSize: '20px' }}
          >
            {newsItem.title}
          </Text>
          <Text
            secondaryText
            sm
            truncateMultiLine={2}
          >
            {newsItem.description}
          </Text>
        </div>
        <Style.RecommendNewsBottom>
          <Space size={6}>
            <ClockIcon />
            <Text subText xs>
              {`${newsItem.createAt} | ${newsItem.url}`}
            </Text>
          </Space>
          <Space size={36}>
            <Text subText sm>{newsItem.view}</Text>
            <Text subText sm>{newsItem.like}</Text>
            <Text subText sm>{newsItem.share}</Text>
          </Space>
        </Style.RecommendNewsBottom>
      </Style.RecommendNewsContent>
    </Style.RecommendNewsContainer>
  );
};

export default RecommendNews;
