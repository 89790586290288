import logo from "./../assets/images/logo.png";
import user001 from "./../assets/images/user_img001.png";
import homeImg001 from "./../assets/images/home_img001.png";
import homeImg002 from "./../assets/images/home_img002.png";
import homeImg003 from "./../assets/images/home_img003.png";
import homeImg004 from "./../assets/images/home_img004.png";
import homeImg005 from "./../assets/images/home_img005.png";
import homeImg006 from "./../assets/images/home_img006.png";
import homeImg007 from "./../assets/images/home_img007.png";
import homeImg008 from "./../assets/images/home_img008.png";
import homeImg009 from "./../assets/images/home_img009.png";
import homeImg010 from "./../assets/images/home_img010.png";
import homeImg011 from "./../assets/images/home_img011.png";
import homeImg012 from "./../assets/images/home_img012.png";
import homeImg013 from "./../assets/images/home_img013.png";
import homeImg014 from "./../assets/images/home_img014.png";
import homeImg015 from "./../assets/images/home_img015.png";
import homeImg016 from "./../assets/images/home_img016.png";
import homeImg017 from "./../assets/images/home_img017.png";
import homeImg018 from "./../assets/images/home_img018.png";
import homeImg019 from "./../assets/images/home_img019.png";
import homeImg020 from "./../assets/images/home_img020.png";
import homeImg021 from "./../assets/images/home_img021.png";
import homeImg022 from "./../assets/images/home_img022.png";
import homeImg023 from "./../assets/images/home_img023.png";
import homeImg024 from "./../assets/images/home_img024.png";
import homeImg025 from "./../assets/images/home_img025.png";
import homeImg026 from "./../assets/images/home_img026.png";
import homeImg027 from "./../assets/images/home_img027.png";
import homeImg028 from "./../assets/images/home_img028.png";
import homeImg029 from "./../assets/images/home_img029.png";
import homeImg030 from "./../assets/images/home_img030.png";
import homeImg031 from "./../assets/images/home_img031.png";
import homeImg032 from "./../assets/images/home_img032.png";
import homeImg033 from "./../assets/images/home_img033.png";
import homeImg034 from "./../assets/images/home_img034.png";
import homeImg035 from "./../assets/images/home_img035.png";
import homeImg036 from "./../assets/images/home_img036.png";
import homeImg037 from "./../assets/images/home_img037.png";
import homeImg038 from "./../assets/images/home_img038.png";
import homeImg039 from "./../assets/images/home_img039.png";
import homeImg040 from "./../assets/images/home_img040.png";
import homeImg041 from "./../assets/images/home_img041.png";
import homeImg042 from "./../assets/images/home_img042.png";
import adsImg001 from "./../assets/images/ads_img001.png";
import flagIndo from "./../assets/images/flag_indo.png";
import flagUs from "./../assets/images/flag_us.png";
import flagSgd from "./../assets/images/flag_sgd.png";
import flagEu from "./../assets/images/flag_eu.png";
import flagHk from "./../assets/images/flag_hk.png";
import logoArsenal from "./../assets/images/logo_arsenal.png";
import logoLiverpool from "./../assets/images/logo_liverpool.png";
import logoChelsea from "./../assets/images/logo_chelsea.png";
import logoWestHam from "./../assets/images/logo_west_ham.png";
import homeVideo001 from "./../assets/images/home_video001.png";
import homeVideo002 from "./../assets/images/home_video002.png";
import homeVideo003 from "./../assets/images/home_video003.png";
import photoImg001 from "./../assets/images/photo_img001.png";
import photoImg002 from "./../assets/images/photo_img002.png";
import photoImg003 from "./../assets/images/photo_img003.png";
import photoImg004 from "./../assets/images/photo_img004.png";
import photoImg005 from "./../assets/images/photo_img005.png";
import photoImg006 from "./../assets/images/photo_img006.png";
import inboxImg001 from "./../assets/images/inbox_img001.png";
import userImg006 from "./../assets/images/user_img006.png";
import loginImg001 from "./../assets/images/login_img001.png";
import signUpImg001 from "./../assets/images/sign-up_img001.png";
import logoSmall from "./../assets/images/logo-small.png";
import logoSmallNew from "./../assets/images/logo-small-new.png";
import alertOctagon from "./../assets/images/alert-octagon.png";
import alertOctagonOrange from "./../assets/images/alert-octagon-orange.png";
import checkBlack from "./../assets/images/check_black_20dp.png";
import checkmark from "./../assets/images/checkmark.png";
import iconCalendarModule from "./../assets/images/ic_calendar_module.png";
import iconContactModule from "./../assets/images/ic_contact_module.png";
import iconDropdown from "./../assets/images/icon-dropdown.svg";
import iconMinus from "./../assets/images/minus.png";
import iconCompress from "./../assets/images/compress-alt.png";
import iconCompressOut from "./../assets/images/compress-out.png";
import iconClose from "./../assets/images/close.png";
import iconDoc from "./../assets/images/ic-doc.png";
import iconCloseTag from "./../assets/images/ic_close_tag.png";
import icStarred from "./../assets/images/ic_starred.svg";
import icNotStarred from "./../assets/images/ic_not_starred.svg";
import icImportant from "./../assets/images/ic_important.svg";
import icNotImportant from "./../assets/images/ic_not_important.svg";
import icImportantMarked from "./../assets/images/ic_important_marked.png";
import icSetting from "./../assets/images/ic_setting.svg";
import icWindowGrid from "./../assets/images/window-grid.svg";
import icWindowGroup from "./../assets/images/ic_window_group.png";
import icForward from "./../assets/images/ic_forward.svg";
import icReply from "./../assets/images/ic_reply.svg";
import icPin from "./../assets/images/ic_pin.svg";
import icAlertOctagon from "./../assets/images/ic-alert-octagon.svg";
import icMailClose from "./../assets/images/ic_mail_close.svg";
import icMailOpen from "./../assets/images/ic_mail_open.svg";
import icFolderUpload from "./../assets/images/ic_folder_upload.svg";
import icForwardLeft from "./../assets/images/ic_forward_left.svg";
import icForwardRight from "./../assets/images/ic_forward_right.svg";
import icArchive from "./../assets/images/ic_archive.svg";
import icTrash from "./../assets/images/ic_trash.svg";
import icArrowBack from "./../assets/images/ic_arrow_left.svg";
import icUser from "./../assets/images/ic_user.svg";
import icKey from "./../assets/images/ic_key.svg";
import icEmail from "./../assets/images/ic_email.svg";
import icEmailWhite from "./../assets/images/ic_email_white.svg";
import icChatWhite from "./../assets/images/ic_chat_white.svg";
import icStorageWhite from "./../assets/images/ic_storage_white.svg";
import icUpdateWhite from "./../assets/images/ic_update_white.svg";
import icClock from "./../assets/images/ic_clock.svg";
import icHoroscopeBuffalo from "./../assets/images/ic_horoscope_buffalo.png";
import bitmap from "./../assets/images/bitmap.png";
import bitmap2 from "./../assets/images/bitmap2.png";
import firmy from "./../assets/images/firmy.png";
import horoscopes from "./../assets/images/horoscopes.png";
import radio1 from "./../assets/images/radio1.png";
import radio2 from "./../assets/images/radio2.png";
import radio3 from "./../assets/images/radio3.png";
import radio4 from "./../assets/images/radio4.png";
import track from "./../assets/images/track.png";
import icPrinter from "./../assets/images/ic-printer.svg";
import icWindowStore from "./../assets/images/ic-window-store.svg";
import icSearch from "./../assets/images/search_black.png";
import icMail from "./../assets/images/ic_gmail.png";
import icEnvelope from './../assets/images/envelope.svg';
import icLink from "./../assets/images/icon-link.svg";
import icPlayVideo from './../assets/images/ic-play-video.svg';
import videoBackgroundNews from './../assets/images/video-background-news.png';
import arrowDown from './../assets/images/arrow_down.svg';
import logoAdmin from './../assets/images/logo-admin.svg';
import loginBackground from './../assets/images/login-background.png';
import loginImage from './../assets/images/login-image.png';
import registerImage from './../assets/images/register-image.png';

const Images = {
  icMail,
  icSearch,
  logo,
  user001,
  homeImg001,
  homeImg002,
  homeImg003,
  homeImg004,
  homeImg005,
  homeImg006,
  homeImg007,
  homeImg008,
  homeImg009,
  homeImg010,
  homeImg011,
  homeImg012,
  homeImg013,
  homeImg014,
  homeImg015,
  homeImg016,
  homeImg017,
  homeImg018,
  homeImg019,
  homeImg020,
  homeImg021,
  homeImg022,
  homeImg023,
  homeImg024,
  homeImg025,
  homeImg026,
  homeImg027,
  homeImg028,
  homeImg029,
  homeImg030,
  homeImg031,
  homeImg032,
  homeImg033,
  homeImg034,
  homeImg035,
  homeImg036,
  homeImg037,
  homeImg038,
  homeImg039,
  homeImg040,
  homeImg041,
  homeImg042,
  adsImg001,
  flagIndo,
  flagUs,
  flagSgd,
  flagEu,
  flagHk,
  logoArsenal,
  logoLiverpool,
  logoChelsea,
  logoWestHam,
  homeVideo001,
  homeVideo002,
  homeVideo003,
  photoImg001,
  photoImg002,
  photoImg003,
  photoImg004,
  photoImg005,
  photoImg006,
  inboxImg001,
  userImg006,
  loginImg001,
  signUpImg001,
  logoSmall,
  logoSmallNew,
  alertOctagon,
  alertOctagonOrange,
  checkBlack,
  checkmark,
  iconCalendarModule,
  iconContactModule,
  iconDropdown,
  iconMinus,
  iconCompress,
  iconClose,
  iconDoc,
  iconCompressOut,
  iconCloseTag,
  icStarred,
  icNotStarred,
  icImportant,
  icNotImportant,
  icImportantMarked,
  icSetting,
  icWindowGrid,
  icWindowGroup,
  icForward,
  icReply,
  icPin,
  icAlertOctagon,
  icMailClose,
  icMailOpen,
  icFolderUpload,
  icForwardLeft,
  icForwardRight,
  icArchive,
  icTrash,
  icArrowBack,
  icUser,
  icKey,
  icEmail,
  icEmailWhite,
  icChatWhite,
  icStorageWhite,
  icUpdateWhite,
  icClock,
  icHoroscopeBuffalo,
  bitmap,
  bitmap2,
  firmy,
  horoscopes,
  radio1,
  radio2,
  radio3,
  radio4,
  track,
  icPrinter,
  icWindowStore,
  icEnvelope,
  icLink,
  icPlayVideo,
  videoBackgroundNews,
  arrowDown,
  logoAdmin,
  loginBackground,
  loginImage,
  registerImage,
};
export default Images;
