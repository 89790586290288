export const colors = [
  // first block
  { value: 'rgb(0, 0, 0)' },
  { value: 'rgb(68, 68, 68)' },
  { value: 'rgb(102, 102, 102)' },
  { value: 'rgb(153, 153, 153)' },
  { value: 'rgb(204, 204, 204)' },
  { value: 'rgb(238, 238, 238)' },
  { value: 'rgb(243, 243, 243)' },
  { value: 'rgb(255, 255, 255)' },
  //second block
  { value: 'rgb(255, 0, 0)' },
  { value: 'rgb(255, 153, 0)' },
  { value: 'rgb(255, 255, 0)' },
  { value: 'rgb(0, 255, 0)' },
  { value: 'rgb(0, 255, 255)' },
  { value: 'rgb(0, 255, 255)' },
  { value: 'rgb(153, 0, 255)' },
  { value: 'rgb(255, 0, 255)' },
  // third block
  { value: 'rgb(244, 204, 204)' },
  { value: 'rgb(252, 229, 205)' },
  { value: 'rgb(255, 242, 204)' },
  { value: 'rgb(217, 234, 211)' },
  { value: 'rgb(208, 224, 227)' },
  { value: 'rgb(207, 226, 243)' },
  { value: 'rgb(217, 210, 233)' },
  { value: 'rgb(234, 209, 220)' },

  { value: 'rgb(234, 153, 153)' },
  { value: 'rgb(249, 203, 156)' },
  { value: 'rgb(255, 229, 153)' },
  { value: 'rgb(182, 215, 168)' },
  { value: 'rgb(162, 196, 201)' },
  { value: 'rgb(159, 197, 232)' },
  { value: 'rgb(180, 167, 214)' },
  { value: 'rgb(213, 166, 189)' },

  { value: 'rgb(224, 102, 102)' },
  { value: 'rgb(246, 178, 107)' },
  { value: 'rgb(255, 217, 102)' },
  { value: 'rgb(147, 196, 125)' },
  { value: 'rgb(118, 165, 175)' },
  { value: 'rgb(111, 168, 220)' },
  { value: 'rgb(142, 124, 195)' },
  { value: 'rgb(194, 123, 160)' },

  { value: 'rgb(204, 0, 0)' },
  { value: 'rgb(230, 145, 56)' },
  { value: 'rgb(241, 194, 50)' },
  { value: 'rgb(106, 168, 79)' },
  { value: 'rgb(69, 129, 142)' },
  { value: 'rgb(61, 133, 198)' },
  { value: 'rgb(103, 78, 167)' },
  { value: 'rgb(166, 77, 121)' },

  { value: 'rgb(153, 0, 0)' },
  { value: 'rgb(180, 95, 6)' },
  { value: 'rgb(191, 144, 0)' },
  { value: 'rgb(56, 118, 29)' },
  { value: 'rgb(19, 79, 92)' },
  { value: 'rgb(11, 83, 148)' },
  { value: 'rgb(53, 28, 117)' },
  { value: 'rgb(116, 27, 71)' },

  { value: 'rgb(102, 0, 0)' },
  { value: 'rgb(120, 63, 4)' },
  { value: 'rgb(127, 96, 0)' },
  { value: 'rgb(39, 78, 19)' },
  { value: 'rgb(12, 52, 61)' },
  { value: 'rgb(7, 55, 99)' },
  { value: 'rgb(32, 18, 77)' },
  { value: 'rgb(76, 17, 48)' },
];
