import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Space,
  Select,
  Input,
  Button,
  Modal,
} from 'antd';
import classNames from 'classnames';

import { getSubject, convertEmailStringToList } from '../../../utils/string';
import { isDraftReply } from '../../../utils/validation';

import Popup from '../../../commons/Popup/Popup';
import EditorComponent from '../../../commons/Editor/Editor';
import Messsage from '../../../commons/ui/Message/Message';
import ActionButton from './ActionButton';

import { MAIL_REGEX } from '../../../constants/validateRegEx';

import {
  sendMail as sendMailAction,
  deleteDrafts as deleteDraftsAction,
  saveDraft as saveDraftAction,
} from '../../../redux/actions';

import { Text } from '../../../commons/styles';

import { Images } from '../../../themes';
import styles from './ComposeEmail.module.css';

import * as Style from '../styles';

const SAMPLE_USERS = [
  { email: 'clevertechit@gmail.com', image: Images.user001 },
  { email: 'test@gmail.com', image: Images.user001 },
];

const mailEditorInitialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const grammarly = 'contenteditable="true" style="outline: none; white-space: pre-wrap; overflow-wrap: break-word; height: 200px; overflow: auto;"';

function ComposeEmail({
  mailItem = { From: [] },
  isExpandingCompose,
  isVisibleComposeEmail,
  setIsVisibleReplayEmail,
  isReply,
  onHide,
  toggleExpandCompose,
  sendMailForm,
  sendMail,
  deleteDrafts,
  saveDraft,
  savedDraft,
}) {
  const [sendMailData, setSendMailData] = useState({
    to: convertEmailStringToList(mailItem.To),
    cc: convertEmailStringToList(mailItem.Cc),
    bcc: convertEmailStringToList(mailItem.Bcc),
    subject: (mailItem && (isReply ? getSubject(mailItem.Subject) : mailItem.Subject)) || '',
    body: (mailItem && mailItem.Body) || '',
  });
  console.log('🚀 ~ file: ComposeEmail.jsx ~ line 64 ~ sendMailData', sendMailData);
  const [attachments, setAttachments] = useState([]);
  const [insertedImages, setInsertedImages] = useState(null);
  const [editorValue, setEditorValue] = useState(mailEditorInitialValue);

  const [isShowToolBar, setIsShowToolBar] = useState(true);
  const [isShrinking, setIsShrinking] = useState(false);
  const [showSubEmailInput, setShowSubEmailInput] = useState({
    cc: false,
    bcc: false,
  });
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const mailEditorRef = useRef(null);

  function toggleShrink() {
    // UPDATE_LATE
    setIsShrinking(!isExpandingCompose ? !isShrinking : true)
    if (isExpandingCompose) toggleExpandCompose(false)
  };

  function validateEmails() {
    const { to, cc, bcc } = sendMailData;
    const invalidToMail = to.find((item) => !MAIL_REGEX.test(item));
    const invalidCcMail = cc.find((item) => !MAIL_REGEX.test(item));
    const invalidBccMail = bcc.find((item) => !MAIL_REGEX.test(item));
    if (!to.length) {
      Modal.error({
        title: 'Error',
        content: 'Please specify at least one recipient.',
        centered: true,
      });
      return false;
    }
    if (!!invalidToMail) {
      Modal.error({
        title: 'Error',
        content: `The address "${invalidToMail}" in the "To" field was not recognized. Please make sure that all addresses are properly formed.`,
        centered: true,
      });
      return false;
    }
    if (!!invalidCcMail) {
      Modal.error({
        title: 'Error',
        content: `The address "${invalidCcMail}" in the "Cc" field was not recognized. Please make sure that all addresses are properly formed.`,
        centered: true,
      });
      return false;
    }
    if (!!invalidBccMail) {
      Modal.error({
        title: 'Error',
        content: `The address "${invalidBccMail}" in the "Bcc" field was not recognized. Please make sure that all addresses are properly formed.`,
        centered: true,
      });
      return false;
    }
    return true;
  };

  function onSaveDraft() {
    if (!mailEditorRef || isShrinking) return;
    const { to, cc, bcc, subject } = sendMailData;
    saveDraft({
      data: {
        to: to.join('|'),
        cc: cc.join('|'),
        bcc: bcc.join('|'),
        subject: subject.trim(),
        body: mailEditorRef.current?.innerHTML.replace(grammarly, ''),
      },
    });
  };

  function onSendMail(e) {
    e.preventDefault();
    const { to, cc, bcc, subject } = sendMailData;
    const isValid = validateEmails();
    if (isValid) {
      sendMail({
        data: {
          to: to.join('|'),
          cc: cc.join('|'),
          bcc: bcc.join('|'),
          subject: subject.trim(),
          body: mailEditorRef.current?.innerHTML.replace(grammarly, ''),
        },
        callback: {
          onHideComposeEmail: onHide,
        },
      });
    }
  }

  function onReplyEmail(e) {
    e.preventDefault();
    const { From, Subject, Id, MessageID } = mailItem;
    if (From && From.length > 0) {
      sendMail({
        data: {
          to: From[0].Address,
          subject: Subject,
          in_reply_to: MessageID,
          id: Id,
          body: mailEditorRef.current?.innerHTML.replace(grammarly, ''),
        },
        callback: {
          onHideComposeEmail: () => setIsVisibleReplayEmail(false),
        },
      });
    }
  };
  
  // UPDATE_LATE
  // function onUpdateDraft(grammarly) {
  //   if (!savedDraft.id) return;
  //   const { to, cc, bcc, subject } = sendMailData;
  //   saveDraft({
  //     data: {
  //       to: to.value.join("|"),
  //       cc: cc.value.join("|"),
  //       bcc: bcc.value.join("|"),
  //       subject: subject.value,
  //       body:
  //         "<div>" +
  //           mailEditorRef.innerHTML
  //           .replace('data-slate-length="0"', "")
  //           .replace(grammarly, "") +
  //         "</div>",
  //     },
  //   });
  // };

  function onDeleteDraft() {
    if (savedDraft.id) {
      deleteDrafts({ data: { ids: savedDraft.id } });
    }
  }

  function onChangeTargetMail(fieldName, value) {
    setSendMailData({
      ...sendMailData,
      [fieldName]: value,
    });
  };

  function onChangeSubject(e) {
    console.log('🚀 ~ file: ComposeEmail.jsx ~ line 218 ~ onChangeSubject ~ e', e);
    const { value } = e.target;
    setSendMailData({
      ...sendMailData,
      subject: value,
    });
  };


  function selectAttachment(e, type) {
    const files = e.target.files;
    switch (type) {
      case "attachment":
        const filesArr = Array.prototype.slice.call(files);
        setAttachments([...attachments, ...filesArr]);
        return;
      case "image":
        const reader = new FileReader();
        const arrImages = [];
        reader.onload = (upload) => {
          arrImages.push(upload.target.result);
          setInsertedImages(upload.target.result);
        };
        reader.readAsDataURL(files[0]);
        return;
      default:
        break;
    }
  };

  function renderAttachments() {
    return attachments.map((attachmentItem, attachmentIndex) => (
      <li key={attachmentItem.attachmentIndex}>
        <a>
          <img width={22} height={24} src={Images.iconDoc} alt="doc" />
          <span className={classNames(styles.documentTitle)}>
            {attachmentItem.name.substring(0, 20) + "..."}
          </span>
          <span className="document-size">
            {(attachmentItem.size / 1024).toFixed(2) + " Kb"}
          </span>
        </a>
      </li>
    ));
  };

  function renderGroupButton() {
    return (
      <div className="d-flex">
        <button
          type="button"
          onClick={toggleShrink}
          className={classNames(styles.btnComposer)}
        >
          <img src={Images.iconMinus} alt="minus" />
        </button>
        <button
          type="button"
          onClick={() => {
            setIsShrinking(false);
            toggleExpandCompose(!isExpandingCompose);
          }}
          className={classNames(styles.btnComposer)}
        >
          <img
            src={
              isExpandingCompose ? Images.iconCompress : Images.iconCompressOut
            }
            alt="compress"
          />
        </button>
        <button
          type="button"
          onClick={onHide}
          className={classNames(styles.btnComposer)}
        >
          <img src={Images.iconClose} alt="close" />
        </button>
      </div>
    );
  };

  function renderEmailTags({ label, value, closable, onClose  }) {
    return (
      <Style.TargetEmailTag
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        closable={closable}
        onClose={onClose}
        invalidMail={!MAIL_REGEX.test(value)}
      >
        {label}
      </Style.TargetEmailTag>
    );
  }

  function renderSuggestionEmails() {
    return SAMPLE_USERS.map((suggestionItem, suggestionIndex) => (
      <Select.Option key={`suggestion-${suggestionIndex}`} value={suggestionItem.email}>
        {suggestionItem.email}
      </Select.Option>
    ))
  }
  
  function renderTargetEmailInput() {
    if (isReply) return null;
    return (
      <Style.TargetEmailInputContainer>
        <Style.TargetEmailInputContent showFull={showSubEmailInput.cc || showSubEmailInput.bcc}>
          <Text subText style={{ paddingLeft: 8, width: 80, lineHeight: '32px' }}>Send to</Text>
          <Select
            name="to"
            mode="tags"
            tokenSeparators={[',']}
            bordered={false}
            tagRender={renderEmailTags}
            onChange={(values) => onChangeTargetMail('to', values)}
            value={sendMailData.to}
            style={{ width: 'calc(100% - 80px)' }}
          >
            {renderSuggestionEmails()}
          </Select>
        </Style.TargetEmailInputContent>
        {showSubEmailInput.cc && (
          <Style.TargetEmailInputContent showFull={showSubEmailInput.bcc}>
            <Text subText style={{ paddingLeft: 8, width: 80, lineHeight: '32px' }}>Cc</Text>
            <Select
              name="cc"
              mode="tags"
              tokenSeparators={[',']}
              bordered={false}
              tagRender={renderEmailTags}
              onChange={(values) => onChangeTargetMail('cc', values)}
              value={sendMailData.cc}
              style={{ width: 'calc(100% - 80px)' }}
            >
              {renderSuggestionEmails()}
            </Select>
          </Style.TargetEmailInputContent>
        )}
        {showSubEmailInput.bcc && (
          <Style.TargetEmailInputContent showFull={showSubEmailInput.cc}>
            <Text subText style={{ paddingLeft: 8, width: 80, lineHeight: '32px' }}>Bcc</Text>
            <Select
              name="bcc"
              mode="tags"
              tokenSeparators={[',']}
              bordered={false}
              tagRender={renderEmailTags}
              onChange={(values) => onChangeTargetMail('bcc', values)}
              value={sendMailData.bcc}
              style={{ width: 'calc(100% - 80px)' }}
            >
              {renderSuggestionEmails()}
            </Select>
          </Style.TargetEmailInputContent>
        )}
        {!(showSubEmailInput.cc && showSubEmailInput.bcc) && (
          <Style.SubTargetEmailButton>
            <Space style={{ alignSelf: 'flex-end' }}>
              {!showSubEmailInput.cc && (
                <Button
                  type="text"
                  shape="circle"
                  onClick={() => setShowSubEmailInput({
                    ...showSubEmailInput,
                    cc: !showSubEmailInput.cc,
                  })}
                >
                  Cc
                </Button>
              )}
              {!showSubEmailInput.bcc && (
                <Button
                  type="text"
                  shape="circle"
                  onClick={() => setShowSubEmailInput({
                    ...showSubEmailInput,
                    bcc: !showSubEmailInput.bcc,
                  })}
                >
                  Bcc
                </Button>
              )}
            </Space>
          </Style.SubTargetEmailButton>
        )}
      </Style.TargetEmailInputContainer>
    );
  }

  function renderSubjectEmailInput() {
    if (isReply) return null;
    return (
      <Style.SubjectEmailInputContainer>
        <Input
          name="subject"
          bordered={false}
          placeholder="Subject"
          onChange={(e) => onChangeSubject(e)}
          value={sendMailData.subject}
        />
      </Style.SubjectEmailInputContainer>
    )
  }

  function renderComposeContent() {
    const numOfUsersReplied = mailItem.From.length;
    const latestReplyUser = mailItem.From[numOfUsersReplied - 1];
    return (
      <div>
        {!isReply && (
          <div className={classNames(styles.composeHeader)}>
            <div
              className={classNames(styles.titleGroup)}
              onClick={toggleShrink}
            >
              <h3
                className={classNames(styles.composeHeaderTitle)}
                id="exampleModalLongTitle"
              >
                Write New Message
              </h3>
            </div>
            {renderGroupButton()}
          </div>
        )}
        <div className={classNames("modal-body")}>
          <form
            onKeyDown={(e) => e.key !== "Enter"}
            onSubmit={!isReply ? onSendMail : onReplyEmail}
            className={classNames("form-to-account")}
          >
            {renderTargetEmailInput()}
            {renderSubjectEmailInput()}
            <div
              className={`${
                isReply
                  ? "mail-detail-reply"
                  : "form-group detail-reply-content"
              }`}
              style={{ marginBottom: 0 }}
            >
              {isReply && (
                <div className="detail-reply-header mail-reply-group">
                  <div className="user">
                    <div className="reply-to">
                      <i className="icon-reply"></i>
                    </div>
                    <div className="user-info" style={{ width: "100%" }}>
                      <p className="user-name rubikMedium">
                        {latestReplyUser.Name}
                      </p>
                      <p className="user-mail">{latestReplyUser.Address}</p>
                    </div>
                  </div>
                  <ul className="list-flex list-action-more list-unstyled">
                    <li>
                      <span
                        onClick={(e) => {
                          onDeleteDraft();
                          setIsVisibleReplayEmail(false);
                        }}
                        className="delete hover_square"
                      >
                        <i className="icon-trash"></i>
                      </span>
                    </li>
                    <li>
                      <a href="#" className="more hover_square">
                        <i className="icon-more"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              )}
              <div style={{ marginTop: "16px" }}>
                <EditorComponent
                  isShowToolBar={isShowToolBar}
                  setEditorValue={setEditorValue}
                  editorValue={editorValue}
                  mailEditorRef={mailEditorRef}
                />
              </div>
              <div className="form-attach-document pb-5">
                <ul className="document-list list-unstyled">
                  {renderAttachments()}
                </ul>
              </div>
              {/* <div className="col-lg-offset-2 col-lg-10">
                {<Messsage message={sendMailForm && sendMailForm.error} />}
              </div> */}
              <div
                className={classNames(
                  `${
                    isReply ? "mail-action-send" : "modal-footer"
                  } col-lg-offset-2 col-lg-12 pt-3`,
                  styles.footerCompose
                )}
              >
                <div
                  className={classNames(
                    `mail-action-send modal-footer-left ${
                      isReply ? "reply-bottom-box" : ""
                    }`
                  )}
                >
                  <div className="modal-button">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={!!disabledSubmitBtn}
                    >
                      Send Message
                    </button>
                  </div>
                  <ul className="list-flex list-action-attachment list-unstyled">
                    <ActionButton
                      icon="icon-text-box"
                      handleClick={() => setIsShowToolBar(!isShowToolBar)}
                    />
                    <ActionButton
                      icon="icon-attachment"
                      attachment
                      handleSelectAttachment={(e) =>
                        selectAttachment(e, "attachment")
                      }
                    />
                    <ActionButton icon="icon-happiness" />
                    <ActionButton
                      icon="icon-images"
                      attachment
                      handleSelectAttachment={(e) =>
                        selectAttachment(e, "image")
                      }
                      multiple={false}
                      acceptedType="image/*"
                    />
                    <ActionButton icon="icon-google-drive" attachment />
                    <ActionButton icon="icon-dropbox" attachment />
                  </ul>
                </div>
                {!isReply && (
                  <div className="modal-footer-right">
                    <ul className="list-flex list-action-more list-unstyled">
                      <li>
                        <a
                          onClick={() => {
                            onDeleteDraft();
                            onHide();
                          }}
                          className="delete hover_square pointer"
                        >
                          <i className="icon-trash"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="more hover_square">
                          <i className="icon-more"></i>
                        </a>
                      </li>
                      {/* {sendMailForm && sendMailForm.loading && <Spinner />} */}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  if (isShrinking) {
    return (
      <div className="bottom-compose" style={{ maxWidth: "260px" }}>
        <div
          className={classNames(
            styles.composeHeader,
            styles.composeHeaderMin
          )}
        >
          <button
            type="button"
            onClick={toggleShrink}
            className={classNames(styles.btnComposer, styles.smallHeader)}
          >
            <h6 className={classNames(styles.titleMin)}>New Message</h6>
          </button>
          {renderGroupButton()}
        </div>
      </div>
    );
  }
  if (isExpandingCompose) {
    return (
      <Popup
        show={isVisibleComposeEmail}
        title="Compose"
        onHide={onHide}
        className="modal-new-message"
        dialogClassName={"compose-email"}
      >
        {renderComposeContent()}
      </Popup>
    );
  }
  return (
    <div
      className={classNames(
        !isReply ? "bottom-compose" : "reply-email-box",
        styles.borderBodyBox
      )}
    >
      {renderComposeContent()}
    </div>
  );
}

const mapStateToProp = (state) => {
  const { savedDraft } = state.mails;
  return {
    // sendMailForm: state.sendMail.form,
    savedDraft,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMail: (param) => dispatch(sendMailAction(param)),
    deleteDrafts: (params) => dispatch(deleteDraftsAction(params)),
    saveDraft: (params) => dispatch(saveDraftAction(params)),
  };
};

export default connect(mapStateToProp, mapDispatchToProps)(ComposeEmail);
