import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isEmpty } from 'lodash/lang';

import history from '../../utils/history';

import SearchFrom from "../ui/SearchForm/SearchForm";
import Popup from "./../Popup/Popup";

import { Images } from "./../../themes";
import { STANDING_PAGES } from "./../../constants/common";
import defaultAvatar from "./../../assets/images/default-avatar.png";
import {Dropdown} from 'react-bootstrap';

import { LoginStorage } from '../../utils/localStorge';

import { logout as logoutAction } from '../../redux/actions';

import * as Style from './styles';

class NavBar extends React.Component {
  state = {
    showedPopup: "",
    expandSearchBox: false,
    scrolling: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
    }
  };

  toggleForm = (value = "LOGIN", registerSuccessed) => {
    this.setState({
      showedPopup: value,
      registerSuccessed,
    });
  };

  renderIcon = (type) => {
    if (type === "user") return null;
    return (
      <li key={type} className="notifications headphone dropdown">
        <a
          href="#"
          className="dropdown-toggle no-after"
          data-toggle="dropdown"
          aria-expanded="true"
        >
          <i className={`icon-${type}`}></i>
        </a>
        <ul className="dropdown-menu list-unstyled">
          <li>
            <a href="#">dropdown item</a>
          </li>
          <li>
            <a href="#">dropdown item</a>
          </li>
        </ul>
      </li>
    );
  };

  renderGroupIcons = (isMin) => {
    const minIcons = ["notification", "headphone", "user"];
    const mainIcons = ["user", "notification"];
    return (
      <>
        {isMin ? (
          <>{minIcons.map((item) => this.renderIcon(item))}</>
        ) : (
          <>
            <li className="notifications email">
              <Link to="/mail/inbox">
                <i className="icon-message"></i> <span>Email</span>
              </Link>
            </li>
            {mainIcons.map((item) => this.renderIcon(item))}
          </>
        )}
      </>
    );
  };

  CustomToggle = () => React.forwardRef(({ children, onClick }, ref) => {
    const { userInfo } = this.props;
    return (
      <img 
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="avatar-user-info" 
        src={userInfo.avatar || defaultAvatar}
      >
        {children}
      </img>
    )
  });

  onNavigationTo = () => {
// navigator.navigate.
  }

  renderUserInfo = () => {
    const { logout, userInfo } = this.props;
    // UPDATE_LATE:LOADING
    if (isEmpty(userInfo)) return null;
    return (
      <Dropdown>
        <Dropdown.Toggle as={this.CustomToggle()} variant="success" id="dropdown-basic">
        
        </Dropdown.Toggle>
      
        <Dropdown.Menu>
        {
          userInfo.role == 1 ? 
            (
              <Dropdown.Item onClick={() => history.push('/admin')}>              
                Admin
              </Dropdown.Item>
            )
          :
            null
        }

        <Dropdown.Item >Profile</Dropdown.Item>
        <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  render() {
    const { currentPage, toggleSideBar, isShowRightLayout } = this.props;
    const {
      showedPopup,
      expandSearchBox,
      scrolling,
      registerSuccessed,
    } = this.state;

    const userStorage = LoginStorage.getData();

    return (
      <Style.HeaderContainer
        className={`${currentPage === "HOME" ? "header" : "header-mail"} ${
          scrolling ? "header-fixed" : ""
        }`}
        showRightLayout={isShowRightLayout}
        style={{ zIndex: 10 }}
      >
        <div
          className={`${
            currentPage === "HOME" ? "container" : "width-100"
          } header-wrap`}
        >
          <div className="navbar" style={{ marginBottom: 0 }}>
            <button
              className="navbar-toggler navbar-toggler-mail"
              type="button"
              style={!isEmpty(userStorage) ? { display: "block" } : {}}
              onClick={toggleSideBar}
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>

            <div className="navbar-logo">
              <a className="navbar-brand" style={{ height: "auto" }} href="/">
                <div
                  className="logo"
                  style={{
                    backgroundImage: `url(${Images.logoSmallNew})`,
                  }}
                ></div>
              </a>
            </div>
          </div>
          <div className="header-search">
            <a
              onClick={() =>
                this.setState((preState) => ({
                  expandSearchBox: !preState.expandSearchBox,
                }))
              }
              className="search-toggler hover_circle pointer"
            >
              <span className="icon-search"></span>
            </a>
            <SearchFrom
              currentPage={currentPage}
              expandSearchBox={expandSearchBox}
            />
          </div>

          <div className="header-group">
            <ul className="nav-infomation list-unstyled">
              {this.renderGroupIcons(
                (STANDING_PAGES[currentPage] && currentPage !== "HOME") ||
                  currentPage === "MAIL BOX"
              )}
              {(!STANDING_PAGES[currentPage] ||
                currentPage === "HOME"
                ) && (
                  !isEmpty(userStorage) ?
                    (<li>{ this.renderUserInfo() }</li>)
                  :
                    (
                      <li className="action-btn">
                        <a
                          href="#"
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#modal-login"
                          onClick={() => this.toggleForm("LOGIN") }
                        >
                          Login
                        </a>
                      </li>
                    )
              )}
            </ul>
          </div>
        </div>

        {/* Render popup Login */}
        {/* <Popup
          show={showedPopup === "LOGIN"}
          onHide={() => this.toggleForm("")}
          className="modal-login"
        >
          <Login
            openRegisterForm={() => this.toggleForm("REGISTER")}
            registerSuccessed={registerSuccessed}
          />
        </Popup> */}

        {/* Render popup REGISTER */}
        {/* <Popup
          show={showedPopup === "REGISTER"}
          onHide={() => this.toggleForm("")}
          className="modal-login"
        >
          <Register
            openRegisterForm={(registerSuccessed) =>
              this.toggleForm("LOGIN", registerSuccessed)
            }
          />
        </Popup> */}
      </Style.HeaderContainer>
    );
  }
}

const mapStateToProp = (state) => {
  const { userInfo } = state.auth;
  return {
    userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logoutAction()),
  };
};

export default connect(mapStateToProp, mapDispatchToProps)(NavBar);
