import React from 'react';
import { Space } from 'antd';

import { Text } from '../../../commons/styles';

import { ReactComponent as ClockIcon } from "../../../assets/images/ic_clock.svg";

import * as Style from '../styles';

const SmallNews = ({
  newsItem
}) => {
  return (
    <Style.SmallNewsContainer>
      <Style.SmallNewsImage image={newsItem.image} />
      <Style.SmallNewsContent>
        <Text className="small-news-title" headerText w6 truncateMultiLine={2}>
          {newsItem.title}
        </Text>
        <Space size={6}>
          <ClockIcon />
          <Text subText xs>
            {`${newsItem.createAt} | ${newsItem.url}`}
          </Text>
        </Space>
      </Style.SmallNewsContent>
    </Style.SmallNewsContainer>
  );
};

export default SmallNews;
