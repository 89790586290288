import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { isEmpty } from 'lodash/lang';

import { LoginStorage } from '../../../utils/localStorge';

import Header from './components/Header';
import Sidebar from './components/Sidebar';

import * as Style from './styles';

const AdminLayout = ({
  component: Component,
  path,
  ...rest
}) => {
  const [isShowSidebar, setIsShowSidebar] = useState(true);

  const userStorage = LoginStorage.getData();

  if (isEmpty(userStorage)) {
    return <Redirect to={{ pathname: '/login', state: { previousPage: path } }}  />;
  } else if (userStorage.role !== 1) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout style={{ minHeight: '100vh' }}>
          <Sidebar
            {...matchProps}
            setIsShowSidebar={setIsShowSidebar}
            isShowSidebar={isShowSidebar}
          />
          <Layout>
            <Header setIsShowSidebar={setIsShowSidebar} isShowSidebar={isShowSidebar} />
            <Style.MainContainer>
              <Component {...matchProps} />
            </Style.MainContainer>
          </Layout>
        </Layout>
      )}
    />
  );
};

export default AdminLayout;
