import {
  USER,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constants';

const initialState = {
  articleList: {
    items: [],
    loading: false,
    errors: null
  },
  articleDetail: {
    items: {},
    loading: false,
    errors: null
  },
  articleRelated: {
    items: [],
    loading: false,
    errors: null
  },
  articleNewTrending: {
    items: [],
    loading: false,
    errors: null
  },
  articleMostReaction: {
    items: [],
    loading: false,
    errors: null
  },
  articleLikes: {
    items: [],
    loading: false,
    errors: null
  },
  articleComments: {
    items: [],
    loading: false,
    errors: null
  },
};

const articleReducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST(USER.ARTICLE_ACTION.GET_LIST): {
			return {
				...state,
				articleList: {
          ...state.articleList,
          loading: true,
        },
			};
    }
		case SUCCESS(USER.ARTICLE_ACTION.GET_LIST): {
      const { data } = action.payload;
			return {
				...state,
				articleList: {
          ...state.articleList,
          items: data.items,
          loading: false,
          errors: false,
        },
			};
    }
		case FAILURE(USER.ARTICLE_ACTION.GET_LIST): {
			return {
				...state,
        articleList: {
          ...state.articleList,
          loading: false,
          errors: action.errors,
        },
			};
    }

    case REQUEST(USER.ARTICLE_ACTION.GET_DETAIL): {
			return {
				...state,
				articleDetail: {
          ...state.articleDetail,
          loading: true,
        },
			};
    }
		case SUCCESS(USER.ARTICLE_ACTION.GET_DETAIL): {
      const { data } = action.payload;
			return {
				...state,
				articleDetail: {
          ...state.articleDetail,
          items: data.items,
          loading: false,
          errors: false,
        },
			};
    }
		case FAILURE(USER.ARTICLE_ACTION.GET_DETAIL): {
			return {
				...state,
        articleDetail: {
          ...state.articleDetail,
          loading: false,
          errors: action.errors,
        },
			};
    }

    case REQUEST(USER.ARTICLE_ACTION.GET_RELATED): {
			return {
				...state,
				articleRelated: {
          ...state.articleRelated,
          loading: true,
        },
			};
    }
		case SUCCESS(USER.ARTICLE_ACTION.GET_RELATED): {
      const { data } = action.payload;
			return {
				...state,
				articleRelated: {
          ...state.articleRelated,
          items: data.items,
          loading: false,
          errors: false,
        },
			};
    }
		case FAILURE(USER.ARTICLE_ACTION.GET_RELATED): {
			return {
				...state,
        articleRelated: {
          ...state.articleRelated,
          loading: false,
          errors: action.errors,
        },
			};
    }

    case REQUEST(USER.ARTICLE_ACTION.GET_NEW_TRENDING): {
			return {
				...state,
				articleNewTrending: {
          ...state.articleNewTrending,
          loading: true,
        },
			};
    }
		case SUCCESS(USER.ARTICLE_ACTION.GET_NEW_TRENDING): {
      const { data } = action.payload;
			return {
				...state,
				articleNewTrending: {
          ...state.articleNewTrending,
          items: data.items,
          loading: false,
          errors: false,
        },
			};
    }
		case FAILURE(USER.ARTICLE_ACTION.GET_NEW_TRENDING): {
			return {
				...state,
        articleNewTrending: {
          ...state.articleNewTrending,
          loading: false,
          errors: action.errors,
        },
			};
    }

    case REQUEST(USER.ARTICLE_ACTION.GET_MOST_REACTION): {
			return {
				...state,
				articleMostReaction: {
          ...state.articleMostReaction,
          loading: true,
        },
			};
    }
		case SUCCESS(USER.ARTICLE_ACTION.GET_MOST_REACTION): {
      const { data } = action.payload;
			return {
				...state,
				articleMostReaction: {
          ...state.articleMostReaction,
          items: data.items,
          loading: false,
          errors: false,
        },
			};
    }
		case FAILURE(USER.ARTICLE_ACTION.GET_MOST_REACTION): {
			return {
				...state,
        articleMostReaction: {
          ...state.articleMostReaction,
          loading: false,
          errors: action.errors,
        },
			};
    }

    case REQUEST(USER.ARTICLE_ACTION.GET_LIKES): {
			return {
				...state,
				articleLikes: {
          ...state.articleLikes,
          loading: true,
        },
			};
    }
		case SUCCESS(USER.ARTICLE_ACTION.GET_LIKES): {
      const { data } = action.payload;
			return {
				...state,
				articleLikes: {
          ...state.articleLikes,
          items: data.items,
          loading: false,
          errors: false,
        },
			};
    }
		case FAILURE(USER.ARTICLE_ACTION.GET_LIKES): {
			return {
				...state,
        articleLikes: {
          ...state.articleLikes,
          loading: false,
          errors: action.errors,
        },
			};
    }

    case REQUEST(USER.ARTICLE_ACTION.GET_COMMENTS): {
			return {
				...state,
				articleComments: {
          ...state.articleComments,
          loading: true,
        },
			};
    }
		case SUCCESS(USER.ARTICLE_ACTION.GET_COMMENTS): {
      const { data } = action.payload;
			return {
				...state,
				articleComments: {
          ...state.articleComments,
          items: data.items,
          loading: false,
          errors: false,
        },
			};
    }
		case FAILURE(USER.ARTICLE_ACTION.GET_COMMENTS): {
			return {
				...state,
        articleComments: {
          ...state.articleComments,
          loading: false,
          errors: action.errors,
        },
			};
    }
		default:
			return state;
	}
};

export default articleReducer;
