import React, { memo } from 'react'
import { VIDEOS } from './../../../mockdata/homeData'

const VideoItem = memo(props => {
  const { poster, content, author } = props
  return (
    <div className="video-item box-flex">
      <div className="thumbnail">
        <a href="#" style={{ backgroundImage: `url(${poster})` }}></a>
      </div>
      <div className="video-info">
        <h4 className="rubikMedium">
          <a href="#">{content}</a>
        </h4>
        <p className="txt-meta">{author}</p>
      </div>
    </div>
  )
})

const Videos = memo(props => {
  return (
    <div className="sidebar-item">
      <h3 className="sidebar-title rubikMedium">Videos Must You Watch</h3>
      <div className="video-items">
        {
          VIDEOS.map(item => (
            <VideoItem key={`video${item.author}`} {...item} />
          ))
        }
      </div>
      <p className="sidebar-button rubikMedium">
        <a href="#" className="btn btn-primary">continue to Phuk.com</a>
      </p>
    </div>
  )
})

export default Videos
