import { AUTH_ACTION, REQUEST } from '../constants';

export function login(params) {
  return {
    type: REQUEST(AUTH_ACTION.LOGIN),
    payload: params,
  };
}

export function register(params) {
  return {
    type: REQUEST(AUTH_ACTION.REGISTER),
    payload: params,
  };
}

export function logout(params) {
  return {
    type: REQUEST(AUTH_ACTION.LOGOUT),
    payload: params,
  };
}

export function getUserInfo() {
  return {
    type: REQUEST(AUTH_ACTION.GET_USER_INFO),
  };
}
