import React, { useState } from 'react';
import { Row, Col, Input, Checkbox, Button, Collapse, Space } from 'antd';
import {
  DownOutlined,
  UpOutlined,
  SearchOutlined,
  EnvironmentOutlined,
  PhoneFilled,
  MailFilled,
  CheckCircleFilled,
} from '@ant-design/icons';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import history from '../../utils/history';

import Header from '../../commons/Header';

import { Text, LoadMoreButton, CustomCheckbox } from '../../commons/styles';

import { ReactComponent as ArrowDownIcon } from "../../assets/images/arrow_down.svg";
import { ReactComponent as FirmyRestaurantIcon } from "../../assets/images/ic_firmy_restaurant.svg";

import * as Style from './styles';

const POPULAR_THIS_MONTH = [
  { label: 'Automotive', value: 1 },
  { label: 'Automotive', value: 2 },
  { label: 'Automotive', value: 3 },
  { label: 'Automotive', value: 4 },
  { label: 'Automotive', value: 5 },
  { label: 'Automotive', value: 6 },
  { label: 'Automotive', value: 7 },
  { label: 'Automotive', value: 8 },
  { label: 'Automotive', value: 9 },
  { label: 'Automotive', value: 10 },
  { label: 'Automotive', value: 11 },
  { label: 'Automotive', value: 12 },
  { label: 'Automotive', value: 13 },
  { label: 'Automotive', value: 14 },
];

const ALL_BUSINESSES_AND_POINTS = [
  [
    {
      title: 'Restaurants',
      filters: [
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        }
      ]
    },
    {
      title: 'Hotel & Travel',
      filters: [
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        }
      ]
    },
    {
      title: 'Education',
      filters: [
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        }
      ]
    },
  ],
  [
    {
      title: 'Vehicles',
      filters: [
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        }
      ]
    },
    {
      title: 'Art & Recreation',
      filters: [
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        }
      ]
    },
    {
      title: 'Service',
      filters: [
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        }
      ]
    },
  ],
  [
    {
      title: 'Purchase',
      filters: [
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        }
      ]
    },
    {
      title: 'Events',
      filters: [
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        }
      ]
    },
    {
      title: 'Real Estate',
      filters: [
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        },
        {     
          name: 'Cafetaria',
          value: 'cafetaria',
          point: 5212,
        }
      ]
    },
  ],
];

const POPULAR_PLACES = [
  {
    title: 'FLORENC COSTUM RENTAL',
    address: 'Na Poříčí 1063/33, Praha, Nové Město',
    description: 'We rent carnival costumes for children and adults. Our mask and costume rental offers, for example, sexy costumes, movie and historical costumes and accessories such as…',
    phone: '720 980 920',
    image: 'https://via.placeholder.com/236x166',
  },
  {
    title: 'Costume Rental Praha.cz',
    address: 'Na Poříčí 1063/33, Praha, Nové Město',
    description: 'We rent carnival costumes for children and adults. Our mask and costume rental offers, for example, sexy costumes, movie and historical costumes and accessories such as…',
    phone: '720 980 920',
    image: 'https://via.placeholder.com/236x166',
  },
  {
    title: 'Bicycle-costumes-angel.cz',
    address: 'Na Poříčí 1063/33, Praha, Nové Město',
    description: 'We rent carnival costumes for children and adults. Our mask and costume rental offers, for example, sexy costumes, movie and historical costumes and accessories such as…',
    phone: '720 980 920',
    image: 'https://via.placeholder.com/236x166',
  },
  {
    title: 'Barrio B - Lounge Bar',
    address: 'Na Poříčí 1063/33, Praha, Nové Město',
    description: 'We rent carnival costumes for children and adults. Our mask and costume rental offers, for example, sexy costumes, movie and historical costumes and accessories such as…',
    phone: '720 980 920',
    image: 'https://via.placeholder.com/236x166',
  }
];

const FirmyPage = () => {
  const [popularMonthCollapseKeys, setPopularMonthCollapseKeys] = useState(['1']);

  function renderPopularThisMonth() {
    return POPULAR_THIS_MONTH.map((popularItem, popularIndex) => {
      return (
        <Col lg={{ span: 4 }} xs={{ span: 6 }}>
          <CustomCheckbox value={popularItem.value}>
            <Text primaryText>{popularItem.label}</Text>
          </CustomCheckbox>
        </Col>
      )
    })
  }

  function renderBusinessesFilter() {
    return ALL_BUSINESSES_AND_POINTS.map((columnItem, columnIndex) => {
      return (
        <Col lg={{ span: 8 }} xs={{ span: 12 }}>
          {columnItem.map((categoryItem, categoryIndex) => {
            return (
              <Style.BusinessesFilterCard>
                <Space size={8} style={{ marginBottom: 12 }}>
                  <FirmyRestaurantIcon />
                  <Text primaryText xl w6>{categoryItem.title}</Text>
                </Space>
                {categoryItem.filters.map((filterItem, filterIndex) => {
                  return (
                    <Style.BusinessesFilterItem>
                      <CustomCheckbox>{filterItem.name}</CustomCheckbox>
                      <Text subText sm>{filterItem.point}</Text>
                    </Style.BusinessesFilterItem>
                  )
                })}
              </Style.BusinessesFilterCard>
            )
          })}
        </Col>
      )
    })
  }

  function renderPopularPlaces() {
    return POPULAR_PLACES.map((placesItem, placesIndex) => {
      return (
        <Style.PopularPlacesItemContainer>
          <Style.PopularPlacesItemImage image={placesItem.image} />
          <Style.PopularPlacesItemContent>
            <div>
              <Space>
                <Text headerText xl w6 pointer style={{ fontSize: 20 }} onClick={() => history.push('/firmy/1')}>
                  {placesItem.title}
                </Text>
                {placesIndex === 1 && <CheckCircleFilled style={{ fontSize: 18, color: '#25BC30' }} />}
              </Space>
            </div>
            <Space style={{ margin: '6px 0 12px' }} wrap>
              <Text secondaryText sm>{placesItem.address}</Text>
              <Text secondaryText sm>-</Text>
              <Text sm underline style={{ color: '#BC2561' }}>Plan a route</Text>
            </Space>
            <Text subText truncateMultiLine={3} style={{ marginBottom: 10, minHeight: 66 }}>
              {placesItem.description}
            </Text>
            <Space size={[24, 8]} wrap>
              <Button type="link" icon={<PhoneFilled />} style={{ color: '#82BC25' }}>{placesItem.phone}</Button>
              <Button type="link" icon={<MailFilled />} style={{ color: '#82BC25' }}>Send Inquiry</Button>
              <Button type="link" icon={<MailFilled />} style={{ color: '#82BC25' }}>finca-costume.co.dn</Button>
            </Space>
          </Style.PopularPlacesItemContent>
        </Style.PopularPlacesItemContainer>
      )
    })
  }

  return (
    <>
      <Header feature="FIRMY" />
      <Style.MainContainer className="container">
        <Row gutter={24}>
          <Col lg={{ span: 18, order: 1 }} xs={{ span: 24, order: 2 }}>
            <Input.Group compact size="large">
              <Input
                placeholder="Search Shop, Rental, Place, etc"
                prefix={<SearchOutlined style={{ fontSize: 20, color: '#696974' }} />}
                style={{ width: '50%', height: 48 }}
              />
              <Input
                style={{ width: '50%', height: 48 }}
                prefix={<EnvironmentOutlined style={{ fontSize: 20, color: '#696974' }} />}
                placeholder="Molde, Denmark"
                suffix={
                  <Button type="primary" style={{ width: 100 }}>Search</Button>
                } 
              />
            </Input.Group>
            <Style.PopularFilterContainer>
              <Collapse
                activeKey={[...popularMonthCollapseKeys]}
                ghost
                onChange={(keys) => setPopularMonthCollapseKeys([...keys])}
              >
                <Collapse.Panel
                  header={
                    <Space size={10}>
                      <Text primaryText lg w6 inline>Popular this Month</Text>
                      {popularMonthCollapseKeys.includes('1')
                        ? <UpOutlined style={{ color: '#92929D' }} />
                        : <DownOutlined style={{ color: '#92929D' }} />
                      }
                    </Space>
                  }
                  showArrow={false}
                  key="1"
                >
                  <Checkbox.Group onChange={() => null}>
                    <Row>
                      {renderPopularThisMonth()}
                    </Row>
                  </Checkbox.Group>
                </Collapse.Panel>
              </Collapse>
            </Style.PopularFilterContainer>
            <Style.BusinessesFilterContainer>
              <Text primaryText xxl w6>All businesses and points of interest</Text>
              <Text secondaryText style={{ margin: '8px 0 16px' }}>Find the most appropriate business, product or service through keywords or category browsing.</Text>
              <Row gutter={20}>
                {renderBusinessesFilter()}
              </Row>
              <Style.BusinessesFilterMore>
                <LoadMoreButton shape="round" size="large">
                  More
                  <ArrowDownIcon style={{ marginLeft: 6 }} />
                </LoadMoreButton>
              </Style.BusinessesFilterMore>
            </Style.BusinessesFilterContainer>
            <Style.PopularPlacesContainer>
              <Style.PopularPlacesTitle>
                <Text primaryText xxl w6>Popular Places this city</Text>
              </Style.PopularPlacesTitle>
              {renderPopularPlaces()}
            </Style.PopularPlacesContainer>
          </Col>
          <Col lg={{ span: 6, order: 2 }} xs={{ span: 24, order: 1 }}>
            <Style.MapContainer>
              <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false} style={{ width: '100%', height: '100%', zIndex: 0 }}>
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[51.505, -0.09]}></Marker>
              </MapContainer>
            </Style.MapContainer>
          </Col>
        </Row>
      </Style.MainContainer>
    </>
  );
};

export default FirmyPage;
