import React, { useState } from 'react';
import { Space, Menu, Dropdown } from 'antd';
import moment from 'moment';
import classnames from 'classnames';
import styles from './MailDetailCollapse.module.css';
import { Images } from '../../../themes';

import { Text } from '../../../commons/styles';

const ReplyEmailDetail = ({
  replyEmail,
  onToggleReplayMail,
  isLatestEmail,
}) => {
  const [isCollapseEmail, setIsCollapseEmail] = useState(false);

  if (!replyEmail) return null;

  const fromName = replyEmail.From
    ? replyEmail.From.map((from) => from.Name).join(', ')
    : '';
  const fromAddress = replyEmail.From
    ? replyEmail.From.map((from) => from.Address).join(', ')
    : '';

  const lastActivity = replyEmail.LastActivity
    ? replyEmail.LastActivity
    : replyEmail.Date;
  const lastActivityDate = moment(lastActivity);
  const lastActivityDisplay = lastActivityDate.isSame(moment(), "day")
    ? lastActivityDate.format("h:mm a ") + `( ${lastActivityDate.fromNow()} )`
    : lastActivityDate.format(`ll, LT `) + `( ${lastActivityDate.fromNow()} )`;

    
  function renderMoreActionMenu() {
    return (
      <Menu style={{ minWidth: 200 }}>
        <Menu.Item key="0">
          <Space>
            <img src={Images.icForwardLeft} alt="icForwardLeft" width="auto" height={14} />
            <Text>Reply</Text>
          </Space>
        </Menu.Item>
        <Menu.Item key="1">
          <Space>
            <img src={Images.icForwardLeft} alt="icForwardLeft" width="auto" height={14} />
            <Text>Reply to all</Text>
          </Space>
        </Menu.Item>
        <Menu.Item key="2">
          <Space>
            <img src={Images.icForwardRight} alt="icForwardRight" width="auto" height={14} />
            <Text>Forward</Text>
          </Space>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3">Menu item</Menu.Item>
        <Menu.Item key="4">Menu item</Menu.Item>
        <Menu.Item key="5">Menu item</Menu.Item>
        <Menu.Item key="6">Menu item</Menu.Item>
        <Menu.Item key="7">Menu item</Menu.Item>
        <Menu.Item key="8">Menu item</Menu.Item>
        <Menu.Item key="9">Menu item</Menu.Item>
        <Menu.Item key="10">Menu item</Menu.Item>
      </Menu>
    )
  }

  function renderEmailUser() {
    return (
      <div style={{ display: "flex" }}>
        <div
          className="avatar"
          style={{
            flex: 1,
            background: `url(${Images.userImg006}) no-repeat`,
            width: "50px",
            height: "50px",
          }}
        ></div>
        <div className={styles.collapseBody} style={{ flex: 9 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "8px",
            }}
          >
            <div className="user-info" style={{ flex: 1 }}>
              <p
                className="user-name rubikMedium"
                style={{ marginBottom: "0" }}
              >
                {fromName}{" "}
                <a className={styles.info} href="#">
                  Info
                </a>
              </p>
              {!isCollapseEmail && (
                <p className="user-mail">{fromAddress}</p>
              )}
            </div>
            <p>{lastActivityDisplay}</p>
            <ul className={styles.flexOption}>
              <li>
                <a href="#" className="archive hover_square">
                  <i className="icon-star"></i>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="archive hover_square"
                  onClick={onToggleReplayMail}
                >
                  <i className="icon-reply"></i>
                </a>
              </li>
              <li className={styles.itemRight}>
                <Dropdown
                  overlay={renderMoreActionMenu()}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <a
                    href="#"
                    className="archive hover_square"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="icon-more"></i>
                  </a>
                </Dropdown>
              </li>
            </ul>
          </div>
          {isCollapseEmail && (
            <p className={styles.collapseBody}>{replyEmail.TextBody}</p>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="mail-detail" style={{ borderTop: '1px solid #F1F1F5' }}>
      <div
        style={{ paddingTop: "10px", paddingBottom: "10px" }}
        className={classnames(
          "mail-detail-item",
          styles.wrapper,
          isCollapseEmail ? styles.wrapperCollapse : "",
        )}
      >
        <div className="mail-detail-content">
          {isCollapseEmail ? (
            <div
              onClick={() => !isLatestEmail && setIsCollapseEmail(!isCollapseEmail)}
              className={styles.collapseBody}
            >
              {renderEmailUser()}
            </div>
          ) : (
            <>
              <div
                onClick={() => !isLatestEmail && setIsCollapseEmail(!isCollapseEmail)}
              >
                {renderEmailUser()}
              </div>
              <div
                className="mail-d-body"
                style={{ marginTop: "35px" }}
                dangerouslySetInnerHTML={{
                  __html: replyEmail.HTMLBody,
                }}
              />
              {/* <figure
                className="mail-detail-image"
                style={{
                  marginTop: "25px",
                  backgroundImage: image ? `url(${image})` : "none",
                  paddingTop: "10px",
                }}
              ></figure> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReplyEmailDetail;
