import React from "react";

import AgendaList from './components/AgendaList';
import AgendaDetail from './components/AgendaDetail';
import AgendaModify from './components/AgendaModify';
import ContactList from './components/ContactList';
import ContactDetail from './components/ContactDetail';

import * as Style from './styles';

const RightLayout = (props) => {
  const { rightLayout } = props;

  function renderRightLayoutContents() {
    switch (rightLayout.path) {
      case 'agenda-list': return <AgendaList {...props} />;
      case 'agenda-detail': return <AgendaDetail {...props} />;
      case 'agenda-modify': return <AgendaModify {...props} />;
      case 'contact-list': return <ContactList {...props} />;
      case 'contact-detail': return <ContactDetail {...props} />;
      default: return null;
    }
  }

  if (!rightLayout) return null;

  return (
    <Style.RightLayoutContainer showRightLayout={!!rightLayout}>
      {renderRightLayoutContents()}
    </Style.RightLayoutContainer>
  );
};

export default RightLayout;