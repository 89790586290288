import React from 'react';
import { Row, Col, Button, Rate, Space, Badge } from 'antd';
import { ArrowLeftOutlined, RightOutlined } from '@ant-design/icons';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import history from '../../utils/history';

import Header from '../../commons/Header';

import { Text } from '../../commons/styles';

import * as Style from './styles';

const FIRMY_COMMENTS = [
  {
    name: 'Anatoliy Shcherbatykh',
    avatar: 'https://via.placeholder.com/36',
    comment: "The packaging is very attractive and appealing, and also the store concept is very great concept. But.. Mostly customer didn't get the product that they desire. I think the distribution chain is a very very very bad. It's need to be improved so the customer can get a various products.",
    time: '1 hour age',
    rate: 4,
  },
  {
    name: 'Fabiana Capmany',
    avatar: 'https://via.placeholder.com/36',
    comment: "The packaging is very attractive and appealing, and also the store concept is very great concept. But.. Mostly customer didn't get the product that they desire. I think the distribution chain is a very very very bad. It's need to be improved so the customer can get a various products.",
    time: '2 days age',
    rate: 4,
  },
  {
    name: 'Helena Brauer',
    avatar: 'https://via.placeholder.com/36',
    comment: "The packaging is very attractive and appealing, and also the store concept is very great concept. But.. Mostly customer didn't get the product that they desire. I think the distribution chain is a very very very bad. It's need to be improved so the customer can get a various products.",
    time: '3 weeks age',
    rate: 5,
  },
];

const FIRMY_SUGGESTIONS = [
  {
    name: 'Rings Restaurant ',
    address: 'Coffee Shop',
    image: 'https://via.placeholder.com/200',
  },
  {
    name: 'P O M O',
    address: 'Coffee Shop',
    image: 'https://via.placeholder.com/200',
  },
  {
    name: 'Restaurant Villa',
    address: 'Coffee Shop',
    image: 'https://via.placeholder.com/200',
  },
  {
    name: 'Rings Restaurant ',
    address: 'Coffee Shop',
    image: 'https://via.placeholder.com/200',
  },
];

const FirmyDetailPage = () => {
  function renderFirmyPhotos() {
    return [...Array(6)].map((photoItem, photoIndex) => {
      return (
        <Col lg={{ span: 8 }} xs={{ span: 12 }}>
          <Style.PhotoItem image="https://via.placeholder.com/220x120" />
        </Col>
      )
    })
  }

  function renderFirmyComments() {
    return FIRMY_COMMENTS.map((commentItem, commentIndex) => {
      return (
        <Style.CommentContainer>
          <Style.CommentAvatar image={commentItem.avatar} />
          <Style.CommentContent>
            <Text primaryText lg>{commentItem.name}</Text>
            <Space size={8}>
              <Rate disabled defaultValue={commentItem.rate} />
              <Text subText sm>{commentItem.time}</Text>
            </Space>
            <Text secondaryText sm>{commentItem.comment}</Text>
          </Style.CommentContent>
        </Style.CommentContainer>
      )
    })
  }

  function renderFirmySuggestions() {
    return FIRMY_SUGGESTIONS.map((suggestionItem, suggestionIndex) => {
      return (
        <Col lg={{ span: 6 }} xs={{ span: 12 }}>
          <Style.SuggestionsItem>
            <Style.SuggestionsImage image={suggestionItem.image} />
            <Text lg color="#BC2561" style={{ marginTop: 12 }}>{suggestionItem.name}</Text>
            <Badge color="#D8D8D8" text={<Text secondaryText inline>{suggestionItem.address}</Text>} />
          </Style.SuggestionsItem>
        </Col>
      )
    })
  }

  return (
    <>
      <Header feature="FIRMY" />
      <Style.MainContainer className="container">
        <Row gutter={24}>
          <Col lg={{ span: 18, order: 1 }} xs={{ span: 24, order: 2 }}>
            <Button type="link" icon={<ArrowLeftOutlined />} onClick={() => history.push('/firmy')}>
              Back one page
            </Button>
            <Text headerText xl w6 style={{ marginTop: 24, fontSize: 20 }}>GizziGrill Center</Text>
            <Text secondaryText sm style={{ margin: '6px 0 12px' }}>Na Poříčí 1063/33, Praha, Nové Město</Text>
            <Row gutter={30}>
              <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                <Style.FirmyDetailInfo>
                  <Row gutter={16} style={{ width: '100%' }}>
                    <Col span={12}>
                      <Text secondaryText>Our Website</Text>
                    </Col>
                    <Col span={12}>
                      <Text>https://www.google.com/</Text>
                    </Col>
                  </Row>
                </Style.FirmyDetailInfo>
                <Style.FirmyDetailInfo>
                  <Row gutter={16} style={{ width: '100%' }}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                      <Text secondaryText>Total ratings by visitor</Text>
                    </Col>
                    <Col span={12}>
                      <Space>
                        <Rate disabled defaultValue={4} />
                        <Text subText>4.5</Text>
                      </Space>
                    </Col>
                  </Row>
                </Style.FirmyDetailInfo>
                <Style.FirmyDetailInfo>
                  <Row gutter={16} style={{ width: '100%' }}>
                    <Col span={12}>
                      <Text secondaryText>Mobile Phone</Text>
                    </Col>
                    <Col span={12}>
                      <Text primaryText w6>+420 773 573 073</Text>
                    </Col>
                  </Row>
                </Style.FirmyDetailInfo>
                <Style.FirmyDetailInfo>
                  <Row gutter={16} style={{ width: '100%' }}>
                    <Col span={12}>
                      <Text secondaryText>Official E-mail</Text>
                    </Col>
                    <Col span={12}>
                      <Text primaryText>info@pujcovnakostymupraha.cz</Text>
                    </Col>
                  </Row>
                </Style.FirmyDetailInfo>
                <Style.FirmyDetailInfo style={{ padding: '8px 0' }}>
                  <Row gutter={16} style={{ width: '100%' }}>
                    <Col span={12}>
                      <Text secondaryText>Opening hours</Text>
                    </Col>
                    <Col span={12}>
                      <Text primaryText>Monday	08:00 - 22:00</Text>
                      <Text primaryText>Tuesday	08:00 - 22:00</Text>
                      <Text primaryText w6>Wednesday	08:00 - 22:00</Text>
                      <Text primaryText>Thursday	08:00 - 22:00</Text>
                      <Text primaryText>Friday	08:00 - 22:00</Text>
                      <Text primaryText>Saturday	08:00 - 22:00</Text>
                      <Text primaryText>Sunday	08:00 - 22:00</Text>
                    </Col>
                  </Row>
                </Style.FirmyDetailInfo>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                <Style.FirmyDetailAction>
                  <Text primaryText>Send inquiry</Text>
                </Style.FirmyDetailAction>
                <Style.FirmyDetailAction>
                  <Text primaryText>View branches</Text>
                </Style.FirmyDetailAction>
                <Style.FirmyDetailAction>
                  <Text primaryText>Add to Favourites</Text>
                </Style.FirmyDetailAction>
                <Style.FirmyDetailAction>
                  <Text primaryText>Suggest an edit</Text>
                </Style.FirmyDetailAction>
                <Style.FirmyDetailAction>
                  <Text primaryText>Share in Socmed</Text>
                </Style.FirmyDetailAction>
              </Col>
            </Row>
            <Style.DescriptionContainer>
              <Row>
                <Col span={20}>
                  <Text primaryText xl w6 style={{ marginBottom: 12, fontSize: 20 }}>What is GizziGrill Center ?</Text>
                  <Text secondaryText>Costume Rental offers a wide range of carnival costumes. We offer thousands of carnival and masquerade costumes for women, men and children. We also sell carnival accessories, makeup, wigs, masks, masks and other necessary accessories.</Text>
                </Col>
                <Col span={4}>
                  <Style.DescriptionImage image="https://via.placeholder.com/100" />
                </Col>
              </Row>
            </Style.DescriptionContainer>
            <Style.PhotoContainer>
              <Style.PhotoTitle>
                <Text primaryText xl w6 style={{ fontSize: 20 }}>GizziGrill Photo</Text>
                <Button type="link">See all 16 Photos <RightOutlined /></Button>
              </Style.PhotoTitle>
              <Row wrap gutter={[20, 20]}>
                {renderFirmyPhotos()}
              </Row>
            </Style.PhotoContainer>
            <Style.ReviewContainer>
              <Style.ReviewTitle>
                <Space gutter={10}>
                  <Text primaryText xl w6 style={{ fontSize: 20 }}>Customer Review</Text>
                  <Rate disabled defaultValue={4} />
                  <Text subText>4.5</Text>
                </Space>
                <Button type="link">Read 231 Review <RightOutlined /></Button>
              </Style.ReviewTitle>
              <Text secondaryText style={{ margin: '12px 0 24px' }}>Restaurant - Coffee Shop</Text>
              {renderFirmyComments()}
            </Style.ReviewContainer>
            <Style.SuggestionsContainer>
              <Style.SuggestionsTitle>
                <Text primaryText xl w6 style={{ fontSize: 20 }}>People also search for</Text>
                <Button type="link">See 11 More <RightOutlined /></Button>
              </Style.SuggestionsTitle>
              <Row gutter={[20, 20]}>
                {renderFirmySuggestions()}
              </Row>
            </Style.SuggestionsContainer>
          </Col>
          <Col lg={{ span: 6, order: 2 }} xs={{ span: 24, order: 1 }}>
            <Style.MapContainer>
              <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false} style={{ width: '100%', height: '100%', zIndex: 0 }}>
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[51.505, -0.09]}></Marker>
              </MapContainer>
            </Style.MapContainer>
          </Col>
        </Row>
      </Style.MainContainer>
    </>
  );
};

export default FirmyDetailPage;
