import React, { useState, useRef } from 'react';
import { Space, Select, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';

import Header from '../../commons/Header';

import CalendarSidebar from './components/CalendarSidebar';
import EventInfoModal from './components/EventInfoModal';
import ModifyEventModal from './components/ModifyEventModal';

import { Text } from '../../commons/styles';

import * as Style from './styles';

const CalendarPage = () => {
  let todayStr = new Date().toISOString().replace(/T.*$/, '');

  const [isShowInfoModal, setIsShowInfoModal] = useState(false);
  const [isShowModifyModal, setIsShowModifyModal] = useState(false);
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);

  const calendarRef = useRef();
  let calendarApi = calendarRef.current && calendarRef.current.getApi();

  function handleDateSelect(selectInfo) {
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect() // clear date selection

    setIsShowModifyModal(true);
  }

  function handleEventClick(clickInfo) {
    setIsShowInfoModal(true);
  }

  function renderEventContent(eventInfo) {
    return (
      <Style.EventContentContainer
        className="custom-event-content"
        borderColor={eventInfo.event.borderColor}
        backgroundColor={eventInfo.event.backgroundColor}
      >
        <Text sm w6 truncate color={eventInfo.event.borderColor} style={{ marginBottom: 8 }}>
          {eventInfo.event.title}
        </Text>
        <Text xs truncate color={eventInfo.event.borderColor}>{eventInfo.timeText}</Text>
      </Style.EventContentContainer>
    )
  }

  function renderHeaderContent(headerInfo) {
    const today = moment().format('YYYY/MM/DD');
    const weekday = moment(headerInfo.date).format('YYYY/MM/DD');
    if (['timeGridWeek', 'timeGridFourDay'].includes(headerInfo.view.type)) {
      return (
        <Style.TimeGridWeekContainer>
          <Text primaryText primaryColor={weekday === today} uppercase w3>
            {moment(headerInfo.date).format('ddd')}
          </Text>
          <Text primaryText primaryColor={weekday === today} xxxl w6>
            {moment(headerInfo.date).format('D')}
          </Text>
        </Style.TimeGridWeekContainer>
      );
    }
    if (headerInfo.view.type === 'dayGridMonth') {
      return (
        <Style.DayGridMonthContainer>
          <Text primaryText uppercase w3>
            {moment(headerInfo.date).format('ddd')}
          </Text>
        </Style.DayGridMonthContainer>
      );
    }
  }

  return (
    <>
      <Header isFull feature="CALENDAR" />
      <Style.MainContainer>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <CalendarSidebar />
          <Style.CalendarContainer>
            <Style.SubHeaderContainer>
              <Space size={20}>
                <Text primaryText w6 style={{ fontSize: 30 }}>
                  {calendarApi?.currentDataManager?.data?.viewTitle}
                </Text>
                <Select defaultValue="GMT +07" style={{ width: 100, color: '#92929D' }} bordered={false}>
                  <Select.Option value="GMT +07">GMT +07</Select.Option>
                </Select>
              </Space>
              <Space size={20}>
                <Space size={6}>
                  <Button
                    shape="circle"
                    icon={<LeftOutlined />}
                    onClick={() => calendarApi.prev()}
                  />
                   <Button
                    shape="circle"
                    icon={<RightOutlined />}
                    onClick={() => calendarApi.next()}
                  />
                </Space>
                <Style.CalendarTypeSelect
                  defaultValue="timeGridWeek"
                  onChange={(value) => calendarApi.changeView(value)}
                  optionLabelProp="label"
                  size="large"
                >
                  <Select.Option value="timeGridDay" label="Day">
                    <Style.CalendarTypeOption>
                      <Text>Day</Text>
                      <Text>D</Text>
                    </Style.CalendarTypeOption>
                  </Select.Option>
                  <Select.Option value="timeGridWeek" label="Week">
                    <Style.CalendarTypeOption>
                      <Text>Week</Text>
                      <Text>W</Text>
                    </Style.CalendarTypeOption>
                  </Select.Option>
                  <Select.Option value="dayGridMonth" label="Month">
                    <Style.CalendarTypeOption>
                      <Text>Month</Text>
                      <Text>M</Text>
                    </Style.CalendarTypeOption>
                  </Select.Option>
                  <Select.Option value="listWeek" label="Schedule List">
                    <Style.CalendarTypeOption>
                      <Text>Schedule List</Text>
                      <Text>A</Text>
                    </Style.CalendarTypeOption>
                  </Select.Option>
                  <Select.Option value="timeGridFourDay" label="4 Days">
                    <Style.CalendarTypeOption>
                      <Text>4 Days</Text>
                      <Text>X</Text>
                    </Style.CalendarTypeOption>
                  </Select.Option>
                </Style.CalendarTypeSelect>
              </Space>
            </Style.SubHeaderContainer>
            <Style.CalendarContent>
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                headerToolbar={false}
                initialView="timeGridWeek"
                customButtons
                editable
                selectable
                selectMirror
                dayMaxEvents
                weekends={weekendsVisible}
                allDayContent={{ html: '<p>All Day</p>' }}
                contentHeight={700}
                views={{
                  timeGridFourDay: {
                    type: 'timeGrid',
                    duration: { days: 4 },
                    buttonText: '4 day'
                  }
                }}
                initialEvents={[
                  {
                    id: 1,
                    title: 'All-day event',
                    start: todayStr,
                    borderColor: '#0DB7FF',
                    backgroundColor: '#ECFAFF',
                  },
                  {
                    id: 2,
                    title: 'Timed event 1',
                    start: todayStr + 'T06:00:00',
                    end: todayStr + 'T09:00:00',
                    borderColor: '#52C17B',
                    backgroundColor: '#F2FAF5',
                  },
                  {
                    id: 3,
                    title: 'Timed event 2',
                    start: todayStr + 'T06:30:00',
                    borderColor: '#F89E23',
                    backgroundColor: '#FFF8EE',
                  },
                  {
                    id: 4,
                    title: 'Timed event 3',
                    start: todayStr + 'T31:00:00',
                    borderColor: '#FF6088',
                    backgroundColor: '#FFF3F6',
                  }
                ]}
                select={(selectInfo) => handleDateSelect(selectInfo)}
                dayHeaderContent={(headerInfo) => renderHeaderContent(headerInfo)}
                eventContent={(eventInfo) => renderEventContent(eventInfo)}
                eventClick={(clickInfo) => handleEventClick(clickInfo)}
                eventsSet={(events) => setCurrentEvents([...events])}
              />
            </Style.CalendarContent>
          </Style.CalendarContainer>
        </div>
      </Style.MainContainer>
      <EventInfoModal
        isShowInfoModal={isShowInfoModal}
        setIsShowInfoModal={setIsShowInfoModal}
      />
      <ModifyEventModal
        isShowModifyModal={isShowModifyModal}
        setIsShowModifyModal={setIsShowModifyModal}
      />
    </>
  );
};

export default CalendarPage;
