import RestClient from './restClient';

export default class AuthServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  login({ data }) {
    return this.restClient.post('/login', data);
  }

  register({ data }) {
    return this.restClient.post('/register', data);
  }

  getUserInfo() {
    return this.restClient.get('/me/detail');
  }
}
