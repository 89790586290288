import styled from 'styled-components';
import { Layout } from 'antd';

export const HeaderContainer = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  background-color: white;

  & > span:hover {    
    color: #FF570D;
    transition: all 0.3s;
  }

  // UPDATE_LATE: Fix common css
  position: static;
  top: unset;
  left: unset;
  margin-bottom: unset;
`;

export const MainContainer = styled(Layout.Content)`
  padding: 24px;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  height: 64px;
  background-color: #FF570D;

  & > img {
    cursor: pointer;
  }
`;
