import React from 'react';
import { Space, Table, Badge, Button } from 'antd';
import { ClockCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

import { Text } from '../../../commons/styles';

import programTVBrand1 from '../../../assets/images/program_tv_brand_1.png';

import * as Style from '../styles';

const PROGRAM_LIST = [
  {
    tvLogo: programTVBrand1,
    startTime: '09:30',
    endTime: '10:30',
    programName: 'Lion King Simba',
    category: {
      label: 'Cartoon',
      color: '#00C574',
    },
    nextShow: 'Spongebob Squarepants',
  },
  {
    tvLogo: programTVBrand1,
    startTime: '09:30',
    endTime: '10:30',
    programName: 'Lion King Simba',
    category: {
      label: 'Cartoon',
      color: '#00C574',
    },
    nextShow: 'Spongebob Squarepants',
  },
  {
    tvLogo: programTVBrand1,
    startTime: '09:30',
    endTime: '10:30',
    programName: 'Lion King Simba',
    category: {
      label: 'Cartoon',
      color: '#00C574',
    },
    nextShow: 'Spongebob Squarepants',
  },
  {
    tvLogo: programTVBrand1,
    startTime: '09:30',
    endTime: '10:30',
    programName: 'Lion King Simba',
    category: {
      label: 'Cartoon',
      color: '#00C574',
    },
    nextShow: 'Spongebob Squarepants',
  },
  {
    tvLogo: programTVBrand1,
    startTime: '09:30',
    endTime: '10:30',
    programName: 'Lion King Simba',
    category: {
      label: 'Cartoon',
      color: '#00C574',
    },
    nextShow: 'Spongebob Squarepants',
  },
  {
    tvLogo: programTVBrand1,
    startTime: '09:30',
    endTime: '10:30',
    programName: 'Lion King Simba',
    category: {
      label: 'Cartoon',
      color: '#00C574',
    },
    nextShow: 'Spongebob Squarepants',
  },
  {
    tvLogo: programTVBrand1,
    startTime: '09:30',
    endTime: '10:30',
    programName: 'Lion King Simba',
    category: {
      label: 'Cartoon',
      color: '#00C574',
    },
    nextShow: 'Spongebob Squarepants',
  },
  {
    tvLogo: programTVBrand1,
    startTime: '09:30',
    endTime: '10:30',
    programName: 'Lion King Simba',
    category: {
      label: 'Cartoon',
      color: '#00C574',
    },
    nextShow: 'Spongebob Squarepants',
  },
  {
    tvLogo: programTVBrand1,
    startTime: '09:30',
    endTime: '10:30',
    programName: 'Lion King Simba',
    category: {
      label: 'Cartoon',
      color: '#00C574',
    },
    nextShow: 'Spongebob Squarepants',
  },
  {
    tvLogo: programTVBrand1,
    startTime: '09:30',
    endTime: '10:30',
    programName: 'Lion King Simba',
    category: {
      label: 'Cartoon',
      color: '#00C574',
    },
    nextShow: 'Spongebob Squarepants',
  },
  {
    tvLogo: programTVBrand1,
    startTime: '09:30',
    endTime: '10:30',
    programName: 'Lion King Simba',
    category: {
      label: 'Cartoon',
      color: '#00C574',
    },
    nextShow: 'Spongebob Squarepants',
  },  {
    tvLogo: programTVBrand1,
    startTime: '09:30',
    endTime: '10:30',
    programName: 'Lion King Simba',
    category: {
      label: 'Cartoon',
      color: '#00C574',
    },
    nextShow: 'Spongebob Squarepants',
  },
  {
    tvLogo: programTVBrand1,
    startTime: '09:30',
    endTime: '10:30',
    programName: 'Lion King Simba',
    category: {
      label: 'Cartoon',
      color: '#00C574',
    },
    nextShow: 'Spongebob Squarepants',
  },
]

const TVProgramList = () => {
  const columns = [
    {
      title: <Text w6 color="#B5B5BE">TV</Text>,
      dataIndex: 'tvLogo',
      render: (_, record) => (
        <img src={record.tvLogo} alt="" />
      ),
    },
    {
      title: <Text w6 color="#B5B5BE">TIME</Text>,
      dataIndex: 'time',
      render: (_, record) => (
        <Space size={10}>
          <ClockCircleOutlined style={{ fontSize: 14, color: '#344563' }} />
          <Text primaryText>{`${record.startTime}-${record.endTime}`}</Text>
        </Space>
      ),
      with: 100,
    },
    {
      title: <Text w6 color="#B5B5BE">PROGRAM</Text>,
      dataIndex: 'programName',
      render: (_, record) => (
        <Text primaryText w6>{record.programName}</Text>
      ),
      with: 400,
    },
    {
      title: <Text w6 color="#B5B5BE">CATEGORY</Text>,
      dataIndex: 'category',
      render: (_, record) => (
        <Badge color={record.category.color} text={<Text primaryText inline>{record.category.label}</Text>} />
      ),
      with: 150,
    },
    {
      title: <Text w6 color="#B5B5BE">NEXT SHOW</Text>,
      dataIndex: 'nextShow',
      render: (_, record) => (
        <Space size={10}>
          <PlayCircleOutlined style={{ fontSize: 20, color: '#8397AF' }} />
          <Text primaryText>{record.nextShow}</Text>
        </Space>
      ),
      with: 250,
    },
    {
      title: null,
      dataIndex: 'action',
      render: (_, record) => (
        <Button type="primary">Watch</Button>
      ),
    },
  ];
  const tableData = PROGRAM_LIST.map((row) => ({
    ...row,
    key: row.id,
  }));

  return (
    <Style.ListChannelContainer>
      <Table
        pagination={false}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={tableData}
      />
    </Style.ListChannelContainer>
  );
};

export default TVProgramList;
