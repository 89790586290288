import React, { useEffect } from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux';

import {
  Text,
  AuthInput,
  AuthPassword,
  AuthCheckbox,
  AuthButton,
} from '../../commons/styles';

import history from '../../utils/history';

import { login as loginAction } from './../../redux/actions';

import Image from '../../themes/images';
import { ReactComponent as UserIcon } from "../../assets/images/ic_user.svg";
import { ReactComponent as KeyIcon } from "../../assets/images/ic_key.svg";

import * as Style from './styles';

const LoginPage = ({ login, loginData, location }) => {
  const [loginForm] = Form.useForm();

  const previousPage = location.state?.previousPage;
  const autoFillEmail = location.state?.email;

  useEffect(() => {
    loginForm.setFields([
      {
        name: ['email'],
        errors: [''],
      },
      {
        name: ['password'],
        errors: [loginData.errors],
      },
    ])
  }, [loginData.errors])

  useEffect(() => {
    loginForm.setFields([
      {
        name: ['email'],
        value: autoFillEmail,
        errors: [''],
      },
      {
        name: ['password'],
        errors: [''],
      },
    ])
  }, [autoFillEmail])

  return (
    <Style.LoginContainer>
      <Style.LoginImage>
        <Style.LoginBackground>
          <img src={Image.loginImage} alt="LoginImage" />
          <Style.LoginIntroduce>
            <Text white xxl center w6 style={{ margin: '16px 0', fontSize: 28 }}>
              Phuk not just a Plattform. We are More than You expected. We are Bigger
            </Text>
            <Text color="#B5B5C3" lg center>
              There’s no roadmap to success as an entrepreneur. No matter what other people tell you or how much you believe in a philosophy, it’s important for you to remember that success is highly personal.
            </Text>
          </Style.LoginIntroduce>
        </Style.LoginBackground>
      </Style.LoginImage>
      <Style.LoginContent>
        <Style.LoginWrapper>
          <img src={Image.logoSmallNew} width={88} height="auto" alt="Logo" />
          <Text primaryText xxxl w6 style={{ margin: '46px 0 20px' }}>Login First</Text>
          <Text subText lg>to continue using PhukMail</Text>
          <Style.LoginFormContainer>
            <Form
              form={loginForm}
              name="loginForm"
              onFinish={(values) => login({ data: values, previousPage })}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Please input your username!' },
                  { type: 'email', message: 'Invalid email!' },
                ]}
              >
                <AuthInput placeholder="Username or email" prefix={<UserIcon />} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <AuthPassword placeholder="Password" prefix={<KeyIcon />} />
              </Form.Item>
              <Form.Item
                name="agree"
                // rules={[{ required: true, message: 'Please agree about Terms & Condition!' }]}
              >
                <AuthCheckbox>
                  <Text subText>I agree about Terms & Condition</Text>
                </AuthCheckbox>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <AuthButton
                  htmlType="submit"
                  type="primary"
                  block
                  loading={loginData && loginData.loading}
                >
                  Continue
                </AuthButton>
              </Form.Item>
            </Form>
          </Style.LoginFormContainer>
          <Text subText>
            Don’t Have an account?&nbsp;
            <Style.RedirectLink onClick={() => history.push('/register')}>
              Register First!
            </Style.RedirectLink>
          </Text>
        </Style.LoginWrapper>
      </Style.LoginContent>
    </Style.LoginContainer>
  );
};

const mapStateToProp = (state) => {
  return {
    loginData: state.auth.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (params) => dispatch(loginAction(params)),
  };
};

export default connect(mapStateToProp,mapDispatchToProps)(LoginPage);
