import React, { memo } from 'react'
import { Images } from './../../../themes'

const IMAGES_1 = [Images.photoImg001, Images.photoImg003, Images.photoImg005]
const IMAGES_2 = [Images.photoImg002, Images.photoImg004, Images.photoImg006]
const Gallery = memo(props => {
  const renderGallery = (images) => {
    return (
      <ul className="list-unstyled list">
        {
          images.map(item => (
            <li key={item}>
              <a href="#" style={{ backgroundImage: `url(${item})` }}></a>
            </li>
          ))
        }
      </ul>
    )
  }
  return (
    <div className="widget-item media-group">
      <h3 className="group-title rubikMedium">Photos & Videos</h3>
      <div className="media-list">
        {renderGallery(IMAGES_1)}
        {renderGallery(IMAGES_2)}
      </div>
    </div>

  )
})

export default Gallery
