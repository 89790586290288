import React from 'react';
import { Space, Button, Avatar } from 'antd';
import {
  CloseOutlined,
  EditOutlined,
  ShareAltOutlined,
  DeleteOutlined,
  MoreOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Text } from '../../../commons/styles';

import * as Style from '../styles';

const EventInfoModal = ({
  setIsShowInfoModal,
  isShowInfoModal,
}) => {
  return (
    <Style.CalendarEventModal
      visible={isShowInfoModal}
      closable={false}
      footer={false}
      onCancel={() => setIsShowInfoModal(false)}
      width={400}
    >
      <Style.ModalTopContainer>
        <Space size={8}>
          <Space size={4}>
            <Button
              type="text"
              shape="circle"
            >
              <EditOutlined style={{ fontSize: 20, color: '#B5B5BF' }} />
            </Button>
            <Button
              type="text"
              shape="circle"
            >
              <ShareAltOutlined style={{ fontSize: 20, color: '#B5B5BF' }} />
            </Button>
            <Button
              type="text"
              shape="circle"
            >
              <DeleteOutlined style={{ fontSize: 20, color: '#B5B5BF' }} />
            </Button>
            <Button
              type="text"
              shape="circle"
            >
              <MoreOutlined style={{ fontSize: 20, color: '#B5B5BF' }} />
            </Button>
          </Space>
          <Button
            type="text"
            shape="circle"
            onClick={() => setIsShowInfoModal(false)}
          >
            <CloseOutlined style={{ fontSize: 16, color: '#92929D' }} />
          </Button>
        </Space>
      </Style.ModalTopContainer>
      <Style.ModalInfoContainer>
        <Style.ModalInfoLabel color="#00B9FF" />
        <Text primaryText xxl style={{ margin: '24px 0 12px' }}>Design a Wireframe</Text>
        <Text primaryText xs>Wednesday, February 24⋅15:00 – 16:00</Text>
        <div style={{ margin: '20px 0' }}>
          <Text primaryText sm>Copenhagen City</Text>
          <Text xs color="#8397AF">Denmark, Europe, 121818</Text>
        </div>
        <div style={{ marginBottom: 8 }}>
          <Space>
            <Avatar shape="circle" size="small" icon={<UserOutlined />} />
            <Text primaryText xs>paulopez12@mail.com</Text>
          </Space>
        </div>
        <div style={{ marginBottom: 8 }}>
          <Space>
            <Avatar shape="circle" size="small" icon={<UserOutlined />} />
            <Text primaryText xs>paulo_sergio@mail.com</Text>
          </Space>
        </div>
        <Text primaryText xs style={{ marginTop: 20 }}>This is a project about mail service project like seznam.cz, Gjirafa.com. yahoo mail also Yandex. We should make the wireframe first for pitching.</Text>
      </Style.ModalInfoContainer>
    </Style.CalendarEventModal>
  );
};

export default EventInfoModal;
