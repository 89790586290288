import styled from 'styled-components';

export const MainContainer = styled.div`
  padding-top: 4px;
`;

export const PopularFilterContainer = styled.div`
  margin: 22px 0 10px;
  
  & .ant-collapse-header {
    padding: 0 !important;
  }

  & .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const BusinessesFilterContainer = styled.div`
  padding: 24px 0 48px;
  border-top: 1px solid #F1F1F5;
  border-bottom: 1px solid #F1F1F5;
`;

export const BusinessesFilterCard = styled.div`
  margin-bottom: 20px;
  padding: 16px;
  border: 1px solid #F1F1F5;
  border-radius: ${(props) => props.theme.radius.xxl};

  & .ant-space-item {
    display: flex;
    align-items: center;
  }
`;

export const BusinessesFilterItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const BusinessesFilterMore = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 22px 0;
`;

export const PopularPlacesContainer = styled.div`
`;

export const PopularPlacesTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 28px;
`;

export const PopularPlacesItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export const PopularPlacesItemImage = styled.div`
  align-self: flex-start;
  min-width: 236px;
  height: 166px;
  border-radius: ${(props) => props.theme.radius.xl};
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  @media (max-width: 992px) {
    min-width: 180px;
    height: 120px;
  }
`;

export const PopularPlacesItemContent = styled.div`
  margin-left: 24px;
  width: calc(100% - 260px);
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 800px;

  @media (max-width: 992px) {
    margin-bottom: 24px;
    height: 200px;
  }
`;
