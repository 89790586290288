import axios from 'axios';
import { get, isEmpty } from 'lodash';
import qs from 'qs';

import { LoginStorage } from '../utils/localStorge';

const SERVER_API_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_DEV_URL
  : process.env.REACT_APP_PRO_URL

const DEFAULT_APP_URL = {
  baseURL: SERVER_API_URL,
  headers: {
    Accept: 'application/x-www-form-urlencoded',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

export const statusCode = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

export default class RestClient {
  constructor(config = {}) {
    this.config = Object.assign({}, DEFAULT_APP_URL, config);
  }

  get(url, params = {}, config = {}) {
    return this.executeRequest(url, { ...config, params });
  }

  post(url, data, config = {}, paramsType= '') {
    return this.executeRequest(url, { method: 'post', ...config, data, paramsType });
  }

  put(url, data, config = {}, paramsType= '') {
    return this.executeRequest(url, { method: 'put', ...config, data, paramsType });
  }

  delete(url, data = {}, config = {}) {
    return this.executeRequest(url, { method: 'delete', ...config, data });
  }

  patch(url, data = {}, config = {}, paramsType= '') {
    return this.executeRequest(url, { method: 'patch', ...config, data, paramsType });
  }

  executeRequest(url, config) {
    const userStorage = LoginStorage.getData();
    let finalHeaderConfig = { ...this.config.headers, ...config.headers };
    const authorization = !isEmpty(userStorage) ? `Bearer ${userStorage.token}` : '';
    if (userStorage) {
      finalHeaderConfig = {
        ...finalHeaderConfig,
        'Authorization': authorization,
      };
    }

    const finalConfig = {
      ...this.config,
      ...config,
      url,
      timeout: 30000,
      transformRequest: [(data, headers) => qs.stringify(data)],
      data: config.data,
      params: config.params,
      headers: { ...finalHeaderConfig },
    };

    return axios
      .request(finalConfig)
      .then((response) => Promise.resolve(response.data))
      .catch((errorResponse) => {
        const status = get(errorResponse, 'response.status', '');
        const error = get(errorResponse, 'response.data', { code: 1, data: null, message: ['Network error!'] });

        if (status === statusCode.UNAUTHORIZED) {
          LoginStorage.clearData();
        }

        if (status === statusCode.NOT_FOUND) {
          // 404
        }

        if (status >= statusCode.INTERNAL_SERVER_ERROR) {
          // 500
        }
        const newError = {
          status,
          ...error,
        };
        return Promise.reject(newError);
      });
  }
}
