import React from "react";
import { Images } from "../../../themes";
import styles from "./NewsStyles.module.css";
import "./Wherething.css";
import { SocialIcons } from './SocialIcons/SocialIcons'
import ReactWOW from 'react-wow';

class WhereThing extends React.Component {
  state = {
    header: "Where things stands now",
    listContent: [
      {
        title: "This week: ",
        content:
          "A vote in the House Judiciary Committee on whether to impeach Trump could happen. That means a full House vote to impeach Trump could take place as soon as next week.",
      },
      {
        title: "Sign up ",
        content: "for CNN's Impeachment Watch newsletter here.",
      },
    ],
  };
  render() {
    return (
      <ReactWOW delay='0.2s' animation='bounceInLeft' duration='1s' >
          <section className="box-wrapper">
            <h2 className="box-header">{this.state.header}</h2>
            <ul className="box-border">
              <li className="box-item">
                <p className="box-item-title">The latest: </p>
                House Democrats{" "}
                <a class="box-item-highlight">unveiled articles of impeachment </a>
                against President Trump. There are two: abuse of power and
                obstruction of Congress.
              </li>
              {this.state.listContent.map((item, index) => {
                return (
                  <li className="box-item" key={index}>
                    <p className="box-item-title">{item.title}</p>
                    {item.content}
                  </li>
                );
              })}
            </ul>
            <div className="box-item-footer">
            <SocialIcons /> 
              {/* <p>Share</p>
              <div></div>
              <a href="/">
                <img
                  src={Images.icFacebook}
                  alt="ic-facebook"
                  className="box-icon"
                />
              </a>
              <a href="/">
                <img src={Images.icTwitter} alt="ic-twitter" className="box-icon" />
              </a>
              <a href="/">
                <img src={Images.icMail} alt="ic-twitter" className="box-icon" />
              </a>
              <a href="/">
                <img src={Images.icAttach} alt="ic-twitter" className="box-icon" />
              </a>*/}
            </div> 
          </section>
        </ReactWOW>
    );
  }
}

export default WhereThing;
