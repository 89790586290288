import React, { useState, memo } from "react";
import { Space, Menu, Dropdown } from 'antd';

import { Text } from '../../../commons/styles';

import classnames from "classnames";
import moment from "moment";
import styles from "./MailDetailCollapse.module.css";
import { Images } from "./../../../themes";

const createMarkup = (html) => {
  return { __html: html };
};

class MailDetailCollapse extends React.Component {
  state = {
    isCollapse: this.props.collapse,
  };

  setCollapse = (isCollapse) => {
    this.setState({
      isCollapse,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.collapse !== this.props.collapse) {
      this.setCollapse(this.props.collapse);
    }
  }

  renderMoreActionMenu() {
    return (
      <Menu style={{ minWidth: 200 }}>
        <Menu.Item key="0">
          <Space>
            <img src={Images.icForwardLeft} alt="icForwardLeft" width="auto" height={14} />
            <Text>Reply</Text>
          </Space>
        </Menu.Item>
        <Menu.Item key="1">
          <Space>
            <img src={Images.icForwardLeft} alt="icForwardLeft" width="auto" height={14} />
            <Text>Reply to all</Text>
          </Space>
        </Menu.Item>
        <Menu.Item key="2">
          <Space>
            <img src={Images.icForwardRight} alt="icForwardRight" width="auto" height={14} />
            <Text>Forward</Text>
          </Space>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3">Menu item</Menu.Item>
        <Menu.Item key="4">Menu item</Menu.Item>
        <Menu.Item key="5">Menu item</Menu.Item>
        <Menu.Item key="6">Menu item</Menu.Item>
        <Menu.Item key="7">Menu item</Menu.Item>
        <Menu.Item key="8">Menu item</Menu.Item>
        <Menu.Item key="9">Menu item</Menu.Item>
        <Menu.Item key="10">Menu item</Menu.Item>
      </Menu>
    )
  }

  render() {
    const { mail, borderTop, image, isLatestEmail } = this.props;
    const { isCollapse } = this.state;
    if (!mail) return null;

    const fromName = mail.From
      ? mail.From.map((from) => from.Name).join(", ")
      : "";
    const fromAddress = mail.From
      ? mail.From.map((from) => from.Address).join(", ")
      : "";

    const lastActivity = mail.LastActivity ? mail.LastActivity : mail.Date;
    const lastActivityDate = moment(lastActivity);
    const lastActivityDisplay = lastActivityDate.isSame(moment(), "day")
      ? lastActivityDate.format("h:mm a ") + `( ${lastActivityDate.fromNow()} )`
      : lastActivityDate.format(`ll, LT `) +
        `( ${lastActivityDate.fromNow()} )`;

    const renderUser = () => {
      return (
        <div style={{ display: "flex" }}>
          <div
            className="avatar"
            style={{
              flex: 1,
              background: `url(${Images.userImg006}) no-repeat`,
              width: "50px",
              height: "50px",
            }}
          ></div>
          <div className={styles.collapseBody} style={{ flex: 9 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "8px",
              }}
            >
              <div className="user-info" style={{ flex: 1 }}>
                <p
                  className="user-name rubikMedium"
                  style={{ marginBottom: "0" }}
                >
                  {fromName}{" "}
                  <a className={styles.info} href="#">
                    Info
                  </a>
                </p>
                {!this.state.isCollapse && (
                  <p className="user-mail">{fromAddress}</p>
                )}
              </div>
              <p>{lastActivityDisplay}</p>
              <ul className={styles.flexOption}>
                <li>
                  <a href="#" className="archive hover_square">
                    <i className="icon-star"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="archive hover_square"
                    onClick={this.props.onToggleReplayMail}
                  >
                    <i className="icon-reply"></i>
                  </a>
                </li>
                <li className={styles.itemRight}>
                  <Dropdown
                    overlay={this.renderMoreActionMenu()}
                    trigger={['click']}
                    placement="bottomRight"
                  >
                    <a href="#" className="archive hover_square" onClick={(e) => e.preventDefault()}>
                      <i className="icon-more"></i>
                    </a>
                  </Dropdown>
                </li>
              </ul>
            </div>
            {this.state.isCollapse && (
              <p className={styles.collapseBody}>{mail.TextBody}</p>
            )}
          </div>
        </div>
      );
    };
    return (
      <div className="mail-detail">
        <div
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
          className={classnames(
            "mail-detail-item",
            styles.wrapper,
            isCollapse ? styles.wrapperCollapse : "",
            borderTop ? styles.borderTop : ""
          )}
        >
          <div className="mail-detail-content">
            {isCollapse ? (
              <div
                onClick={() =>
                  !isLatestEmail ? this.setCollapse(!isCollapse) : {}
                }
                className={styles.collapseBody}
              >
                {renderUser()}
              </div>
            ) : (
              <>
                <div
                  onClick={() =>
                    !isLatestEmail ? this.setCollapse(!isCollapse) : {}
                  }
                >
                  {renderUser()}
                </div>
                <div
                  className="mail-d-body"
                  style={{ marginTop: "35px" }}
                  dangerouslySetInnerHTML={createMarkup(
                    mail.HTMLBody?.replace('contenteditable="true"', "")
                  )}
                />
                <figure
                  className="mail-detail-image"
                  style={{
                    marginTop: "25px",
                    backgroundImage: image ? `url(${image})` : "none",
                    paddingTop: "10px",
                  }}
                ></figure>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MailDetailCollapse;
