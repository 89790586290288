import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  user-select: none;
`;

export const Wrapper = styled.div`
  user-select: none;
  cursor: pointer;
  opacity: 0.5;
  color: #222;

  &:hover {
    opacity: 1;
    color: #444;
    background: rgba(32,33,36,0.059);
  }
`;

export const Button = styled.span`
  cursor: pointer;
  user-select: none;
`;

export const DropDown = styled.div`
  position: absolute;
  bottom: 25px;
  z-index: 10;
  font-size: .875rem;
  letter-spacing: .2px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
  line-height: 20px;
  user-select: none;
  background: #fff;
`;
