import React from "react";
import styles from "./SearchInput.module.css";

const SearchInput = ({ label, value, onChange }) => {
  return (
    <div className={styles.inputWrapper}>
      <div className={styles.inputLabel}>{label}</div>
      <input className={styles.input} value={value} onChange={onChange} />
    </div>
  );
};

export default SearchInput;
