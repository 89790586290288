import React from "react";
import { Button, Modal } from "react-bootstrap";

class MailLabel extends React.Component {
  state = {
    nestSelected: "1",
    isChecked: false,
  };
  handleNestLabel = (e) => {
    this.setState({
      ...this.state,
      isChecked: e.target.checked,
      nestSelected: "1",
    });
  };
  handleNestSelect = (e) => {
    this.setState({
      ...this.state,
      nestSelected: e.target.value,
      isChecked: e.target.value !== "1",
    });
  };
  handleHide = () => {
    this.props.onHide();
    this.setState({
      nestSelected: "1",
      isChecked: false,
    });
  };
  render() {
    return (
      <Modal
        {...this.props}
        show={this.props.show}
        onHide={this.handleHide}
        className="modal-label"
      >
        <Modal.Header closeButton>
          <Modal.Title>New label</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter a new label name:</p>
          <input type="text" name="label" className="new-label-name" />
          <input
            type="checkbox"
            className="new-label-nest-checkbox"
            onClick={this.handleNestLabel}
            checked={this.state.isChecked}
          />
          <p className="new-label-nest-title">Nest label under:</p>
          <select
            className="new-label-nest-select"
            onChange={this.handleNestSelect}
            value={this.state.isChecked ? this.state.nestSelected : "1"}
          >
            <option value="1">
              {this.state.isChecked ? "Please select a parent.." : ""}
            </option>
            <option value="2">Value 1</option>
            <option value="3">Value 2</option>
            <option value="4">Value 3</option>
          </select>
        </Modal.Body>
        <div className="modal-footer-label">
          <Button
            className="new-label-close"
            variant="secondary"
            onClick={this.handleHide}
          >
            Close
          </Button>
          <Button
            disabled={this.state.nestSelected === "1" && this.state.isChecked}
            variant="primary"
            className="btn-primary-original"
          >
            Create
          </Button>
        </div>
      </Modal>
    );
  }
}
export default MailLabel;
