import React, { useState } from "react";
import styles from "./CreateFilterForm.module.css";
import classnames from "classnames";
import {
  AddressForwardCheckBox,
  ApplyLabelCheckBox,
  CategoriesCheckBox,
  CheckBoxItem,
} from "./CheckBoxItem/CheckBoxItem";

const CreateFilterForm = ({ doesShow, onClosed }) => {
  const [listCheckBoxValues, setListCheckBoxValue] = useState({
    skipInbox: false,
    markAsRead: false,
    star: false,
    applyLabel: false,
    forward: false,
    delete: false,
    neverSendToSpam: false,
    markAsImportant: false,
    neverMarkAsImportant: false,
    categories: false,
    applyConversation: false,
  });

  const [label, setLabel] = useState("ChooseLabel");
  const [addressForward, setAddressForward] = useState("");
  const [category, setCategory] = useState("Choose category");

  const setCheckBoxValue = (key) => {
    setListCheckBoxValue((prev) => {
      return {
        ...prev,
        [key]: !prev[key],
      };
    });
  };
  const onChooseLabelToApply = (value) => {
    setLabel(value);
  };
  const onChooseForwardingAddress = (value) => {
    setAddressForward(value);
  };
  const onCreateFilterSubmit = () => {
    console.log("Create Filter...");
  };

  const listCheckBoxProps = {
    skipInbox: {},
    markAsRead: {},
    star: {},
    applyLabel: {
      showSelect: true,
      showCreateNew: true,
      onChangeOption: onChooseLabelToApply,
    },
    forward: {
      showSelect: true,
      showCreateNew: true,
      onChangeOption: onChooseForwardingAddress,
    },
    delete: {},
    neverSendToSpam: {},
    markAsImportant: {},
    neverMarkAsImportant: {},
    categories: { showSelect: true, showCreateNew: false },
    applyConversation: {},
  };

  return (
    <form
      className={styles.createFilterForm}
      style={{ display: doesShow ? "block" : "none" }}
    >
      <div className={styles.createFilterFormHeader}>
        <button
          type="button"
          className={classnames("hover_circle arrow-left", styles.iconBack)}
          onClick={onClosed}
        >
          <i className="icon-arrow-left" />
        </button>
        <span>When a message exactly matches your search criteria:</span>
      </div>
      <div className={styles.listCheckBox}>
        {Object.entries(listCheckBoxValues).map(([key, value]) => {
          return (
            <CheckBoxItem
              key={key}
              checkBoxKey={key}
              checked={value}
              onItemSelect={setCheckBoxValue}
              {...listCheckBoxProps[key]}
            />
          );
        })}
      </div>
      <div className={styles.createFilterFormFooter}>
        <a className={styles.learnMore} href="/">
          <span>? Learn more</span>
        </a>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={onCreateFilterSubmit}
        >
          Create Filter
        </button>
      </div>
    </form>
  );
};
export default CreateFilterForm;
