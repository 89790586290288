import { all, put, takeLatest } from "redux-saga/effects";
import {
  USER,
  REQUEST,
  SUCCESS,
  FAILURE,
} from "../../constants";
import { mailServices } from '../../../services';
import history from '../../../utils/history';

function* getMailListSaga(action) {
  const { path } = action.payload;
  try {
    const result = path.type !== "draft" 
      ? yield mailServices.getMailList(action.payload)
      : yield mailServices.getDraftList(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION[`GET_${path.type.toUpperCase()}_MAILS`]),
        payload: {
          path,
          data: result.data,
        },
      });
    } else {
      yield put({
        type: FAILURE(USER.MAIL_ACTION[`GET_${path.type.toUpperCase()}_MAILS`]),
        errors: result.error,
        payload: { path },
      });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION[`GET_${path.type.toUpperCase()}_MAILS`]), errors: e.errors });
  }
}

function* getMailDetailSaga(action) {
  try {
    const result = yield mailServices.getMailDetail(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.GET_MAIL_DETAIL),
        payload: result,
      });
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.GET_MAIL_DETAIL), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.GET_MAIL_DETAIL), errors: e.errors });
  }
}

function* getDraftDetailSaga(action) {
  try {
    const result = yield mailServices.getDraftDetail(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.GET_DRAFT_DETAIL),
        payload: result,
      });
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.GET_DRAFT_DETAIL), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.GET_DRAFT_DETAIL), errors: e.errors });
  }
}

function* sendMailSaga(action) {
  try {
    const { callback } = action.payload;
    const result = yield mailServices.sendMail(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.SEND_MAIL),
        payload: result,
      });
      // UPDATE_LATE: Refactor function
      yield callback.onHideComposeEmail();
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.SEND_MAIL), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.SEND_MAIL), errors: e.errors });
  }
}

function* searchMailSaga(action) {
  const { path } = action.payload;
  try {
    const result = yield mailServices.searchMail(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.SEARCH_MAIL),
        payload: {
          path,
          data: result.data,
        },
      });
    } else {
      yield put({
        type: FAILURE(USER.MAIL_ACTION.SEARCH_MAIL),
        errors: result.error,
        payload: { path },
      });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.SEARCH_MAIL), errors: e.errors });
  }
}

function* removeMailsSaga(action) {
  try {
    const { type, data, meta } = action.payload;
    const result = yield mailServices.removeMails(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.REMOVE_MAILS),
        payload: { data },
      });
      yield put({
        type: REQUEST(USER.MAIL_ACTION[`GET_${type.toUpperCase()}_MAILS`]),
        payload: {
          path: { type },
          param: { page: 1 },
        },
      });
      if (meta.isMailDetail) yield history.goBack();
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.REMOVE_MAILS), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.REMOVE_MAILS), errors: e.errors });
  }
}

function* deleteMailsSaga(action) {
  try {
    const { data, meta } = action.payload;
    const result = yield mailServices.deleteMails(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.DELETE_MAILS),
        payload: { data },
      });
      yield put({
        type: REQUEST(USER.MAIL_ACTION.GET_TRASH_MAILS),
        payload: {
          path: { type: 'trash' },
          param: { page: 1 },
        },
      });
      if (meta.isMailDetail) yield history.goBack();
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.DELETE_MAILS), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.DELETE_MAILS), errors: e.errors });
  }
}

function* deleteDraftsSaga(action) {
  try {
    const { meta } = action.payload;
    const result = yield mailServices.deleteDrafts(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.DELETE_DRAFTS),
        payload: result,
      });
      yield put({
        type: REQUEST(USER.MAIL_ACTION.GET_DRAFT_MAILS),
        payload: {
          path: { type: 'draft' },
          param: { page: 1 },
        },
      });
      if (meta.isMailDetail) yield history.goBack();
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.DELETE_DRAFTS), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.DELETE_DRAFTS), errors: e.errors });
  }
}

function* saveDraftSaga(action) {
  try {
    const result = yield mailServices.saveDraft(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.SAVE_DRAFT),
        payload: result,
      });
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.SAVE_DRAFT), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.SAVE_DRAFT), errors: e.errors });
  }
}

function* setImportantSaga(action) {
  try {
    const { type, data } = action.payload;
    const result = yield mailServices.setImportant(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.SET_IMPORTANT),
        payload: {
          type,
          data,
        },
      });
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.SET_IMPORTANT), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.SET_IMPORTANT), errors: e.errors });
  }
}

function* removeImportantSaga(action) {
  try {
    const { type, data } = action.payload;
    const result = yield mailServices.removeImportant(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.REMOVE_IMPORTANT),
        payload: {
          type,
          data,
        },
      });
      if (type === "important") {
        yield put({
          type: REQUEST(USER.MAIL_ACTION.GET_IMPORTANT_MAILS),
          payload: {
            path: { type: "important" },
            param: { page: 1 },
          },
        });
      }
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.REMOVE_IMPORTANT), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.REMOVE_IMPORTANT), errors: e.errors });
  }
}

function* setStarredSaga(action) {
  try {
    const { type, data } = action.payload;
    const result = yield mailServices.setStarred(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.SET_STARRED),
        payload: {
          type,
          data,
        },
      });
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.SET_STARRED), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.SET_STARRED), errors: e.errors });
  }
}

function* removeStarredSaga(action) {
  try {
    const { type, data } = action.payload;
    const result = yield mailServices.removeStarred(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.REMOVE_STARRED),
        payload: {
          type,
          data,
        },
      });
      if (type === "starred") {
        yield put({
          type: REQUEST(USER.MAIL_ACTION.GET_STARRED_MAILS),
          payload: {
            path: { type: "starred" },
            param: { page: 1 },
          },
        });
      }
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.REMOVE_STARRED), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.REMOVE_STARRED), errors: e.errors });
  }
}

function* setSpamSaga(action) {
  try {
    const { type, data } = action.payload;
    const result = yield mailServices.setSpam(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.SET_SPAM),
        payload: {
          type,
          data,
        },
      });
      yield put({
        type: REQUEST(USER.MAIL_ACTION[`GET_${type.toUpperCase()}_MAILS`]),
        payload: {
          path: { type },
          param: { page: 1 },
        },
      });
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.SET_SPAM), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.SET_SPAM), errors: e.errors });
  }
}

function* markSeenSaga(action) {
  try {
    const { type, data } = action.payload;
    const result = yield mailServices.markSeen(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.MARK_SEEN),
        payload: {
          type,
          data,
        },
      });
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.MARK_SEEN), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.MARK_SEEN), errors: e.errors });
  }
}

function* markUnSeenSaga(action) {
  try {
    const { type, data } = action.payload;
    const result = yield mailServices.markUnSeen(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.MARK_UNSEEN),
        payload: {
          type,
          data,
        },
      });
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.MARK_UNSEEN), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.MARK_UNSEEN), errors: e.errors });
  }
}

function* getTotalUnseenMailSaga(action) {
  try {
    const result = yield mailServices.getTotalUnseenMail();
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.GET_TOTAL_UNSEEN_MAIL),
        payload: { data: result.data },
      });
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.GET_TOTAL_UNSEEN_MAIL), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.GET_TOTAL_UNSEEN_MAIL), errors: e.errors });
  }
}

function* moveToTabSaga(action) {
  try {
    const { type, data, } = action.payload;
    const result = yield mailServices.moveToTab(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(USER.MAIL_ACTION.MOVE_TO_TAB),
        payload: {
          type,
          data,
        },
      });
      yield put({
        type: REQUEST(USER.MAIL_ACTION[`GET_${type.toUpperCase()}_MAILS`]),
        payload: {
          path: { type },
          param: { page: 1 },
        },
      });
    } else {
      yield put({ type: FAILURE(USER.MAIL_ACTION.MOVE_TO_TAB), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.MAIL_ACTION.MOVE_TO_TAB), errors: e.errors });
  }
}

export default function* mailSaga() {
  yield all([
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_ALL_MAILS), getMailListSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_INBOX_MAILS), getMailListSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_WORKS_MAILS), getMailListSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_SOCIAL_MAILS), getMailListSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_PROMOTION_MAILS), getMailListSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_IMPORTANT_MAILS), getMailListSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_STARRED_MAILS), getMailListSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_SENT_MAILS), getMailListSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_SPAM_MAILS), getMailListSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_DRAFT_MAILS), getMailListSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_TRASH_MAILS), getMailListSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.SEARCH_MAIL), searchMailSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_MAIL_DETAIL), getMailDetailSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_DRAFT_DETAIL), getDraftDetailSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.SEND_MAIL), sendMailSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.REMOVE_MAILS), removeMailsSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.DELETE_MAILS), deleteMailsSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.DELETE_DRAFTS), deleteDraftsSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.SAVE_DRAFT), saveDraftSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.SET_IMPORTANT), setImportantSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.REMOVE_IMPORTANT), removeImportantSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.SET_STARRED), setStarredSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.REMOVE_STARRED), removeStarredSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.SET_SPAM), setSpamSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.MARK_SEEN), markSeenSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.MARK_UNSEEN), markUnSeenSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.GET_TOTAL_UNSEEN_MAIL), getTotalUnseenMailSaga),
    takeLatest(REQUEST(USER.MAIL_ACTION.MOVE_TO_TAB), moveToTabSaga),
  ]);
}
