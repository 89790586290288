import styled, { css } from 'styled-components';
import { Button } from 'antd';

export const MainContainer = styled.div``;

export const SidebarContainer = styled.div`
  width: 300px;
  padding: 0 24px;
  background-color: white;

  @media (max-width: 992px) {
    position: fixed;
    top: 64px;
    left: 0;
    height: calc(100vh - 64px);
    border-right: 1px solid #F1F1F5;
    z-index: 9;
    transform: translateX(-100%);
  }
`;

export const AddCityContainer = styled.div`
  display: flex;
  align-items: center;
  height: 76px;
`;

export const AddCityButton = styled(Button)`
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: ${(props) => props.theme.fontWeight.w6};
`;

export const SidebarCalendar = styled.div`
  margin: 30px 0 24px;
`;

export const SidebarCalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const SidebarFilter = styled.div`
  margin: 24px 0;
  
  & .ant-collapse-header {
    padding: 0 !important;
  }

  & .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const SidebarFilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WeatherContainer = styled.div`
  padding: 0 0 24px 24px;
  width: calc(100% - 600px);
  border-left: 1px solid #F1F1F5;

  @media (max-width: 1400px) {
    padding: 0 24px 24px;
    width: calc(100% - 300px);
  }

  @media (max-width: 992px) {
    padding: 0 24px 24px;
    width: 100%;
  }
`;

export const WeatherContent = styled.div`
  margin: 0 auto;
  padding: 24px 0;
  max-width: 900px;
`;

export const MoreInformationContainer = styled.div`
  width: 300px;
  padding: 0 24px;

  @media (max-width: 1400px) {
    display: none
  }
`;

export const WeatherMapContainer = styled.div`
  position: relative;
  margin-top: 32px;
  width: 100%;
  height: 528px;
`;

export const WeatherMapContent = styled.div`
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.theme.radius.xl};
  overflow: hidden;
`;

export const WeatherTemperatureContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 24px;
  width: 300px;
  height: 426px;
  border-radius: ${(props) => props.theme.radius.xl};
  background-color: white;
`;

export const WeatherTemperatureContent = styled.div``;

export const WeatherTemperatureWrapper = styled.div`
  position: relative;
  margin-top: 12px;
`;

export const WeatherTemperatureNumber = styled.h1`
  margin: 20px 0 8px;
  font-size: 54px;
  line-height: 78px;
  font-weight: ${(props) => props.theme.fontWeight.w6};
  color: ${(props) => props.theme.colors.primaryText};
`;

export const WeatherForecastSlider = styled.div`
  position: absolute;
  right: 0;
  bottom: -34px;
  padding: 0 24px;
  width: 100%;
`;

export const SliderNext = styled(Button)`
  position: absolute;
  right: -12px;
  padding: 4px 10px;
  width: 36px;
  height: 36px;
  border-color: ${(props) => props.theme.colors.primaryText};
  border-radius: ${(props) => props.theme.radius.circle};
  background-color: ${(props) => props.theme.colors.primaryText};
  color: white;
  z-index: 1;

  &:hover, &:focus {
    border-color: #40577D;
    background-color: #40577D;
    color: white;
  }
`;

export const SliderPrev = styled(Button)`
  position: absolute;
  left: -12px;
  padding: 4px 10px;
  width: 36px;
  height: 36px;
  border-color: ${(props) => props.theme.colors.primaryText};
  border-radius: ${(props) => props.theme.radius.circle};
  background-color: ${(props) => props.theme.colors.primaryText};
  color: white;
  z-index: 1;

  &:hover, &:focus {
    border-color: #40577D;
    background-color: #40577D;
    color: white;
  }
`;

export const WeatherForecastInfo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 68px;
  border: 1px solid #F1F1F5;
  border-radius: ${(props) => props.theme.radius.xxl};
  background-color: white;

  ${(props) => props.active
    && css`
      &::after {
        content: "";
        position: absolute;
        top: 11;
        left: 0;
        width: 2px;
        height: 46px;
        background-color: #FFC300;
      }
    `}
`;

export const WeatherCharContainer = styled.div`
  margin: 82px 0 48px;
  padding: 24px;
  border-radius: ${(props) => props.theme.radius.xxl};
  background-color: #FCFCFC;
`;

export const WeatherCharTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 24px;
`;

export const WeatherCharContent = styled.div`
  height: 360px;
`;

export const RecommendNewsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const RecommendNewsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 12px 20px 12px 12px;
  border-radius: ${(props) => props.theme.radius.xxl};
  border: 1px solid #E6E6E7;
  cursor: pointer;

  &:hover {
    & .primary-news-title {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const RecommendNewsImage = styled.div`
  margin-right: 16px;
  min-width: 180px;
  height: 120px;
  border-radius: ${(props) => props.theme.radius.xxl};
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const RecommendNewsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 196px);
`;

export const RecommendNewsBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RecommendNewsMore = styled.div`
  display: flex;
  justify-content: center;
  margin: 42px 0 52px;
`;
