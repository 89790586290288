import React, { memo } from 'react'
import { LIVE_SCORE } from './../../../mockdata/homeData'

const LiveScoreItem = memo(props => {
  const { flag, name, number, timeAt, address } = props
  return (
    <div className="score-item box-flex">
      <div className="flag">
        <div className="flag-image" style={{ backgroundImage: `url(${flag})` }}></div>
        <div className="flag-name">{name}</div>
      </div>
      <div className="score-numb">{number}</div>
      <div className={`score-${address ? 'address' : 'time'}`}>{timeAt || address}</div>
    </div>
  )
})

const LiveScore = memo(props => {
  return (
    <div className="sidebar-item">
      <div className="sidebar-title box-flex">
        <h3 className="rubikMedium">Live Score</h3>
        <div className="dropdown">
          <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">Football</a>
          <ul className="dropdown-menu list-unstyled">
            <li><a href="#">dropdown item</a></li>
            <li><a href="#">dropdown item</a></li>
          </ul>
        </div>
      </div>

      <div className="score-list">
        <div className="score-items">
          {LIVE_SCORE.map((item, index) => (
            index < 2 ?
              <LiveScoreItem key={`score${index}`} {...item} /> :
              null
          ))}
        </div>
        <div className="score-items">
          {LIVE_SCORE.map((item, index) => (
            index >= 2 ?
              <LiveScoreItem key={`score${index}`} {...item} /> :
              null
          ))}
        </div>
      </div>
      <p className="sidebar-button rubikMedium">
        <a href="#" className="btn btn-primary">see another match</a>
      </p>
    </div>
  )
})

export default LiveScore
