import { USER, REQUEST } from '../../constants';

export function getMailList(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION[`GET_${params.path.type.toUpperCase()}_MAILS`]),
    payload: params,
  };
}

export function getMailDetail(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.GET_MAIL_DETAIL),
    payload: params,
  };
}

export function getDraftDetail(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.GET_DRAFT_DETAIL),
    payload: params,
  };
}

export function searchMail(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.SEARCH_MAIL),
    payload: params,
  };
}

export function sendMail(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.SEND_MAIL),
    payload: params,
  };
}

export function removeMails(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.REMOVE_MAILS),
    payload: params,
  };
}

export function deleteMails(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.DELETE_MAILS),
    payload: params,
  };
}

export function deleteDrafts(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.DELETE_DRAFTS),
    payload: params,
  };
}

export function saveDraft(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.SAVE_DRAFT),
    payload: params,
  };
}

export function setMailDetail(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.SET_MAIL_DETAIL),
    payload: params,
  };
}

export function setImportant(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.SET_IMPORTANT),
    payload: params,
  };
}

export function removeImportant(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.REMOVE_IMPORTANT),
    payload: params,
  };
}

export function setStarred(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.SET_STARRED),
    payload: params,
  };
}

export function removeStarred(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.REMOVE_STARRED),
    payload: params,
  };
}

export function setSpam(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.SET_SPAM),
    payload: params,
  };
}

export function markSeen(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.MARK_SEEN),
    payload: params,
  };
}

export function markUnSeen(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.MARK_UNSEEN),
    payload: params,
  };
}

export function getTotalUnseenMail() {
  return {
    type: REQUEST(USER.MAIL_ACTION.GET_TOTAL_UNSEEN_MAIL),
  };
}

export function moveToTab(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.MOVE_TO_TAB),
    payload: params,
  };
}

export function setDraftMail(params) {
  return {
    type: REQUEST(USER.MAIL_ACTION.SET_SAVED_DRAFT),
    payload: params,
  };
}

// UPDATE_LATE
export const resetSendMailForm = () => {
  return {
    type: 'RESET_SEND_MAIL_FORM',
  };
};
