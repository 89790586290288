import React from 'react';
import { Layout, Menu } from 'antd';
import { DashboardOutlined, PicLeftOutlined } from '@ant-design/icons';

import history from '../../../../utils/history';

import { ROUTES } from '../../../../constants/routes';

import fullLogo from '../../../../assets/images/logo-small-new.png';
import smallLogo from '../../../../assets/images/logo.png';

import * as Style from '../styles';

const SIDEBAR_ITEMS = [
  {
    title: 'Dashboard',
    path: ROUTES.ADMIN.DASHBOARD,
    icon: <DashboardOutlined />,
  },
  {
    title: 'Articles',
    path: ROUTES.ADMIN.ARTICLE_LIST,
    icon: <PicLeftOutlined />,
  }
]

const AdminSidebar = ({
  setIsShowSidebar,
  location,
  isShowSidebar,
}) => {
  function renderSidebarItems() {
    return SIDEBAR_ITEMS.map((sidebarItem) => (
      <Menu.Item
        key={sidebarItem.path}
        icon={sidebarItem.icon}
        onClick={() => history.push(sidebarItem.path)}
      >
        {sidebarItem.title}
      </Menu.Item>
    ))
  }

  return (
    <Layout.Sider
      collapsible
      collapsed={!isShowSidebar}
      onCollapse={() => setIsShowSidebar(!isShowSidebar)}
    >
      <Style.LogoContainer>
        <img
          src={isShowSidebar ? fullLogo : smallLogo}
          height="100%"
          width="auto"
          alt="logo"
          onClick={() => history.push(ROUTES.USER.HOME)}
        />
      </Style.LogoContainer>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[location.pathname]}
      >
        {renderSidebarItems()}
      </Menu>
    </Layout.Sider>
  );
};

export default AdminSidebar;
