import { all, put, takeLatest } from "redux-saga/effects";
import {
  AUTH_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from "../constants";
import { authServices } from '../../services';
import { LoginStorage } from '../../utils/localStorge';
import history from '../../utils/history';

function* loginSaga(action) {
  try {
    const { previousPage } = action.payload;
    const loginResult = yield authServices.login(action.payload);
    if (loginResult && !loginResult.error) {
      yield LoginStorage.setData({ token: loginResult.token });
      const userInfoResult = yield authServices.getUserInfo();
      if (userInfoResult.data && !userInfoResult.error) {
        yield LoginStorage.setData({ token: loginResult.token, role: userInfoResult.data.role });
        yield put({
          type: SUCCESS(AUTH_ACTION.LOGIN),
          payload: {
            data: {
              ...loginResult,
              userInfo: userInfoResult.data,
            },
          },
        });
        yield history.push(previousPage ? previousPage : '/');
      } else {
        yield put({ type: FAILURE(AUTH_ACTION.LOGIN), errors: userInfoResult.message });
      }
    } else {
      yield put({ type: FAILURE(AUTH_ACTION.LOGIN), errors: loginResult.message });
    }
  } catch (e) {
    yield put({ type: FAILURE(AUTH_ACTION.LOGIN), errors: e.errors });
  }
}

function* logoutSaga(action) {
  try {
    yield localStorage.clear();
    yield put({ type: SUCCESS(AUTH_ACTION.LOGOUT) });
  } catch (e) {
    yield put({ type: FAILURE(AUTH_ACTION.LOGOUT), errors: e.errors });
  }
}

function* registerSaga(action) {
  try {
    const { data } = action.payload;
    const result = yield authServices.register(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(AUTH_ACTION.REGISTER),
        payload: result,
      });
      yield history.push({
        pathname: '/login',
        state: { email: data.email }
      });
    } else {
      yield put({ type: FAILURE(AUTH_ACTION.REGISTER), errors: result.erros });
    }
  } catch (e) {
    yield put({ type: FAILURE(AUTH_ACTION.REGISTER), errors: e.errors });
  }
}

function* getUserInfo(action) {
  try {
    const result = yield yield authServices.getUserInfo();
    if (result.data && !result.error) {
      yield put({
        type: SUCCESS(AUTH_ACTION.GET_USER_INFO),
        payload: result,
      });
    } else {
      yield put({ type: FAILURE(AUTH_ACTION.GET_USER_INFO), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(AUTH_ACTION.GET_USER_INFO), errors: e.errors });
  }
}

export default function* mailSaga() {
  yield all([
    takeLatest(REQUEST(AUTH_ACTION.LOGIN), loginSaga),
    takeLatest(REQUEST(AUTH_ACTION.LOGOUT), logoutSaga),
    takeLatest(REQUEST(AUTH_ACTION.REGISTER), registerSaga),
    takeLatest(REQUEST(AUTH_ACTION.GET_USER_INFO), getUserInfo),
  ]);
}
