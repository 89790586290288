import React, { useEffect, useState } from 'react';
import { Item } from './item';
import './article_styles.scss';
import { ReactComponent as ArrowDown } from "../../../../assets/images/arrow_down.svg";
import styles from '../NewsStyles.module.css';


export const Articles = ({ articleList }) => {

  const MoreNews = () => {
    return (
      <div className="content-more" >
        <button className="article-btn-more" type="button"> More News &nbsp;&nbsp;
        <ArrowDown alt='ic-arrow-down' fill="#44444F" className={styles.Icon}/>
        </button>
      </div>
    )
  }

  function renderArticleItems() {
    return articleList.items.map((articleItem, articleIndex) => {
      return (
        <Item key={`article-item-${articleIndex}`} articleItem={articleItem} />
      )
    })
  }

  return (
    
      <div className="article-container">
        {renderArticleItems()}
        <MoreNews />
      </div>
  )
} 
