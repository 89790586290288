import React, { useRef, useState, useEffect, } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./MailItem.module.css";
import './mail_item.scss';
import moment from "moment";
import { MAIL_TYPES, MAIL_BOX_PARAMS } from "../../../../constants/mail";
import CheckBox from "../../../../commons/ui/CheckBox/CheckBox";
import { connect } from "react-redux";
import { Images } from "./../../../../themes";
import {
  convertEmailStringToList,
} from "./../../../../utils/string";

import { SelectEmailCheckbox } from '../../../../commons/styles';

import {
  setImportant as setImportantAction,
  removeImportant as removeImportantAction,
  setStarred as setStarredAction,
  removeStarred as removeStarredAction,
  markSeen as markSeenAction,
  moveToTab as moveToTabAction,
} from "../../../../redux/actions";

const MAX_LENGTH_DISPLAY_NAME = 25;

const getShortText = (text, maxLength) => {
  return text.slice(0, maxLength) + (text.length > maxLength + 1 ? "..." : "");
};

const getDisplayFrom = (mail, mailType) => {
  let displayName = "";
  switch (mailType) {
    case MAIL_TYPES.ALL:
    case MAIL_TYPES.INBOX:
    case MAIL_TYPES.WORK:
    case MAIL_TYPES.SOCIAL:
    case MAIL_TYPES.PROMOTION:
    case MAIL_TYPES.IMPORTANT:
    case MAIL_TYPES.STARRED:
    case MAIL_TYPES.SPAM:
    case MAIL_TYPES.SCHEDULED:
    case MAIL_TYPES.TRASH:
    case MAIL_TYPES.SEARCH:
      if (Array.isArray(mail.From)) {
        const fromNameArray = mail.From.map((f) => f.Name);
        if (fromNameArray.join("") !== "") {
          const fromNameStr = fromNameArray.join(", ");
          displayName = getShortText(fromNameStr, MAX_LENGTH_DISPLAY_NAME);
        } else {
          const fromAddress = mail.From.map((f) => f.Address).join(", ");
          displayName = getShortText(fromAddress, MAX_LENGTH_DISPLAY_NAME);
        }
      } else {
        displayName = mail.From;
      }
      break;
    case MAIL_TYPES.SENT:
    case MAIL_TYPES.DRAFT:
      if (Array.isArray(mail.To)) {
        const toNameArray = mail.To.map((f) => f.Name);
        if (toNameArray.join("") !== "") {
          const toNameStr = toNameArray.join(", ");
          displayName = getShortText(toNameStr, MAX_LENGTH_DISPLAY_NAME);
        } else {
          const toAddress = mail.To.map((f) => f.Address).join(", ");
          displayName = getShortText(toAddress, MAX_LENGTH_DISPLAY_NAME);
        }
      } else {
        displayName =
          mail.To && mail.To.includes("|")
            ? convertEmailStringToList(mail.To).join(", ")
            : mail.To;
      }
      displayName = !displayName ? "" : `To: ${displayName}`;
      displayName = getShortText(displayName, MAX_LENGTH_DISPLAY_NAME);
      break;
    default:
  }
  return displayName;
};

const MailItem = ({
  mail,
  mailType,
  selected,
  onSelectItem,
  userInfo,
  gridType,
  onMailDetail,
  setImportant,
  removeImportant,
  setStarred,
  removeStarred,
  markSeen,
  currentMail,
  setCurrentMail,
  moveToTab,
  inboxTab,
}) => {
  const currentUserEmail = (userInfo || {}).email;
  let displayName = getDisplayFrom(mail, mailType);
  const isRead = !selected; // FIXME: Update the isRead email item with the correctponding field
  const eventItem = useRef(null);
  const contentMenu = useRef(null);
  const [visibleItem, setVisibleItem] = useState(false);
  const [handleRight, setHandleRight] = useState(false);

  useEffect(
    () => {
      if (mailType === 'inbox') {
        eventItem.current.addEventListener('contextmenu', e => handleContextMenu(e));
        eventItem.current.addEventListener('click', e => handleClickMenu(e));
        document.addEventListener('click', e => closeContentMenu(e));
        document.addEventListener('mousedown', e => rightMouse(e));

        return () => {
          eventItem.current.removeEventListener('contextmenu', handleContextMenu);
          eventItem.current.removeEventListener('click', handleClickMenu);
          document.removeEventListener('click', closeContentMenu);
          document.removeEventListener('mousedown', rightMouse);
        };
      }
    },
    [mail.Id],
  );

  useEffect(
    () => {
      if (currentMail && currentMail !== mail.Id) {
        setVisibleItem(false);
      }
    },
    [currentMail],
  );

  const handleContextMenu = event => {
    try {
      event.preventDefault();

      setVisibleItem(true);
      setCurrentMail(mail.Id);

      const clickX = event.clientX;
      const clickY = event.clientY;
      const screenW = window.innerWidth;
      const screenH = window.innerHeight;
      const contentMenuW = contentMenu.current.offsetWidth;
      const contentMenuH = contentMenu.current.offsetHeight;
      const right = screenW - clickX > contentMenuW;
      const left = !right;
      const top = screenH - clickY > contentMenuH;
      const bottom = !top;

      setHandleRight(!(screenW - clickX - 210 > contentMenuW));

      if (right) {
        contentMenu.current.style.left = `${clickX}px`;
      }

      if (left) {
        contentMenu.current.style.left = `${clickX - contentMenuW}px`;
      }

      if (top) {
        contentMenu.current.style.top = `${clickY }px`;
      }

      if (bottom) {
        contentMenu.current.style.top = `${clickY - contentMenuH }px`;
      }
    } catch (_e) {
    }
  };

  const handleClickMenu = event => {
    // const wasOutside = !(event.target.contains === contentMenu.current);
    // if (wasOutside && visibleFavorite) setVisibleItem(false);
    let value = event.target.innerText.trim();
    switch (value) {
      case 'Works':
      case 'Social':
      case 'Promotions':
        // event.preventDefault();
        apiMoveToTab(value);
        setVisibleItem(false);
        break;
      default:
        break;
    }

    // if (wasOutside) setVisibleItem(false);
  };

  const apiMoveToTab = (value) => {
    moveToTab({
      type: inboxTab,
      data: {
        ids: mail.Id,
        mailbox_move: mailBoxMoveType(value)
      }
    });
  }

  const listOptionMove = () => {
    switch (inboxTab) {
      case 'works':
        return ['Social', 'Promotions'];
      case 'social':
        return ['Works', 'Promotions'];
      case 'promotion':
        return ['Works', 'Social'];
      default:
        return ['Works', 'Social', 'Promotions'];
    }
  }

  const MoveTabView = () => {
    return listOptionMove().map((option, _i) => (
      <li key={`sub-${option}`}>
        <a href={'javascript:void(0)'}>
        <img src={Images.icFolderUpload} alt="icFolderUpload" />&nbsp;&nbsp;&nbsp;&nbsp;{option}
        </a>
      </li>
    ));
  }

  const mailBoxMoveType = (value) => {
    switch (value) {
      case 'Works':
        return 'works';
      case 'Social':
        return 'social';
      case 'Promotions':
        return 'promotion';
      default:
        return 'inbox';
    }
  }

  const closeContentMenu = e => {
    if (!checkClassParent(e)) {
      setVisibleItem(false);
    }
  };

  const checkClassParent = (e) => {
    try {
      return (
        e.target.parentElement.classList.value === "contextMenu" ||
        e.target.parentElement.parentElement.classList.value === "contextMenu" ||
        e.target.parentElement.parentElement.parentElement.classList.value === "contextMenu" ||
        e.target.parentElement.parentElement.parentElement.parentElement.classList.value === "contextMenu" ||
        e.target.parentElement.parentElement.parentElement.parentElement.parentElement.classList.value === "contextMenu" ||
        e.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.value === "contextMenu"
      )
    } catch (_e) {
      return false;
    }
  }

  const rightMouse = e => {
    if (e.button === 2 && e.target.contains !== contentMenu.current) {
      setVisibleItem(false);
    }
  };

  if (currentUserEmail && displayName) {
    displayName = displayName.replace(currentUserEmail, "me");
  }

  const attachments = mail.Attachments;
  const subject = mail.Subject;
  const lastActivity = mail.LastActivity ? mail.LastActivity : mail.Date;
  const lastActivityDate = moment(lastActivity);
  const lastActivityDisplay = lastActivityDate.isSame(moment(), "day")
    ? lastActivityDate.format("h:mm a")
    : lastActivityDate.format("MMM YY");
  const countOfReplies = mail.CountOfReplies ? mail.CountOfReplies : "";
  const lastBodyReply = mail.LastBodyReply;

  const onClickMail = () => {
    if (mailType === "draft") {
      onSelectItem(mail.Id, mailType === "draft");
    } else {
      onMailDetail && onMailDetail()
    }
    if (mail.Seen === 0) {
      markSeen({
        type: mailType,
        data: { ids: mail.Id }
      });
    }
  }

  const ContextMenuView = () => {
    if (visibleItem && mailType === 'inbox') {
      return (
        <div ref={contentMenu} className="contextMenu">
          <ul key="menu" id="menu">
            <li key="option-1">
              <a href="javascript:void(0)">
                <img src={Images.icFolderUpload} alt="icFolderUpload" />&nbsp;&nbsp;&nbsp;&nbsp;Move to tab
                <span className="arrow arrow-right"></span>
              </a>
              <ul key="dropdown_menu" className={`dropdown_menu ${ handleRight ? 'c-right' : ''}`}>
                <MoveTabView />
              </ul>
            </li>
            <hr></hr>
            <li key="option-2">
              <a href="javascript:void(0)">
                <img src={Images.icReply} alt="icFolderUpload" />&nbsp;&nbsp;&nbsp;&nbsp;Reply
              </a>
            </li>
            <li key="option-3">
              <a href="javascript:void(0)">
                <img src={Images.icReply} alt="icFolderUpload" />&nbsp;&nbsp;&nbsp;&nbsp;Reply all
              </a>
            </li>
            <li key="option-4">
              <a href="javascript:void(0)">
                <img src={Images.icForward} alt="icFolderUpload" />&nbsp;&nbsp;&nbsp;&nbsp;Forward
              </a>
            </li>
            <li key="option-5">
              <a href="javascript:void(0)">
                <i className="icon-attachment"></i>&nbsp;&nbsp;&nbsp;&nbsp;Forward as attachment
              </a>
            </li>
          </ul>
        </div>
      )
    } else {
      return null;
    }
  }

  const renderListGrid = (
    mail,
    mailType,
    onSelectItem,
    onMailDetail,
    gridType
  ) => {
    return (
      <Link
        to={
          mailType !== "draft" && gridType !== "rowGrid"
            ? {
                pathname: `/mail/${mailType}/${mail.Id}`,
                state: { mail },
              }
            : "#"
        }
        className="mail-item-content"
        onClick={() => onClickMail()}
      >
          <div className="user-send-mail">
            <img src={Images.user001} alt="user" className="avatar" />
            <span
              className={classNames("user-name", !isRead && styles.strongText)}
            >
              {displayName}
            </span>
            {countOfReplies && (
              <span className={styles.countOfReplies}>
                &nbsp;
                ({countOfReplies + 1})
              </span>
            )}
          </div>
          <div className="content-mail-inbox">
            <span
              className={classNames("mail-title", !isRead && styles.strongText)}
            >
              {subject || "(no subject)"}
            </span>
            {lastBodyReply && (
              <span className="mail-title-info"> - {lastBodyReply}</span>
            )}
          </div>
      </Link>
    );
  };

  const renderColumnGrid = (mail, mailType, onSelectItem, onMailDetail) => {
    return (
      <div
        onClick={() => {
          mailType === 'draft' ? onSelectItem(mail.Id, mailType === "draft") : onMailDetail();
          if (mail.Seen === 0) {
            markSeen({
              type: mailType,
              data: { ids: mail.Id }
            });
          }
        }}
      >
        <div className="user-send-mail">
          <img src={Images.user001} alt="user" className="avatar" />
          <span
            className={classNames("user-name", !isRead && styles.strongText)}
          >
            {displayName}
          </span>
          {countOfReplies && (
            <span className={styles.countOfReplies}>
              {" "}
              ({countOfReplies + 1})
            </span>
          )}
        </div>
        <div className="content-mail-inbox">
          <span
            className={classNames("mail-title", !isRead && styles.strongText)}
          >
            {subject || "(no subject)"}
          </span>
          {lastBodyReply && (
            <span className="mail-title-info"> - {lastBodyReply}</span>
          )}
        </div>
      </div>
    );
  };

  const switchLayout = (
    gridType,
    mail,
    mailType,
    onSelectItem,
    onMailDetail
  ) => {
    switch (gridType) {
      case "listGrid":
        return renderListGrid(mail, mailType, onSelectItem);
      case "columnGrid":
        return renderColumnGrid(mail, mailType, onSelectItem, onMailDetail);
      case "rowGrid":
        return renderListGrid(
          mail,
          mailType,
          onSelectItem,
          onMailDetail,
          gridType
        );
      default:
        return renderListGrid();
    }
  };

  return (
    <div
      className={classNames(
        `${selected ? "item-checked" : ""} mail-item`,
        mail.Seen === 1 && mailType !== "draft" ? styles.isSeen : ""
      )}
      ref={eventItem}
    >
      <SelectEmailCheckbox
        checked={selected}
        onChange={() => onSelectItem(mailType !== "draft" ? mail.Id : mail.Id)}
        style={{ marginRight: 12 }}
      />

      {switchLayout(gridType, mail, mailType, onSelectItem, onMailDetail)}

      <div
        className="mail-star hover_circle"
        onClick={() => {
          if (mail.Starred === 1) {
            removeStarred({
              type: mailType === 'inbox' ? inboxTab : mailType,
              data: { ids: mail.Id }
            });
          } else {
            setStarred({
              type: mailType === 'inbox' ? inboxTab : mailType,
              data: { ids: mail.Id }
            });
          }
        }}
      >
        <img
          src={mail.Starred === 1 ? Images.icStarred : Images.icNotStarred}
          alt="starred"
          style={{ minWidth: '20px'}}
        />
      </div>
      <div
        className={classNames("mail-star hover_circle", styles.iconBtn)}
        onClick={() => {
          if (mail.Important === 1) {
            removeImportant({
              type: mailType === 'inbox' ? inboxTab : mailType,
              data: { ids: mail.Id }
            });
          } else {
            setImportant({
              type: mailType === 'inbox' ? inboxTab : mailType,
              data: { ids: mail.Id }
            });
          }
        }}
      >
        <img
          src={mail.Important === 1 ? Images.icImportant : Images.icNotImportant}
          alt="important"
          style={{ minWidth: '20px'}}
        />
      </div>
      {/* {attachments && attachments.length ? ( */}
      <div className="mail-attachment hover_circle">
        <i className="icon-attachment"></i>
      </div>
      {/* ) : null} */}
      <div className="mail-inbox-time" style={{ minWidth: "60px" }}>
        {lastActivityDisplay}
      </div>

      <ContextMenuView />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setImportant: (params) => dispatch(setImportantAction(params)),
    removeImportant: (params) => dispatch(removeImportantAction(params)),
    setStarred: (params) => dispatch(setStarredAction(params)),
    removeStarred: (params) => dispatch(removeStarredAction(params)),
    markSeen: (params) => dispatch(markSeenAction(params)),
    moveToTab: (params) => dispatch(moveToTabAction(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailItem);
