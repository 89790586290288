import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MailBodyWrapper from "../../../commons/ui/MailBodyWrapper/MailBodyWrapper";
// import ComposeEmail from "../../ComposeEmail/ComposeEmail";
import ComposeEmail from "../components/ComposeEmail";
import { MAIL_TYPES } from "../../../constants/mail";
import { convertEmailStringToList } from "../../../utils/string";
import MailDetailCollapse from "../../../commons/ui/MailDetailCollapse/MailDetailCollapse";
import ToastNotification from "../../../commons/ui/ToastNotification/ToastNotification";
import ReplyEmailDetail from '../components/ReplyEmailDetail';
import Gallery from "./Gallery";
import Attachments from "./Attachments";
import { Images } from "./../../../themes";
import NoResult from "./../MailBoxWrapper/NoResult";

import { getMailDetail as getMailDetailAction } from "../../../redux/actions";

// class MailDetail extends Component {
//   state = {
//     mail: null,
//     replies: [],
//     redirect: null,
//     isVisibleReplayEmail: false,
//     loading: false,
//     showGallery: false,
//   };

//   componentDidMount() {
//     if (this.isNotRedirect()) {
//       const { mail } = this.props;
//       this.setState({ mail });
//     } else {
//       const locationState = this.props.location.state;
//       if (locationState && locationState.mail) {
//         const { mail } = locationState;
//         this.setState({ mail });
//       }
//     }

//     // this._fetchEmailDetail();
//   }

//   componentDidUpdate(prevProps, prevState) {
//     if (!isEqual(this.props.mail, prevState.mail) && this.props.mail) {
//       this.setState({
//         mail: this.props.mail,
//       });
//     }
//   }

//   isNotRedirect = () => {
//     return ["columnGrid", "rowGrid"].includes(this.props.gridType);
//   };

//   _fetchEmailDetail = () => {
//     if (this.isNotRedirect()) {
//       const { mailType, mail } = this.props;
//       const id = mail.Id;
//       this.setState({ loading: true });
//       const request =
//         mailType === MAIL_TYPES.DRAFT
//           ? api.getDraftMailDetail(id)
//           : api.getMailDetail(id);

//       request.then((response) => {
//         if (response && !response.data.error) {
//           this.setState({
//             mail: response.data.data.detail,
//             replies: response.data.data.replies
//               ? response.data.data.replies.reverse()
//               : [],
//             loading: false,
//           });
//           this.scrollToLastMail();
//         } else {
//           // TODO handle not found id
//           this.setState({ loading: false });
//         }
//       });
//     } else {
//       const { id, type } = this.props.match.params;
//       if (id) {
//         this.setState({ loading: true });
//         const request = type === MAIL_TYPES.DRAFT
//           ? api.getDraftMailDetail(id)
//           : api.getMailDetail(id);

//         request.then((response) => {
//           if (response && !response.data.error) {
//             this.setState({
//               mail: response.data.data.detail,
//               replies: response.data.data.replies
//                 ? response.data.data.replies.reverse()
//                 : [],
//               loading: false,
//             });
//             this.scrollToLastMail();
//           } else {
//             this.setState({ redirect: `/mail/${type}`, loading: false });
//           }
//         });
//       }
//     }
//   };

//   onDeleteMailDetail = () => {
//     const { type: mailType } = this.props.match.params;
//     const mailId = this.props.location?.state?.mail?.Id;

//     if (mailId) {
//       let request = null;
//       switch (mailType) {
//         case MAIL_TYPES.TRASH:
//           request = api.deleteEmailsFromTrash([mailId]);
//           break;
//         case MAIL_TYPES.DRAFT:
//           request = api.deleteDraftMail([mailId]);
//           break;
//         default:
//           request = api.deleteEmails([mailId]);
//       }

//       request.then((response) => {
//         if (response && !response.data.error) {
//           this.props.history.goBack();
//         } else {
//           this.setState({
//             isDeletingError: true,
//           });
//         }
//       });
//     }
//   };

//   createMarkup = (html) => {
//     return { __html: html };
//   };

//   _toggleReplayMail = () => {
//     const { isVisibleReplayEmail } = this.state;
//     this.setState(
//       {
//         isVisibleReplayEmail: !isVisibleReplayEmail,
//         isDeletingError: false,
//       },
//       () => {
//         setTimeout(() => {
//           this.repliedComposeBox &&
//             this.repliedComposeBox.scrollIntoView({ behavior: "smooth" });
//         }, 100);
//       }
//     );
//   };

//   _handleReplayEmail = () => {
//     this.props.fetchMailStartByType(MAIL_TYPES.DRAFT, 1);
//     this.props.fetchMailStartByType(MAIL_TYPES.SENT, 1);
//     this._fetchEmailDetail();
//     this.setState({ isVisibleReplayEmail: false, isDeletingError: false });
//   };

//   scrollToLastMail = () => {
//     if (this.messagesEnd) {
//       this.messagesEnd.scrollIntoView({ behavior: "smooth" });
//     }
//   };

//   renderPageNew = () => {
//     const {
//       mail,
//       replies,
//       redirect,
//       isVisibleReplayEmail,
//       loading,
//       isDeletingError,
//     } = this.state;
//     const { type } = this.props.match.params;
//     if (redirect) return <Redirect to={redirect} />;
//     if (!mail) return null;
//     return (
//       <div
//         className="mail-content-inbox mail-content-reply"
//         style={{ width: "100%" }}
//       >
//         <div className="tab-content">
//           <MailBodyWrapper>
//             {type === MAIL_TYPES.DRAFT ? (
//               <ComposeEmail
//                 onSuccess={this._handleReplayEmail}
//                 mailItem={{
//                   ...mail,
//                   isDraft: true,
//                   To: convertEmailStringToList(mail.To),
//                   Cc: convertEmailStringToList(mail.Cc),
//                 }}
//               />
//             ) : (
//               <div>
//                 <div
//                   className="tab-pane fade show active"
//                   id="nav-primary"
//                   role="tabpanel"
//                   aria-labelledby="nav-primary-tab"
//                 >
//                   <div className="mail-detail">
//                     {isDeletingError && (
//                       <NoResult text="Delete email error" isError={true} />
//                     )}
//                     <div className="mail-detail-item">
//                       <div
//                         className="mail-detail-title"
//                         style={{ marginBottom: 0 }}
//                       >
//                         <h2 className="mail-d-subject">{mail.Subject}</h2>
//                         <p className="set-important hover_circle">
//                           <i className="icon-important"></i>
//                         </p>
//                         <p>
//                           <a href="#">{type}</a>
//                         </p>
//                         <ul className="mail-option-list">
//                           <li>
//                             <span className="archive hover_square">
//                               <img
//                                 src={Images.icPrinter}
//                                 alt="phuk ico printer"
//                               />
//                             </span>
//                           </li>
//                           <li>
//                             <span className="archive hover_square">
//                               <img src={Images.icWindowStore} alt="phuk ico" />
//                             </span>
//                           </li>
//                         </ul>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <MailDetailCollapse
//                   mail={mail}
//                   collapse={replies.length > 0}
//                   isLatestEmail={replies.length === 0}
//                   onToggleReplayMail={this._toggleReplayMail}
//                 />

//                 {replies.map((mailReply, index) => (
//                   <div key={mailReply.Id} ref={(el) => (this.messagesEnd = el)}>
//                     <MailDetailCollapse
//                       key={mailReply.Id}
//                       mail={mailReply}
//                       collapse={index + 1 !== replies.length}
//                       borderTop
//                       isLatestEmail={index === replies.length - 1}
//                       onToggleReplayMail={this._toggleReplayMail}
//                     />
//                   </div>
//                 ))}
//                 <div style={{ float: "left", clear: "both" }} />
//                 {isVisibleReplayEmail && (
//                   <div
//                     ref={(refCompose) => (this.repliedComposeBox = refCompose)}
//                   >
//                     <ComposeEmail
//                       mailItem={mail}
//                       isReply={true}
//                       onSuccess={this._handleReplayEmail}
//                     />
//                   </div>
//                 )}
//                 {type !== MAIL_TYPES.DRAFT && !isVisibleReplayEmail && (
//                   <>
//                     <button
//                       className="btn btn-info"
//                       style={{ marginLeft: "20px" }}
//                       onClick={this._toggleReplayMail}
//                     >
//                       <img
//                         src={Images.icReply}
//                         alt="forward"
//                         style={{ marginRight: "10px", marginBottom: "2px" }}
//                       />
//                       <span>Reply</span>
//                     </button>
//                     <button
//                       className="btn btn-info"
//                       style={{ marginLeft: "20px" }}
//                     >
//                       <img
//                         src={Images.icForward}
//                         alt="forward"
//                         style={{ marginRight: "10px", marginBottom: "2px" }}
//                       />
//                       Forward
//                     </button>
//                   </>
//                 )}
//               </div>
//             )}
//             <div
//               style={{
//                 top: 0,
//                 zIndex: 100,
//                 position: "absolute",
//                 left: "50%",
//                 transform: "translateX(-50%)",
//               }}
//             >
//               {loading && <ToastNotification message="Loading" />}
//             </div>
//           </MailBodyWrapper>
//         </div>

//         {/* render right content */}
//         <div className={`${this.state.showGallery ? "show" : ""} mail-widget`}>
//           <div
//             className="show-mail-widget"
//             onClick={() =>
//               this.setState((preState) => ({
//                 showGallery: !preState.showGallery,
//               }))
//             }
//           ></div>
//           <div className="mail-widget-content">
//             {/* <div className="widget-item widget-action">
//               <ul className="list-flex list-action-more list-unstyled">
//                 <li>
//                   <a href="#" className="calendar hover_square">
//                     <i className="icon-calendar"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" className="security hover_square">
//                     <i className="icon-security"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" className="emoji hover_square">
//                     <i className="icon-happy"></i>
//                   </a>
//                 </li>
//               </ul>
//               <p className="add-widget">
//                 <a href="#" className="rubikMedium">
//                   add widget
//                 </a>
//               </p>
//             </div> */}
//             <Gallery />
//             <Attachments />
//           </div>
//         </div>
//       </div>
//     );
//   };

//   renderPageCurrent = () => {
//     const {
//       mail,
//       replies,
//       redirect,
//       isVisibleReplayEmail,
//       loading,
//     } = this.state;
//     const { type } = this.props.mailType;
//     if (redirect) return <Redirect to={redirect} />;
//     if (!mail) return null;
//     return (
//       <div
//         className="mail-content-inbox mail-content-reply"
//         style={{ width: "100%" }}
//       >
//         <div className="tab-content" style={{ width: "100%" }}>
//           <MailBodyWrapper>
//             {type === MAIL_TYPES.DRAFT ? (
//               <ComposeEmail
//                 onSuccess={this._handleReplayEmail}
//                 mailItem={{
//                   ...mail,
//                   isDraft: true,
//                   To: convertEmailStringToList(mail.To),
//                   Cc: convertEmailStringToList(mail.Cc),
//                 }}
//               />
//             ) : (
//               <div>
//                 <div
//                   className="tab-pane fade show active"
//                   id="nav-primary"
//                   role="tabpanel"
//                   aria-labelledby="nav-primary-tab"
//                 >
//                   <div className="mail-detail">
//                     <div className="mail-detail-item">
//                       <div
//                         className="mail-detail-title"
//                         style={{ marginBottom: 0 }}
//                       >
//                         <h2 className="mail-d-subject">{mail.Subject}</h2>
//                         <p className="set-important hover_circle">
//                           <i className="icon-important"></i>
//                         </p>
//                         <p>
//                           <a href="#">{type}</a>
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <MailDetailCollapse
//                   mail={mail}
//                   collapse={replies.length > 0}
//                   isLatestEmail={replies.length === 0}
//                   onToggleReplayMail={this._toggleReplayMail}
//                 />

//                 {replies.map((mailReply, index) => (
//                   <div key={mailReply.Id} ref={(el) => (this.messagesEnd = el)}>
//                     <MailDetailCollapse
//                       key={mailReply.Id}
//                       mail={mailReply}
//                       collapse={index + 1 !== replies.length}
//                       borderTop
//                       isLatestEmail={index === replies.length - 1}
//                       onToggleReplayMail={this._toggleReplayMail}
//                     />
//                   </div>
//                 ))}
//                 <div style={{ float: "left", clear: "both" }} />
//                 {isVisibleReplayEmail && (
//                   <ComposeEmail
//                     mailItem={mail}
//                     isReply={true}
//                     onSuccess={this._handleReplayEmail}
//                   />
//                 )}
//                 {type !== MAIL_TYPES.DRAFT && !isVisibleReplayEmail && (
//                   <>
//                     <button
//                       className="btn btn-info"
//                       style={{ marginLeft: "20px" }}
//                       onClick={this._toggleReplayMail}
//                     >
//                       <img
//                         src={Images.icReply}
//                         alt="forward"
//                         style={{ marginRight: "10px", marginBottom: "2px" }}
//                       />
//                       <span>Reply</span>
//                     </button>
//                     <button
//                       className="btn btn-info"
//                       style={{ marginLeft: "20px" }}
//                     >
//                       <img
//                         src={Images.icForward}
//                         alt="forward"
//                         style={{ marginRight: "10px", marginBottom: "2px" }}
//                       />
//                       Forward
//                     </button>
//                   </>
//                 )}
//               </div>
//             )}
//             <div
//               style={{
//                 top: 0,
//                 zIndex: 100,
//                 position: "absolute",
//                 left: "50%",
//                 transform: "translateX(-50%)",
//               }}
//             >
//               {loading && <ToastNotification message="Loading" />}
//             </div>
//           </MailBodyWrapper>
//         </div>

//         {/* render right content */}
//         {/* <div className={`${this.state.showGallery ? "show" : ""} mail-widget`}>
//           <div
//             className="show-mail-widget"
//             onClick={() =>
//               this.setState((preState) => ({
//                 showGallery: !preState.showGallery,
//               }))
//             }
//           ></div>
//           <div className="mail-widget-content">
//             <div className="widget-item widget-action">
//               <ul className="list-flex list-action-more list-unstyled">
//                 <li>
//                   <a href="#" className="calendar hover_square">
//                     <i className="icon-calendar"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" className="security hover_square">
//                     <i className="icon-security"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" className="emoji hover_square">
//                     <i className="icon-happy"></i>
//                   </a>
//                 </li>
//               </ul>
//               <p className="add-widget">
//                 <a href="#" className="rubikMedium">
//                   add widget
//                 </a>
//               </p>
//             </div>
//             <Gallery />
//             <Attachments />
//           </div>
//         </div> */}
//       </div>
//     );
//   };

//   render() {
//     if (this.isNotRedirect()) {
//       return this.renderPageCurrent();
//     } else {
//       return this.renderPageNew();
//     }
//   }
// }

const MailDetail = ({
  match,
  mailDetail,
  mailType,
  gridType,
  getMailDetail,
}) => {
  const [isVisibleReplayEmail, setIsVisibleReplayEmail] = useState(false);
  const [isShowGallery, setIsShowGallery] = useState(false);

  const mailId = match?.params?.id;

  useEffect(() => {
    if (!!match && mailId) {
      getMailDetail({
        path: { id: mailId },
      });
    }
  }, [mailId]);

  function onToggleReplayMail() {
    setIsVisibleReplayEmail(!isVisibleReplayEmail);
  };

  function onReplayMail() {
    // Replay mail
    setIsVisibleReplayEmail(false);
  };

  return (
    <div
      className="mail-content-inbox mail-content-reply"
      style={{ width: "100%" }}
    >
      <div
        className="tab-content"
        {...["columnGrid", "rowGrid"].includes(gridType) && { style: { width: "100%" } }}
      >
        <MailBodyWrapper>
          {mailType === MAIL_TYPES.DRAFT ? (
            <ComposeEmail
              onSuccess={onReplayMail}
              mailItem={{
                ...mailDetail.data,
                isDraft: true,
                To: convertEmailStringToList(mailDetail.data.To),
                Cc: convertEmailStringToList(mailDetail.data.Cc),
              }}
            />
          ) : (
            <div>
              <div
                className="tab-pane fade show active"
                id="nav-primary"
                role="tabpanel"
                aria-labelledby="nav-primary-tab"
              >
                <div className="mail-detail">
                  {/* {isDeletingError && (
                    <NoResult text="Delete email error" isError={true} />
                  )} */}
                  <div className="mail-detail-item">
                    <div
                      className="mail-detail-title"
                      style={{ marginBottom: 0 }}
                    >
                      <h2 className="mail-d-subject">{mailDetail.data.Subject}</h2>
                      <p className="set-important hover_circle">
                        <i className="icon-important"></i>
                      </p>
                      <p>
                        <a href="#">{mailType}</a>
                      </p>
                      <ul className="mail-option-list">
                        <li>
                          <span className="archive hover_square">
                            <img
                              src={Images.icPrinter}
                              alt="phuk ico printer"
                            />
                          </span>
                        </li>
                        <li>
                          <span className="archive hover_square">
                            <img src={Images.icWindowStore} alt="phuk ico" />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <MailDetailCollapse
                mail={mailDetail.data}
                // collapse={replies.length > 0}
                // isLatestEmail={replies.length === 0}
                onToggleReplayMail={onToggleReplayMail}
              />

              {mailDetail.data.replies && mailDetail.data.replies.map((replyItem, replyIndex) => (
                <ReplyEmailDetail
                  key={`reply-${replyIndex}`}
                  replyEmail={replyItem}
                  onToggleReplayMail={onToggleReplayMail}
                  isLatestEmail={replyIndex === mailDetail.data.replies.length - 1}
                />
              ))}

              {/* {replies.map((mailReply, index) => (
                <div key={mailReply.Id} ref={(el) => (this.messagesEnd = el)}>
                  <MailDetailCollapse
                    key={mailReply.Id}
                    mail={mailReply}
                    collapse={index + 1 !== replies.length}
                    borderTop
                    isLatestEmail={index === replies.length - 1}
                    onToggleReplayMail={onToggleReplayMail}
                  />
                </div>
              ))} */}
              <div style={{ float: "left", clear: "both" }} />
              {isVisibleReplayEmail && (
                <ComposeEmail
                  mailItem={mailDetail.data}
                  isReply
                  setIsVisibleReplayEmail={setIsVisibleReplayEmail}
                />
              )}
              {mailType !== MAIL_TYPES.DRAFT && !isVisibleReplayEmail && (
                <>
                  <button
                    className="btn btn-info"
                    style={{ marginLeft: "20px" }}
                    onClick={() => onToggleReplayMail()}
                  >
                    <img
                      src={Images.icReply}
                      alt="forward"
                      style={{ marginRight: "10px", marginBottom: "2px" }}
                    />
                    <span>Reply</span>
                  </button>
                  <button
                    className="btn btn-info"
                    style={{ marginLeft: "20px" }}
                  >
                    <img
                      src={Images.icForward}
                      alt="forward"
                      style={{ marginRight: "10px", marginBottom: "2px" }}
                    />
                    Forward
                  </button>
                </>
              )}
            </div>
          )}
          <div
            style={{
              top: 0,
              zIndex: 100,
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {mailDetail.loading && <ToastNotification message="Loading" />}
          </div>
        </MailBodyWrapper>
      </div>

      {/* render right content */}
      {!gridType && (
        <div className={`${isShowGallery ? "show" : ""} mail-widget`}>
          <div
            className="show-mail-widget"
            onClick={() => setIsShowGallery(!isShowGallery)}
          ></div>
          <div className="mail-widget-content">
            {/* <div className="widget-item widget-action">
              <ul className="list-flex list-action-more list-unstyled">
                <li>
                  <a href="#" className="calendar hover_square">
                    <i className="icon-calendar"></i>
                  </a>
                </li>
                <li>
                  <a href="#" className="security hover_square">
                    <i className="icon-security"></i>
                  </a>
                </li>
                <li>
                  <a href="#" className="emoji hover_square">
                    <i className="icon-happy"></i>
                  </a>
                </li>
              </ul>
              <p className="add-widget">
                <a href="#" className="rubikMedium">
                  add widget
                </a>
              </p>
            </div> */}
            <Gallery />
            <Attachments />
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    mailDetail: state.mails.detail,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getMailDetail: (params) => dispatch(getMailDetailAction(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailDetail);
