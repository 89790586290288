import { all, put, takeLatest } from 'redux-saga/effects';
import {
  ADMIN,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constants';
import { adminArticleServices } from '../../../services';

import history from '../../../utils/history';

function* getArticleListSaga(action) {
  try {
    const result = yield adminArticleServices.getArticleList(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(ADMIN.ARTICLE_ACTION.GET_LIST),
        payload: result,
      });
    } else {
      yield put({ type: FAILURE(ADMIN.ARTICLE_ACTION.GET_LIST), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(ADMIN.ARTICLE_ACTION.GET_LIST), errors: e.errors });
  }
}

function* getArticleDetailSaga(action) {
  try {
    const result = yield adminArticleServices.getArticleDetail(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(ADMIN.ARTICLE_ACTION.GET_DETAIL),
        payload: result,
      });
    } else {
      yield put({ type: FAILURE(ADMIN.ARTICLE_ACTION.GET_DETAIL), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(ADMIN.ARTICLE_ACTION.GET_DETAIL), errors: e.errors });
  }
}

function* createArticleSaga(action) {
  try {
    const result = yield adminArticleServices.createArticle(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(ADMIN.ARTICLE_ACTION.CREATE_ARTICLE),
        payload: result,
      });
      yield history.goBack();
    } else {
      yield put({ type: FAILURE(ADMIN.ARTICLE_ACTION.CREATE_ARTICLE), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(ADMIN.ARTICLE_ACTION.CREATE_ARTICLE), errors: e.errors });
  }
}

function* updateArticleSaga(action) {
  try {
    const result = yield adminArticleServices.updateArticle(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(ADMIN.ARTICLE_ACTION.UPDATE_ARTICLE),
        payload: result,
      });
      yield history.goBack();
    } else {
      yield put({ type: FAILURE(ADMIN.ARTICLE_ACTION.UPDATE_ARTICLE), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(ADMIN.ARTICLE_ACTION.UPDATE_ARTICLE), errors: e.errors });
  }
}

function* deleteArticleSaga(action) {
  try {
    const result = yield adminArticleServices.deleteArticle(action.payload);
    if (!result.error) {
      yield put({
        type: SUCCESS(ADMIN.ARTICLE_ACTION.DELETE_ARTICLE),
        payload: result,
      });
      yield put({
        type: SUCCESS(ADMIN.ARTICLE_ACTION.GET_LIST),
        payload: { param: { page: 1, limit: 10 } },
      });
    } else {
      yield put({ type: FAILURE(ADMIN.ARTICLE_ACTION.DELETE_ARTICLE), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(ADMIN.ARTICLE_ACTION.DELETE_ARTICLE), errors: e.errors });
  }
}

export default function* adminArticleSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN.ARTICLE_ACTION.GET_LIST), getArticleListSaga),
    takeLatest(REQUEST(ADMIN.ARTICLE_ACTION.GET_DETAIL), getArticleDetailSaga),
    takeLatest(REQUEST(ADMIN.ARTICLE_ACTION.CREATE_ARTICLE), createArticleSaga),
    takeLatest(REQUEST(ADMIN.ARTICLE_ACTION.UPDATE_ARTICLE), updateArticleSaga),
    takeLatest(REQUEST(ADMIN.ARTICLE_ACTION.DELETE_ARTICLE), deleteArticleSaga),
  ]);
}
