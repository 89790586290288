import React, { memo } from "react";
import { Space, Dropdown, Menu } from 'antd';

import history from '../../../utils/history';

import { Text } from '../../styles';

import * as Style from './styles';

const SUB_HEADER_ITEMS = [
  {
    title: "News",
    path: '/news',
    icon: 'icon-news',
  },
  {
    title: "Sports",
    path: '/',
    icon: 'icon-sports',
  },
  {
    title: "Calendar",
    path: '/calendar',
    icon: 'icon-calendar',
  },
  {
    title: "TV Program",
    path: '/tv',
    icon: 'icon-tech',
  },
  {
    title: "Firmy",
    path: '/firmy',
    icon: 'icon-diagram-right',
  },
  {
    title: "Horoscope",
    path: '/horoscope',
    icon: 'icon-politics',
  },
  {
    title: "Entertainment",
    path: '/entertainment',
    icon: 'icon-video',
  },
  {
    title: "Health",
    path: '/',
    icon: 'icon-health',
  },
  {
    title: "Lifestyle",
    path: '/',
    icon: 'icon-lifestyle',
  },
];

const SubHeader = memo((props) => {
  const { currentPage } = props;

  function renderSubHeaderItems() {
    return SUB_HEADER_ITEMS.map((subHeaderItem, subHeaderIndex) => {
      return (
        <Style.SubHeaderItem
          key={`sub-header-${subHeaderIndex}`}
          active={currentPage === subHeaderItem.title}
          size={6}
          onClick={() => history.push(subHeaderItem.path)}
        >
          <i className={subHeaderItem.icon}></i>
          <Text secondaryText>{subHeaderItem.title}</Text>
        </Style.SubHeaderItem>
      )
    })
  }

  function renderMoreSubHeader() {
    return (
      <Menu>
        <Menu.Item>
          <Style.SubHeaderItem
            size={6}
            onClick={() => history.push('/computer')}
          >
            <i className="icon-computer"></i>
            <Text secondaryText>Computer</Text>
          </Style.SubHeaderItem>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Space size={16} wrap>
      {renderSubHeaderItems()}
      <Dropdown overlay={renderMoreSubHeader()} placement="bottomRight">
        <Style.SubHeaderItem>
          More...
        </Style.SubHeaderItem>
      </Dropdown>
    </Space>
  );
});

export default SubHeader;
