import RestClient from '../restClient';

export default class AdminArticleServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getArticleList({ param }) {
    return this.restClient.get('/admin/article/list', param);
  }

  getArticleDetail({ path }) {
    return this.restClient.get(`/admin/article/detail/${path.id}`);
  }

  createArticle = ({ data }) => {
    return this.restClient.post('/admin/article/save', data);
  };

  updateArticle = ({ path, data }) => {
    return this.restClient.post(`/admin/article/update/${path.id}`, data);
  };

  deleteArticle = ({ data }) => {
    return this.restClient.post('/admin/article/delete', data);
  };
}
