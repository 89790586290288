import React, { useState } from 'react';
import { Space, Select, Radio } from 'antd';

import Header from '../../commons/Header';

import TVProgramSidebar from './components/TVProgramSidebar';
import TVProgramColumn from './components/TVProgramColumn';
import TVProgramList from './components/TVProgramList';

import { Text } from '../../commons/styles';

import * as Style from './styles';

const TVProgramPage = () => {
  const [tvViewType, setTvViewType] = useState('column');

  function renderTVProgramContent() {
    if (tvViewType === 'column') {
      return <TVProgramColumn />
    }
    return <TVProgramList />
  }

  return (
    <>
      <Header isFull feature="TV_PROGRAM" />
      <Style.MainContainer>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <TVProgramSidebar />
          <Style.TVProgramContainer>
            <Style.SubHeaderContainer>
              <Space size={20}>
                <Text primaryText w6 style={{ fontSize: 30 }}>Program</Text>
                <Select defaultValue="GMT +07" style={{ width: 100, color: '#92929D' }} bordered={false}>
                  <Select.Option value="GMT +07">GMT +07</Select.Option>
                </Select>
              </Space>
              <Radio.Group
                onChange={(e) => setTvViewType(e.target.value)}
                value={tvViewType}
                optionType="button"
                buttonStyle="solid"
              >
                <Radio.Button value="column">Column</Radio.Button>
                <Radio.Button value="line">Line</Radio.Button>
                <Radio.Button value="list">List</Radio.Button>
              </Radio.Group>
            </Style.SubHeaderContainer>
            {renderTVProgramContent()}
          </Style.TVProgramContainer>
        </div>
      </Style.MainContainer>
    </>
  );
};

export default TVProgramPage;
