import React from 'react';
import styled, { css } from 'styled-components';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';

import { colors } from './Colors';
import Images from '../../../../themes/images';

const ColorPickerWrapper = styled.td`
  border: 1px solid transparent;
  cursor: pointer;
  margin: 0;
  position: relative;

  &:hover {
    border: 1px solid #000;
  }

  ${p => p.active && css`
    outline: 1px solid #000;
  `}
`;

const ColorPickerItem = styled.div`
  background-color: ${p => p.color};
  user-select: none;
  height: 16px;
  width: 16px;

  ${p => p.active && css`
    && {
      background-color: ${p => p.color}
    };
    background: url(${Images.checkmark}) no-repeat 50% 50%;
  `}
`;

const reapplyMark = (editor, format, data) => {
  Editor.removeMark(editor, format);
  Editor.addMark(editor, format, data || true);
}

const getActiveColor = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? colors.find(color => color.value === marks[format]) || {} : {};
}

const ColorPickerComponent = ({ color , format}) => {
  const editor = useSlate();
  const activeColor = getActiveColor(editor, format).value === color;
  return (
    <ColorPickerWrapper
      onMouseDown={event => {
        event.preventDefault();
        reapplyMark(editor, format, color);
      }}
      active={activeColor}
    >
      <ColorPickerItem
        color={color}
        active={activeColor}
      />
    </ColorPickerWrapper>
  )
}

export default ColorPickerComponent;
