export const fontFamilyList = [
  { value: 'arial, sans-serif', label: 'Sans Serif' },
  { value: 'times new roman, serif', label: 'Serif' },
  { value: 'monospace', label: 'monospace' },
  { value: 'arial black, sans-serif', label: 'Wide' },
  { value: 'arial narrow, sans-serif', label: 'Narrow' },
  { value: 'comic sans ms, sans-serif', label: 'Comic Sans MS' },
  { value: 'garamond, times new roman, serif', label: 'Garamond' },
  { value: 'georgia, serif', label: 'Georgia' },
  { value: 'tahoma, sans-serif', label: 'Tahoma' },
  { value: 'trebuchet ms, sans-serif', label: 'Trebuchet MS' },
  { value: 'verdana, sans-serif', label: 'Verdana' },
]