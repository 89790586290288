import React, { useEffect, useState } from 'react';
import Images from '../../../../themes/images';
import './category_styles.scss';

export const Item = ({ item }) => {

    return (
      <div className="category-item">
        <button>
            <i className={item.icon}></i>&nbsp;&nbsp;
            {item.name}
        </button>
      </div>
    )
} 