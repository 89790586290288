import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from './../../../commons/Header';
import SubHeader from './../../Home/components/SubHeader'
import classNames from 'classnames';
import styles from './NewsStyles.module.css';
import RelatedArticles from './RelatedArticles/RelatedArticles';
import LiveArticle from './LiveUpdates/LiveArticle'
import WhereThing from './Wherething';
import { Articles } from './Articles';
import Footer from './../../../commons/Footer/Footer';
import { PickCategories } from './PickCategories';
import Trends from './Trends';
import { Advertisement } from './Advertisement';
import history from '../../../utils/history';

import {
  getArticleList as getArticleListAction,
  getArticleRelated as getArticleRelatedAction,
  getArticleMostReaction as getArticleMostReactionAction,
  getArticleNewTrending as getArticleNewTrendingAction,
} from '../../../redux/actions';

const NewsPage = ({
  getArticleList,
  articleList,
  getArticleRelated,
  articleRelated,
  getArticleMostReaction,
  articleMostReaction,
  getArticleNewTrending,
  articleNewTrending,
}) => {
  useEffect(() => {
    getArticleList();
    getArticleRelated();
    getArticleMostReaction();
    getArticleNewTrending();
  }, []);

  function renderSubInfo() {
    return (
      <div className={classNames(styles.groupInfo)}>
        <div className={classNames(styles.weatherInfo)} onClick={() => history.push('/weather')}>
          <i className={classNames('icon-weather-rain-storms', styles.socialIcon, styles.iconWeather)} />
          <span>Moscow, 35°C</span>
        </div>
        <div className={classNames(styles.groupInfo)}>
          <span>Follow Us:</span>
          <Link to='#'>
            <i className={classNames('icon-facebook', styles.socialIcon)} />
          </Link>
          <Link to='#'>
            <i className={classNames('icon-twiter', styles.socialIcon)} />
          </Link>
        </div>
      </div>
    )
  }

  return (
    <>
      <Header feature="NEWS" />
      <main>
        <div className={classNames("content-wrap", styles.headerWrapper)}>
          <div className={classNames("container")}>
            <div className={classNames("main-content", styles.groupNavItems)}>
              <SubHeader currentPage={"News"} />
            </div>
            <div className={classNames(styles.groupHeader)}>
              {renderSubInfo()}
            </div>
          </div>
        </div>
        <div className={classNames("container")}>
          <LiveArticle />
          <div className={classNames(styles.newMainContent)}>
            <div className={classNames(styles.leftSideNews)}>
              <WhereThing />
              <RelatedArticles articleRelated={articleRelated} />
              <PickCategories />
              <Advertisement />
              <Trends articleNewTrending={articleNewTrending} />
            </div>
            <div className={classNames(styles.newsArticle)}>
            {/* Put your main article detail here */}
              <Articles articleList={articleList} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    articleList,
    articleRelated,
    articleNewTrending,
    articleMostReaction,
  } = state.article;
  return {
    articleList,
    articleRelated,
    articleNewTrending,
    articleMostReaction,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getArticleList: (params) => dispatch(getArticleListAction(params)),
    getArticleRelated: (params) => dispatch(getArticleRelatedAction(params)),
    getArticleMostReaction: (params) => dispatch(getArticleMostReactionAction(params)),
    getArticleNewTrending: (params) => dispatch(getArticleNewTrendingAction(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);
