import React from "react";
import styles from "../../NewsStyles.module.css";

export const RelatedArticleItem = ({ relatedItem }) => {
  // const configAuthors = (authors) => {
  //   switch (authors.length) {
  //     case 0:
  //       return "";
  //     case 1:
  //       return <span className={styles.author}>{authors[0]}</span>;
  //     case 2:
  //       return (
  //         <>
  //           <span className={styles.author}>{authors[0]}</span>
  //           <span> and </span>
  //           <span className={styles.author}>{authors[1]} </span>
  //         </>
  //       );
  //     default:
  //       const compactAuthors = authors.slice(0, -1).map((item) => (
  //         <>
  //           <span className={styles.author}>{item}</span>
  //           <span>, </span>
  //         </>
  //       ));
  //       const lastAuthor = (
  //         <>
  //           <span>and </span>
  //           <span className={styles.author}>{authors[authors.length - 1]}</span>
  //         </>
  //       );
  //       return [compactAuthors, lastAuthor];
  //   }
  // };
  return (
    <div className={styles.relatedArticleItem}>
      <h5 className={styles.title}>{relatedItem.title}</h5>
      <p>
        <span>{relatedItem.user?.id !== 0 && "By "}</span>
        <span>{relatedItem.user?.username}</span>
        <span>, {relatedItem.company}</span>
      </p>
    </div>
  );
};
