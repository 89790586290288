import RestClient from '../restClient';

export default class ArticleServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getArticleList() {
    return this.restClient.get('/article/list');
  }

  getArticleDetail({ path }) {
    return this.restClient.post(`/article/detail/${path.id}`);
  }

  getArticleRelated() {
    return this.restClient.get('/article/related');
  }

  getArticleNewTrending() {
    return this.restClient.get('/article/new-trending');
  }

  getArticleMostReaction() {
    return this.restClient.get('/article/most-reaction');
  }

  getArticleLikes({ path }) {
    return this.restClient.get(`/article/like/${path.id}`);
  }

  getArticleComments({ path }) {
    return this.restClient.get(`/article/comment/${path.id}`);
  }
}
