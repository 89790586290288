import React, { memo } from 'react'

const SmallNews = memo(props => {
  const { image, content, link } = props
  return (
    <div className="item box-flex">
      <div className="thumbnail">
        <a href="#" style={{ backgroundImage: `url(${image})` }}></a>
      </div>
      <div className="box-text">
        <h4><a href="#" className="rubikMedium">{content}</a></h4>
        <p className="link">
          <a href="www.gazetaexpress.com">{link}</a>
        </p>
      </div>
    </div>
  )
})

export default SmallNews
