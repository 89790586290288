import React from 'react';
import { Row, Col, Space, Badge, Button } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import Slider from 'react-slick';

import Header from '../../commons/Header';
import TrendWrapper from '../../commons/components/TrendWrapper';
import WeatherWrapper from '../../commons/components/WeatherWrapper';
import CurrencyWrapper from '../../commons/components/CurrencyWrapper';
import SubHeader from '../../commons/components/SubHeader';

import WeatherSidebar from './components/WeatherSidebar';
import RecommendNews from './components/RecommendNews';

import { Text, LoadMoreButton, TagButton } from '../../commons/styles';

import { ReactComponent as ArrowDownIcon } from "../../assets/images/arrow_down.svg";
import { ReactComponent as WeatherFeelingIcon } from "../../assets/images/ic_weather_feeling.svg";
import { ReactComponent as WeatherPressureIcon } from "../../assets/images/ic_weather_pressure.svg";
import { ReactComponent as WeatherBioIcon } from "../../assets/images/ic_weather_bio.svg";
import { ReactComponent as WeatherSpeedIcon } from "../../assets/images/ic_weather_speed.svg";
import { ReactComponent as WeatherShockIcon } from "../../assets/images/ic_weather_shock.svg";
import { ReactComponent as WeatherPrecipitationIcon } from "../../assets/images/ic_weather_precipitation.svg";
import { ReactComponent as LocationIcon } from "../../assets/images/ic_location.svg";
import { ReactComponent as WeatherNowIcon } from "../../assets/images/weather_now.svg";
import { ReactComponent as Weather1Icon } from "../../assets/images/weather_1.svg";
import { ReactComponent as Weather2Icon } from "../../assets/images/weather_2.svg";
import { ReactComponent as Weather3Icon } from "../../assets/images/weather_3.svg";
import { ReactComponent as Weather4Icon } from "../../assets/images/weather_4.svg";

import * as Style from './styles';

const WEATHER_FORECAST = [
  {
    day: 'Today',
    minTemp: 9,
    maxTemp: 11,
    icon: <Weather1Icon />
  },
  {
    day: 'Monday',
    minTemp: 7,
    maxTemp: 8,
    icon: <Weather2Icon />
  },
  {
    day: 'Tuesday',
    minTemp: 8,
    maxTemp: 10,
    icon: <Weather3Icon />
  },
  {
    day: 'Wednesday',
    minTemp: 7,
    maxTemp: 9,
    icon: <Weather4Icon />
  },
  {
    day: 'Today',
    minTemp: 9,
    maxTemp: 11,
    icon: <Weather1Icon />
  },
  {
    day: 'Monday',
    minTemp: 7,
    maxTemp: 8,
    icon: <Weather2Icon />
  },
];

const RECOMMEND_NEWS = [
  {
    title: 'Dream Interpretation Common Symbols And Their Meanings',
    description: 'All users on MySpace will know that there are millions of people out there. Every day besides so many people joining this community',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Dream Interpretation Common Symbols And Their Meanings',
    description: 'All users on MySpace will know that there are millions of people out there. Every day besides so many people joining this community',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Dream Interpretation Common Symbols And Their Meanings',
    description: 'All users on MySpace will know that there are millions of people out there. Every day besides so many people joining this community',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
  {
    title: 'Dream Interpretation Common Symbols And Their Meanings',
    description: 'All users on MySpace will know that there are millions of people out there. Every day besides so many people joining this community',
    image: 'https://via.placeholder.com/900x600',
    createAt: '4hrs ago',
    url: 'bloomberg.com',
    view: '43K',
    like: 62,
    share: 21,
  },
];

const WEATHER_CHAR_DATA = [
  {
    time: '09:00',
    temp: 10,
  },
  {
    time: '10:00',
    temp: 16,
  },
  {
    time: '11:00',
    temp: 14,
  },
  {
    time: '12:00',
    temp: 18,
  },
  {
    time: '13:00',
    temp: 27,
  },
  {
    time: '14:00',
    temp: 23,
  },
  {
    time: '15:00',
    temp: 16,
  },
  {
    time: '16:00',
    temp: 21,
  },
];

const WeatherPage = () => {
  function renderRecommendNews() {
    return RECOMMEND_NEWS.map((recommendNewsItem, recommendNewsIndex) => {
      return <RecommendNews key={`recommend-news-${recommendNewsIndex}`} newsItem={recommendNewsItem} />
    })
  }

  function renderForecastSlider() {
    return WEATHER_FORECAST.map((item, index) => {
      return (
        <div>
          <div style={{ padding: '0 5px' }}>
            <Style.WeatherForecastInfo active={index === 0}>
              <Space size={8}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {item.icon}
                </div>
                <div>
                  <Text primaryText={index === 0} subText={index !== 0} sm>
                    {item.day}
                  </Text>
                  <Text primaryText lg w6>
                    {`${item.minTemp}° C - ${item.maxTemp}° C`}
                  </Text>
                </div>
              </Space>
            </Style.WeatherForecastInfo>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <Header isFull feature="WEATHER" />
      <Style.MainContainer>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <WeatherSidebar />
          <Style.WeatherContainer>
            <Style.WeatherContent>
              <SubHeader currentPage="Weather" />
              <Style.WeatherMapContainer>
                <Style.WeatherMapContent>
                  <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false} style={{ width: '100%', height: '100%', zIndex: 0 }}>
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[51.505, -0.09]}></Marker>
                  </MapContainer>
                </Style.WeatherMapContent>
                <Style.WeatherTemperatureContainer>
                  <Style.WeatherTemperatureContent>
                    <Text primaryText xl w6 style={{ fontSize: 20 }}>Copenhagen</Text>
                    <Style.WeatherTemperatureWrapper>
                      <WeatherNowIcon
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: -24,
                        }}
                      />
                      <Space size={12}>
                        <Space size={8}>
                          <LocationIcon />
                          <Text primaryText xl>Denmark</Text>
                        </Space>
                        <Button type="link">Change<RightOutlined /></Button>
                      </Space>
                      <Style.WeatherTemperatureNumber>
                        80°
                      </Style.WeatherTemperatureNumber>
                      <Text primaryText w6>Clear Sky</Text>
                    </Style.WeatherTemperatureWrapper>
                  </Style.WeatherTemperatureContent>
                  <Row gutter={[0, 24]}>
                    <Col span={8}>
                      <WeatherFeelingIcon />
                      <Text primaryText xs>Feeling</Text>
                      <Text primaryText w6>Noc: 4° C</Text>
                    </Col>
                    <Col span={8}>
                      <WeatherPressureIcon />
                      <Text primaryText xs>Pressure</Text>
                      <Text primaryText w6>1007 hPa</Text>
                    </Col>
                    <Col span={8}>
                      <WeatherBioIcon />
                      <Text primaryText xs>Bio</Text>
                      <Text primaryText w6>2-Medium</Text>
                    </Col>
                    <Col span={8}>
                      <WeatherSpeedIcon />
                      <Text primaryText xs>Speed</Text>
                      <Text primaryText w6>8 m / s</Text>
                    </Col>
                    <Col span={8}>
                      <WeatherShockIcon />
                      <Text primaryText xs>Shock</Text>
                      <Text primaryText w6>15 m / s</Text>
                    </Col>
                    <Col span={8}>
                      <WeatherPrecipitationIcon />
                      <Text primaryText xs>Precipitation</Text>
                      <Text primaryText w6>0 mm</Text>
                    </Col>
                  </Row>
                </Style.WeatherTemperatureContainer>
                <Style.WeatherForecastSlider>
                  <Slider
                    infinite={false}
                    slidesToShow={4}
                    speed={500}
                    initialSlide={0}
                    dots={false}
                    nextArrow={<Style.SliderNext icon={<RightOutlined />} />}
                    prevArrow={<Style.SliderPrev icon={<LeftOutlined />} />}
                    responsive={[
                      {
                        breakpoint: 1100,
                        settings: {
                          slidesToShow: 3,
                        }
                      },
                    ]}
                  >
                    {renderForecastSlider()}
                  </Slider>
                </Style.WeatherForecastSlider>
              </Style.WeatherMapContainer>
              <Style.WeatherCharContainer>
                <Text primaryText xl w6 style={{ fontSize: 20 }}>Weather Details</Text>
                <Style.WeatherCharTop>
                  <Space size={20}>
                    <Badge color="#2D4569" text={<Text primaryText lg inline>Weather</Text>} />
                    <Badge color="#7B59F7" text={<Text color="#7B59F7" lg inline>Wind</Text>} />
                    <Badge color="#1FB8E6" text={<Text color="#00BBEB" lg inline>Precipitation</Text>} />
                  </Space>
                  <Space size={8}>
                    <TagButton type="primary" shape="round">Today</TagButton>
                    <TagButton shape="round">Tomorrow</TagButton>
                    <TagButton shape="round">Yesterday</TagButton>
                  </Space>
                </Style.WeatherCharTop>
                <Style.WeatherCharContent>
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      width={500}
                      height={400}
                      data={WEATHER_CHAR_DATA}
                      margin={{
                        top: 10,
                        right: 0,
                        left: -30,
                        bottom: 0,
                      }}
                    >
                      <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#9fbeeb" stopOpacity={0.3}/>
                          <stop offset="95%" stopColor="#9fbeeb" stopOpacity={0}/>
                        </linearGradient>
                      </defs>
                      <CartesianGrid strokeDasharray="0" vertical={false} />
                      <XAxis axisLine={false} tickLine={false} dataKey="time" />
                      <YAxis axisLine={false} tickLine={false} domain={[10, (dataMax) => (dataMax < 10 ? 10 : Math.ceil(dataMax / 5) * 5)]}/>
                      <Tooltip />
                      <Area type="temperature" dataKey="temp" fillOpacity={1} fill="url(#colorUv)" />
                    </AreaChart>
                  </ResponsiveContainer>
                </Style.WeatherCharContent>
              </Style.WeatherCharContainer>
              <Style.RecommendNewsTitle>
                <Text primaryText xxl w6>Recommened News on Nearby Country</Text>
                <Space size={12}>
                  <Space size={8}>
                    <LocationIcon />
                    <Text primaryText xl>Denmark</Text>
                  </Space>
                  <Button type="link">Change<RightOutlined /></Button>
                </Space>
              </Style.RecommendNewsTitle>
              {renderRecommendNews()}
              <Style.RecommendNewsMore>
                <LoadMoreButton shape="round" size="large">
                  More
                  <ArrowDownIcon style={{ marginLeft: 6 }} />
                </LoadMoreButton>
              </Style.RecommendNewsMore>
            </Style.WeatherContent>
          </Style.WeatherContainer>
          <Style.MoreInformationContainer>
            <div className="content-wrap">
              <div className="sidebar">
                <TrendWrapper />
                <WeatherWrapper />
                <CurrencyWrapper />
              </div>
            </div>
          </Style.MoreInformationContainer>
        </div>
      </Style.MainContainer>
    </>
  );
};

export default WeatherPage;
