
import styled, { css } from 'styled-components';
import { Space } from 'antd';

export const SubHeaderItem = styled(Space)`
  cursor: pointer;

  & i {
    color: #B5B5BE;
  }

  &:hover {
    & p {
      color: ${(props) => props.theme.colors.headerText};
    }

    & i {
      color: #44444F;
    }
  }

  ${(props) => props.active
    && css`
      & p {
        color: ${(props) => props.theme.colors.headerText};
        font-weight: ${(props) => props.theme.fontWeight.w6};
      }

      & i {
        color: #44444F;
      }
    `}
`;
