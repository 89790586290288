import React from "react";
import classnames from "classnames";
import styles from "./MailBoxWrapper.module.css";

const NoResult = ({ mailType, text, isError }) => {
  const message = text || `There are no conversations in ${mailType}`;
  return (
    <div className={classnames(styles.noResultWraper)}>
      <p
        className={classnames(styles.noResultText)}
        style={isError && { color: "red" }}
      >
        {message}
      </p>
    </div>
  );
};

export default NoResult;
