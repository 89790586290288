export const APP_NAME = "Ayde";

// code for TAB, ENTER , SPACE
export const KEY_CODES = [9, 13, 32];

export const STANDING_PAGES = {
  HOME: true,
  LOGIN: true,
  'SIGN UP': true,
  'MAIL BOX': false
}

export const MAIL_TYPE = {
  INBOX: 'inbox',
  IMPORTANT: 'important',
  SENT: 'sent',
  SPAM: 'spam',
  DRAFT: 'draft',
  TRASH: 'trash',
}
