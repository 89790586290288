import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Space, Input, Dropdown, Menu } from 'antd';
import { MenuOutlined, AppstoreOutlined, SearchOutlined, AimOutlined } from '@ant-design/icons';

import history from '../../utils/history';

import { Text } from '../../commons/styles';

import mainBrand from '../../assets/images/logo-small-new.png';
import calendarBrand from '../../assets/images/calendar_brand.png';
import firmyBrand from '../../assets/images/firmy_brand.png';
import horoscopeBrand from '../../assets/images/horoscope_brand.png';
import tvProgramBrand from '../../assets/images/tvprogram_brand.png';
import weatherBrand from '../../assets/images/weather_brand.png';
import defaultAvatar from './../../assets/images/default-avatar.png';

import { logout as logoutAction } from '../../redux/actions';

import * as Style from './styles';

const FEATURE_BRAND_LOGO = {
  HOME: mainBrand,
  NEWS: mainBrand,
  MAIL: mainBrand,
  ENTERTAINMENT: mainBrand,
  CALENDAR: calendarBrand,
  FIRMY: firmyBrand,
  HOROSCOPE: horoscopeBrand,
  TV_PROGRAM: tvProgramBrand,
  WEATHER: weatherBrand,
}

const Header = ({
  logout,
  userInfo,
  feature,
  isFull,
}) => {
  console.log('🚀 ~ file: index.jsx ~ line 41 ~ userInfo', userInfo);
  const [isScrolling, setIsScrolling] = useState(false);
  const [toggleDropdownSearch, setToggleDropdownSearch] = useState(false);
  let location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolling]);

  function handleScroll() {
    setIsScrolling(!(window.scrollY === 0));
  };

  function renderUserMenu() {
    return (
      <Menu style={{ width: 160 }}>
        {userInfo.role === 1 && (
          <Menu.Item key="admin" onClick={() => history.push('/admin')}>
            Admin
          </Menu.Item>
        )}
        <Menu.Item key="Profile">
          Profile
        </Menu.Item>
        <Menu.Item key="logout" onClick={() => logout()}>Logout</Menu.Item>
      </Menu>
    )
  }

  function renderSearchComponent() {
    switch (feature) {
      case 'HOME':
      case 'NEWS': {
        return (
          <Input
            allowClear
            prefix={<SearchOutlined style={{ color: '#696974' }} />}
            size="large"
            placeholder="Search Mail"
            style={{ marginRight: 300, maxWidth: 600 }}
          />
        )
      }
      case 'ENTERTAINMENT': {
        return (
          <Input
            allowClear
            prefix={<SearchOutlined style={{ color: '#696974' }} />}
            size="large"
            placeholder="Search Entertainment"
            style={{ marginRight: 300, maxWidth: 600 }}
          />
        )
      }
      case 'CALENDAR': {
        return (
          <Input
            allowClear
            prefix={<SearchOutlined style={{ color: '#696974' }} />}
            size="large"
            placeholder="Search Calendar"
            style={{ marginRight: 300, maxWidth: 600 }}
          />
        )
      }
      case 'HOROSCOPE': {
        return (
          <Input
            allowClear
            prefix={<SearchOutlined style={{ color: '#696974' }} />}
            size="large"
            placeholder="Search Horoscope"
            style={{ marginRight: 300, maxWidth: 600 }}
          />
        )
      }
      case 'TV_PROGRAM': {
        return (
          <Input
            allowClear
            prefix={<SearchOutlined style={{ color: '#696974' }} />}
            size="large"
            placeholder="Search Program"
            style={{ marginRight: 300, maxWidth: 600 }}
          />
        )
      }
      case 'WEATHER': {
        return (
          <Input
            prefix={<SearchOutlined style={{ color: '#696974' }} />}
            suffix={<AimOutlined style={{ color: '#FF570D' }} />}
            size="large"
            placeholder="Search City"
            style={{ marginRight: 300, maxWidth: 600 }}
          />
        )
      }
      default: return null;
    }
  }

  return (
    <Style.HeaderContainer isFull={isFull} isScrolling={isScrolling}>
      <Style.HeaderContent isFull={isFull} {...!isFull && { className: 'container' }}>
        <Space size={isFull ? 16 : 32}>
          {isFull && (
            <Button type="text" shape="circle" size="large">
              <MenuOutlined
                style={{ display: 'flex', alignItems: 'center', fontSize: 24, color: '#344563' }}
              />
            </Button>
          )}
          <img src={FEATURE_BRAND_LOGO[feature]} onClick={() => history.push('/')} alt="" />
          {isFull && (
            <Style.MenuButton type="text" shape="circle" size="large">
              <AppstoreOutlined
                style={{ display: 'flex', alignItems: 'center', fontSize: 24, color: '#344563' }}
              />
            </Style.MenuButton>
          )}
          {!isFull && (
            <Style.SearchContainer>
              {renderSearchComponent()}
            </Style.SearchContainer>
          )}
          <Style.SearchButton type="text" shape="circle" size="large" onClick={() => setToggleDropdownSearch(!toggleDropdownSearch)}>
            <SearchOutlined
              style={{ display: 'flex', alignItems: 'center', fontSize: 24, color: '#344563' }}
            />
          </Style.SearchButton>
        </Space>
        {isFull && (
          <Style.FullSearchContainer>
            <Style.FullSearchContent>
              {renderSearchComponent()}
            </Style.FullSearchContent>
          </Style.FullSearchContainer>
        )}
        <Space>
          {location.pathname !== '/mail' && (
            <Style.EmailButton
              type="text"
              shape="round"
              size="large"
              onClick={() => history.push('/mail')}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <i className="icon-message" style={{ marginRight: 8, fontSize: 24, color: '#344563' }}></i>
              <Text primaryText inline>Email</Text>
            </Style.EmailButton>
          )}
          <Button type="text" shape="circle" size="large">
            <i className="icon-notification" style={{ fontSize: 24, color: '#344563' }}></i>
          </Button>
          <Button type="text" shape="circle" size="large">
            <i className="icon-headphone" style={{ fontSize: 24, color: '#344563' }}></i>
          </Button>
          {userInfo
            ? (
              <Dropdown overlay={renderUserMenu()} trigger={['click']} placement="bottomRight">
                {/* UPDATE_LATE */}
                <Button type="link" style={{ padding: 0 }}>
                  <img 
                    className="avatar-user-info" 
                    src={userInfo.avatar || defaultAvatar}
                    onClick={(e) => e.preventDefault()}
                    alt=""
                  />
                </Button>
              </Dropdown>
            )
            : (
              <Button
                type="primary"
                onClick={() => history.push({ pathname: '/login', state: { previousPage: location.pathname } })}
                style={{ height: 38, borderRadius: 5 }}
              >
                Login
              </Button>
            )
          }
        </Space>
      </Style.HeaderContent>
      <Style.DropdownSearchContainer isShow={toggleDropdownSearch}>
        {renderSearchComponent()}
      </Style.DropdownSearchContainer>
    </Style.HeaderContainer>
  );
};

const mapStateToProp = (state) => {
  const { userInfo } = state.auth;
  return {
    userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logoutAction()),
  };
};

export default connect(mapStateToProp, mapDispatchToProps)(Header);
