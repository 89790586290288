import styled from 'styled-components';
import { Button } from 'antd';

export const AuthButton = styled(Button)`
  height: 50px;
  border-radius: ${(props) => props.theme.radius.md};
  border-color: ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.fontSize || props.theme.fontSize.md};
  font-weight: ${(props) => props.theme.fontWeight.w6};

  &:hover {
    border-color: ${(props) => props.theme.colors.primary75};
    background-color: ${(props) => props.theme.colors.primary75};
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.primary75};
    background-color: ${(props) => props.theme.colors.primary75};
  }

  &:active {
    border-color: ${(props) => props.theme.colors.primary125};
    background-color: ${(props) => props.theme.colors.primary125};
  }
`;
