// Import external libs
import React, { Component } from "react";
import classNames from "classnames";

// Import UI
import Modal from "react-bootstrap/Modal";


class Popup extends Component {
  render() {
    const { show, children, onHide, title, className, styles, backdropClassName, dialogClassName } = this.props;
    return (
      <Modal
        show={show}
        centered
        onHide={onHide}
        tabIndex="-1"
        id="composeModal"
        dialogClassName={'modal-dialog mail-wrap mail-login ' + dialogClassName}
        style={styles}
        className={className}
        backdropClassName={backdropClassName}
      >
        {children}
      </Modal>
    );
  }
}

export default Popup;
