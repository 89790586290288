import React, { memo } from 'react'
import { WEATHER } from '../../../mockdata/homeData';

const WeatherWrapper = memo(props => {
  function renderWeatherItems() {
    return WEATHER.map((item) => (
      <li key={`weather${item.day}`}>
        <span className={`icon-weather-${item.indication}`}></span>
        <span className="weather-txt">{item.day}</span>
        <span className="weather-temperature txt-meta">{item.temperature}</span>
      </li>
    ))
  }
  return (
    <div className="sidebar-item">
      <div className="sidebar-title box-flex">
        <h3 className="rubikMedium">Weather</h3>
        <div className="dropdown">
          <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">Moscow</a>
          <ul className="dropdown-menu list-unstyled">
            <li><a href="#">dropdown item</a></li>
            <li><a href="#">dropdown item</a></li>
          </ul>
        </div>
      </div>

      <ul className="weather-list box-flex list-unstyled">
        {renderWeatherItems()}
      </ul>
    </div>
  )
})

export default WeatherWrapper;
