import React, { Component } from 'react';
import classNames from "classnames";
import styles from './LiveStyles.module.css';
import { SocialIcons } from './../SocialIcons/SocialIcons'
import LiveVideo from './LiveVideo'
import { Link } from "react-router-dom";
import ReactWOW from 'react-wow';

class LiveArticle extends Component {
  renderArticle = () => {
    return (
        <div className={classNames(styles.groupTitle)}>
          <div><div className={classNames(styles.dot)}/> <span>Live Update</span></div>
          <h2 className={classNames(styles.title)}>Articles of impeachment against President Trump unveiled</h2>
          <p className={classNames(styles.extraText)}>By <Link>Woorawot Tsanaka</Link> | Updated 7:07 p.m. ET, December 10, 2019</p>
          <div className={classNames(styles.groupSupportInfo)}>
            <SocialIcons />
            <div>
              <span className={classNames(styles.emailSupport)}>phk.com/share/2819/politics news</span>
            </div>
          </div>
        </div>
    )
  }
  render() {
    return (
      <ReactWOW delay='0.2s' animation='bounceInLeft' duration='1s' >
        <div className={classNames(styles.groupVideoInfo)}>
          {this.renderArticle()}
          <LiveVideo />
        </div>
      </ReactWOW>
    )
  }
}

export default LiveArticle