import React from "react";
import { Select, Button, Calendar } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { Text } from '../../../../commons/styles';

import * as Style from '../styles';

const AGENDA_LIST = [
  {
    date: 'Sat, Feb 14, 2020',
    content: 'Goes to Campus',
    type: 'personal'
  },
  {
    date: 'Sat, Feb 15, 2020',
    content: 'Coldplay’s Concert 2020',
    type: 'social'
  },
  {
    date: 'Sat, Feb 16, 2020',
    content: 'Website Project Sneaky',
    type: 'personal'
  },
  {
    date: 'Sat, Feb 17, 2020',
    content: 'Website Project Sneaky',
    type: 'social'
  },
  {
    date: 'Sat, Feb 18, 2020',
    content: 'Website Project Sneaky',
    type: 'personal'
  },
  {
    date: 'Sat, Feb 19, 2020',
    content: 'Website Project Sneaky',
    type: 'work'
  },
]

const AGENDA_COLORS = {
  personal: '#00A6F9',
  social: '#52C17B',
  work: '#F89E23',
}

const AgendaList = ({ setRightLayout }) => {
  function renderAgendaItems() {
    return AGENDA_LIST.map((agendaItem, agendaIndex) => {
      return (
        <Style.AgendaItemContainer onClick={() => setRightLayout({ path: 'agenda-detail', params: { id: 1 } })}>
          <Text primaryText>{agendaItem.date}</Text>
          <Style.AgendaItemContent color={AGENDA_COLORS[agendaItem.type]}>
            <Text white xs>{agendaItem.content}</Text>
          </Style.AgendaItemContent>
        </Style.AgendaItemContainer>
      )
    })
  }

  return (
    <>
      <Style.RightLayoutHeader>
        <div>
          <Text xxs color="#B5B5BE">CALENDAR</Text>
          <Select
            defaultValue="GMT +07"
            size="small"
            bordered={false}
            style={{ width: 100, color: '#FF570D' }}
          >
            <Select.Option value="GMT +07">GMT +07</Select.Option>
          </Select>
        </div>
        <Button type="text" icon={<CloseOutlined />} shape="circle" onClick={() => setRightLayout(null)}></Button>
      </Style.RightLayoutHeader>
      <Style.AgendaCalendar>
        <Calendar
          fullscreen={false}
          onPanelChange={(value, mode) => null}
          headerRender={() => null}
        />
      </Style.AgendaCalendar>
      <Style.AgendaListTop>
        <Text primaryText>Agenda List</Text>
        <Select
          defaultValue="all"
          size="small"
          style={{ width: 100 }}
        >
          <Select.Option value="all">All</Select.Option>
          <Select.Option value="personal">Personal</Select.Option>
          <Select.Option value="work">Work</Select.Option>
        </Select>
      </Style.AgendaListTop>
      <Style.RightLayoutContent>
        {renderAgendaItems()}
      </Style.RightLayoutContent>
    </>
  );
};

export default AgendaList;