import React, { memo } from 'react'
import ReactWOW from 'react-wow'
import { Images } from './../../../themes'

const BannerNews = memo(props => {
  return (
    <div className="news-item item-primary box-flex fadeIn" data-wow-duration="1s" data-wow-delay="0.3s">
      <ReactWOW delay='0.2s' animation='bounceInLeft' duration='0.5s' >
        <div className="thumbnail flex-50 bounceInLeft">
          <a href="#" style={{ backgroundImage: `url(${Images.homeImg001})` }}></a>
        </div>

      </ReactWOW>
      <ReactWOW delay='0.2s' animation='bounceInRight' duration='1s' >
        <div className="item-text flex-50 bounceInRight" data-wow-duration="1s" data-wow-delay="0.3s">
          <h2><a href="#" className="rubikMedium">get the boot a birds eye look into mcse boot camps</a></h2>
          <p>
            All users on MySpace will know that there are millions of people out there. Every day besides so many people joining this community...<br />
            <a href="#" className="rubikMedium">continue reading...</a>
          </p>
          <p className="comment-total rubikMedium">
            <i className="icon-comment-large"></i>2500 people reactingthis
        </p>
        </div>
      </ReactWOW>
    </div>
  )
})

export default BannerNews
