import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./MailBoxWrapper.module.css";
import MailList from "../MailList/MailList";
import { connect } from "react-redux";
import { ReactComponent as ListGridIcon } from "../../../assets/images/list-grid.svg";
import { ReactComponent as ColumnGridIcon } from "../../../assets/images/column-grid.svg";
import { ReactComponent as WindowGridIcon } from "../../../assets/images/window-grid.svg";

import InboxTabs from '../components/InboxTabs';

import {
  getMailList as getMailListAction,
  setMailDetail as setMailDetailAction,
} from "../../../redux/actions";

const MailBoxWrapper = ({
  mails,
  pages,
  mailType,
  draftEmailOpening,
  selectedItemIds,
  onSelectMailItem,
  getMailList,
  mailDetail,
  setMailDetail,
  totalUnseen,
  gridType,
  setGridType,
  inboxTab,
  setInboxTab,
}) => {
  useEffect(() => {
    if (mailType !== 'search') {
      getMailList({
        path: { type: mailType },
        param: { page: 1 },
      });
    }
  }, [getMailList, mailType]);

  function onChangeInboxTab(type) {
    setInboxTab(type);
    getMailList({
      path: { type },
      param: { page: 1 },
    });
  };

  const onLayoutListGrid = () => {
    setMailDetail({});
    setGridType("listGrid");
  }

  return (
    <div className={classnames(styles.wrapper)}>
      {mailType === 'inbox' && (
        <div className="mail-info">
          <InboxTabs onChangeInboxTab={onChangeInboxTab} inboxTab={inboxTab} totalUnseen={totalUnseen} />
          {/* <div className="mail-grid">
            <p className="layout-view">Layout View:</p>
            <ListGridIcon
              className={`grid-icon list-grid-icon ${gridType === "listGrid" ? "active" : ""}`}
              onClick={() => onLayoutListGrid()}
            />
            <ColumnGridIcon
              className={`grid-icon ${gridType === "columnGrid" ? "active" : ""}`}
              onClick={() => setGridType("columnGrid")}
            />
            <WindowGridIcon
              className={`grid-icon ${gridType === "rowGrid" ? "active" : ""}`}
              onClick={() => setGridType("rowGrid")}
            />
          </div> */}
        </div>
      )}
      {/* {gridType === "listGrid" && ((!mails || mails.length === 0) && !['work', 'social', 'promotion'].includes(inboxTab)) && <NoResult mailType={mailType} />}
      {gridType === "listGrid" && (mailType === 'inbox' && inboxTab !== 'primary' && !tabLoading && items.length === 0) && <NoResult mailType={inboxTab} />} */}
      <MailList
        key={gridType}
        mailType={mailType}
        mails={mails}
        gridType={gridType}
        onSelectMailItem={onSelectMailItem}
        selectedItemIds={selectedItemIds}
        mailDetail={mailDetail}
        setMailDetail={setMailDetail}
        inboxTab={inboxTab}

      />

      {/* Open popup if press draft email */}
      {/* {!!draftEmailOpening && (
        <ComposeEmail
          isVisibleComposeEmail={!!draftEmailOpening}
          onSuccess={this._onRefreshMailList}
          onHide={this.props.onCloseComposePopup}
          mailItem={draftEmailOpening}
          onDeleteMails={this._onDeleteMails}
          isExpandingCompose={this.state.isExpandingCompose}
          toggleExpandCompose={() =>
            this.setState({
              isExpandingCompose: !this.state.isExpandingCompose,
            })
          }
          fetchCurrentListEmails={() =>
            this.props.fetchMailStartByType(this.props.mailType, 1)
          }
          styles={{ alignItems: "center" }}
        />
      )} */}
    </div>
  );
}

MailBoxWrapper.propTypes = {
  mails: PropTypes.array,
  pages: PropTypes.object,
  pagination: PropTypes.object,
  mailType: PropTypes.string,
  goToPage: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    mailDetail: state.mails.detail,
    totalUnseen: state.mails.totalUnseen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMailList: (params) => dispatch(getMailListAction(params)),
    setMailDetail: (params) => dispatch(setMailDetailAction(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailBoxWrapper);
