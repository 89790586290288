import RestClient from '../restClient';

export default class AdminServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  createArticle = (data) => {
    return this.restClient.post("/admin/article/save", data);
  };

  updateArticle = (articleId, data) => {
    return this.restClient.post(`/admin/article/update/${articleId}`, data);
  };

  deleteArticle = (data) => {
    return this.restClient.post("/admin/article/delete", data);
  };

  listArticle = (page) => {
    return this.restClient.get(`/admin/article/list?limit=5000&page=${page}`);
  };
}
