import React, { Component } from 'react'
import ReactWOW from 'react-wow'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Header from './../../../commons/Header'
import SubHeader from './SubHeader'
import BannerNews from './BannerNews'
import MediumNews from './MediumNews'
import SmallNews from './SmallNews'
import Proclamation from './Proclamation'
import Mall from './Mall'
import Currency from './Currency'
import LiveScore from './LiveScore'
import Weather from './Weather'
import Videos from './Videos'
import Radio from './Radio';
import Footer from './../../../commons/Footer/Footer'

import { Images } from './../../../themes'
import { SMALL_NEWS, MEDIUM_NEWS, TRENDS } from './../../../mockdata/homeData'

class Home extends Component {
  state = {
    expandRightSidebar: false
  }
  renderGroupNews = () => {
    let news = []
    for (let i = 0; i < 4; i++) {
      news.push(
        <div key={`news${i}`} className="news-items">
          <div className="news-item box-flex">
            <ReactWOW delay='0.45s' animation='flipInY' duration='1s' >
              <div className="item item-position">
                <MediumNews {...MEDIUM_NEWS[i]} />
              </div>
            </ReactWOW>
            <ReactWOW delay='0.45s' animation='bounceInRight' duration='1s' >
              <div className="items">
                <SmallNews {...SMALL_NEWS[i > 0 ? (i + 5) : (i + 4)]} />
                <SmallNews {...SMALL_NEWS[i > 0 ? (i + 6) : (i + 5)]} />
              </div>

            </ReactWOW>
          </div>
        </div>
      )
    }
    return news
  }

  render() {
    const { authenticated } = this.props;
    const { expandRightSidebar } = this.state

    // if (authenticated) {
    //   return <Redirect to="/mail" />;
    // }
    return (
      <>
        <Header feature="HOME" />
        <main>
          <div className="content-wrap">
            <div className="container">
              <div className="main-content">
                <ReactWOW delay='0.2s' animation='bounceInUp' duration='1s' >
                  <div>
                    <SubHeader />
                  </div>
                </ReactWOW>
                <section className="news-item-wrap">
                  <div className="news-items">
                    <BannerNews />

                    <div className="news-item box-flex">
                      <ReactWOW offset={50} delay='0.3s' animation='rotateInDownLeft' duration='0.7s' >
                        <div className="item box-flex">
                          <SmallNews {...SMALL_NEWS[0]} />
                        </div>
                      </ReactWOW>
                      <ReactWOW offset={50} delay='0.3s' animation='rotateInDownRight' duration='0.7s' >
                        <div className="item box-flex">
                          <SmallNews {...SMALL_NEWS[1]} />
                        </div>
                      </ReactWOW>
                    </div>
                    <div className="news-item box-flex mt25">
                      <ReactWOW offset={50} delay='0.35s' animation='rotateInUpLeft' duration='0.7s' >
                        <div className="item box-flex">
                          <SmallNews {...SMALL_NEWS[2]} />
                        </div>
                      </ReactWOW>
                      <ReactWOW offset={50} delay='0.35s' animation='rotateInUpLeft' duration='0.7s' >
                        <div className="item box-flex">
                          <SmallNews {...SMALL_NEWS[3]} />
                        </div>
                      </ReactWOW>
                    </div>
                  </div>
                  {this.renderGroupNews()}
                </section>

                <section className="product-item-wrap">
                  <Proclamation />
                  <Mall
                    mallTitle='PhukMall'
                    image={Images.homeImg023}
                  />
                  <Mall
                    mallTitle='Hardware'
                    image={Images.homeImg030}
                  />
                  <Mall mallTitle='PhukPlus' />
                </section>

              </div>
              <ReactWOW offset={50} delay='0.45s' animation='bounceInDown' duration='1s' >
                <div className={`sidebar ${expandRightSidebar ? 'show' : ''}`}>
                  <div onClick={() => this.setState(preState => ({ expandRightSidebar: !preState.expandRightSidebar }))} className="show-sidebar"></div>
                  <div className="sidebar-wrap">
                    <div className="sidebar-item">
                      <h3 className="sidebar-title rubikMedium">Trends for You</h3>
                      <ul className="list-tag list-unstyled">
                        {TRENDS.map(item =>
                          <li key={item}><a href="#">#EndGame</a></li>
                        )}
                      </ul>
                    </div>

                    <div className="sidebar-item">
                      <div className="ads">
                        <a href="#" style={{ backgroundImage: `url(${Images.adsImg001})` }}></a>
                      </div>
                    </div>
                    <Currency />
                    <LiveScore />
                    <Weather />
                    <Videos />
                    <Radio />
                  </div>
                </div>
              </ReactWOW>
            </div>
          </div>
        </main>
        <Footer />
      </>
    )
  }
}


const mapStateToProp = state => {
  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token
  };
};

export default connect(mapStateToProp)(Home)
