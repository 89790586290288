import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import styles from "./SearchFrom.module.css";
import SearchInput from "./SearchInput/SearchInput";
import SearchFilterSize from "./SearchFilter/Size";
import SearchFilterDateWithin from "./SearchFilter/DateWithin";
import SearchFilterSearch from "./SearchFilter/Search";
import SearchFilterCheckbox from "./SearchFilter/Checkbox";
import CreateFilterForm from "./CreateFilterForm/CreateFilterForm";
import { searchMail as searchMailAction } from "../../../redux/actions";
import { reverseString } from "../../../utils/string";
import { STANDING_PAGES } from "./../../../constants/common";

const transformFiltersToText = (filters) => {
  const filtersText = [];
  if (filters.from) {
    filtersText.push(`from:(${filters.from})`);
  }
  if (filters.to) {
    filtersText.push(`to:(${filters.to})`);
  }
  if (filters.subject) {
    filtersText.push(`sub:(${filters.subject})`);
  }
  if (filters.query) {
    filtersText.push(filters.query);
  }
  return filtersText.join(" ");
};

const regrexExtractFilter = (key, str) => {
  let result = "";
  if (str) {
    const reg = new RegExp(`(${key}:)\\((.*?)\\)`);
    const matchers = str.match(reg);
    if (matchers && matchers.length === 3) {
      result = matchers[2];
    }
  }
  return result;
};

const matchQueryFilter = (str) => {
  let result = "";
  const reverseStr = reverseString(str);
  const matchers = reverseStr.match(/(.*?)\)/);
  if (matchers && matchers.length === 2) {
    result = reverseString(matchers[1]);
  }
  return result;
};

const formatFilterFromText = (text) => {
  const filters = {};
  if (text) {
    filters.from = regrexExtractFilter("from", text);
    filters.to = regrexExtractFilter("to", text);
    filters.subject = regrexExtractFilter("sub", text);
    filters.query = matchQueryFilter(text);
  }
  return filters;
};

const isSearchFormat = (searchText) => searchText.includes(":(");

class SearchFrom extends Component {
  sizeOptions = [
    {
      value: 0,
      label: "Greater than",
    },
    {
      value: 1,
      label: "Less than",
    },
  ];

  state = {
    isSeachExpand: false,
    searchText: "",
    from: "",
    to: "",
    subject: "",
    query: "",
    isFocus: false,
    isCreateFilterActive: false,
    showCreateFilterForm: false,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.state.isSeachExpand &&
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target)
    ) {
      this.setState({
        isSeachExpand: false,
        isFocus: false,
      });
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  onSearchMailHandler = () => {
    const { query, isSeachExpand, searchText } = this.state;
    const { onSearchMail, searchMail, history } = this.props;
    if (isSeachExpand) {
      this.onSearchExpandHandler();
    } else if (searchText && isSearchFormat(searchText)) {
      const filters = formatFilterFromText(searchText);
      // onSearchMail(1, filters);
      searchMail({
        path: {
          type: "search",
        },
        param: {
          page: 1,
          filters,
        },
      })
      history.push("/mail/search");
    } else if (query) {
      const filters = {
        query: query,
      };
      // onSearchMail(1, filters);
      searchMail({
        path: {
          type: "search",
        },
        param: {
          page: 1,
          filters,
        },
      })
      history.push("/mail/search");
    }
    this.setState({ isSeachExpand: false });
  };

  onSearchExpandHandler = () => {
    const { from, to, subject, query } = this.state;
    const { onSearchMail, searchMail, history } = this.props;
    let filters = { from, to, subject, query };
    if (from || to || subject || query) {
      // onSearchMail(1, filters);
      searchMail({
        path: {
          type: "search",
        },
        param: {
          page: 1,
          filters,
        },
      })
      history.push("/mail/search");
      this.setState({
        searchText: transformFiltersToText(filters),
        isSeachExpand: false,
      });
    }
  };

  setSearhExpand = (value, isFocus = false) =>
    this.setState({
      isSeachExpand: value,
      isFocus,
    });

  onChangeSearchText = (e) => {
    const value = e.target.value;
    this.setState({
      searchText: value,
      query: isSearchFormat(value) ? "" : value,
    });
  };
  onChangeFrom = (e) => this.setState({ from: e.target.value });
  onChangeTo = (e) => this.setState({ to: e.target.value });
  onChangeSubject = (e) => this.setState({ subject: e.target.value });
  onChangeQuery = (e) => this.setState({ query: e.target.value });
  onChangeDoesntHave = (e) => this.setState({ doesntHave: e.target.value });
  onChangeSize = (e) => this.setState({ size: e.target.value });

  clearSearch = () => {
    this.setState({
      searchText: "",
      from: "",
      to: "",
      subject: "",
      query: "",
      hasAWords: "",
      doesntHave: "",
      size: {},
      dateWithin: {},
      option: "",
      hasAttachment: false,
      dontIncludeChats: false,
      isSeachExpand: false,
    });
  };

  checkCreateFilterActive = () => {
    const dataFilter = {
      from: this.state.from ?? "",
      to: this.state.to ?? "",
      subject: this.state.subject ?? "",
      query: this.state.query ?? "",
      hasAWords: this.state.hasAWords ?? "",
      doesntHave: this.state.doesntHave ?? "",
      size: this.state.size?.value ?? "",
    };
    if (
      Object.entries(dataFilter).filter(([key, value]) => value !== "")
        .length === 0
    ) {
      return false;
    }
    return true;
  };

  setCreateFilter = () => {
    if (this.checkCreateFilterActive()) {
      this.setState({ checkCreateFilterActive: true });
    } else {
      this.setState({ checkCreateFilterActive: false });
    }
  };

  onCreateFilter = () => {
    if (!this.checkCreateFilterActive()) {
      alert("Please enter some search criteria.");
    } else {
      this.setState({ showCreateFilterForm: true });
    }
  };
  onCloseCreateFilterForm = () => {
    this.setState({ showCreateFilterForm: false });
  };
  render() {
    const {
      isSeachExpand,
      searchText,
      from,
      to,
      subject,
      query,
      doesntHave,
      size,
    } = this.state;
    const showClose = !!(
      searchText ||
      (isSeachExpand && (from || to || subject || query))
    );
    const { currentPage, expandSearchBox } = this.props;
    return (
      <form
        ref={this.setWrapperRef}
        className={`form-search-top ${expandSearchBox ? "open" : ""} ${
          styles.formSearchTopWidth
        }`}
      >
        <div className="input-group" id={styles.inputSearch}>
          <div className="input-group-prepend">
            <div
              className={classnames(
                "input-group-text",
                styles.inputGroupTextCustom
              )}
            >
              <span className="icon-search"></span>
            </div>
          </div>
          <input
            type="text"
            className={classnames("form-control", styles.formControlCustom)}
            placeholder="Search Mail"
            value={searchText}
            onChange={this.onChangeSearchText}
            onFocus={() => this.setSearhExpand(false, true)}
            onBlur={() => this.setState({ isFocus: false })}
          />
        </div>
        <div className="open" style={{ position: "relative" }}>
          {!STANDING_PAGES[currentPage] && (
            <div
              className={classnames(styles.caretPos)}
              onClick={() => this.setSearhExpand(!isSeachExpand)}
            >
              <span className={classnames("caret", styles.caretSize)} />
              {showClose && (
                <div
                  className={classnames(styles.closeBtn)}
                  onClick={this.clearSearch}
                >
                  <i className="fa fa-close" />
                </div>
              )}
            </div>
          )}
          <div
            className={classnames(
              styles.dropDownMenu,
              styles.extraForm,
              styles.searchExpand,
              isSeachExpand ? styles.show : ""
            )}
          >
            <SearchInput
              label="From"
              value={from}
              onChange={this.onChangeFrom}
            />
            <SearchInput label="To" value={to} onChange={this.onChangeTo} />
            <SearchInput
              label="Subject"
              value={subject}
              onChange={this.onChangeSubject}
            />
            <SearchInput
              label="Has the words"
              value={query}
              onChange={this.onChangeQuery}
            />
            <SearchInput
              label="Doesn't have"
              value={doesntHave}
              onChange={this.onChangeDoesntHave}
            />
            <SearchFilterSize onChange={this.onChangeSize} value={size} />
            <SearchFilterDateWithin onChange={this.onChangeSize} value={size} />
            <SearchFilterSearch onChange={this.onChangeSize} value={size} />
            <SearchFilterCheckbox onChange={this.onChangeSize} value={size} />
            <div className={classnames("row", styles.searchFloatContainerBtn)}>
              <button
                onClick={this.onCreateFilter}
                type="button"
                className={styles.createFilterBtn}
              >
                Create Filter
              </button>
              <button
                onClick={this.onSearchExpandHandler}
                type="button"
                className={classnames("btn btn-primary", styles.multiSearchBtn)}
                style={{ fontSize: "14px" }}
              >
                Search
              </button>
            </div>
          </div>
          <CreateFilterForm
            doesShow={this.state.showCreateFilterForm}
            onClosed={this.onCloseCreateFilterForm}
          />
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchMail: (params) => dispatch(searchMailAction(params)),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(SearchFrom));
