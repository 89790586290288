import React from "react";
import classnames from 'classnames';
import styles from "../SearchInput/SearchInput.module.css";

const SearchFilterCheckbox = ({ value, onChange }) => {
  return  (
    <div className={styles.inputWrapper}>
      <div className={styles.inputLabel}></div>
      <div className={styles.formCheckbox}>
        <label className={styles.labelContainer}>Has attachment
          <input type="checkbox" />
          <span className={styles.checkmark}></span>
        </label>
        <label className={styles.labelContainer}>Don't include chats
          <input type="checkbox" />
          <span className={styles.checkmark}></span>
        </label>
      </div>
    </div>
  );
}

export default SearchFilterCheckbox;
