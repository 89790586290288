import React, { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NavBar from "../../commons/NavBar/NavBar";
import LeftSideBar from "../../commons/LeftSideBar/LeftSideBar";
import MailDetail from "./MailDetail/MailDetail";
import { MAIL_TYPES } from "../../constants/mail";
import { connect } from "react-redux";
import ToastNotification from "./../../commons/ui/ToastNotification/ToastNotification";
import MailLabel from "./MailLabel/MailLabel";

import TopMenu from './components/TopMenu';
import RightModule from "./RightModule";
import RightLayout from "./RightLayout";
import MailBoxWrapper from "./MailBoxWrapper/MailBoxWrapper";

import {
  removeMails as removeMailsAction,
  deleteMails as deleteMailsAction,
  deleteDrafts as deleteDraftsAction,
  markSeen as markSeenAction,
  markUnSeen as markUnSeenAction,
  setSpam as setSpamAction,
} from "../../redux/actions";

import * as Style from './styles';

const MailBox = ({
  location,
  mailType,
  mailList,
  isMailDetail,
  authenticated,
  removeMails,
  deleteMails,
  deleteDrafts,
  markSeen,
  markUnSeen,
  setSpam,
  history,
}) => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [rightLayout, setRightLayout] = useState(null);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [inboxTab, setInboxTab] = useState('inbox');
  const [gridType, setGridType] = useState("listGrid");
  const [draftEmailOpening, setDraftEmailOpening] = useState(null);
  const [isShowMailLabelModal, setIsShowMailLabelModal] = useState(false);

  const {
    items: mails,
    loading,
    pages,
  } = mailList[mailType === 'inbox' ? inboxTab : mailType];
    console.log('🚀 ~ file: index.jsx ~ line 56 ~ pages', pages);

  const isSelectingSomeItems = selectedItemIds.length > 0 && selectedItemIds.length < mails.length;
  const isSelectAllItems = selectedItemIds.length > 0 && selectedItemIds.length === mails.length;

  function onSelectMailItem(id, isDraftEmail = false) {
    // UPDATE_LATE:ERROR SELECT DRAFT
    // if (!isDraftEmail) {
      let newSelectedItemIds = selectedItemIds;
      if (selectedItemIds.includes(id)) {
        newSelectedItemIds = selectedItemIds.filter((selectedId) => selectedId !== id);
      } else {
        newSelectedItemIds.push(id);
      }
      setSelectedItemIds([...newSelectedItemIds]);
    // } else {
    //   setDraftEmailOpening(mails.find((mailItem) => mailItem.Id === id));
    // }
  };

  function onSelectAllMail(isSelectAll) {
    if (isSelectAll || selectedItemIds.length === 0) {
      setSelectedItemIds([...mails.map((mailItem) => mailItem.Id)])
    } else {
      resetSelectItem();
    }
  };
  
  function resetSelectItem() {
    setSelectedItemIds([]);
  };

  function onDeleteMails() {
    if (selectedItemIds.length > 0 || isMailDetail) {
      const mailId = location?.state?.mail?.Id;
      const ids = mailId ? mailId : selectedItemIds.join("|");
      switch (mailType) {
        case MAIL_TYPES.TRASH:
          deleteMails({ 
            data: { ids },
            meta: { isMailDetail },
          });
          break;
        case MAIL_TYPES.DRAFT:
          deleteDrafts({ 
            data: { ids },
            meta: { isMailDetail },
          });
          break;
        default:
          removeMails({ 
            type: mailType,
            data: { ids },
            meta: { isMailDetail },
          });
      }
      return resetSelectItem();
    }
  };

  function onSeenMails() {
    if (selectedItemIds.length > 0 || isMailDetail) {
      const mailId = location?.state?.mail?.Id;
      const ids = mailId ? mailId : selectedItemIds.join("|");
      if (mailType !== MAIL_TYPES.DRAFT) {
        markSeen({
          type: mailType === 'inbox' ? inboxTab : mailType,
          data: { ids },
          meta: { isMailDetail },
        });
      }
      return resetSelectItem();
    }
  };

  function onUnSeenMails() {
    if (selectedItemIds.length > 0 || isMailDetail) {
      const mailId = location?.state?.mail?.Id;
      const ids = mailId ? mailId : selectedItemIds.join("|");
      if (mailType !== MAIL_TYPES.DRAFT) {
        markUnSeen({
          type: mailType === 'inbox' ? inboxTab : mailType,
          data: { ids },
          meta: { isMailDetail },
        });
      }
      return resetSelectItem();
    }
  };

  function onSetSpamMails() {
    if (selectedItemIds.length > 0 || isMailDetail) {
      const mailId = location?.state?.mail?.Id;
      const ids = mailId ? mailId : selectedItemIds.join("|");
      if (mailType !== MAIL_TYPES.DRAFT) {
        setSpam({
          type: mailType === 'inbox' ? inboxTab : mailType,
          data: { ids },
          meta: { isMailDetail },
        });
      }
      return resetSelectItem();
    }
  };

  function onCloseComposePopup() {
    setDraftEmailOpening(null);
  };

  return (
    <Style.MainContainer>
      <Style.MainContent showRightLayout={!!rightLayout}>
        <div
          className={`mail-wrap ${isOpenSidebar ? "sidebar-toggler sidebar-open" : ""}`}
        >
          <NavBar
            toggleSideBar={() => setIsOpenSidebar(!isOpenSidebar)}
            currentPage="MAIL BOX"
            isShowRightLayout={!!rightLayout}
          />
          <div className="mail-box" styles={{ zIndex: 10000 }}>
            <LeftSideBar
              toggleForm={() => setIsShowMailLabelModal(!isShowMailLabelModal)}
              sideBarOpen={isOpenSidebar}
              toggleSideBar={() => setIsOpenSidebar(!isOpenSidebar)}
              resetSelectItem={resetSelectItem}
            />
            <RightModule setRightLayout={setRightLayout} />
            <div
              className="content"
              style={{ position: "relative", zIndex: 1, height: 'calc(100vh - 75px)', overflowY: 'auto' }}
            >
              <div>
                <TopMenu
                  isSelectingSomeItems={isSelectingSomeItems}
                  isSelectAllItems={isSelectAllItems}
                  onSelectAllMail={onSelectAllMail}
                  onDeleteMails={onDeleteMails}
                  onSeenMails={onSeenMails}
                  onUnSeenMails={onUnSeenMails}
                  onSetSpamMails={onSetSpamMails}
                  isMailDetail={isMailDetail}
                  mailType={mailType}
                  pages={pages}
                  gridType={gridType}
                  setGridType={setGridType}
                />
                {/* {isDeletingError && (
                  <NoResult text="Delete email error" isError={true} />
                )} */}
                <Switch>
                  <Route
                    exact
                    path="/mail/"
                    render={() => <Redirect to="mail/inbox" />}
                  />
                  <Route
                    exact
                    path="/mail/:type/:id"
                    render={(props) => {
                      return (
                        <MailDetail
                          {...props}
                          mailType={mailType}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/mail/:type"
                    render={(props) => {
                      return (
                        <MailBoxWrapper
                          {...props}
                          mailType={mailType}
                          mails={mails}
                          pages={pages}
                          draftEmailOpening={draftEmailOpening}
                          selectedItemIds={selectedItemIds}
                          onSelectMailItem={onSelectMailItem}
                          
                          isSelectAllItems={isSelectAllItems}
                          onSelectAllMail={onSelectAllMail}
                          onDeleteMails={onDeleteMails}
                          onCloseComposePopup={onCloseComposePopup}
                          history={history}
                          gridType={gridType}
                          setGridType={setGridType}
                          inboxTab={inboxTab}
                          setInboxTab={setInboxTab}
                        />
                      );
                    }}
                  />
                </Switch>
                <div
                  style={{
                    top: 0,
                    zIndex: 100,
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  {loading && (
                    <ToastNotification message="Loading" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <MailLabel show={isShowMailLabelModal} onHide={() => setIsShowMailLabelModal(!isShowMailLabelModal)} />
      </Style.MainContent>
      <RightLayout rightLayout={rightLayout} setRightLayout={setRightLayout} />
    </Style.MainContainer>
  );
}

const mapStateToProps = (state, ownProps) => {
  const pathItems = ownProps.location.pathname.split('/');
  const mailType = pathItems && pathItems.length > 2 ? pathItems[2] : 'inbox';
  return {
    mailType,
    mailList: state.mails,
    isMailDetail: pathItems.length >= 4,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    removeMails: (params) => dispatch(removeMailsAction(params)),
    deleteMails: (params) => dispatch(deleteMailsAction(params)),
    deleteDrafts: (params) => dispatch(deleteDraftsAction(params)),
    markSeen: (params) => dispatch(markSeenAction(params)),
    markUnSeen: (params) => dispatch(markUnSeenAction(params)),
    setSpam: (params) => dispatch(setSpamAction(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailBox);
