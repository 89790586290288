import styled from 'styled-components';
import { Button } from 'antd';

export const LoadMoreButton = styled(Button)`
  width: 150px;
  color: #44444F;
  font-weight: ${(props) => props.theme.fontWeight.w6};
  
  // UPDATE_LATE
  & svg {
    transition: unset;
  }

  &:hover {
    border-color: ${(props) => props.theme.colors.primary75};
    color: ${(props) => props.theme.colors.primary75};

    & svg {
      fill: ${(props) => props.theme.colors.primary75};
    }
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.primary75};
    color: ${(props) => props.theme.colors.primary75};

    & svg {
      fill: ${(props) => props.theme.colors.primary75};
    }
  }

  &:active {
    border-color: ${(props) => props.theme.colors.primary125};
    color: ${(props) => props.theme.colors.primary125};

    & svg {
      fill: ${(props) => props.theme.colors.primary125};
    }
  }
`;
