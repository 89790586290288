import React from "react";
import classnames from 'classnames';
import styles from "../SearchInput/SearchInput.module.css";

const SearchFilterSize = ({ value, onChange }) => {
  return  (
    <div className={styles.inputWrapper}>
      <div className={styles.inputLabel}>Size</div>
      <select name="size" className={classnames(styles.input, styles.flex2dot6, styles.selectDropdownIcon)}>
        <option value="0">Greater than</option>
        <option value="1">Less than</option>
      </select>
      <div className={styles.flex0dot2}></div>
      <input className={classnames(styles.input, styles.flex2dot6)} value={value} onChange={onChange} />
      <div className={styles.flex0dot2}></div>
      <select name="size" className={classnames(styles.input, styles.flex2dot6, styles.selectDropdownIcon)}>
        <option value="0">MB</option>
        <option value="1">KB</option>
        <option value="2">Bytes</option>
      </select>
    </div>
  );
}

export default SearchFilterSize;
