import styled, { css } from 'styled-components';
import { Button } from 'antd';

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: ${(props) => props.isScrolling && !props.isFull ? '68px' : '94px'};
  box-shadow: ${(props) => props.isScrolling
    ? '0px 0px 5px 0px rgb(50 54 67 / 50%)'
    : 'none'
  };
  background-color: white;
  transition: 0.3s;
  z-index: 99;

  ${(props) => props.isFull
    && css`
      height: 68px;
      border-bottom: 1px solid #F1F1F5;
    `}
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  width: 100%;
  height: 100%;

  ${(props) => props.isFull
    && css`
      padding: 0 24px;
    `}
`;

export const SearchContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const FullSearchContainer = styled.div`
  position: absolute;
  top: 0;
  left: 300px;
  width: calc(100% - 600px);
  height: 100%;

  @media (max-width: 1400px) {
    width: calc(100% - 300px);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const FullSearchContent = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 900px;
  height: 100%;
`;

export const EmailButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px !important;

  @media (max-width: 992px) {

    & > i {
      margin-right: 0 !important;
    }

    & > p {
      display: none;
    }
  }
`;

export const MenuButton = styled(Button)`
  @media (max-width: 992px) {
    display: none !important;
  }
`;

export const SearchButton = styled(Button)`
  display: none !important;

  @media (max-width: 768px) {
    display: flex !important;
  }
`;

export const DropdownSearchContainer = styled.div`
  position: absolute;
  top: 68px;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  height: 64px;
  background-color: white;
  border-bottom: 1px solid #F1F1F5;

  & .ant-input-affix-wrapper {
    margin-right: 0 !important;
    max-width: 100% !important;
  }

  ${(props) => props.isShow
    && css`
      display: flex;
    `}
`;
