import { Images } from '../themes'

const categoriesByNumber = {
  6: 'finance',
  9: 'politics',
  12: 'health',
  15: 'sports'
}
const createNews = (type) => {
  let SMALL_NEWS = []
  let MEDIUM_NEWS = []
  let image = ''
  for (let i = 2; i < 18; i++) {
    image = i < 10 ? Images[`homeImg00${i}`] : Images[`homeImg0${i}`]
    if (i !== 6 && i !== 9 && i !== 12 && i !== 15) {
      SMALL_NEWS.push({
        image,
        content: 'converter ipod video taking portable video...',
        link: 'www.gazetaexpress.com',
      })
    } else {
      MEDIUM_NEWS.push({
        image,
        category: categoriesByNumber[i],
        content: 'converter ipod video taking portable video...',
        link: 'www.gazetaexpress.com',
        time: '08:22 PM',
        like: '43K',
        comment: '62',
        share: '21'
      })
    }
  }
  return type === 'SMALL' ? SMALL_NEWS : MEDIUM_NEWS
}

const SMALL_NEWS = createNews('SMALL')
const MEDIUM_NEWS = createNews('MEDIUM')

const PROCLAMATION = [{
  image: Images.homeImg019,
  title: 'Nissan Pathfinder 10',
  price: '250 €',
  distance: '129,000 KM'
}, {
  image: Images.homeImg020,
  title: 'Audi A3 3.0 Turbo',
  price: '250 €',
  distance: '129,000 KM'
}, {
  image: Images.homeImg021,
  title: 'Opel Astra H - GTC',
  price: '250 €',
  distance: '129,000 KM'
}, {
  image: Images.homeImg022,
  title: 'VW Golf 5 Special',
  price: '250 €',
  distance: '129,000 KM'
}]

const createBusSchedules = () => {
  let schedules = []
  const tos = {
    0: 'Portland',
    1: 'miami',
    2: 'san francisco'
  }
  for (let i = 0; i < 6; i++) {
    schedules.push({
      from: 'New York',
      to: tos[i % 3],
      time: '11:11 AM'
    })
  }
  return schedules
}
const BUS_SCHEDULES = createBusSchedules()

const PHUK_MALL = [{
  image: Images.homeImg024,
  name: 'adias campus shoes',
  price: '250 €'
}, {
  image: Images.homeImg025,
  name: 'audio technica ATH20',
  price: '250 €'
}, {
  image: Images.homeImg026,
  name: 'macbook pro 2017',
  price: '250 €'
}, {
  image: Images.homeImg027,
  name: 'ikea storage minimal',
  price: '250 €'
}, {
  image: Images.homeImg028,
  name: 'camera leather bag',
  price: '250 €'
}, {
  image: Images.homeImg029,
  name: 'timex men watch',
  price: '250 €'
}]

const PHUK_50 = [{
  image: Images.homeImg031,
  name: 'monitor samsung 4k',
  price: '250 €'
}, {
  image: Images.homeImg032,
  name: 'imac pro thunderbold',
  price: '250 €'
}, {
  image: Images.homeImg033,
  name: 'PS4 64GB hard disk',
  price: '250 €'
}, {
  image: Images.homeImg034,
  name: 'xiaomi redmi note 7',
  price: '250 €'
}, {
  image: Images.homeImg035,
  name: 'mi headphones white',
  price: '250 €'
}, {
  image: Images.homeImg036,
  name: 'magic mouse gen 2',
  price: '250 €'
}]

const PHUK_PLUS = [{
  image: Images.homeImg037,
  name: 'pizza hut 91 store',
  description: 'Kaliurang Streeet KM 5.6',
  btnTitle: 'Order Food',
}, {
  image: Images.homeImg038,
  name: 'AXA mandiri finance',
  description: 'Kaliurang Streeet KM 5.6',
  btnTitle: 'Goes to there place',
}, {
  image: Images.homeImg039,
  name: 'PS4 64GB hard disk',
  description: 'Kaliurang Streeet KM 5.6',
  btnTitle: 'Booking Hotels'
}, {
  image: Images.homeImg040,
  name: 'western union global',
  description: 'Kaliurang Streeet KM 5.6',
  btnTitle: 'Goes to there place'
}, {
  image: Images.homeImg041,
  name: 'larissa beauty home',
  description: 'Kaliurang Streeet KM 5.6',
  btnTitle: 'Buy Ticket'
}, {
  image: Images.homeImg042,
  name: 'monitor samsung 4k',
  description: 'Kaliurang Streeet KM 5.6',
  btnTitle: 'Goes to there place'
}]

const PHUK_DATA = {
  PhukMall: PHUK_MALL,
  Hardware: PHUK_50,
  PhukPlus: PHUK_PLUS
}

const CURRENCY = [{
  flag: Images.flagUs,
  unit: 'USD',
  currencyKey: 'Rp',
  value: '14,177.60'
}, {
  flag: Images.flagSgd,
  unit: 'SGD',
  currencyKey: 'Rp',
  value: '10,301.26'
}, {
  flag: Images.flagEu,
  unit: 'EUR',
  currencyKey: 'Rp',
  value: '15,922.91'
}, {
  flag: Images.flagHk,
  unit: 'HKD',
  currencyKey: 'Rp',
  value: '1,815.94'
}]

const LIVE_SCORE = [{
  flag: Images.logoArsenal,
  name: 'Arsenal',
  number: '1',
  timeAt: 'Min 87'
}, {
  flag: Images.logoLiverpool,
  name: 'Liverpool',
  number: '1',
  address: 'Emirates Std'
}, {
  flag: Images.logoChelsea,
  name: 'Chelsea',
  number: '3',
  timeAt: 'Full time'
}, {
  flag: Images.logoWestHam,
  name: 'West Ham',
  number: '0',
  address: 'Stamford Br.'
}]

const VIDEOS = [{
  poster: Images.homeVideo001,
  content: 'Easy Home Remedy For Moisture Control Of Painted Skin',
  author: 'Adam Warlock'
}, {
  poster: Images.homeVideo002,
  content: 'Internet Advertising What Went Wrong',
  author: 'Hearbeat Agency'
}, {
  poster: Images.homeVideo003,
  content: 'Hearing Aids To Best Suit Your Needs',
  author: 'Health Clinic'
}]

const WEATHER = [{
  day: 'Sun',
  temperature: '37°',
  indication: 'bright'
}, {
  day: 'Mon',
  temperature: '31°',
  indication: 'normal'
}, {
  day: 'Tue',
  temperature: '40°',
  indication: 'rain'
}, {
  day: 'Wed',
  temperature: '35°',
  indication: 'rain-storms'
}]

const TRENDS = ['#EndGame', '#JoeBidden', '#DontSpoilEn..', '#Champions..', '#FarFromHo..', '#LiverpoolFC..', '#LaborDay', '#ManCityBlue', '#Education..', '#LionelMessi..']
export {
  MEDIUM_NEWS,
  SMALL_NEWS,
  PROCLAMATION,
  BUS_SCHEDULES,
  PHUK_PLUS,
  PHUK_50,
  PHUK_MALL,
  PHUK_DATA,
  CURRENCY,
  LIVE_SCORE,
  VIDEOS,
  WEATHER,
  TRENDS
}
