import styled, { css } from 'styled-components';

export const HeaderContainer = styled.header`
  width: 100%;

  ${(props) => props.showRightLayout
    && css`
      width: calc(100% - 280px);
    `}
`;
