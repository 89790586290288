import React, { useEffect, useState } from "react";
import styles from "../NewsStyles.module.css";
import { ProgressBar } from "react-bootstrap";
import { Item } from "./item";
import ReactWOW from 'react-wow';

const defaultData = [
  {
    name: "news",
    icon: "icon-news",
    link: "/news",
  },
  {
    name: "sports",
    icon: "icon-sports",
  },
  {
    name: "email",
    icon: "icon-sports",
  },
  {
    name: "tech",
    icon: "icon-sports",
  },
  {
    name: "finance",
    icon: "icon-diagram-right",
  },
  {
    name: "politics",
    icon: "icon-politics",
  },
  {
    name: "entertaiment",
    icon: "icon-video",
  },
  {
    name: "health",
    icon: "icon-health",
  },
  {
    name: "lifestyle",
    icon: "icon-lifestyle",
  },
  {
    name: "More...",
  },
];

export const PickCategories = () => {
  const [data, setData] = useState(defaultData);
  useEffect(() => {
    //get data
  }, []);
  return (
    <ReactWOW delay='0.2s' animation='bounceInLeft' duration='1s' >
      <div className={styles.relatedArticles}>
        <ProgressBar
          now={10}
          className={styles.progressBar}
          bsPrefix={`${styles.bsProgressBarCategory} progress`}
        />
        <h3 className={styles.relatedArticlesTittleCategory}>Pick From Category</h3>
        {data.map((item) => (<Item item={item} />))}
        <div style={{clear: 'both'}}></div>
      </div>
    </ReactWOW>
  );
};
