export const MAIL_ACTION = {
  GET_ALL_MAILS: 'USER/MAIL_ACTION/GET_ALL_MAILS',
  GET_INBOX_MAILS: 'USER/MAIL_ACTION/GET_INBOX_MAILS',
  GET_WORKS_MAILS: 'USER/ACTION/GET_WORKS_MAILS',
  GET_SOCIAL_MAILS: 'USER/MAIL_ACTION/GET_SOCIAL_MAILS',
  GET_PROMOTION_MAILS: 'USER/MAIL_ACTION/GET_PROMOTION_MAILS',
  GET_IMPORTANT_MAILS: 'USER/MAIL_ACTION/GET_IMPORTANT_MAILS',
  GET_STARRED_MAILS: 'USER/MAIL_ACTION/GET_STARRED_MAILS',
  GET_SENT_MAILS: 'USER/MAIL_ACTION/GET_SENT_MAILS',
  GET_SPAM_MAILS: 'USER/MAIL_ACTION/GET_SPAM_MAILS',
  GET_SCHEDULED_MAILS: 'USER/MAIL_ACTION/GET_SCHEDULED_MAILS',
  GET_DRAFT_MAILS: 'USER/MAIL_ACTION/GET_DRAFT_MAILS',
  GET_TRASH_MAILS: 'USER/MAIL_ACTION/GET_TRASH_MAILS',
  SEARCH_MAIL: 'USER/MAIL_ACTION/SEARCH_MAIL',
  GET_MAIL_DETAIL: 'USER/MAIL_ACTION/GET_MAIL_DETAIL',
  GET_DRAFT_DETAIL: 'USER/MAIL_ACTION/GET_DRAFT_DETAIL',

  SEND_MAIL: 'USER/MAIL_ACTION/SEND_MAIL',
  REMOVE_MAILS: 'USER/MAIL_ACTION/REMOVE_MAILS',
  DELETE_MAILS: 'USER/MAIL_ACTION/DELETE_MAILS',
  DELETE_DRAFTS: 'USER/MAIL_ACTION/DELETE_DRAFTS',
  SAVE_DRAFT: 'USER/MAIL_ACTION/SAVE_DRAFT',

  SET_IMPORTANT: 'USER/MAIL_ACTION/SET_IMPORTANT',
  REMOVE_IMPORTANT: 'USER/MAIL_ACTION/REMOVE_IMPORTANT',
  
  SET_STARRED: 'USER/MAIL_ACTION/SET_STARRED',
  REMOVE_STARRED: 'USER/MAIL_ACTION/REMOVE_STARRED',

  SET_SPAM: 'USER/MAIL_ACTION/SET_SPAM',

  MARK_SEEN: 'USER/MAIL_ACTION/MARK_SEEN',
  MARK_UNSEEN: 'USER/MAIL_ACTION/MARK_UNSEEN',

  GET_TOTAL_UNSEEN_MAIL: 'USER/MAIL_ACTION/GET_TOTAL_UNSEEN_MAIL',

  MOVE_TO_TAB: 'USER/MAIL_ACTION/MOVE_TO_TAB',

  SET_MAIL_DETAIL: 'USER/MAIL_ACTION/SET_MAIL_DETAIL',
  SET_SAVED_DRAFT: 'USER/MAIL_ACTION/SET_SAVED_DRAFT',
};
