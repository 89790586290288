import React, { memo } from 'react'
import { CURRENCY } from './../../../mockdata/homeData'
import { Images } from './../../../themes'

const CurrencyItem = memo(props => {
  const { flag, unit, currentKey, value } = props
  return (
    <div className="country-item">
      <div className="flag">
        <div className="flag-image" style={{ backgroundImage: `url(${flag})` }}></div>
        <div className="flag-name">{unit}</div>
      </div>
      <div className="currency-key rubikMedium">{currentKey}</div>
      <div className="currency-numb rubikMedium">{value}</div>
    </div>
  )
})

const Currency = memo(props => {
  return (
    <div className="sidebar-item">
      <h3 className="sidebar-title rubikMedium">Currency Updates</h3>
      <ul className="list-select list-unstyled">
        <li className="dropdown">
          <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">1</a>
          <ul className="dropdown-menu list-unstyled">
            <li><a href="#">1</a></li>
            <li><a href="#">2</a></li>
            <li><a href="#">3</a></li>
          </ul>
        </li>
        <li className="dropdown">
          <a href="#" className="dropdown-toggle flag" data-toggle="dropdown" aria-expanded="true">
            <span className="flag-image" style={{ backgroundImage: `url(${Images.flagIndo})` }}></span>
            <span className="flag-name">IDR - Indonesia</span>
          </a>
          <ul className="dropdown-menu list-unstyled">
            <li><a href="#">dropdown item</a></li>
            <li><a href="#">dropdown item</a></li>
          </ul>
        </li>
      </ul>
      <div className="list-country-currency">
        {
          CURRENCY.map(item => (
            <CurrencyItem key={`currency${item.unit}`} {...item} />
          ))
        }
      </div>
      <p className="sidebar-button rubikMedium">
        <a href="#" className="btn btn-primary">see other currency</a>
      </p>
    </div>
  )
})

export default Currency
