import React from 'react';
import { Images } from './../../../../themes'
import classNames from "classnames"
import styles from './LiveStyles.module.css';

const LiveVideo = () => {
  return(
    <div className={classNames(styles.videoGroup)}>
      <img src={Images.videoBackgroundNews} className={classNames(styles.backgroundVideo)} alt='background video'/>
      <button className={classNames(styles.playButton)} >
        <img src={Images.icPlayVideo} alt='play button'/>
      </button>
      <span className={classNames(styles.duration)}>08:11</span>
    </div>
  )
}

export default LiveVideo 