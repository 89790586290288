import React from 'react';
import classnames from 'classnames';
import styles from './ToastNotification.module.css';
const ToastNotification = ({ onClose, message, type, isShowingCloseBtn }) => {
	if (message) {
		return (
			<div className={styles.wrapper}>
				<div
					className={classnames(styles.message, {
						'text-success': type === 'success',
						'text-danger': type === 'error',
					})}
				>
					{message}
				</div>
				{isShowingCloseBtn &&
					<div className={styles.closeButton} onClick={onClose}>
						<i className="fa fa-close" />
					</div>}
			</div>
		);
	}
	return null;
};

export default ToastNotification;
