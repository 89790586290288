const AUTH_KEY = 'phuk-auth';

export const LoginStorage = {
  getData: () => {
    const localStorageData = JSON.parse(localStorage.getItem(AUTH_KEY) || 'null');
    const sessionStorageData = JSON.parse(sessionStorage.getItem(AUTH_KEY) || 'null');
    const storageData = localStorageData || sessionStorageData;
    if (!storageData) return null;
    return storageData;
  },
  setData: (res, remember) => {
    localStorage.setItem(AUTH_KEY, JSON.stringify(res));
  },
  clearData: () => {
    localStorage.clear();
    sessionStorage.clear();
  },
};
