import React from 'react';
import { Row, Col, Tabs, Space, Rate, Button } from 'antd';
import { ClockCircleOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import Slider from 'react-slick';

import Header from '../../commons/Header';
import SubHeader from '../../commons/components/SubHeader';

import { Text, TagButton } from '../../commons/styles';

import { Images } from "../../themes";

import * as Style from './styles';

const TODAY_MATCHES = [
  {
    name: 'Pisces',
    description: 'Love Interest',
  },
  {
    name: 'Virgo',
    description: 'Best Friendship',
  },
  {
    name: 'Sagittarius',
    description: 'Career Future',
  },
];

const TODAY_MOODS = [
  {
    name: 'Sex',
    rate: 3,
  },
  {
    name: 'Hustle',
    rate: 4,
  },
  {
    name: 'Vibe',
    rate: 3,
  },
  {
    name: 'Vibe',
    rate: 5,
  },
];

const HoroscopePage = () => {
  function renderHoroscopeCard() {
    return [...Array(12)].map((item, index) => {
      return (
        <Style.HoroscopeCardContainer>
          <Style.HoroscopeCardContent active={index === 0}>
            <Text primaryText lg>Taurus</Text>
            <Style.HoroscopeImage>
              <img src={Images.icHoroscopeBuffalo} alt="" />
            </Style.HoroscopeImage>
          </Style.HoroscopeCardContent>
        </Style.HoroscopeCardContainer>
      )
    })
  }

  function renderHoroscopeTabContent() {
    return (
      <>
        <Space size={8} style={{ marginBottom: 32 }}>
          <TagButton type="primary" shape="round">Overview</TagButton>
          <TagButton shape="round">Love</TagButton>
          <TagButton shape="round">Career</TagButton>
          <TagButton shape="round">Money</TagButton>
          <TagButton shape="round">Health</TagButton>
        </Space>
        <Text><strong>Jan 31, 2020</strong> - If you are expecting a run-of-the-mill day as far as love and romance are concerned, then you are in for a surprise. One or two unexpected events take your breath away, and actually encourage you to get off that couch. Whether you are in a long-term relationship or going out on that first date, you have a treat in store.</Text>
        <Text style={{ margin: '24px 0' }}>Try to not let your partner's little quirks rub you the wrong way. Everyone has eccentricities, even you! Focus on their good qualities and you can minimize your annoyance at their odd ones.</Text>
      </>
    )
  }

  function renderTodayMatches() {
    return TODAY_MATCHES.map((todayMatchItem, todayMatchIndex) => {
      return (
        <Space size={12}>
          <Style.HoroscopeMatchImage>
            <img src="https://via.placeholder.com/32" alt="" />
          </Style.HoroscopeMatchImage>
          <div>
            <Text subText>{todayMatchItem.description}</Text>
            <Text headerText lg w6>{todayMatchItem.name}</Text>
          </div>
        </Space>
      )
    })
  }

  function renderTodayMoods() {
    return TODAY_MOODS.map((todayMoodItem, todayMoodIndex) => {
      return (
        <Space size={12}>
          <Style.HoroscopeMoodImage>
            <img src="https://via.placeholder.com/24" alt="" />
          </Style.HoroscopeMoodImage>
          <div>
            <Text headerText lg w6>{todayMoodItem.name}</Text>
            <Rate disabled defaultValue={todayMoodItem.rate} />
          </div>
        </Space>
      )
    })
  }

  return (
    <>
      <Header feature="HOROSCOPE" />
      <Style.HoroscopeContainer className="container">
        <Row gutter={24}>
          <Col xl={{ span: 18 }} lg={{ span: 16 }} xs={{ span: 24 }}>
            <SubHeader currentPage="News" />
            <Style.HoroscopeSliderContainer>
              <Slider
                infinite={false}
                slidesToShow={7}
                speed={500}
                initialSlide={0}
                dots={false}
                nextArrow={<Style.SliderNext icon={<RightOutlined />} />}
                prevArrow={<Style.SliderPrev icon={<LeftOutlined />} />}
                responsive={[
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 6,
                    }
                  },
                  {
                    breakpoint: 1080,
                    settings: {
                      slidesToShow: 5,
                    }
                  },
                  {
                    breakpoint: 992,
                    settings: {
                      slidesToShow: 4,
                    }
                  },
                ]}
              >
                {renderHoroscopeCard()}
              </Slider>
            </Style.HoroscopeSliderContainer>
            <Style.HoroscopeContent>
              <Text primaryText xxxl w6 style={{ marginBottom: 16 }}>Cancer Horoscope</Text>
              <Tabs defaultActiveKey="2">
                <Tabs.TabPane tab="YESTERDAY" key="1">
                  {renderHoroscopeTabContent()}
                </Tabs.TabPane>
                <Tabs.TabPane tab="TODAY" key="2">
                  {renderHoroscopeTabContent()}
                </Tabs.TabPane>
                <Tabs.TabPane tab="TOMORROW" key="3">
                  {renderHoroscopeTabContent()}
                </Tabs.TabPane>
                <Tabs.TabPane tab="WEEKLY" key="4">
                  {renderHoroscopeTabContent()}
                </Tabs.TabPane>
                <Tabs.TabPane tab="MONTHLY" key="5">
                  {renderHoroscopeTabContent()}
                </Tabs.TabPane>
              </Tabs>
            </Style.HoroscopeContent>
          </Col>
          <Col xl={{ span: 6 }} lg={{ span: 8 }} xs={{ span: 24 }}>
            <Row gutter={[16, 16]}>
              <Col lg={{ span: 24 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <Style.HoroscopeTodayContainer>
                  <Style.HoroscopeTodayTitle>
                    <Text primaryText xl w6 style={{ fontSize: 20 }}>Today’s Matches</Text>
                    <Button><ClockCircleOutlined /></Button>
                  </Style.HoroscopeTodayTitle>
                  <Style.HoroscopeTodayContent>
                    {renderTodayMatches()}
                  </Style.HoroscopeTodayContent>
                </Style.HoroscopeTodayContainer>
              </Col>
              <Col lg={{ span: 24 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <Style.HoroscopeTodayContainer>
                  <Style.HoroscopeTodayTitle>
                    <Text primaryText xl w6 style={{ fontSize: 20 }}>Today’s Moods</Text>
                    <Button><ClockCircleOutlined /></Button>
                  </Style.HoroscopeTodayTitle>
                  <Style.HoroscopeTodayContent>
                    {renderTodayMoods()}
                  </Style.HoroscopeTodayContent>
                </Style.HoroscopeTodayContainer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Style.HoroscopeContainer>
    </>
  );
};

export default HoroscopePage;
