import React, { memo } from 'react'

const MediumNews = memo(props => {
  const { image, category, content, link, time, like, comment, share } = props
  return (
    <div className="height-100">
      <div className="thumbnail" style={{ backgroundImage: `url(${image})` }}></div>
      <p className={`category-tag category-tag_${category} rubikMedium`}>{category}</p>
      <div className="box-text">
        <h4><a href="#" className="rubikMedium">{content}</a></h4>
        <ul className="item-info list-unstyled">
          <li className="link"><a href="www.gazetaexpress.com">{link}</a></li>
          <li className="time">{time}</li>
        </ul>
        <ul className="user-interactive list-unstyled">
          <li className="like"><i className="icon-like"></i>{like}</li>
          <li className="comment"><i className="icon-comment-large"></i>{comment}</li>
          <li className="share"><i className="icon-share"></i>{share}</li>
        </ul>
      </div>
    </div>
  )
})

export default MediumNews
