import React, { useEffect, useState } from 'react';
import Images from '../../../../themes/images';
import './advertisement_styles.scss';
import ReactWOW from 'react-wow';

export const Advertisement = () => {
  return (
    <ReactWOW delay='0.2s' animation='bounceInLeft' duration='1s' >
        <div className="advertisement-container">
        <img src={Images.adsImg001} />
        </div>
    </ReactWOW>
  )
} 

