import React from "react";

import Images from '../../../themes/images'

const RightModule = ({ setRightLayout }) => {
  return (
    <div className="module-right">
      <ul className="list-action-more list-unstyled">
        <li key="e-calendar">
          <a href="#" className="calendar hover_square" onClick={() => setRightLayout({ path: 'agenda-list' })}>
            <img src={Images.iconCalendarModule} alt=""/>
          </a>
        </li>
        <li key="e-security">
          <a href="#" className="security hover_square" onClick={() => setRightLayout({ path: 'contact-list' })}>
            <img src={Images.iconContactModule} alt=""/>
          </a>
        </li>
        <li key="e-happy">
          <a href="#" className="emoji hover_square">
            <i className="icon-happy"></i>
          </a>
        </li>
      </ul>
      <p className="add-widget">
        <a href="#" className="rubikMedium">
          +
        </a>
      </p>
    </div>
  );
};

export default RightModule;