import React, { useEffect, useState } from "react";
import CheckBox from "../../../CheckBox/CheckBox";
import styles from "../CreateFilterForm.module.css";
import classnames from "classnames";

const listCheckBoxLabel = {
  skipInbox: "Skip the inbox (Archive it)",
  markAsRead: "Mark as read",
  star: "Star it",
  applyLabel: "Apply the label",
  forward: "Forward it",
  delete: "Delete it",
  neverSendToSpam: "Never send it to spam",
  markAsImportant: "Always mark it as important",
  neverMarkAsImportant: "Never mark it as important",
  categories: "Categories as: ",
  applyConversation: "Also apply filter to matching conversations",
};

export const CheckBoxItem = ({
  checkBoxKey,
  checked,
  onItemSelect,
  showSelect,
  showCreateNew,
  onChangeOption,
  // onAddOption,
  getListOption,
}) => {
  const [listOption, setListOption] = useState(["Choose one"]);

  useEffect(() => {
    //get list option
  }, []);
  const ChangeOptionHandler = (e) => {
    if (onChangeOption) onChangeOption(e.target.value);
  };

  // const addOptionHandler = () => {
  //   do somthing
  // }

  return (
    <div key={checkBoxKey} className={styles.itemCheck}>
      <div
        className={classnames(
          "arrow-left form-check hover_circle",
          styles.iconCheck
        )}
      >
        <CheckBox
          checked={checked}
          onSelectItem={(e) => onItemSelect(checkBoxKey)}
        />
      </div>
      <span>{listCheckBoxLabel[checkBoxKey]}</span>
      {showSelect && listOption.length !== 0 && (
        <select onChange={ChangeOptionHandler} className={styles.selectLabel}>
          {listOption.map((item) => (
            <option key={item}>{item}</option>
          ))}
        </select>
      )}
      {showCreateNew && <a href="/">Create a new one</a>}
    </div>
  );
};
