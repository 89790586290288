import React from "react";
import { Form, Button, Input, Select, DatePicker } from 'antd';
import { CloseOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import { Text } from '../../../../commons/styles';

import * as Style from '../styles';

const AgendaModify = ({ setRightLayout }) => {
  return (
    <>
      <Style.RightLayoutHeader>
        <Button type="text" icon={<ArrowLeftOutlined />} shape="circle" onClick={() => setRightLayout({ path: 'agenda-detail', params: { id: 1 } })}></Button>
        <Button type="text" icon={<CloseOutlined />} shape="circle" onClick={() => setRightLayout(null)}></Button>
      </Style.RightLayoutHeader>
      <Style.RightLayoutContent>
        <Form
          layout="vertical"
        >
          <Form.Item label={<Text subText xs>NAME</Text>}>
            <Input size="large" placeholder="Start Sneaky Project" />
          </Form.Item>
          <Form.Item label={<Text subText xs>SET DATE</Text>}>
            <DatePicker size="large" placeholder="Set date" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label={<Text subText xs>SET STATUS</Text>} initialValue="public">
            <Select size="large">
              <Select.Option value="public">Public</Select.Option>
              <Select.Option value="private">Private</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" size="large" block>Save</Button>
          </Form.Item>
        </Form>
      </Style.RightLayoutContent>
    </>
  );
};

export default AgendaModify;