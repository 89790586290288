import React, { memo } from 'react'

const ScheduleItem = memo(props => {
  const { from, to, time } = props
  return (
    <div className="location-item box-flex">
      <div className="location-item_icon">
        <i className="icon-bus"></i>
      </div>
      <div className="location-item_address box-flex">
        <p className="from">{from}</p>
        <p className="to rubikMedium">{to}</p>
      </div>
      <div className="location-item_time">{time}</div>
    </div>
  )
})

export default ScheduleItem
