import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Row,
  Col,
  Space,
  Card,
  Form,
  Input,
  Select,
  Button,
  Switch,
  Upload,
} from 'antd';
import { UploadOutlined  } from '@ant-design/icons';
import SunEditor from 'suneditor-react';

import history from '../../../utils/history';
import { decodeHTML } from '../../../utils/common';

import {
  createArticleAdmin as createArticleAdminAction,
  updateArticleAdmin as updateArticleAdminAction,
} from '../../../redux/actions';

const ModifyArticle = ({
  createArticleAdmin,
  updateArticleAdmin,
  match,
  articleList,
}) => {
  const [modifyArticleForm] = Form.useForm();

  const articleId = match.params.id;
  const articleData = articleList.data.find(item => item.id === articleId);

  const modifyArticleInitialValues = articleData
    ? {
      ...articleData,
      thumbnail: [
        {
          uid: `thumbnail-${articleData.id}`,
          name: "thumbnail.jpg",
          type: 'image/jpeg',
          thumbUrl: articleData.thumbnail,
        }
      ],
      content: decodeHTML(articleData.content),
    }
    : {
      is_trending: true,
    };

  useEffect(() => {
    modifyArticleForm.resetFields();
  }, [articleId])

  function onSubmitForm(values) {
    const newValues = {
      title: values.title.trim(),
      meta_keyword: values.meta_keyword.trim(),
      meta_description: values.meta_description.trim(),
      type: values.type,
      slug: values.slug.trim(),
      is_trending: values.is_trending ? 1 : 0,
      thumbnail: values.thumbnail[0].thumbUrl,
      content: values.content.trim(),
    };
    if (articleId) {
      return updateArticleAdmin({ path: { id: articleId }, data: newValues });
    }
    return createArticleAdmin({ data: newValues });
  }

  if (articleId && !articleData) return <Redirect to="/admin/articles" />;
  return (
    <Card title="Modify Article">
      <Form
        name="modifyArticleForm"
        form={modifyArticleForm}
        initialValues={modifyArticleInitialValues}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        colon={false}
        onFinish={(values) => onSubmitForm(values)}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, whitespace: true, message: 'Title is required!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="meta_keyword"
          label="Meta Keyword"
          rules={[{ required: true, whitespace: true, message: 'Meta keyword is required!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="meta_description"
          label="Meta Description"
          rules={[{ required: true, whitespace: true, message: 'Meta description is required!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
        >
          <Select allowClear>
            <Select.Option value="related">Related</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="slug"
          label="Slug"
          rules={[{ required: true, whitespace: true, message: 'Slug is required!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="is_trending"
          label="Is trending"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="thumbnail"
          label="Thumbnail"
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) return e;
            return e && e.fileList;
          }}
          validateFirst
          rules={[
            { required: true, message: 'Thumbnail is required!' },
            () => ({
              validator(_, value) {
                if (!['image/png', 'image/jpeg'].includes(value[0].type)) {
                  return Promise.reject('The file is not in the correct format!');
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            listType="picture"
            maxCount={1}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="content"
          label="Content"
          rules={[{ required: true, whitespace: true, message: 'Content is required!' }]}
        >
          <div>
            <SunEditor
              defaultValue={articleData ? decodeHTML(articleData.content) : ''}
              onChange={(value) => modifyArticleForm.setFieldsValue({ content: value })}
              autoFocus={false}
              height={300}
            />
          </div>
        </Form.Item>
        <Row>
          <Col span={4}></Col>
          <Col span={20}>
            <Space size={24}>
              <Space>
                <Button type="primary" htmlType="submit">Submit</Button>
                <Button
                  type="primary"
                  ghost
                  htmlType="reset"
                >
                  Reset
                </Button>
              </Space>
              <Button type="link" onClick={() => history.goBack()}>Cancel</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

const mapStateToProps = (state) => {
  const { articleList } = state.adminArticle;
  return {
    articleList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createArticleAdmin: (data) => dispatch(createArticleAdminAction(data)),
    updateArticleAdmin: (data) => dispatch(updateArticleAdminAction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifyArticle);
