import React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Menu } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

import { logout as logoutAction } from '../../../../redux/actions';

import defaultAvatar from '../../../../assets/images/default-avatar.png';

import * as Style from '../styles';

const AdminHeader = ({
  setIsShowSidebar,
  isShowSidebar,
  logout,
  userInfo,
}) => {
  function renderUserMenu() {
    return (
      <Menu style={{ width: 160 }}>
        <Menu.Item key="Profile">
          Profile
        </Menu.Item>
        <Menu.Item key="logout" onClick={() => logout()}>Logout</Menu.Item>
      </Menu>
    )
  }

  return (
    <Style.HeaderContainer>
      {isShowSidebar
        ? <MenuFoldOutlined onClick={() => setIsShowSidebar(!isShowSidebar)} style={{ fontSize: 20 }} />
        : <MenuUnfoldOutlined onClick={() => setIsShowSidebar(!isShowSidebar)} style={{ fontSize: 20 }} />
      }
      <Dropdown overlay={renderUserMenu()} trigger={['click']} placement="bottomRight">
        {/* UPDATE_LATE */}
        <Button type="link" style={{ padding: 0 }}>
          <img 
            className="avatar-user-info" 
            src={userInfo.avatar || defaultAvatar}
            onClick={(e) => e.preventDefault()}
            alt=""
          />
        </Button>
      </Dropdown>
    </Style.HeaderContainer>
  );
};

const mapStateToProp = (state) => {
  const { userInfo } = state.auth;
  return {
    userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logoutAction()),
  };
};

export default connect(mapStateToProp, mapDispatchToProps)(AdminHeader);
