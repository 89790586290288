export const ROUTES = {
  USER: {
    HOME: '/',
    MAIL: '/mail',
  },
  ADMIN: {
    DASHBOARD: '/admin',
    ARTICLE_LIST: '/admin/articles',
    CREATE_ARTICLE: '/admin/article/new',
    EDIT_ARTICLE: '/admin/article/:id',
  },
}
