import styled, { css } from 'styled-components';
import { Tag } from 'antd';

export const MainContainer = styled.div`
  display: flex;
`;

export const MainContent = styled.div`
  margin-top: 75px;
  width: 100%;
  
  ${(props) => props.showRightLayout
    && css`
      width: calc(100% - 280px);
    `}
`;

export const TopMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 16px;
  min-height: 56px;
  border-bottom: 1px solid #F1F1F5;
`;

export const TopMenuSeparationLine  = styled.div`
  width: 1px;
  height: 24px;
  background-color: #F1F1F5;
`;

export const TargetEmailInputContainer = styled.div`
  position: relative;
  padding-bottom: 4px;
  border-bottom: 1px solid #F1F1F5;
`;

export const TargetEmailInputContent = styled.div`
  display: flex;
  padding-bottom: 4px;
  width: calc(100% - 72px); //Cc vs Bcc button

  ${(props) => props.showFull
    && css`
      width: 100%;
    `}
`;

export const TargetEmailTag = styled(Tag)`
  display: flex;
  align-items: center;
  height: 24px;
  font-size: 13px;

  & .ant-tag-close-icon {
    font-size: 14px !important;
  }

  ${(props) => props.invalidMail
    && css`
      border: none;
      background-color: ${(props) => props.theme.colors.error};
      color: white;

      & .ant-tag-close-icon {
        color: white;
      }
    `}
`;

export const SubTargetEmailButton = styled.div`
  position: absolute;
  bottom: 4px;
  right: 0;
`;

export const SubjectEmailInputContainer = styled.div`
  padding: 8px 0 6px;
  border-bottom: 1px solid #F1F1F5;

  & > input {
    padding: 4px 8px;
  }
`;

export const NoEmailResultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
`;
