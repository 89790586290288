import { fork } from 'redux-saga/effects';
import authSaga from './auth.saga';
import mailSaga from './user/mail.saga';
import articleSaga from './user/article.saga';

import adminArticleSaga from './admin/article.saga';

export default function* rootSaga() {
  yield fork(authSaga);
  yield fork(mailSaga);
  yield fork(articleSaga);

  yield fork(adminArticleSaga);
}
