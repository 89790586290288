import React from "react";
import { Button, Tabs, Space } from 'antd';
import { CloseOutlined, PlusOutlined, ArrowLeftOutlined, PaperClipOutlined, FilePdfOutlined, PictureOutlined } from '@ant-design/icons';

import { Text } from '../../../../commons/styles';

import * as Style from '../styles';

const CONTACT_HISTORIES = [
  {
    name: 'Pol Soria',
    title: 'Phuk Redesign Project',
    content: 'Hey Cak, Could you free now? Can you look and read the brief first before you decide and…',
    time: '7:15 AM',
    attach: true,
    type: 'mail',
  },
  {
    name: 'Pol Soria',
    title: 'Apps Sneaky',
    content: 'Hey Cak, Could you free now? Can you look and read the brief first before you decide and…',
    time: '7:15 AM',
    attach: true,
    type: 'mail',
  },
  {
    name: 'Pol Soria',
    title: 'Freelance Inquiry',
    content: 'Hey Cak, Could you free now? Can you look and read the brief first before you decide and…',
    time: '7:15 AM',
    attach: true,
    type: 'mail',
  },
  {
    name: 'Pol Soria',
    title: 'Brief Project 01',
    time: 'Yesterday',
    attach: true,
    type: 'pdf',
    image: 'https://via.placeholder.com/280x164',
    openAt: '4.14PM',
    size: '176Kb',
  },
  {
    name: 'Pol Soria',
    title: 'Image-001',
    time: 'Yesterday',
    attach: true,
    type: 'image',
    image: 'https://via.placeholder.com/280x164',
    openAt: '4.14PM',
    size: '1.4Mb',
  },
]

const ContactDetail = ({ setRightLayout }) => {
  const ATTACH_ICON = {
    pdf: <FilePdfOutlined style={{ color: '#4DC278' }} />,
    image: <PictureOutlined style={{ color: '#FFC20D' }} />
  }

  function renderContactHistories() {
    return CONTACT_HISTORIES.map((historyItem, historyIndex) => {
      if (historyItem.type !== 'mail') {
        return (
          <Style.ContactHistoryThumbnail thumbnail={historyItem.image}>
            <Style.ContactHistoryAttachInfo>
              <Space size={8} style={{ marginBottom: 6 }}>
                {ATTACH_ICON[historyItem.type]}
                <Text primaryText>{historyItem.title}</Text>
              </Space>
              <Text secondaryText xxs>{`${historyItem.size} | ${historyItem.time} | Opened today at ${historyItem.openAt}`}</Text>
            </Style.ContactHistoryAttachInfo>
          </Style.ContactHistoryThumbnail>
        )
      }
      return (
        <Style.ContactHistoryContainer>
          <Style.ContactHistoryWrapper>
            <Text primaryColor xs>{historyItem.name}</Text>
            <Text subText xxs>{historyItem.time}</Text>
          </Style.ContactHistoryWrapper>
          <Style.ContactHistoryWrapper style={{ margin: '6px 0 12px' }}>
            <Text primaryText>{historyItem.name}</Text>
            <PaperClipOutlined />
          </Style.ContactHistoryWrapper>
          <Text secondary xxs truncateMultiLine={2}>{historyItem.content}</Text>
        </Style.ContactHistoryContainer>
      )
    })
  }

  return (
    <>
      <Style.RightLayoutHeader>
        <Space>
          <Button type="text" icon={<ArrowLeftOutlined />} shape="circle" onClick={() => setRightLayout({ path: 'contact-list' })}></Button>
          <Text primaryText lg w6>Contact Info</Text>
        </Space>
        <Button type="text" icon={<CloseOutlined />} shape="circle" onClick={() => setRightLayout(null)}></Button>
      </Style.RightLayoutHeader>
      <Style.ContactDetailInfo>
        <Style.ContactDetailAvatar image="https://via.placeholder.com/60" />
        <Style.ContactDetailContent>
          <Space size={8}>
            <Text primaryText sm w6>Pol Soria</Text>
            <Text subText>|</Text>
            <Text subText xxs>3 Same</Text>
          </Space>
          <Text subText xs>polpolpolll@mail.com</Text>
          <Button type="link" icon={<PlusOutlined />} size="small" style={{ marginTop: 8, fontSize: 12 }}>
            Add New Contacts
          </Button>
        </Style.ContactDetailContent>
      </Style.ContactDetailInfo>
      <Style.ContactListTabs>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="All" key="1">
            {renderContactHistories()}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Email" key="2">
            Content of Tab Pane 2
          </Tabs.TabPane>
          <Tabs.TabPane tab="Media" key="3">
            Content of Tab Pane 3
          </Tabs.TabPane>
        </Tabs>
      </Style.ContactListTabs>
    </>
  );
};

export default ContactDetail;