import React from 'react'
import ReactDOM from 'react-dom'
import { cx, css } from 'emotion'
import styled from 'styled-components';
import { useFocused, useSelected } from 'slate-react';

import Images from '../../themes/images';

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      ...props
    },
    ref,
  ) => (
    <span
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          cursor: pointer;
          user-select: none;
          margin: 0 6px;
          opacity: ${active ? 1 : .55};
          color: ${active
            ? '#444'
            : '#222'};
          &:hover {
            opacity: 1;
            color: #444;
            background: rgba(32,33,36,0.059);
          }
        `
      )}
    />
  )
)

export const EditorValue = React.forwardRef(
  (
    {
      className,
      value,
      ...props
    },
    ref,
  ) => {
    const textLines = value.document.nodes
      .map(node => node.text)
      .toArray()
      .join('\n')
    return (
      <div
        ref={ref}
        {...props}
        className={cx(
          className,
          css`
            margin: 30px -20px 0;
          `
        )}
      >
        <div
          className={css`
            font-size: 14px;
            padding: 5px 20px;
            color: #404040;
            border-top: 2px solid #eeeeee;
            background: #f8f8f8;
          `}
        >
          Slate's value as text
        </div>
        <div
          className={css`
            color: #404040;
            font: 12px monospace;
            white-space: pre-wrap;
            padding: 10px 20px;
            div {
              margin: 0 0 0.5em;
            }
          `}
        >
          {textLines}
        </div>
      </div>
    )
  }
)

export const Icon = React.forwardRef(
  (
    { className, ...props },
    ref,
  ) => (
    <span
      {...props}
      ref={ref}
      className={cx(
        'material-icons',
        className,
        css`
          font-size: 18px;
          user-select: none;
          vertical-align: text-bottom;
        `
      )}
    />
  )
)

export const Instruction = React.forwardRef(
  (
    { className, ...props },
    ref,
  ) => (
    <div
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          white-space: pre-wrap;
          margin: 0 -20px 10px;
          padding: 10px 20px;
          font-size: 14px;
          background: #f8f8e8;
        `
      )}
    />
  )
)

export const Menu = React.forwardRef(
  (
    { className, ...props },
    ref,
  ) => (
    <div
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          & > * {
            display: inline-block;
          }
        `
      )}
    />
  )
)

export const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}

export const Toolbar = React.forwardRef(
  (
    { className, ...props },
    ref,
  ) => (
    <Menu
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          position: absolute;
          bottom: 80px;
          left: 16px;
          z-index: 10;
        `
      )}
    />
  )
)

export const ImageElement = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div {...attributes}>
      {children}
      <img
        src={element.url}
        className={css`
          display: block;
          max-width: 100%;
          max-height: 20em;
          box-shadow: ${selected && focused ? '0 0 0 2px blue;' : 'none'};
        `}
        alt='imgelement'
      />
    </div>
  )
}

export const FontSizeMenu = styled.div`
  user-select: none;
  padding-bottom: 6px;
  padding-top: 6px;
  color: #202124;
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  background: white;
  z-index: 10;

  &:hover {
    background: rgba(32,33,36,0.059);
  }
`;

const renderFontSize = format => {
  switch (format) {
    case 'small':
      return 'x-small';
    case 'normal':
      return 'small';
    case 'large':
      return 'large';
    case 'very-large':
      return 'xx-large';
    default:
      return 'small'
  }
}

export const FontSizeItem = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  font-size: ${p => renderFontSize(p.format)};
`;

export const Active = styled.div`
  user-select: none;
  background-image: url(${Images.checkBlack});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  display: block;
  height: 20px;
  margin-left: -28px;
  position: absolute;
  width: 20px;
  margin-right: 12px;
  position: absolute;
  right: 100%;
`;

export const ToolBarWrapper = styled.div`
  align-items: center;
  border: none;
  border-radius: 2px;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);
  display: inline-flex !important;
  margin: 0;
  padding: 8px 2px;
  white-space: nowrap;
`;

export const Spliter = styled.div`
  margin: 0 2px;
  border-left: 1px solid rgba(100,121,143,0.122);
  height: 20px;
  line-height: normal;
  list-style: none;
  outline: none;
  overflow: hidden;
  padding: 0;
  text-decoration: none;
  vertical-align: middle;
  width: 0;
`;