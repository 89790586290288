import Images from './images'

export {
  Images
}

export default {
  breakpoints: {
    xs: '0',
    sm: '400px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1920px',
  },
  colors: {
    primary: 'rgb(255, 87, 13)', // #FF570D
    primary125: '#CE460A',
    primary75: 'rgba(255, 87, 13, 0.75)',
    primary50: 'rgba(255, 87, 13, 0.5)',
    primary25: 'rgba(255, 87, 13, 0.25)',
    primaryText: '#344563',
    secondaryText: '#696974',
    headerText: '#171725',
    subText: '#92929D',
    primaryIcon: '#CCCCCC',
    primaryBackground: '#F8F9FF',
    secondaryBackground: '#FAFAFA',
    tertiaryBackground: '#F6F6F6',
    primaryBorder: '#F5F5F7',
    secondaryBorder: '#EEEEEE',
    tertiaryBorder: '#999999',

    success: '#5FC73A',
    error: '#D25109',
    warning: '#F2994A',
    info: '#36ADD3',
  },
  fontWeight: {
    w3: '300',
    w6: '600',
  },
  fontSize: {
    xxxs: '8px',
    xxs: '11px',
    xs: '12px',
    sm: '13px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    xxl: '24px',
    xxxl: '36px',
    // h3: '',
    // h2: '',
    // h1: '',
  },
  lineHeight: {
    xxs: '16px',
    xs: '17px',
    sm: '19px',
    md: '22px',
    lg: '24px',
    xl: '26px',
    xxl: '32px',
    xxxl: '42px',
    // h3: '',
    // h2: '',
    // h1: '',
  },
  radius: {
    sm: '4px',
    md: '5px',
    lg: '6px',
    xl: '8px',
    xxl: '10px',
    circle: '999px',
  },
  shadow: {
    sm: '',
    md: '2px 5px 45px rgba(146, 146, 157, 0.1)',
    lg: '-8px 4px 14px rgba(47, 61, 89, 0.1)',
  },
};
