import React from "react";
import moment from "moment";
import ReactWOW from "react-wow";
import { SocialIcons } from "./../SocialIcons/SocialIcons";

import { decodeHTML } from '../../../../utils/common';

export const Item = ({ articleItem }) => {
  const HeaderItem = () => {
    return (
      <div className="article-header">
        <div className="a-title">{articleItem.title}</div>
        <div className="a-info">
          <span className="a-from">From</span>
          <span className="a-author">{articleItem.user?.username}</span>
          <span className="a-time">
            {moment(articleItem.created_at).fromNow()}
          </span>
        </div>
      </div>
    );
  };

  const ImageItem = () => {
    return (
      <div className="article-image">
        <div className="a-img">
          <img src={articleItem.thumbnail} alt="" />
        </div>
        <div className="a-img-name">{articleItem.meta_description}</div>
      </div>
    );
  };

  const ContentItem = () => {
    return (
      <div
        className="article-content"
        dangerouslySetInnerHTML={{
          __html: decodeHTML(articleItem.content),
        }}
      ></div>
    );
  };

  const FooterItem = () => {
    return (
      <div className="article-footer">
        <SocialIcons />
      </div>
    );
  };

  return (
    <ReactWOW delay="0s" animation="bounceInRight" duration="1s">
      <div className="article-body">
        <HeaderItem />
        <ImageItem />
        <ContentItem />
        <FooterItem />
      </div>
    </ReactWOW>
  );
};
