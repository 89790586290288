import styled from 'styled-components';
import Image from '../../themes/images';

export const LoginContainer = styled.div`
  display: flex;
  height: 100vh;

  @media (max-width: 992px) {
    flex-direction: column;
    height: auto;
  }
`;

export const LoginImage = styled.div`
  width: 50%;
  height: 100%;
  background-image: url(${Image.loginBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  overflow: hidden;
  
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const LoginBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 54, 67, 0.8);

  & > img {
    padding: 0 16px;
  }

  @media (max-width: 992px) {
    padding: 56px 0;
    
    & > img {
      max-width: 500px;
      height: auto;
    }
  }
`;

export const LoginContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;

  @media (max-width: 992px) {
    padding: 56px 0;
    width: 100%;
  }
`;

export const LoginIntroduce = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  max-width: 532px;
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 450px;

  & > img {
    width: 88px;
    height: auto;
  }
`;

export const LoginFormContainer = styled.div`
  margin: 48px 0 56px;
  padding: 30px;
  width: 100%;
  border: 1px solid #F1F1F5;
  box-shadow: ${(props) => props.theme.shadow.md};
  border-radius: ${(props) => props.theme.radius.lg};
`;

export const RedirectLink = styled.span`
  color: ${(props) => props.theme.colors.primary};
  font-weight: ${(props) => props.theme.fontWeight.w6};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.primary75};
  }
`;
