import { all, put, takeLatest } from 'redux-saga/effects';
import {
  USER,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constants';
import { articleServices } from '../../../services';

function* getArticleListSaga(action) {
  try {
    const result = yield articleServices.getArticleList(action.payload);
    if (result.data && !result.error) {
      yield put({
        type: SUCCESS(USER.ARTICLE_ACTION.GET_LIST),
        payload: result,
      });
    } else {
      yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_LIST), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_LIST), errors: e.errors });
  }
}

function* getArticleDetailSaga(action) {
  try {
    const result = yield articleServices.getArticleDetail(action.payload);
    if (result.data && !result.error) {
      yield put({
        type: SUCCESS(USER.ARTICLE_ACTION.GET_DETAIL),
        payload: result,
      });
    } else {
      yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_DETAIL), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_DETAIL), errors: e.errors });
  }
}

function* getArticleRelatedSaga(action) {
  try {
    const result = yield articleServices.getArticleRelated(action.payload);
    if (result.data && !result.error) {
      yield put({
        type: SUCCESS(USER.ARTICLE_ACTION.GET_RELATED),
        payload: result,
      });
    } else {
      yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_RELATED), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_RELATED), errors: e.errors });
  }
}

function* getArticleNewTrendingSaga(action) {
  try {
    const result = yield articleServices.getArticleNewTrending(action.payload);
    if (result.data && !result.error) {
      yield put({
        type: SUCCESS(USER.ARTICLE_ACTION.GET_NEW_TRENDING),
        payload: result,
      });
    } else {
      yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_NEW_TRENDING), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_NEW_TRENDING), errors: e.errors });
  }
}

function* getArticleMostReactionSaga(action) {
  try {
    const result = yield articleServices.getArticleMostReaction(action.payload);
    if (result.data && !result.error) {
      yield put({
        type: SUCCESS(USER.ARTICLE_ACTION.GET_MOST_REACTION),
        payload: result,
      });
    } else {
      yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_MOST_REACTION), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_MOST_REACTION), errors: e.errors });
  }
}

function* getArticleLikesSaga(action) {
  try {
    const result = yield articleServices.getArticleLikes(action.payload);
    if (result.data && !result.error) {
      yield put({
        type: SUCCESS(USER.ARTICLE_ACTION.GET_LIKES),
        payload: result,
      });
    } else {
      yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_LIKES), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_LIKES), errors: e.errors });
  }
}

function* getArticleCommentsSaga(action) {
  try {
    const result = yield articleServices.getArticleComments(action.payload);
    if (result.data && !result.error) {
      yield put({
        type: SUCCESS(USER.ARTICLE_ACTION.GET_COMMENTS),
        payload: result,
      });
    } else {
      yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_COMMENTS), errors: result.error });
    }
  } catch (e) {
    yield put({ type: FAILURE(USER.ARTICLE_ACTION.GET_COMMENTS), errors: e.errors });
  }
}

export default function* articleSaga() {
  yield all([
    takeLatest(REQUEST(USER.ARTICLE_ACTION.GET_LIST), getArticleListSaga),
    takeLatest(REQUEST(USER.ARTICLE_ACTION.GET_DETAIL), getArticleDetailSaga),
    takeLatest(REQUEST(USER.ARTICLE_ACTION.GET_RELATED), getArticleRelatedSaga),
    takeLatest(REQUEST(USER.ARTICLE_ACTION.GET_NEW_TRENDING), getArticleNewTrendingSaga),
    takeLatest(REQUEST(USER.ARTICLE_ACTION.GET_MOST_REACTION), getArticleMostReactionSaga),
    takeLatest(REQUEST(USER.ARTICLE_ACTION.GET_LIKES), getArticleLikesSaga),
    takeLatest(REQUEST(USER.ARTICLE_ACTION.GET_COMMENTS), getArticleCommentsSaga),
  ]);
}
