import React, { useEffect, useState } from "react";
import styles from "../NewsStyles.module.css";
import { RelatedArticleItem } from "./RelatedArticleItem/RelatedArticleItem";
import { ProgressBar } from "react-bootstrap";
import ReactWOW from "react-wow";
import { connect } from "react-redux";

const RelatedArticles = ({ articleRelated }) => {
  function renderRelatedItems() {
    return articleRelated.items.map((relatedItem, relatedIndex) => {
      return (
        <RelatedArticleItem key={`related-item-${relatedIndex}`} relatedItem={relatedItem} />
      )
    })
  }

  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   props.onFetchRelateArticle();
  // }, []);

  // useEffect(() => {
  //   const { loading, items } = props.relateArticle;
  //   if (loading) {
  //     api.listRelateArticle().then((response) => {
  //       if (response && !response.data.error) {
  //         props.onUpdateRelateArticles(response.data.data);
  //       }
  //     });
  //   } else {
  //     let relateArticles = items.map((item) => {
  //       return {
  //         id: item.id,
  //         title: item.title,
  //         company: "CNN",
  //         authors: ["Lauren Fox", "Manu Raju", "Jeremy Herb"],
  //       };
  //     });
  //     setData(relateArticles);
  //   }
  // }, [props.relateArticle.loading]);

  return (
    <ReactWOW delay="0.2s" animation="bounceInLeft" duration="1s">
      <div className={styles.relatedArticles}>
        <ProgressBar
          now={10}
          className={styles.progressBar}
          bsPrefix={`${styles.bsProgressBar} progress`}
        />
        <h3 className={styles.relatedArticlesTittle}>Related Articles</h3>
        {renderRelatedItems()}
      </div>
    </ReactWOW>
  );
};

const mapStateToProp = (state) => {
  return {
    relateArticle: state.relateArticle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProp, mapDispatchToProps)(RelatedArticles);
