import React, { useState } from 'react';
import { Row, Col, Button, Calendar, Input, Collapse, Checkbox, Space } from 'antd';
import { PlusOutlined, DownOutlined, UpOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Text, CustomCheckbox } from '../../../commons/styles';

import * as Style from '../styles';

const PROGRAM_TYPE_OPTION = [
  { label: 'Cartoon', value: '1', color: '#52C17B' },
  { label: 'News & Info', value: '2', color: '#0DB7FF' },
  { label: 'Drama & Film', value: '3', color: '#785ADD' },
  { label: 'Talkshow', value: '4', color: '#EF547B' },
  { label: 'Sport', value: '5', color: '#F89E23' },
];

const ANOTHER_TYPE_OPTION = [
  { label: 'Premium Program', value: '6', color: '#3484F9' },
  { label: 'Exclusive Program', value: '7', color: '#24756A' },
];

const TVProgramSidebar = ({
  newsItem
}) => {
  const [programTypeCollapseKeys, setProgramTypeCollapseKeys] = useState(['1']);
  const [anotherTypeCollapseKeys, setAnotherTypeCollapseKeys] = useState(['1']);

  function onChangeDate(value, mode) {
    console.log(value, mode);
  }

  function renderProgramType() {
    return PROGRAM_TYPE_OPTION.map((item, index) => {
      return (
        <Col span={24}>
          <CustomCheckbox value={item.value} color={item.color}>
            <Text primaryText>{item.label}</Text>
          </CustomCheckbox>
        </Col>
      )
    })
  }

  function renderAnotherType() {
    return ANOTHER_TYPE_OPTION.map((item, index) => {
      return (
        <Col span={24}>
          <CustomCheckbox value={item.value} color={item.color}>
            <Text primaryText>{item.label}</Text>
          </CustomCheckbox>
        </Col>
      )
    })
  }

  return (
    <Style.SidebarContainer>
      <Style.AddTVProgramContainer>
        <Style.AddTVProgramButton
          type="text"
          size="large"
          shape="round"
          icon={<PlusOutlined style={{ fontSize: 16, color: '#FF570D' }} />}
        >
          Add City/Place
        </Style.AddTVProgramButton>
      </Style.AddTVProgramContainer>
      <Style.SidebarCalendar>
        <Style.SidebarCalendarHeader>
          <Space size={4}>
            <Button type="text" shape="circle" size="small">
              <LeftOutlined />
            </Button>
            <Text primaryText w6>February 2020</Text>
            <Button type="text" shape="circle" size="small">
              <RightOutlined />
            </Button>
          </Space>
          <Button size="small" style={{ borderRadius: 5 }}>Today</Button>
        </Style.SidebarCalendarHeader>
        <Calendar
          fullscreen={false}
          onPanelChange={(value, mode) => onChangeDate(value, mode)}
          headerRender={() => null}
        />
      </Style.SidebarCalendar>
      <Text primaryText lg w6 style={{ marginBottom: 8 }}>Quick Search</Text>
      <Input placeholder="Search here…" style={{ height: 36, borderRadius: 6 ,background: '#FAFAFA' }} />
      <Style.SidebarFilter>
        <Collapse
          activeKey={[...programTypeCollapseKeys]}
          ghost
          onChange={(keys) => setProgramTypeCollapseKeys([...keys])}
        >
          <Collapse.Panel
            header={
              <Style.SidebarFilterHeader>
                <Space size={10}>
                  <Text primaryText lg w6 inline>Program Type</Text>
                  {programTypeCollapseKeys.includes('1')
                    ? <UpOutlined style={{ color: '#92929D' }} />
                    : <DownOutlined style={{ color: '#92929D' }} />
                  }
                </Space>
                <Button type="text" shape="circle"><PlusOutlined style={{ color: '#FF570D' }} /></Button>
              </Style.SidebarFilterHeader>
            }
            showArrow={false}
            key="1"
          >
            <Checkbox.Group onChange={() => null}>
              <Row>
                {renderProgramType()}
              </Row>
            </Checkbox.Group>
          </Collapse.Panel>
        </Collapse>
      </Style.SidebarFilter>
      <Style.SidebarFilter>
        <Collapse
          activeKey={[...anotherTypeCollapseKeys]}
          ghost
          onChange={(keys) => setAnotherTypeCollapseKeys([...keys])}
        >
          <Collapse.Panel
            header={
              <Style.SidebarFilterHeader>
                <Space size={10}>
                  <Text primaryText lg w6 inline>Another Program</Text>
                  {anotherTypeCollapseKeys.includes('1')
                    ? <UpOutlined style={{ color: '#92929D' }} />
                    : <DownOutlined style={{ color: '#92929D' }} />
                  }
                </Space>
                <Button type="text" shape="circle"><PlusOutlined style={{ color: '#FF570D' }} /></Button>
              </Style.SidebarFilterHeader>
            }
            showArrow={false}
            key="1"
          >
            <Checkbox.Group onChange={() => null}>
              <Row>
                {renderAnotherType()}
              </Row>
            </Checkbox.Group>
          </Collapse.Panel>
        </Collapse>
      </Style.SidebarFilter>
    </Style.SidebarContainer>
  );
};

export default TVProgramSidebar;
