import styled from 'styled-components';

export const RegisterContainer = styled.div`
  display: flex;
  height: 100vh;

  @media (max-width: 992px) {
    flex-direction: column;
    height: auto;
    background-color: #F5F5F7;
  }
`;

export const RegisterBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  border-radius: 15px;
  background-color: #F5F5F7;
  overflow: hidden;

  @media (max-width: 992px) {
    width: 100%;

    & > img {
      max-width: 500px;
      height: auto;
    }
  }
`;

export const RegisterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  
  @media (max-width: 992px) {
    padding: 56px 0;
    width: 100%;
  }
`;

export const RegisterIntroduce = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  max-width: 600px;
`;

export const RegisterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 450px;

  & > img {
    width: 88px;
    height: auto;
  }
`;

export const RegisterFormContainer = styled.div`
  margin: 48px 0 56px;
  padding: 30px;
  width: 100%;
  border: 1px solid #F1F1F5;
  box-shadow: ${(props) => props.theme.shadow.md};
  border-radius: ${(props) => props.theme.radius.lg};
  background-color: white;
`;

export const RedirectLink = styled.span`
  color: ${(props) => props.theme.colors.primary};
  font-weight: ${(props) => props.theme.fontWeight.w6};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.primary75};
  }
`;

export const OvalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: ${(props) => props.theme.radius.circle};
  background-color: ${(props) => props.theme.colors.primary};

  & > img {
    width: auto;
    height: auto;
  }
`;
