import styled from 'styled-components';
import { Checkbox } from 'antd';

export const CustomCheckbox = styled(Checkbox)`
  & .ant-checkbox-inner {
    border-radius: ${(props) => props.theme.radius.sm};
  }

  &:hover .ant-checkbox-inner {
    border-color: ${(props) => props.color || props.theme.colors.primaryText};
  }

  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props) => props.color || props.theme.colors.primaryText};
  }
  
  & .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${(props) => props.color || props.theme.colors.primaryText};
    background-color: ${(props) => props.color || props.theme.colors.primaryText};
  }

  & .ant-checkbox-checked::after {
    border-radius: ${(props) => props.theme.radius.sm};
    border-color: ${(props) => props.color || props.theme.colors.primaryText};
  }
`;
