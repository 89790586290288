import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';

import { LoginStorage } from '../../../utils/localStorge';

const AuthLayout = ({
  component: Component,
  path,
  ...rest
}) => {
  const userStorage = LoginStorage.getData();

  if (isEmpty(userStorage)) {
    return <Redirect to={{ pathname: '/login', state: { previousPage: path } }}  />;
  }
  return (
    <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
  );
};

export default AuthLayout;
