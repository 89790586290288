import React from "react";
import classnames from 'classnames';
import styles from "../SearchInput/SearchInput.module.css";

const SearchFilterDateWithin = ({ value, onChange }) => {
  return  (
    <div className={styles.inputWrapper}>
      <div className={styles.inputLabel}>Date Within</div>
      <select name="dateWithin" style={{backgroundPositionX: "97%"}} className={classnames(styles.input, styles.flex3dot8, styles.selectDropdownIcon)}>
        <option value="0">1 day</option>
        <option value="1">3 days</option>
        <option value="2">1 week</option>
        <option value="3">2 weeks</option>
        <option value="4">1 month</option>
        <option value="5">2 months</option>
        <option value="6">6 months</option>
        <option value="7">1 year</option>
      </select>
      <div className={styles.flex0dot2}></div>
      <input className={classnames(styles.input, styles.flex3dot8)} value={value} onChange={onChange} type="date" />
    </div>
  );
}

export default SearchFilterDateWithin;
